/* CUSTOM STYLES ---------------*/

[hidden] {
    display: none;
}

* {
    margin: 0;
}

.noLink {
    cursor: default;
}

.skip-nav {
    position: fixed;
    top: -40px;
    left: 0;
    background: var(--black-color);
    color: var(--white-color);
    padding: 8px;
    z-index: 99999;
    transition: all 0.3s;
    opacity: 0;
}

.skip-nav:focus,
.skip-nav:active {
    background: var(--black-color);
    color: var(--white-color);
    top: 0;
    opacity: 1;
}

:focus-visible,
a:focus-visible,
input:focus-visible,
select:focus-visible,
textarea:focus-visible,
button:focus-visible,
.department-title:focus-visible,
.styled-form .form-control:focus-visible,
.panel-collapse:focus-visible {
    outline: 2px solid var(--blue-color);
    outline-offset: 2px;
}

.department-title:focus-visible,
.panel-collapse:focus-visible {
    outline-offset: -4px;
}

.btn:focus-visible,
.blue-btn:focus-visible {
    outline-color: var(--black-color);
}

html,
body {
    height: 100%;

}

.securly-free,
address .address-item.securly-free,
.main-nav li.securly-free,
.on-boarding .box .questions.securly-free,
.link-list li.securly-free {
    display: none;
}

.securly-free-pages .sign-up-btn.btn-grey {
    background-color: #4C5C62;
    /* background-color: #2ac1e1;    */
    /*  padding: 11px 27px 9px !important;
    border-radius: 4px; */
}

/*.securly-free-pages .sign-up-btn.btn-grey:hover {
    color: #253136 !important;
    background-color: #f8f8f8 !important;
    }*/

.securly-free-pages .btn-hero.big-grey-button {
    background-color: #4C5C62;
    /*  border-radius: 4px;
    padding: 15px 74px; */
}

.securly-free-pages .btn-hero.big-grey-button:hover,
.securly-free-pages .btn-hero.big-grey-button:focus {
    background-color: #253136;
}

/*HERO CONTAINER*/

.hero-container {
    height: calc(110vh - 68px);
    display: block;
    z-index: 2;
    max-height: 1000px;
}

.hero-title {
    margin-top: 4vh;
}

.not-us label {
    font-size: 13px !important;
}

.wrapper {
    background: #ebebeb;
    min-height: 100%;
    margin: 0 auto -202px;
}

/*
footer,
.push {
    height: 202px;
}
*/

.privacy-seals img {
    width: 75px !important;
    min-height: 75px !important;
    margin-top: 10px;
    margin-right: 20px;
}

/*FEATURE PAGES*/

.feature-header {
    padding-top: 70px;
    width: 100%;
    padding-bottom: 0px;
    height: 250px;
    background: #C6B3A0;
}

.feature-title h1 {
    margin-top: 140px;
    /*color: var(--white-color);*/
}

.sky-blue {
    background: #b1d7ff;
    background: linear-gradient(#b1d7ff, #f0faff);
}

.cotton-candy {
    background: #d6f0ff;
    background: linear-gradient(#d6f0ff, #f9d9dd);
}

.sunset {
    background: #dad5f8;
    background: linear-gradient(#fae6eb, #dad5f8);
}

.night-sky {
    background: #484963;
    background: linear-gradient(#484963, #484963);
}


.features .container:nth-of-type(even) {
    /*    background: #F8F8F8;
        margin: 0px;
        width: 100%;*/
    padding-top: 20px;
    padding-bottom: 20px;
}

.features .container:nth-of-type(even) .row {
    max-width: 1170px;
    margin: auto;
}

.container.staff.hero {
    height: 100%;
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/staff_landingimage.webp');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0px;
    padding: 10% 20%;
    text-align: center;
}

.container.staff.hero span {
    font-size: 30px;
    padding: 30px;
    display: block;
}

.container.staff.hero h4 {
    color: #54b2e2;
    font-size: 1.6em;
    font-weight: bold;
    letter-spacing: 1.2px;
}

.container.staff.hero .gmail-btn {
    display: inline-block;
}

.sign-in-block {
    border-radius: 20px;
    background: var(--white-color);
    max-width: 520px;
    color: #333;
    padding: 50px 0px;
    margin: 50px auto;
}

.hero-img {
    height: 100%;
    width: 100%;
    display: block;
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/hero.webp');
    background-size: cover;
    background-position: bottom;
    text-align: left;
}

.hero-icon {
    width: 30px;
    top: -3px;
}

.free-hero-img {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/free-hero-img.webp');
}


/*MOVE INTERCOM TO THE LEFT*/

/*.intercom-launcher-frame, .intercom-messenger-frame {
    left: 20px !important;
}

.intercom-launcher-hovercard {
    left: -5px !important;
}

#intercom-container .intercom-launcher-hovercard:after, #intercom-container .intercom-launcher-hovercard:before  {
    left: 20px !important;
    }*/


.hero-image {
    max-height: 380px;
    max-width: 720px;
    position: absolute;
    z-index: -1;
}

.hero-image.center {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0px;
}

.hero-image.drop-right {
    right: 0px;
    bottom: 50px;
}

.hero-image.drop-left {
    left: 0px;
    bottom: 40px;
}

.hero-image.left {
    left: 0px;
    top: 0px;
}


/*MOVE INTERCOM TO THE LEFT*/

.hero-promo-window {
    width: 400px;
    height: 140px;
    background: var(--white-color);
    margin: 20px;
    padding: 10px 10px 0px 10px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 5px;
    z-index: 9;
}

.hero-promo-window:hover,
.hero-promo-window:active,
.hero-promo-window:focus {
    background: var(--white-color);
}

.hero-promo-window img {
    width: 185px !important;
    display: block;
    min-height: inherit !important;
}

.hero-promo-window p {
    color: var(--black-color);
    line-height: 20px;
    margin: 10px 10px 10px 0px;
    display: block;
    width: 170px;
    font-size: var(--font-size-body-extra-small);
}

.hero-promo-window p .blue-text {
    color: #28b3ba;
}

.hero-promo-window .btn {
    background: var(--white-color);
    color: #54b2e2;
    border: solid 1px #ccc;
    border-radius: 3px;
    font-size: 11px;
    cursor: pointer;
}

.down-pointer {
    position: absolute;
    display: inline-block;
    text-align: center;
    color: #ccc;
    bottom: 50px;
    left: 50%;
    margin-left: -18px;
}

.down-pointer i.ss-navigatedown {
    font-size: var(--font-size-heading-medium);
    position: relative;
    top: 6px;
    color: #3994ff;
}

.arrowBounce {
    animation: arrowBounce 1.5s infinite;
    cursor: pointer;
    background-color: var(--white-color);
    border: 1px solid #3994ff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
}

.arrowBounce:hover,
.arrowBounce:focus {
    background-color: #f8f8f8;
}

@keyframes arrowBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-16px);
    }

    60% {
        transform: translateY(-8px);
    }
}

.hero-img h2 {
    margin: 10px 0px 20px 70px;
    font-size: 26px;
    letter-spacing: 1.5px;
}

.top-nav {
    position: absolute;
    background: rgba(0, 0, 0, .3);
    top: 0px;
    right: 0px;
    padding-right: 20px;
    z-index: 1;
    width: 100%;
    height: 80px;
    color: var(--white-color);
    font-size: 17px;
    text-transform: uppercase;
    line-height: 46px;
}

.recent-awards {
    padding-top: 100px;
    padding-bottom: 10px;
    text-align: center;
    background-color: #e9f6ff;
}

.recent-awards ul {
    padding-left: 0;
}

.recent-awards span {
    display: block;
    margin-top: 5%;
    font-size: 1.6em;
}

.recent-awards ul {
    list-style-type: none;
    /*display: inline-block;*/
}

.recent-awards ul li {
    margin-right: 10px;
}

.recent-awards ul li img {
    padding: 0 !important;
    max-height: 70px;
}

.g-recaptcha {
    margin-left: 7px;
}

.parents-page .main-nav {
    position: fixed;
}

.parents-main-nav .container {
    margin: -95px 0px 0px 0px !important;
    width: 100% !important;
}

.parents-main-nav {
    pointer-events: none;
}

.parents-main-nav .top-nav {
    pointer-events: all;
    position: absolute;
    margin-top: 0px !important;
}

.parents-main-nav .top-nav ul {
    padding-right: 20px;
}

.parents-main-nav .top-nav ul a {
    text-transform: uppercase;
}

.parents-main-nav .top-nav ul li ul {
    z-index: 2;
    max-width: 115px;
    padding: 5px 0 0px 5px;
}

.parents-main-nav .top-nav ul li ul li a {
    padding: 10px 30px 0px 10px;
    margin-bottom: -10px;
    margin-left: 0px;
    line-height: 10px;
    text-align: left;
    text-transform: none;
}

.parents-main-nav.head-top.shrink {
    display: none;
}

.parents .hero a,
.schools .hero a {
    margin: 0px 70px;
}

#payment-form {
    text-align: center;
}

.second-nav ul {
    margin-top: 50px;
}

.hero h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 70px;
}

.hero-dash {
    height: 60%;
    position: absolute;
    bottom: 0px;
    right: 30px;
    background-repeat: no-repeat;
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/hero-dash.webp');
    background-size: contain;
    display: block;
    background-position: bottom;
}

.hero-list {
    list-style-type: none;
    padding-left: 0px;
    z-index: 1;
    margin-bottom: 30px;
}

.hero-list li {
    margin-bottom: 10px;
    padding: 0px;
}

.hero-list li span {
    font-size: var(--font-size-body-large) !important;
}

.hero-list li i {
    font-size: 26px;
    margin-right: 10px;
    vertical-align: -10px;
    /*float: left;*/
}

.hero-list li .ss-file {
    padding: 0px;
    color: inherit;
}

.hero-list .fa-frown-o {
    vertical-align: 1px;
}

.hero-list li span {
    font-size: var(--font-size-body-medium);
    margin-top: -4px;
    margin-top: 17px;
    float: left;
}

.hero-list li .chrome-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}

.hero-title h2 {
    margin-top: 10px;
}

.fl-left {
    float: left;
    margin-bottom: 20px;
}

.sub-text {
    font-size: var(--font-size-body-small);
    font-weight: bold;
    margin-left: 70px;
    margin-top: 20px;
}

/*THANK YOU PAGE*/
.thank-you {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/thankyou.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.thank-you section {
    margin-top: 120px;
}

.thank-you section div {
    background: var(--white-color);
    padding: 0px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 50px;
}

.thank-you section div .great-choice {
    background: #52b266;
    padding: 10px;
    padding-bottom: 0px;
    width: 100%;
    height: 45px;
    text-align: center;
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    font-weight: 600;
    line-height: 24px;
    display: block;
    margin-bottom: 10px;
}

.thank-you section div .ty-block {
    border-radius: 0px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.thank-you section div .ty-block h4 {
    margin-top: 20px;
    margin-bottom: 0px;
}

.thank-you section div .next-steps span {
    padding-left: 0px;
}

.thank-you section div .ty-block .btn {
    padding: 8px 40px;
    font-size: 15px;
}

.thank-you section .get-started-now {
    margin-bottom: 10px;
}

.thank-you section div img {
    padding: 30px 40px 40px;
}

.thank-you section div h4 {
    font-weight: bold;
}

.thank-you section div p {
    line-height: 25px;
    margin-bottom: 20px;
    color: #aaa;
    margin-right: 80px;
    margin-top: 8px;
}

.thank-you section div label {
    color: #52b266;
    margin-left: 20px;
}

.thank-you section div .next-steps span {
    font-weight: 600;
    font-size: var(--font-size-body-small);
}

.thank-you section div .next-steps {
    background: #F4F8FC;
    margin-bottom: 0px;
    padding-top: 10px;
    border-radius: 0px 0px 20px 20px;
    margin-top: 30px;
}

.thank-you section div .next-steps div {
    background: none;
    padding-bottom: 30px;
}

.thank-you section div .fa-check-circle {
    color: #52b266;
    margin: 2px 10px 10px 0px;
    font-size: var(--font-size-heading-small);
    vertical-align: middle;
}

/*ONBOARDING AREA*/
.try-securly-free {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/thankyou.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.thank-you {

    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/thankyou.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.read-terms label {
    font-size: 10px;
}

.on-boarding .form-group {
    margin: 8px auto 5px;
    width: 100%;
    text-align: center !important;
}

.on-boarding .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: var(--font-size-body-extra-small);
    line-height: 1.42857143;
    color: var(--dark-gray-color);
    background-color: var(--white-color);
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.on-boarding .form-control:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.on-boarding {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
    overflow: scroll;
}

.on-boarding {
    overflow-x: hidden
}

.on-boarding h3 {
    margin-top: 10px;
    font-size: var(--font-size-body-medium);
    font-weight: 600;
    letter-spacing: 1px;
}

.on-boarding h3:nth-child(1) {
    color: #428bca;
}

.on-boarding h3.welcome {
    color: #4cbce0;
    margin-top: 25px;
    font-size: 12px;
}


.fa-question .tooltiptext {
    visibility: hidden;
    width: 220px;
    height: 54px;
    background-color: black;
    color: var(--white-color);
    text-align: center;
    border-radius: 5px;
    padding: 13px 21px 13px 21px;
    font-size: 11px;
    line-height: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.fa-question:hover .tooltiptext {
    visibility: visible;
}

.on-boarding .box {
    max-width: 800px;
    margin-top: 80px;
    min-height: inherit;
    margin-bottom: 40px;
    text-align: center;
    background: #EDF5FD;
    border-radius: 20px;
    padding: 40px 10px 30px;
}

@media only screen and (max-width: 500px) {
    .on-boarding .box {
        max-width: none;
    }
}

.on-boarding .sub-head {
    font-size: var(--font-size-body-small);
}

.on-boarding .box .image {
    width: 80%;
    padding: 10px 30px 20px;
    max-width: 400px;
    margin: 0px auto;
}

.on-boarding .box form {
    background: var(--white-color);
    display: inline-block;
    width: 90%;
    border-radius: 5px;
    padding: 0px 0px 10px;
}

.on-boarding .box form div {
    padding: 0px
}

.on-boarding .box .form-control {
    width: 80%;
    display: inline-block;
    margin: 10px;
}

.on-boarding .box .bracket {
    position: relative;
    display: block;
    width: 38px;
    height: 38px;
    border-left: solid 1px #cbd0d5;
    border-bottom: solid 1px #cbd0d5;
    margin-left: 10.7%;
    margin-top: -11px;
    margin-bottom: -26px;
}

.on-boarding .box .tooltip {
    min-width: 210px;
    text-transform: none;
}

.on-boarding .box .form-control:nth-of-type(2) {
    width: calc(80% - 50px);
    display: inline-block;
    margin: 10px 15px 10px 65px;
}

.on-boarding .box .note {
    color: #bdb8b5;
    margin: 6px auto;
    font-size: 10px;
    display: block;
    white-space: inherit;
}

.on-boarding .box .questions {
    color: #bdb8b5;
    margin-top: 15px;
    display: block;
    font-size: 10px;
}

.on-boarding .box .onboard-error {
    background: #f1746e;
    width: 100%;
    position: relative;
    display: inline-block;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    height: 25px;
    line-height: 27px;
    color: #f6f6f6;
}

.on-boarding .fa-question {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 50%;
    background: #f1f3f2;
    color: #b6b1ae;
    cursor: pointer;
    font-size: 11px;
}

.on-boarding .gmail-signup-btn {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--white-color);
    padding-left: 45px;
    padding-bottom: 10px;
    margin: 10px auto;
    padding-top: 0px;
    font-size: var(--font-size-body-extra-small);
    display: block;
    width: 240px;
    height: 60px;
    border: none;
}

.on-boarding .gmail-signup-btn:hover {
    background-size: contain;
    border: none !important;
    color: var(--white-color);
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
}

/*ABOUT US PAGE*/

#about-hero {
    min-height: 50%;
    width: 100%;
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/about-hero.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 25%;
    padding-bottom: 20%;
}

.bio {
    text-align: left;
}

.bio p {
    margin-left: 0px !important;
    margin-bottom: 10px;
}

.bio-pic {
    text-align: right;
    padding-right: 40px;
}

#about-hero .down-pointer {
    left: 0px;
}

.images {
    margin-top: 40px;
}

.images img {
    margin: 1px 0px;
}

.about h2 {
    padding: 30px 0px;
}

.about p {
    font-size: var(--font-size-body-large);
    line-height: 30px;
    max-width: 600px;
    margin: auto;
}

.about .images,
.about .images img {
    padding: 0px;
}

#about-one,
#how-we {
    margin: 60px auto;
}

#about-one p,
#how-we p {
    max-width: 830px;
    margin: auto;
}

#leaders {
    background: #f4f2f3;
    margin-top: 60px;
    padding: 50px 0 30px;
}

#board-of-members {
    background-color: #eef2f4;
    padding: 50px 0 100px;
}

#board-of-members h2 {
    margin-bottom: 60px;
}

#board-of-members p {
    color: #333;
    line-height: 24px;
}

#board-of-members p:first-of-type {
    margin-bottom: 12px;
}

#board-of-members h3 {
    margin: 10px 0 14px;
    font-size: var(--font-size-heading-small);
    text-transform: uppercase;
}

#leaders h2,
#how-we h2,
#about-one h2 {
    margin-bottom: 30px;
}

.leader {
    margin-bottom: 60px;
}

.leader h3 {
    text-transform: uppercase;
    font-size: var(--font-size-heading-small);
    margin-bottom: 12px;
}

.leader h4 {
    font-weight: 400;
}

.leader img {
    padding: 30px 0px;
    border-radius: 50%;
}

.leader i {
    font-size: 2.2em;
    color: #848484;
}

#imaginek12 {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/imaginek12.webp);
}

.vcenter {
    max-width: 180px;
    margin: 20px auto 0;
}

.locations {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/locations-bg.webp);
    background-size: cover;
    padding-bottom: 100px;
    padding-top: 100px;
    margin-top: 40px;
    margin-left: 0px !important;
    color: var(--white-color) !important;
    position: relative;
    top: 20px;
}

.locations div {
    margin-top: 40px;
}

.locations div a {
    color: var(--white-color) !important;
}

.locations i,
.locations .fa {
    font-size: 2em !important;
    color: var(--white-color) !important;
}

.locations p,
.locations h3,
.locations h2,
.locations i {
    color: #f7f7f7;
}

.locations h3 {
    margin-bottom: 12px;
}

.sub-text span {
    float: left;
    clear: left;
    line-height: 22px;
}

.gmail-btn {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
    background-size: contain;
    color: var(--white-color);
    padding-left: 45px;
    padding-top: 15px;
    font-size: var(--font-size-body-large);
    display: block;
    width: 300px;
    height: 66px;
    border: none;
}

.gmail-btn:hover {
    background-size: contain;
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
}

.gmail-signup-btn {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--white-color);
    padding-left: 45px;
    margin: 10px 10px 25px;
    padding-top: 0px;
    font-size: var(--font-size-body-small);
    display: block;
    width: 240px;
    height: 60px;
    border: none;
}

.gmail-signup-btn:hover {
    background-size: contain;
    border: none !important;
    color: var(--white-color);
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
}

.gmail-signup-btn:disabled {
    opacity: .5;
}

.gmail-btn-small {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/google_sso.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent !important;
    width: 250px;
    margin-left: 45px;
    height: 50px;
    border: none;
}

.gmail-btn-small:hover {
    margin-top: -2px;
    border: none;
}

.btn-hero {
    border: 1px solid #ebebeb;
    background: none;
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    padding: 13px;
    font-weight: bold;
    margin-top: 30px;
}

.btn-grey {
    color: var(--white-color);
    background: #4C5C62;
    border: solid 1px #4C5C62;
}

.btn-blue {
    color: var(--white-color);
    background: #54b2e2;
    border: solid 1px #54b2e2;
}

.btn-blue:hover {
    color: var(--white-color);
    background: #54b2e2;
}

.btn-green {
    color: var(--white-color);
    background: #46bea8;
    border: none;
}

.head-top.parents-only.shrink {
    visibility: visible;
    background: none;
}

.head-top.shrink .white-logo {
    visibility: hidden;
    position: absolute;
}

.head-top.parents-only a:hover {
    color: #ccc;
}

.head-top.parents-only button:hover {
    color: #ebebeb;
}

.head-top.parents-only.shrink .main-nav a:hover {
    color: #333 !important;
}

.top-nav>ul {
    display: inline-flex;
    float: right;
}

.top-nav li {
    display: inline;
    height: 40px;
    float: right;
    padding: 30px 0px;
    list-style: none;
}

.parents-page .head-logo {
    margin: 20px 20px 20px 70px;
}

.parents-page .head-top h2 {
    position: fixed;
    color: var(--white-color);
    margin-top: 0px;
    margin-left: 5px;
}

.parents-page .head-top.shrink h2 {
    position: fixed;
    color: var(--black-color);
    margin-top: 10px;
}

.parents-page .head-top a {
    text-transform: none;
}

.clear-left {
    clear: left;
}

.signup-form.button-inverted {
    margin-left: 0px;
    background: #1facd3;
    border: none;
}

#parent-login {
    padding-top: 20px;
}

#return-login {
    display: block;
}

.disclaimer {
    font-size: 10px;
}

.credit-card-logos {
    margin: 10px auto 30px;
    display: block;
}

#signup-form input.amount {
    margin-left: 15px
}

.payment-info {
    margin-left: 23px;
}

.credit-card-logos-pay {
    display: block;
}

.explain-credit {
    margin-top: 120px;
    padding-top: 30px;
    width: 100%;
    border-top: 1px solid #ebebeb;
}

.pass-error {
    color: red;
    margin-bottom: -20px;
    font-size: 12px;
    font-style: italic;
    display: block;
    margin-top: 10px;
}

#confirm {
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.parents-icon {
    position: absolute;
    width: 85px !important;
    height: 65px !important;
    min-height: 65px !important;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.sign-up-school {
    height: 290px;
    margin-top: 15px;
    padding-top: 30px;
    width: 50%;
    float: left;
    background: #53c2cd;
    cursor: pointer;
    display: initial;
}

.school-h3 {
    margin-top: 25px;
    color: var(--white-color) !important;
}

.sign-up-home {
    height: 290px;
    margin-top: 15px;
    padding-top: 30px;
    width: 50%;
    float: right;
    background: #60cfda;
    cursor: pointer;
}

.sign-up-header {
    margin: 20px 0px 0px 20px;
    color: var(--black-color);
    text-align: left;
    font-size: var(--font-size-body-medium);
}

.sign-up-home i,
.sign-up-school i {
    font-size: 3em;
    color: var(--white-color)
}

.sign-up-home p,
.sign-up-school p {
    width: 60%;
    margin: 40px auto;
    color: var(--white-color)
}

.sign-up-home h3 {
    color: var(--white-color);
    margin-top: 0px;
}

.sign-up-home:hover i {
    padding: 30px 40px 50px;
    border: solid 2px var(--white-color);
    border-radius: 50%;
    display: initial;
}

.sign-up-school:hover i {
    padding: 15px 45px 65px;
    border: solid 2px var(--white-color);
    border-radius: 50%;
    display: initial;
}

.sign-up-location {
    width: 500px;
    height: 300px;
    color: var(--white-color);
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    background: var(--white-color);
    border-radius: 10px;
}

.parent-info,
.school-info {
    height: 100px;
    width: calc(50% - 75px);
    letter-spacing: 1.5px;
    position: absolute;
    bottom: 0px;
    padding-left: 30px;
    text-align: left;
    transition: all .5s ease;
}

.filter-slash {
    position: absolute;
    left: 70px;
    margin-top: -59px;
    width: 171px !important;
    max-height: 130px !important;
    min-height: 130px !important;
}

.parent-info:hover,
.school-info:hover {
    height: 105px;
    padding-top: 5px;
}

.parent-info {
    background: rgba(243, 114, 114, .2);
    left: 50%;
    margin-right: 70px;
}

.info-right-arrow {
    border-right: 1px solid var(--white-color);
    border-bottom: 1px solid var(--white-color);
    transform: rotate(-45deg);
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    right: 60px;
    top: 25px;
}

.school-info {
    background-color: rgba(80, 209, 244, .2);
    margin-left: 70px;
}

.parent-info h3,
.school-info h3 {
    color: var(--white-color) !important;
    margin-left: 20px;
    font-size: var(--font-size-body-large);
    text-transform: uppercase;
}

.parent-info p,
.school-info p {
    color: var(--white-color);
    margin-left: 20px;
    font-size: var(--font-size-body-extra-small);
    line-height: 15px;
    height: 15px;
    max-width: 70%;
}

.get-started-link {
    cursor: pointer;
}

.get-started {
    background: #1facd3;
    color: var(--white-color);
    margin-left: 70px;
    font-size: .5em;
    padding: 15px 75px 10px;
    letter-spacing: 1px;
}

.video-container {
    height: 800px;
}

.parents .slide-content {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/parents.webp');
    background-size: cover;
}

.schools .slide-content {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/school-hero.webp');
    background-size: cover;
}

.video-container img {
    width: 100%;
    min-height: 600px;
}

.mobile-quote {
    display: none;
}

.screen-quote {
    display: block;
}

.combobox button.dropdown-toggle {
    background: none !important;
    color: #333;
    margin-top: 30px;
}

.dropdown-menu>li>a {
    height: 35px;
}

.white-input {
    background-color: var(--white-color) !important;
}

#learn-more-form {
    margin-top: 80px;
}

.submit_panel,
.requestquote_btn {
    float: left;
    clear: left;
    margin: 20px auto;
}

.contact_contentarea h2 {
    color: var(--white-color);
    margin-left: 20px;
}

.contact_formarea li {
    list-style-type: none !important;
    margin-left: -40px;
}

.contact_formarea li input {
    box-shadow: none;
    background: #777;
    border: none;
    border-radius: 5px;
}

.contact_formarea li input:focus {
    box-shadow: none;
    background: var(--white-color);
    border: 1px solid #4c8ec1;
    border-radius: 5px;
}

.contact_formarea li select {
    height: 35px;
    margin-bottom: 10px;
}

.label {
    text-align: left;
    float: left;
    clear: left;
}

.text {
    float: left;
    width: 100%;
    clear: left;
}

.tabs {
    /*z-index: 9999999999;*/
    position: relative;
    padding-top: 24px;
    /*margin-top: -40px;*/
    /*margin-left: 15%;*/
    margin-bottom: 30px;
    border-top: 1px solid hsla(0, 0%, 100%, .4);
}

.tabs div p {
    margin-top: 300px;
}

.tabs li {
    display: inline;
    margin-right: 10px;
    margin-left: 0;
}

.tabs li:last-child {
    margin-right: 0;
}

.tabs a {
    color: #777;
    line-height: 18px;
    padding: 8px 4px;
    font-size: var(--font-size-body-medium);
    font-weight: 500;
    font-family: var(--semi-bold-font);
    border-bottom: 3px solid #b2b2b2;
}

.tabs a:hover,
.tabs a:focus {
    color: #444;
}

.tabs .active {
    border-bottom-color: var(--blue-color);
    color: #444;
}

.forgot-password {
    display: block;
    cursor: pointer;
    margin-bottom: 40px;
}

#forgot-password {
    display: block;
    cursor: pointer;
}

#login a:hover {
    color: #777;
}

#login input,
.text {
    background: #f8f8f8;
    border-radius: 5px;
    box-shadow: none;
    font-size: var(--font-size-body-extra-small);
    line-height: 35px;
    min-width: 310px;
}

#login {
    padding: 40px 40px 0px;
    color: #333;
    background: var(--white-color);
    border-radius: 10px;
}

#login .small-mobile-only .message h1 {
    color: #2c8bbc;
    text-align: left;
    font-size: 2.2em;
    padding: 60px 40px 10px;
}

#login .small-mobile-only .message span {
    display: block;
    color: #333;
    line-height: 20px;
    text-align: left;
    padding: 0px 40px 55px;
}

#login img {
    margin-bottom: 40px;
    width: 250px;
}

#login button.button-inverted {
    width: 310px;
    margin-top: 0px;
    margin-bottom: 20px;
    background: #219b89;
    border: none;
}

.press_title {
    color: #777 !important;
    font-size: var(--font-size-body-medium) !important;
    font-weight: bold !important;
}

.press_title:hover {
    color: #999 !important;
}

.calculate-message {
    width: 180px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}

.hero-gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    opacity: .2;
    height: 50%;
    background: linear-gradient(to bottom, rgba(157, 68, 225, 1) 0%, rgba(157, 68, 225, 0) 100%);
}

.hidden {
    display: none;
}

.check label {
    float: left;
    clear: left;
}

.bubble {
    position: absolute;
    width: 15%;
    min-width: 120px;
    height: 130%;
    text-align: left;
    left: 72%;
    top: -9px;
    padding: 0px;
    /* float: left; */
    background: #1d1d1d;
    border-radius: 5px;
}

.bubble span {
    position: absolute;
    color: var(--white-color);
    font-size: 12px;
    line-height: 13px;
    width: 80%;
    z-index: 9;
    margin-top: 15px;
    margin-left: 10px;
}

.bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 9px 14px 9px 0;
    border-color: transparent #1d1d1d;
    display: block;
    width: 0;
    z-index: 1;
    left: -14px;
    top: 20px;
}

.sources .bubble {
    left: 260px;
    height: 55px;
    margin-top: 30px
}

.sources {
    height: 60px;
}

.take-home-options {
    display: none;
}

.take-home-options .small-check:nth-child(odd) {
    clear: left;
}

.price-line {
    margin: 10px;
    width: 100%;
}

.quote-price {
    font-size: 30px;
    font-weight: bold;
    color: #33b294 !important;
}

.free-trial {
    background-color: #4c8ec1;
    color: var(--white-color);
    width: 200px;
}

.big-right-arrow {
    font-family: "SSGizmo";
    font-size: 100px;
    color: #eeecec;
    line-height: 200px;
    position: absolute;
    top: 0px;
    padding-left: 20px;
}

.quote-row {
    background: var(--white-color);
    border-radius: 10px;
    border-top: solid 10px #cb483b;
    padding-top: 30px;
    min-height: 200px;
    margin-bottom: 100px;
    position: relative;
    color: #868686;
}

.quote-row div.left {
    float: left;
    padding-right: 70px;
    z-index: 999;
}

.quote-page {
    background-color: #f3f3f3;
    margin-top: -30px;
    z-index: 9999999;
}



.signup-page {
    background-color: #f3f3f3;
    margin-top: -70px;
    z-index: 9999999;
}

.signup-page-row {
    padding-top: 80px;
}

.page-footer.quotes-footer {
    margin-top: 0px !important;
}

.pricing-faq {
    background: var(--white-color);
    width: 100%;
    margin-bottom: 50px;
}

.get-quote {
    background: #515151;
    width: 200px;
    color: var(--white-color);
    margin-top: 20px;
    margin-bottom: 60px;
    border: none;
}

.right-feature {
    float: right;
}

.granular {
    background: var(--white-color);
}

.head-logo,
.head-logo img,
.head-logo video {
    height: 30px;
}

.terms-container {
    margin-top: 60px;
    font-family: var(--regular-font);
}

.terms-container h3 {
    font-family: var(--semi-bold-font);
}

.privacy-container {
    margin-top: 60px;
}

/*.homepage {
    background: #f8f8f8;
    }*/


/*Blocked and Other Special Pages*/

.blocked-page {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    /*position: fixed;*/
    /*background: rgb(128, 32, 32);
    background: transparent\9;
    background: rgba(128, 32, 32, 0.8);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr=#cc802020, endColorstr=#cc802020);
    */
}

.blocked-page:nth-child(n) {
    filter: none;
}

.error-page {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background: hsla(0, 0%, 0%, .7);
}

.block_1,
.error_1 {
    margin-top: 150px;
    color: var(--white-color);
    font-size: 3.5em;
}

.blocked-logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 155px;
}

.btn-blocked {
    border: solid 1px var(--white-color);
    color: var(--white-color);
    width: 200px;
    margin: 20px 20px 60px;
    background: none !important;
    letter-spacing: 1px;
}

.btn-blocked:hover {
    color: #ccc;
    border-color: #ccc;
}

.blocked-page a {
    text-decoration: none;
}

.blocked-page .subtitle {
    font-weight: 300;
    font-size: var(--font-size-body-medium);
    color: var(--white-color);
    width: 560px;
    margin: 10px auto 30px;
}

.school-logo {
    width: 100%;
    height: auto;
    background: hsla(0, 0%, 100%, .2);
    margin-top: 40px;
}

.school-logo img {
    margin: 10px;
}

.approve-page {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background: rgba(0, 0, 0, .8);
}

#parent-signup-form h2 {
    margin: 0px 30px;
}

#parent-signup-form h3 {
    margin: 30px 30px 0px;
    width: 100%;
}

#parent-signup-form h3 .pass-error {
    padding-bottom: 0px;
}

#parent-signup-form span {
    margin: 5px 30px;
    width: 100%;
    display: block;
    color: #df4a32;
    font-size: 1.2em;
}

#parent-signup-form .pass-error {
    padding-bottom: 0px;
    margin-bottom: -10px;
}

#parent-signup-form {
    border-top: solid 10px #54b2e2;
    background: var(--white-color);
    padding-top: 40px;
    border-radius: 10px;
    margin-top: 40px;
    padding-bottom: 180px;
    height: 100%;
}

#signup-form {
    background: var(--white-color);
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 80px;
    padding-bottom: 20px;
    height: 100%;
}

#signup-thankyou {
    background: var(--white-color);
    border-top: 10px solid #52b266;
    text-align: center !important;
    border-radius: 10px;
    margin-top: 40px;
    min-height: 270px !important;
    padding-top: 60px;
    margin-bottom: 40px;
}

#signup-form label.error {
    float: left;
    clear: left;
    margin-left: 25px;
    display: block;
}

#signup-form h3 {
    padding: 20px;
    font-size: 30px;
    border-radius: 10px;
    color: var(--black-color);
}

.signup-button {
    float: left;
    width: 240px;
    height: 45px;
    font-size: var(--font-size-body-small);
    text-transform: none;
    font-weight: bold;
}

.signup-buttons {
    height: 90px;
    margin-left: -5px;
}

.parent-signup-button {
    width: 100%;
    margin-top: 20px;
    height: 70px;
    background: #dd4b39;
    font-size: var(--font-size-body-large);
    border: none;
    display: block;
    text-align: center;
    border-radius: 3px;
    color: var(--white-color);
}

.parent-signup-button:hover {
    background: #dd4b39;
    border: none;
    color: var(--white-color);
}

/*#signup-form button {
    margin-left: 10px;
    background: #33b294;
    opacity: 1;
    border-color: #f3f3f3;
    clear: left;
    float: left;
}

#signup-form button:hover {
    color: #777;
    background: #f3f3f3;
    border-color: #33b294;
    }*/

#signup-form .input-group {
    padding-bottom: 20px;
}

.try-btn {
    height: 53px;
    margin-top: -2px;
}

.human-input {
    background: #f3f3f3;
    padding: 0px 10px !important;
    box-shadow: -1px -1px 2px #ccc !important;
    box-sizing: border-box !important;
}

#parent-email,
#parent-password {
    background: #f3f3f3;
    height: 40px;
    width: 90%;
    margin: 1% 5%;
    line-height: 40px;
    text-decoration: none;
    font-weight: 300;
    font-size: var(--font-size-body-small);
    margin-bottom: 20px;
    box-shadow: -1px -1px 2px #ccc;
    box-sizing: border-box;
}

#cemail,
#email,
#phone,
#login-password,
#password-reset,
#password-reset-conf {
    background: #f3f3f3;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    font-weight: 400;
    font-size: var(--font-size-body-small);
    box-shadow: -1px -1px 2px #ccc;
    box-sizing: border-box;
}

.ipadd {
    clear: both;
    width: 66%;
    margin-right: 30px;
    margin-left: 15px !important;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
}

.small-check {
    font-size: 10px;
    height: 30px;
}

.check {
    height: 45px;
    margin-top: 15px;
}

.form-group {
    text-align: left !important;
    margin-left: 17px;
    margin-top: 25px;
}

input[type="checkbox"] {
    margin-right: 5px;
    text-align: left;
}

input[type="checkbox"]+label span {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 10px 4px 10px 15px;
    vertical-align: middle;
    box-shadow: -1px -1px 2px #ccc;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/check_sheet.webp) left top no-repeat #f3f3f3;
    cursor: pointer;
}

input[type="checkbox"]:checked+label span {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/check_sheet.webp) -25px top no-repeat #f3f3f3;
}

.small-check input[type="checkbox"]+label span {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 10px 4px 10px 15px;
    vertical-align: middle;
    box-shadow: -1px -1px 2px #ccc;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/check_sheet.webp) left top no-repeat #f3f3f3;
    cursor: pointer;
    background-size: 30px 15px;
}

.small-check input[type="checkbox"]:checked+label span {
    background-size: 30px 15px !important;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/check_sheet.webp) -15px top no-repeat #f3f3f3;
}

label.custom-select {
    position: relative;
    display: inline-block;
    clear: left;
}

.custom-select select {
    display: inline-block;
    width: 212px;
    height: 40px;
    box-shadow: -1px -1px 2px #ccc;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/down-arrow.webp) 175px 5px no-repeat #f3f3f3 !important;
    background-size: 30px !important;
    padding: 4px 3px 3px 5px;
    margin: 25px 25px 25px 13px;
    font: 16px;
    color: #777;
    clear: left;
    font-weight: normal;
    /* remove focus ring from Webkit */
    line-height: 30px !important;
    border: 0;
}

.custom-select select.error {
    border: 1px solid red;
}

.cancel {
    margin-left: 10px;
    margin-top: 10px;
    color: #33b294;
    margin-bottom: 10px;
    padding-bottom: 10px !important;
    float: left;
}

.already {
    padding-bottom: 50px;
    color: #777;
    padding-top: 20px;
    font-size: 11px;
    cursor: pointer;
}

.hero a {
    /*     margin: 25px; */
    text-align: center;
}

.chrome-icon {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/chrome.svg');
    background-repeat: no-repeat;
}

.feature-box.chrome-icon {
    background-size: 35px;
}

.hero-list li .feature-box {
    background-size: 20px;
}

.date {
    font-weight: normal;
    color: #999;
    font-size: 12px;
    float: left;
    width: 100%;
    margin-bottom: 6px;
}

.content_container1 {
    max-width: 900px;
    margin-top: 20px;
    margin-bottom: 20px;
    /*margin-left: 10%;*/
}

.content_container1:last-child {
    padding-bottom: 40px
}

.seperator {
    clear: both;
    margin-bottom: 20px;
    border-bottom: solid 1px #ebebeb;
}

.features-section .row.mar-top-0 {
    margin-top: 0;
}

.news-item {
    list-style: none;
    margin-top: 20px;
    clear: both;
    padding-bottom: 20px;
}

.press_logo {
    /*margin-top: 30px;*/
    float: right;
}

.press_logo img {
    vertical-align: middle;
    margin: auto auto 10px;
    max-width: 150px;
    max-height: 150px;
}

.learn-more {
    border-bottom: 1px solid;
}

.learn-more:hover {
    background: none;
    color: var(--dark-gray-color);
}

.signup-boxes {
    margin-top: -70px !important;
    padding-bottom: 200px;
}

.signup h2 {
    color: var(--white-color);
    font-weight: normal;
}

.sign-up-btn {
    /*border: solid 1px white !important;*/
    border-radius: 20px;
    margin-left: 14px;
    background-color: #0096ff !important;
    border: 0 !important;
    color: var(--white-color) !important;
    padding: 11px 22px !important;
}

.login-btn:hover {
    color: #4c8ec1 !important;
}

.main-nav ul li ul li a .ss-home,
.top-nav ul li ul li a .ss-home {
    vertical-align: -2px;
}

.schools div {
    margin-bottom: 40px
}

.homepage .our-schools {
    background: #f7f7f7 !important;
}

.our-schools.feature {
    background: var(--white-color) !important;
}

.our-schools.feature img {
    width: 50%;
    margin: auto;
    vertical-align: middle;
}

.our-schools.feature div:nth-child(3) {
    padding-top: 20px;
}

.fa-youtube-square {
    color: #ce1525;
    padding: 10px;
}

.white {
    color: var(--white-color);
}

.blue {
    color: #4c8ec1;
}

.circle-border {
    border-radius: 50%;
    border: solid 3px var(--white-color);
}

.ss-lightbulb {
    margin-top: 5px;
    padding: 35px 25px 13px 25px;
    color: var(--white-color);
}

.ss-file {
    padding: 35px 25px 13px 25px;
    color: var(--white-color);
}

.ss-mail {
    padding: 35px 25px 13px 25px;
    color: var(--white-color);
}

.clients p {
    max-width: 50%;
    margin: 0px auto 40px;
}

.clients p:nth-child(even) {
    padding-top: 20px;
}

.resource-title {
    font-size: 1.5em;
    color: var(--white-color);
    text-decoration: underline;
    cursor: pointer;
}

.resources-title {
    color: white !important;
    margin-top: -65px;
}

.resources-title:after {
    background: white !important;
}

.features-title {
    color: var(--white-color);
    margin: 30px auto 0px;
}


/* TESTIMONIAL STYLES =======*/

.testimonials {
    background: #33b294 !important;
    height: 560px !important;
    overflow: hidden !important;
    margin-bottom: -35px !important;
}

testimonial-superslides {
    z-index: 1;
}

.testimonials p {
    color: white !important;
    padding-top: 30px;
    font-size: 34px;
    line-height: 46px;
}

.testimonials.feature {
    background: var(--white-color) !important;
    max-height: 360px !important;
    border: none !important;
    padding-top: 0px;
}

.testimonials.feature p {
    color: #333 !important;
    padding-top: 0px;
    font-size: var(--font-size-body-medium);
    line-height: 30px;
}

.testimonials.feature span {
    color: #4c8ec1;
}

.testimonials.feature cite {
    color: var(--dark-gray-color) !important;
}

.testimonials:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0px 24px 24px 24px;
    border-color: #33b294 transparent;
    display: block;
    width: 0;
    z-index: 1;
    left: 50%;
    margin-left: -12px;
    margin-top: -62px;
}

.testimonials.feature:before {
    display: none;
}

.features a:hover {
    background: none;
    color: #777;
}

.partners {
    background: var(--white-color) !important;
    text-align: center;
    vertical-align: middle;
}

.best-buy {
    margin-top: -7px;
}

.tcrunch {
    margin-top: 1px;
}

.edsurge {
    margin-top: -7px;
}

.edweek {
    margin-top: -4px;
}

.mobile-only {
    display: none;
}

.small-mobile-only {
    display: none;
}

.mobile-only hr {
    opacity: .3;
}

.desktop-only {
    display: block;
}

.more-features {
    background: var(--white-color);
    padding-bottom: 30px;
}

.more-features .fa-arrow-right {
    width: 60px;
    height: 60px;
    background: #333;
    color: var(--white-color);
    line-height: 53px;
    font-size: 30px;
    cursor: pointer;
    transition: all .5s;
}

.more-features .fa-arrow-right:hover {
    color: #333;
    background: #eee;
}

.video.feature {
    margin: -30px auto 30px;
    background: var(--white-color);
    content: "video";
    padding: 5px;
    max-width: 100%;
    border-radius: 3px;
}

.video.feature img {
    background: none;
}

.page-content.feature {
    margin: -30px auto 30px;
    padding-right: 0px;
}

.features {
    background: var(--white-color) !important;
    padding-bottom: 0px !important;
}

.all-features img {
    max-width: 70%;
}

.feature-list {
    width: 100%;
}

.feature-list-top {
    background: #f6f6f6 !important;
}

.feature-list-bottom {
    background: var(--white-color) !important;
    border-top: none !important;
    margin-bottom: 30px !important;
}

.all-features .feature-list .row:nth-child(even) {
    background: #f8f8f8;
    margin: 0px -15px 0px -15px;
}


.row {
    margin-right: 0px;
    margin-left: 0px;
}

.full-width {
    width: 100% !important;
}

.feature-box.centered {
    padding-left: 15px;
    background: none !important;
    margin-bottom: -50px;
}

.feature-box.centered p {
    padding-left: 15px;
    position: relative;
    z-index: 99999 !important;
}

.background {
    position: relative;
    z-index: -1 !important;
}

.section-split.all-features {
    background: none !important;
}

.section-split.testimonials.feature div {
    height: 320px !important;
}

.section-split.testimonials {
    margin-bottom: 0px !important;
}

.testimonials:before {
    margin-top: -44px;
}

.line-graph {
    margin-top: 40px;
}

.schools div img {
    /* max-width: 75px; */
    max-height: 60px;
}

.white-row {
    background: var(--white-color);
}


/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html,
button,
input,
select,
textarea {
    color: #222;
}

html {
    font-size: 1em;
    line-height: var(--line-height-lg);
}

::selection {
    background: var(--blue-color);
    color: var(--white-color);
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 2px;
    border: 0;
    border-top: 1px solid #bbb !important;
    opacity: 1;
    margin: 0;
    padding: 0;
}


/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */

audio,
canvas,
img,
video {
    vertical-align: middle;
}


/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}


/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/* ==========================================================================
   General styles
   ========================================================================== */

body {
    visibility: hidden;
    position: relative;
    font-size: var(--font-size-body-extra-small);
    color: var(--dark-gray-color);
    overflow-x: hidden;
    font-family: var(--regular-font);
}

.setda {
    margin-top: 3px;
}

hr {
    border-color: #eee;
    margin: 50px 0;
}


/* Titles */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    color: #333;
}

.feature-box h4 {
    line-height: 1.3em;
}

.hero {
    text-align: left;
    padding: 0px;
    line-height: 36px;
}

.subtitle {
    font-weight: 300;
    font-size: var(--font-size-body-medium);
    color: var(--white-color);
}

.box .subtitle {
    color: var(--black-color);
}

.subtitle a {
    text-decoration: underline;
    color: var(--white-color) !important;
}

.title-tinted {
    color: #219b89;
    font-weight: normal;
    font-size: var(--font-size-heading-large);
    text-align: center;
}

.detail-title,
.detail-title a {
    font-weight: 500;
    font-size: var(--font-size-body-small);
    color: #333;
    text-transform: uppercase;
    border: none;
    line-height: 24px;
    margin: 20px 0px 10px;
}

.slide-content .page-title {
    text-transform: none !important;
    letter-spacing: 2px !important;
    margin-left: 70px;
}

.page-title {
    color: var(--black-color);
    letter-spacing: 1px;
    line-height: 52px;
    margin-bottom: 16px;
}

.page-subtitle {
    color: var(--white-color);
    font-size: 30px;
    letter-spacing: -2px;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 5%;
}

.page-subtitle.feature {
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    line-height: 24px;
    text-align: center;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 300;
    margin: 0px auto 5%;
    width: 35%;
}

.section-title {
    color: var(--heading-text-color);
    font-weight: 300;
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.section-title:after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 46.4%;
    height: 2px;
    width: 7%;
    background: #4c8ec1;
    display: none;
}

.feature div h2 a {
    color: #333;
}

.feature .section-title:after {
    content: " ";
    position: absolute;
    bottom: -18px;
    left: 46.4%;
    height: 2px;
    width: 7%;
    background: none;
}

.feature .section-title:before {
    content: " ";
    position: absolute;
    top: -16px;
    left: 46.4%;
    height: 2px;
    width: 7%;
    background: var(--dark-gray-color);
}

.paragraph-title {
    font-size: var(--font-size-heading-large);
    text-transform: uppercase;
}

.overlay-title,
.overlay-subtitle {
    color: var(--white-color);
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.overlay-subtitle {
    font-weight: 300;
}

.service-title {
    font-size: var(--font-size-body-medium);
    font-weight: 700;
    line-height: 24px;
}

.article-title {
    font-weight: 300;
    margin: 0 0 20px 0;
}

.blog-full .article-title {
    margin: 0 0 5px 0;
}

.news-item:nth-child(1) {
    border-top: none;
}

a.support-link {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
}

a.support-link img {
    min-height: 50px;
    display: inline-block;
    margin: 0 auto;
}

a.support-link h3,
a.support-link h3 p {
    font-size: var(--font-size-heading-small);
    line-height: var(--line-height-sm);
    font-weight: 600;
    color: #1d2d35;
}

.box.min-height-260 {
    min-height: 260px;
}


/* Paragraph & Typographic */

p {
    margin-bottom: 20px;
}

strong {
    font-weight: 500;
    font-family: var(--semi-bold-font);
}

em {
    font-weight: 300;
}

pre {
    background: #ebebeb;
    border: none;
    font-size: var(--font-size-body-small);
    color: #666;
    padding: 20px;
    line-height: 28px;
}

small {
    font-size: 12px;
}

img {
    max-width: 100%;
    height: auto;
}

iframe {
    max-width: 100%;
    border: none;
}

.paragraph-lead {
    font-size: var(--font-size-body-medium);
    color: #219b89;
    line-height: 32px;
}


/* Links */

a {
    color: #428bca;
    transition: all 0.1s ease-in;
}

a:hover,
a:focus {
    background: none;
    color: #333;
    text-decoration: none;
}

a img {
    position: relative;
}

a.head-logo {
    position: relative;
    top: 21px;
}

a.head-logo,
a.head-logo:hover,
a.head-logo:focus {
    display: block;
    height: 40px;
    width: 120px;
    cursor: pointer;
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/securly-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
}

.page-content .section-split.tinted a img {
    background: #e1f1ef;
}

.article-title a {
    border: none;
    line-height: normal;
}

.s-logo {
    display: inline-block;
    margin-top: 19px;
}

.top-header .s-logo {
    margin-top: 0;
}


/* Buttons */

.button,
.button,
button,
input[type="button"] {
    display: inline-block;
    font-size: var(--font-size-body-extra-small);
    color: var(--white-color);
    text-transform: none;
    font-weight: 200;
    line-height: normal;
    background-color: #219b89;
    border: 1px solid #219b89;
    padding: 10px 13px 10px 14px;
    cursor: pointer;
    margin: 0 5px 10px 0;
    border-radius: 5px;
    transition: all 0.1s ease-in;
}

.button.large,
button.large,
input[type="button"].large {
    font-size: 21px;
}

.button.small,
button.small,
input[type="button"].small {
    font-size: 11px;
}

.button [class*="icon-"]:before {
    position: relative;
    top: 1px;
    margin-left: 3px;
}

.white-btn {
    text-transform: inherit;
}

/* Lime button */

.button.lime {
    background-color: #219b89;
    border: 1px solid #219b89;
}

.button.lime:hover {
    color: #219b89;
}


/* Green button */

.button.green {
    background-color: #329357;
    border: 1px solid #329357;
}

.button.green:hover {
    color: #329357;
}


/* Red button */

.button.red {
    background-color: #b85252;
    border: 1px solid #b85252;
}

.button.red:hover {
    color: #b85252;
}


/* Blue button */

.button.blue {
    background-color: #528eb8;
    border: 1px solid #528eb8;
}

.button.blue:hover {
    color: #528eb8;
}


/* Yellow button */

.button.yellow {
    background-color: #d5b32b;
    border: 1px solid #d5b32b;
}

.button.yellow:hover {
    color: #d5b32b;
}


/* Orange button */

.button.orange {
    background-color: #e67e22;
    border: 1px solid #e67e22;
}

.button.orange:hover {
    color: #e67e22;
}


/* Purple button */

.button.purple {
    background-color: #9b59b6;
    border: 1px solid #9b59b6;
}

.button.purple:hover {
    color: #9b59b6;
}


/* Brown button */

.button.brown {
    background-color: #9b6c21;
    border: 1px solid #9b6c21;
}

.button.brown:hover {
    color: #9b6c21;
}


/* Grey button */

.button.grey {
    background-color: #9E9E9E;
    border: 1px solid #9E9E9E;
}

.button.grey:hover {
    color: #9E9E9E;
}

.button:hover,
button:hover,
input[type="button"]:hover {
    color: #219b89;
    background-color: var(--white-color);
    border: 1px solid #4c8ec1;
}

.button-inverted {
    display: inline-block;
    min-width: 300px;
    background: none;
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    border: 1px solid var(--white-color);
    border-radius: 5px;
    line-height: normal;
    padding: 15px 25px;
    transition: all 0.1s ease-in;
}

.button-inverted:nth-child(3) {
    background: #4c8ec1;
    border-color: #4c8ec1;
    color: var(--white-color);
}

.button-inverted:hover {
    background: var(--white-color);
    color: #333;
    text-decoration: none;
}


/* List */

.link-list {
    margin: 0;
    padding: 0px 0px 30px 0px;
    list-style: none;
    width: 100%;
    display: flex;
}

.link-list li {
    font-weight: 400;
    margin-bottom: 4px;
    text-align: center;
    flex: auto;
    padding-right: 20px;
    /*padding-top: 50px;*/
}

.link-list li a:hover {
    background: none;
    color: var(--black-color);
}

.bordered-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #eee;
}

.bordered-list strong {
    text-transform: uppercase;
    margin-right: 5px;
}

.bordered-list li {
    line-height: normal;
    padding: 18px 0;
    border-top: 1px solid #eee;
}


/* Dropcaps */

.dropcap {
    float: left;
    font-size: 46px;
    margin: 12px 6px 0 0;
    font-weight: bold;
    text-transform: uppercase;
}

.dropcap.rounded,
.dropcap.rectangle {
    border-radius: 100%;
    background: var(--dark-gray-color);
    color: var(--white-color);
    padding: 8px 11px;
    font-size: 26px;
    margin-top: 6px;
}

.dropcap.rectangle {
    border-radius: 3px;
}

.dropcap.color {
    background-color: #219b89;
}


/* Quotes */

blockquote,
blockquote.pull-left,
blockquote.pull-right {
    border-color: #219b89;
}

blockquote p {
    color: var(--white-color);
    margin-bottom: 15px;
}

blockquote cite {
    font-size: 12px;
    color: var(--white-color);
    font-style: italic;
}

blockquote cite:before {
    content: "- "
}

blockquote.colored {
    background-color: #219b89;
    color: var(--white-color);
    border: none;
}

blockquote.pull-left,
blockquote.pull-right {
    width: 30%;
}

blockquote.colored p,
blockquote.colored cite {
    color: var(--white-color);
}

blockquote.quotes {
    position: relative;
    border: none;
}

blockquote.quotes:before {
    content: "“";
    position: absolute;
    top: -10px;
    left: -10px;
    color: #219b89;
    font-size: 74px;
}

blockquote.quotes:after {
    content: "”";
    position: absolute;
    bottom: -50px;
    right: 0;
    color: #219b89;
    font-size: 74px;
}

.testimonial blockquote {
    border: none;
    margin: 0px;
    padding: 0;
    text-align: center;
}

.testimonial blockquote p {
    color: var(--white-color);
    width: 60%;
    max-width: 530px;
    margin: 0px auto 20px;
}

.testimonial img {
    max-width: 68px !important;
    max-height: 68px !important;
    border-radius: 100%;
    margin: 10px 0px;
    position: relative !important;
}

.testimonial blockquote p:before {
    font-family: "SSGizmo";
    content: "“";
    font-size: 1.5em;
    position: relative;
    float: left;
    margin-left: -80px;
}

.testimonial blockquote p:after {
    font-family: "SSGizmo";
    content: '”';
    font-size: 1.5em;
    position: relative;
    float: right;
    margin-right: -60px;
}

.testimonial blockquote cite {
    font-size: var(--font-size-body-extra-small);
    color: var(--white-color);
    font-weight: 700;
    font-style: normal;
}

.testimonial blockquote br {
    display: block;
    height: 0px;
    content: " ";
}

.testimonial blockquote cite span {
    font-style: italic;
    font-weight: 300;
}

.testimonial blockquote cite:before {
    content: "";
}

textarea,
input[type="text"],
input[type="phone"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    height: 50px;
    font-size: 23px;
    color: #333;
    box-sizing: border-box;
    margin-bottom: 25px;
    box-shadow: 3px 3px 0 #eee;
    border-radius: 0;
    border: 1px solid #eee;
    padding: 0 45px 0 15px;
}

textarea {
    padding-top: 18px;
}

.input-field {
    position: relative;
    margin: 0;
    cursor: text;
}

.input-field:before {
    position: absolute;
    right: 13px;
    top: 13px;
    color: #ddd;
    font-size: 21px;
}

input.error,
textarea.error {
    border: 1px solid #ec3a33;
    box-shadow: none;
}

input.valid,
textarea.valid {
    border: 1px solid #7ec0f0;
}

label.error {
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
    color: red;
    text-align: left;
    font-style: italic;
    font-weight: 700;
    display: block;
    width: 310px;
    font-size: 12px;
    padding-left: 5px;
}

#sign-up div {
    text-align: center;
}

#sign-up label.error {
    width: 495px;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
    box-shadow: none;
    color: var(--white-color);
    pointer-events: none;
    opacity: 0.7;
}

.submit-button {
    margin-left: 15px;
}

.feedback-text {
    margin-bottom: 0;
    line-height: 24px;
    font-size: 13px;
    font-style: italic;
}

.js.csstransitions #top,
.js.csstransitions #side,
.js.csstransitions #main {
    opacity: 0;
    transition: all 0.1s ease-in;
}

.js.csstransitions .loaded #top,
.js.csstransitions .loaded #side,
.js.csstransitions .loaded #main {
    opacity: 1;
}


/* Helpers etc. */

.highlight {
    color: #219b89;
    font-weight: bold;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.centered {
    text-align: center;
}

address {
    margin-bottom: 0px;
}

address .address-item {
    position: relative;
    display: block;
    margin-bottom: 25px;
    line-height: 22px;
    padding-left: 14%;
}

address .address-item:before {
    font-family: 'icomoon';
    position: absolute;
    top: 1px;
    left: 0;
    color: #bbb;
    font-size: var(--font-size-heading-medium);
}

.social-icons a {
    display: inline-block;
    font-size: var(--font-size-heading-medium);
    text-decoration: none;
    margin: 0 15px 15px 0;
    padding: 10px;
    color: #f7f3f3;
}

.social-icons a:nth-child(2) {
    padding-left: 17px;
    padding-right: 17px;
}

.social-icons a:hover {
    background: none;
}

.callout {
    text-align: center;
    font-size: var(--font-size-heading-medium);
    line-height: 42px;
}

.huge {
    margin: 50px 0 -50px 0;
    display: block;
    font-size: 420px;
    color: #F5F5F5;
}

.box {
    text-align: center;
    font-size: 15px;
    background: #f8f8f8;
    padding: 50px 20px 20px;
    min-height: 340px;
    margin-bottom: 25px !important;
}

.icon-box {
    width: 100%;
    margin: 0 auto;
}

.icon-box p {
    font-size: 15px;
    line-height: 24px;
}

.icon-box .icon {
    display: inline-block;
    font-size: 48px;
    margin-bottom: 10px;
}

.spinner-holder {
    float: right;
    position: relative;
    left: -30px;
    top: 4px;
}

.section-split .box {
    background: #f8f8f8;
}

.box>*:last-child,
.box>*:last-child>*:last-child,
.box>*:last-child>*:last-child>*:last-child {
    margin-bottom: 0;
}

.box>*:first-child,
.box>*:first-child>*:first-child,
.box>*:first-child>*:first-child>*:first-child {
    margin-top: 0;
}

.icon-overview {
    text-align: center;
}

.icon-overview span {
    font-size: 25px;
    display: inline-block;
    margin: 10px;
    transition: all 0.1s ease-in;
}

.icon-overview span:hover {
    color: #333;
}


/* Re-used transitions */

body,
.head-top,
.nav-trigger,
.head-top .background-blur,
.page-top .toggle-side,
.page-top .toggle-side:before,
.side-meta,
.portfolio-item .item-description,
.portfolio-item .item-description:after,
.main-nav,
.main-nav ul li ul,
.top-nav,
.top-nav ul li ul {
    transition: all 0.2s cubic-bezier(0.712, 0.0376, 0.392, 1);
}


/* ==========================================================================
   Header styles
   ========================================================================== */


.page-top {
    position: relative;
}

.head-top .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
}

/* Header Top Bar */

.head-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: black;
    background: var(--white-color);
    padding: 14px 0;
    z-index: 9999;
    transition: all 1s;
}

#content.wonderbar-content {
    margin-top: 118px;
}

.securly-free-pages #content {
    margin-top: 70px;
    margin-bottom: 20px;
    /* Below margin added for push below it as wonderbar added on all pages */
    /* margin-top: 119px; */
}

#content {
    margin-top: 70px;
    /* Below margin added for push below it as wonderbar added on all pages */
    margin-bottom: 20px;
}

.parents-page .head-top {
    margin-top: 60px;
}

.parents-page .head-top.shrink {
    margin-top: 0px;
    background: rgba(255, 255, 255, 0.85);
}

.parents-page .head-top button {
    padding: 10px 30px 5px;
    margin: 0px;
    background-color: #1facd3;
}

.parents-page .head-top.shrink li a {
    color: var(--black-color);
}

.head-top.shrink {
    background: var(--white-color);
    padding: 14px 0;
}

.head-top.darken {
    background: var(--white-color);
}

.head-top .background-blur {
    visibility: hidden;
    opacity: 0;
}

.head-top.shrink .background-blur {
    visibility: hidden;
    opacity: 0;
}

.nav-trigger {
    display: none;
    position: absolute;
    top: 34%;
    right: 25px;
    background: var(--white-color);
    cursor: pointer;
    color: var(--dark-gray-color);
    line-height: normal;
    padding: 10px;
    border-radius: 10px;
    font-size: var(--font-size-body-small);
    line-height: 0;
    z-index: 99999;
}

.nav-trigger.active {
    color: #219b89;
}

.head-top .background-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    /* WebKit bug, no background-attachment + transform3d :( */
    /*background-attachment: fixed;*/
    filter: blur(10px);
}

.head-top .background-blur:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}


/* Main navigation */

.main-nav {
    position: relative;
    z-index: 9999;
    float: right;
}

.main-nav ul,
.top-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.main-nav ul li a,
.top-nav ul li a {
    font-weight: 300;
    position: relative;
}

.main-nav ul li a:hover,
.top-nav ul li a:hover,
.main-nav ul li a:focus,
.top-nav ul li a:focus,
.main-nav ul li a:active,
.top-nav ul li a:active {
    color: var(--blue-color);
    text-decoration: none;
}

.main-nav ul .has-subnav>a:after,
.top-nav ul .has-subnav>a:after {
    font-family: 'icomoon';
    content: "\e25e";
    position: absolute;
    top: 7px;
    right: 8px;
    margin-left: 6px;
    color: #ddd;
    font-size: 8px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    transition: all 0.1s ease-in;
}


/* Page Header */

.page-head {
    position: relative;
    height: 525px;
    background: #2c2e37;
    display: table;
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.page-head:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(16, 24, 61, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.page-head .container {
    position: relative;
    top: 20px;
    z-index: 1;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 15px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.page-head.typography {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.all-features {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/AllFeatures.webp") !important;
    height: 300px;
}

.page-head.parent-features {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/parents.webp") !important;
    height: 420px;
}

.page-head.product {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.team {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/team.webp");
    background-attachment: inherit !important;
}

.page-head.privacy {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/Privacy_Terms.webp");
    height: 320px;
}

.page-head.services {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.terms {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/Privacy_Terms.webp");
    height: 320px;
}

.page-head.columns {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.pricing {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/features.webp");
    height: 300px;
}

.page-head.icons {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.lost {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.work-two {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.work-three {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.work-four {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.grid {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.work-single {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/single-feature1.webp");
}

.page-head.blog {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/single-feature1.webp");
}

.page-head.blog-full {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.news {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/News_Events.webp");
    height: 300px;
}

.page-head.blog-grid {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.blog-single {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.blog-single-full {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.archive {
    background-image: url("http://placehold.it/1400x525");
}

.page-head.contact {
    background-image: url("http://placehold.it/1400x525");
}

.page-top .toggle-side {
    position: absolute;
    top: 0;
    right: -60px;
    background: #24282a;
    width: 60px;
    height: 100%;
    cursor: pointer;
    font-size: 28px;
    color: #aaa;
}

.shrink .toggle-side {
    right: 0;
}

.page-top .toggle-side:before {
    position: absolute;
    top: 27%;
    padding: 0 15px;
}

.page-top .toggle-side:hover:before,
.side-active .toggle-side:before {
    color: var(--white-color);
    transform: rotate(180deg);
}

.shrink .toggle-side:before {
    padding: 0 15px;
}


/* ==========================================================================
   Main/Layout Styles
   ========================================================================== */


/* Section Split */

.section-split {
    background-color: #f8f8f8;
    padding: 40px 0;
    margin: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.section-split.tinted {
    background-color: #e1f1ef;
}

.section-split.stick {
    margin-bottom: -70px;
}

.section-split.embed {
    height: 450px;
}

.section-split:last-child {
    margin-bottom: 0px;
    padding-bottom: 40px;
    background: #ebebeb;
}

.section-split.parallax {
    display: table;
    height: 550px;
    width: 100%;
}

.section-split.parallax a:nth-child(even):hover {
    background: none;
}

.section-split.parallax .container {
    display: table-cell;
    vertical-align: middle;
}

.section-split.clients {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/ResourcesBG.webp");
}

.section-split.product {
    background-image: url("http://placehold.it/1400x800");
}


/* Side Meta Area */

.side-meta {
    position: fixed;
    top: 0;
    right: -275px;
    width: 275px;
    height: 100%;
    padding: 40px 30px;
    background: #24282a;
    color: #eee;
    cursor: pointer;
    overflow-y: scroll;
    z-index: 999999;
}

.side-active .side-meta {
    right: 0;
    cursor: default;
}

.side-meta .close-side {
    position: absolute;
    top: -8px;
    right: 0;
    cursor: pointer;
    font-size: 12px;
    padding: 30px;
    padding-right: 23px;
    z-index: 999;
}

.side-meta .close-side:hover {
    color: var(--white-color);
}

.side-meta .meta-title {
    color: var(--white-color);
    font-size: var(--font-size-heading-medium);
    font-weight: 700;
    margin: 0 0 6px 0;
}

.side-page-description {
    margin-bottom: 50px;
}

.breadcrumbs {
    position: relative;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    list-style: none;
}

.breadcrumbs:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 40%;
    height: 1px;
    background: var(--white-color);
}

.breadcrumbs li {
    display: inline-block;
    font-size: 12px;
    font-style: italic;
}

.breadcrumbs li a {
    display: block;
    position: relative;
    border: none;
    background: none;
    margin-right: 6px;
    padding-right: 6px;
    color: #eee;
    text-decoration: underline;
}

.breadcrumbs li a:after {
    content: "/";
    font-size: 11px;
    text-decoration: none;
    position: absolute;
    right: -4px;
    top: 1px;
}

.block-button {
    display: block;
    line-height: normal;
    color: var(--white-color);
    background: #141719;
    font-size: var(--font-size-body-extra-small);
    border: none;
    text-align: center;
    padding: 15px 5px;
    margin: 0 0 10px 0;
    font-weight: 700;
    text-transform: uppercase;
}

.social-blocks {
    margin: 30px 0 30px 0;
    padding: 0;
    list-style: none;
}

.social-blocks li {
    float: left;
    width: 25%;
}

.social-blocks a {
    display: block;
    border: none;
    text-align: center;
    color: var(--white-color);
    background: #141719;
    line-height: normal;
    padding: 13px 0;
    font-size: var(--font-size-body-small);
    border: 1px solid #24282a;
}


/* Side meta active layout transition */

body {
    left: 0;
}

.side-active,
.side-active .head-top {
    left: -275px;
}


/* Service Box */

.service-box {
    text-align: center;
}

.service-box:before {
    font-size: 68px;
    color: #219b89;
    opacity: 0.7;
}


/* Feature Box */

.feature-box {
    position: relative;
    padding-left: 60px;
    margin-bottom: 35px;
}

.feature-box.short {
    position: relative;
    padding-left: 60px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.feature-box:before {
    position: absolute;
    left: 0;
    top: 4px;
    color: #999;
    font-size: var(--font-size-heading-large);
}

.feature-box:hover:before {
    color: #999 !important;
    transition: all 0.3s ease-in;
}

.feature-title {
    font-size: var(--font-size-body-large);
    color: #219b89;
    font-weight: 300;
}

.feature-title:hover {
    color: var(--dark-gray-color);
}

.circle-stats {
    display: inline-block;
    position: relative;
    z-index: 99;
    padding: 0 3%;
    width: 200px;
}

.circle-stats p {
    position: relative;
    display: inline-block;
    text-align: center;
    color: var(--white-color);
    font-weight: 300;
    font-size: var(--font-size-body-large);
    text-transform: uppercase;
    font-style: italic;
    margin: 0;
}

.circle-stats p:before {
    content: " ";
    position: absolute;
    top: -24%;
    left: -10%;
    background: #219b89;
    width: 0;
    height: 0;
    padding: 60%;
    border-radius: 100%;
    z-index: -1;
    opacity: 0.8;
    transition: all 0.1s ease-in;
}

.circle-stats .stat {
    display: block;
    font-style: normal;
    font-size: 72px;
    line-height: normal;
}


/* Employee */

.employee {
    text-align: left;
    box-sizing: border-box;
    padding: 20px 15px;
    border-radius: 5px;
    background-color: #f8f8f8;
    margin-bottom: 30px;
}

.employee .employee-image {
    border-radius: 100%;
    border: 2px solid var(--white-color);
}

.founder {
    float: left;
    margin-right: 20px;
}

.employee .employee-name {
    color: var(--dark-gray-color);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 21px !important;
}

.employee .employee-title {
    font-style: italic;
    color: #999;
    font-size: var(--font-size-body-small);
    font-weight: 300;
    margin-bottom: 15px;
}

.employee .employee-social {
    margin: 0;
}

.employee .employee-social.founder {
    margin-top: -10px;
    margin-left: 174px;
}

.employee .employee-social a {
    color: #aaa;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0 5px 0 3px;
    border-right: 1px solid #aaa;
    border-bottom: 0;
    font-weight: 700;
    line-height: normal;
}

.employee .employee-social a:first-child {
    padding-left: 0;
}

.employee .employee-social a:last-child {
    padding-right: 0;
    border: none;
}

.employee .employee-social a:hover {
    background: none;
    color: #111;
}

.investor-list li {
    display: inline;
    list-style: none;
    height: 300px;
    vertical-align: middle;
}

.investor-list li img {
    vertical-align: middle;
}

.investor-list li .founder {
    margin-top: 21%;
}

.investor-list li .new-schools {
    margin-top: 26%;
}


/* Image Overlay */

.image-overlay {
    position: relative;
    margin-bottom: 10px;
}

.image-overlay .overlay-text {
    position: absolute;
    z-index: 19;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 27% 0;
    font-weight: 700;
    font-size: var(--font-size-heading-small);
    color: var(--white-color);
    text-transform: uppercase;
}

.image-overlay .overlay-text:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
}

.image-overlay img {
    position: relative;
    display: block;
    z-index: 9;
}


/* Pricing Tables */

.pricing-page {
    background: #f3f3f3;
}

.pricing-table {
    margin: 0 0 45px 0;
    background: var(--white-color);
    border-radius: 10px;
    padding: 0px 0px 50px 0px;
    list-style: none;
    text-align: center;
}

.pricing-table li {
    box-sizing: border-box;
    background: var(--white-color);
    margin: 10px 0 3px 0;
    line-height: normal;
    padding: 3px 15px;
    font-size: var(--font-size-body-medium);
}

.pricing-table li button {
    width: 200px;
}

.pricing-table .pricing-edition {
    position: relative;
    margin-top: -30px;
    background: var(--white-color);
    color: #33b294;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 20px 15px;
    border-top: solid 10px #33b294;
    border-radius: 10px;
}

.blue-edition {
    border-top: solid 10px #4c8ec1 !important;
    color: #4c8ec1 !important;
}

.pricing-table .pricing-edition .special-lead {
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 2px 0;
    background: #219b89;
    color: var(--white-color);
    text-transform: uppercase;
    text-align: center;
    font-size: 11px;
    letter-spacing: normal;
}

.special .pricing-edition {
    background: #0a0b0f;
}

.pricing-table .pricing-edition span {
    display: block;
    font-size: 26px;
}

.pricing-table .pricing-price {
    background: none;
    color: var(--white-color);
    font-size: 70px;
    line-height: 70px;
    padding: 25px 35px 85px 20px;
    text-align: center;
    white-space: nowrap;
    background: #33b294;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0px auto -10px;
}

.pricing-table .pricing-price span {
    font-size: 34px;
    margin-right: 10px;
    margin-left: -10px;
}

.blue-price {
    background: #4c8ec1 !important;
    padding-left: 15px !important;
    letter-spacing: -7px;
}

.blue-price h4 {
    letter-spacing: normal;
    margin-left: 22px !important;
}

.blue-price sup {
    left: 10px !important;
}

.pricing-price h4 {
    margin-left: 13px;
    color: var(--white-color);
    margin-top: -5px;
    font-weight: 300;
}

.special .pricing-price {
    color: #5e5e5e;
}

.pricing-table sup {
    position: relative;
    font-size: var(--font-size-heading-large);
    top: -38px;
    left: 23px;
}

.pricing-table sub {
    position: relative;
    right: 25px;
    bottom: -15px;
    font-size: var(--font-size-body-large);
}

.pricing-table .signup {
    padding: 0;
}

.pricing-table .signup .button {
    font-size: var(--font-size-body-medium);
    font-weight: bold;
    text-transform: none;
    padding: 15px 0;
    display: block;
    margin: 0;
}


/* Portfolio */

.portfolio-item {
    position: relative;
    display: block;
    border: none;
    color: var(--white-color);
    margin-bottom: 30px;
}

.portfolio-item:hover {
    color: var(--white-color);
}

.portfolio-item .item-image {
    width: 100%;
}

.portfolio-item .item-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
    opacity: 0;
}

.portfolio-item:hover .item-description {
    opacity: 1;
}


/* Tap as hover on touch devices DO NOT work with visibility, use display property instead */

.touch .portfolio-item .item-description {
    display: none;
}

.touch .portfolio-item:hover .item-description {
    display: block;
}

.portfolio-item .item-description:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0 solid rgba(255, 255, 255, 0.75);
}

.portfolio-item:hover .item-description:after {
    border-width: 10px;
}

.portfolio-item .inner-content {
    position: absolute;
    top: 42%;
    left: 0;
    width: 100%;
    padding: 0 30px;
}

.portfolio-item .inner-content span {
    display: block;
}

.portfolio-item .item-description .item-title {
    font-size: var(--font-size-body-small);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3px;
}

.portfolio-item .item-description .item-tags {
    font-style: italic;
    font-size: 12px;
    color: #eee;
    margin-top: 3px;
}

.portfolio-container.grid {
    margin-bottom: 50px;
}

.grid .portfolio-item {
    float: left;
    margin: 0;
    max-width: 24.9%;
}


/* Portfolio filter */

.portfolio-filter {
    margin: 0 0 45px 0;
    padding: 30px 0 20px 0;
    list-style: none;
    text-align: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.portfolio-filter li {
    display: inline-block;
}

.portfolio-filter li a {
    display: block;
    margin: 0 12px 10px 12px;
    border: none;
    line-height: normal;
    font-size: var(--font-size-body-medium);
    color: #bbb;
    text-transform: uppercase;
    font-weight: bold;
}

.portfolio-filter li .selected,
.portfolio-filter li a:hover {
    color: #222;
    background: none;
}


/* Portfolio Navigation */

.portfolio-nav {
    border-bottom: 1px solid #eee;
    padding-bottom: 28px;
    margin-bottom: 70px;
}

.portfolio-nav a {
    display: inline-block;
    border: none;
    color: var(--dark-gray-color);
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: lowercase;
    padding: 0 20px 0 0;
}

.portfolio-nav .pull-right a {
    border-right: 1px solid #eee;
}

.portfolio-nav .pull-right a:last-child {
    border-right: none;
    padding-right: 0;
}

.portfolio-nav a:before {
    font-family: 'icomoon';
    content: "\e2ad";
    font-size: 11px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
}

.portfolio-nav a:hover {
    color: #222;
    background: none;
}

.portfolio-nav .nav-next {
    padding: 0 0 0 20px;
}

.portfolio-nav .nav-next:before {
    content: "\e2a9";
    float: right;
    margin: 0 0 0 5px;
    top: 4px;
}

.portfolio-nav .nav-overview:before {
    content: "\e1c1";
    top: 1px;
    margin: 0 9px 0 0;
}


/* Pagination */

.pagination {
    display: block;
    text-align: center;
    color: #ccc;
    border-top: 1px solid #eee;
    padding-top: 35px;
}

.pagination a {
    display: inline-block;
    border: none;
    color: var(--dark-gray-color);
}

.pagination .paging-link {
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: lowercase;
    margin: 0 20px 0 0;
}

.pagination .paging-link:before {
    font-family: 'icomoon';
    content: "\e2ad";
    font-size: 11px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
}

.pagination a.paging-link:hover {
    color: #222;
    background: none;
}

.pagination .next {
    margin: 0 0 0 20px;
}

.pagination .next:before {
    content: "\e2a9";
    float: right;
    margin: 0 0 0 5px;
    top: 4px;
}

.pagination ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
}

.pagination ul li {
    display: inline-block;
}

.pagination ul li a {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    padding: 9px 0 11px 1px;
    background: var(--white-color);
    border: 1px solid #ccc;
    border-radius: 100%;
    text-align: center;
    color: #333;
    font-weight: 700;
    line-height: normal;
}

.pagination ul .current,
.pagination ul li a:hover {
    background: #888;
    color: var(--white-color);
    border-color: #888;
}

.blog-full .pagination {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px dotted #eee;
}


/* Blog */

.article-post {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px dotted #eee;
}

.article-post:last-of-type {
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: none;
}

.article-container .article-post {
    width: 30%;
    margin: 15px;
    background: #fafafa;
    padding: 0;
    border: none;
}

.article-container .article-title {
    font-weight: 400;
    font-size: var(--font-size-heading-medium);
    margin-bottom: 10px;
}

.article-post .article-content {
    padding: 10px 18px;
}

.blog-full .article-post {
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
}

.article-post .article-image,
.article-post .article-embed,
.article-post .fluid-width-video-wrapper {
    margin-bottom: 20px;
}

.article-embed {
    width: 100%;
    height: auto;
}

.article-meta {
    font-size: 12px;
    color: #777;
    font-style: italic;
}

.article-meta span {
    display: block;
    margin-bottom: 10px;
}

.article-container .article-meta {
    margin-bottom: 20px;
    line-height: 18px;
}

.article-container .article-meta span {
    display: inline;
    margin-bottom: 5px;
}

.article-meta .meta-author-image {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

.article-meta .meta-author-bio {
    position: relative;
    font-size: var(--font-size-body-extra-small);
    color: #777;
    margin-bottom: 22px;
    padding-bottom: 20px;
}

.article-meta .meta-author {
    position: relative;
    font-size: var(--font-size-body-small);
    font-weight: 700;
    font-style: normal;
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.article-meta .meta-author:before,
.article-meta .meta-author-bio:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    border-bottom: 1px solid #eee;
}

.article-full .article-meta {
    text-align: right;
}

.article-full .article-meta .meta-author {
    margin-bottom: 10px;
    padding-bottom: 0;
}

.article-full .article-meta .meta-author:before {
    content: none;
}

.article-full .article-meta .meta-author:before,
.article-full .article-meta .meta-author-bio:before {
    left: auto;
    right: 0;
}

.article-tags {
    color: #aaa;
    text-transform: uppercase;
    font-size: 12px;
}

.article-tags a {
    color: #aaa;
    margin-right: 7px;
    border: none;
}

.article-tags a:hover {
    color: var(--white-color);
}


/* Article Navigation */

.article-nav {
    padding: 25px 0;
    line-height: normal;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: 0 0 60px 0;
}

.article-nav a {
    display: block;
    font-size: 13px;
    color: #999;
    border: none;
    padding: 0 10px;
}

.article-nav .col-xs-6 {
    border-right: 1px solid #eee;
}

.article-nav>div:last-child {
    border: none;
}

.article-nav .article-post-date {
    font-style: italic;
}

.article-nav .prev-article {
    text-align: right;
}

.article-nav a .nav-label {
    display: inline-block;
    text-transform: uppercase;
    color: #666;
    font-size: 11px;
    font-weight: bold;
}

.article-nav .prev-article .nav-label:before {
    font-family: 'icomoon';
    content: "\e2ad";
    margin-right: 5px;
    font-size: 10px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
}

.article-nav .next-article .nav-label:after {
    font-family: 'icomoon';
    content: "\e2a9";
    margin-left: 5px;
    font-size: 10px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
}

.article-nav .post-title {
    color: #219b89;
    font-size: var(--font-size-body-medium);
    font-weight: 300;
    line-height: 22px;
    margin: 8px 0 3px 0;
}

.article-nav a:hover {
    background: none;
}

.article-nav a:hover .post-title {
    color: #333;
    transition: all 0.1s ease-in;
}


/* Comments */

.comments {
    margin-top: 70px;
}

.comments ul {
    list-style: none;
}

.comments .comment-count {
    margin: 0 0 35px 0;
    font-size: 26px;
}

.comment-count .count-bubble {
    display: inline-block;
    position: relative;
    top: -12px;
    color: var(--white-color);
    line-height: 14px;
    font-weight: 700;
    font-size: var(--font-size-body-extra-small);
    margin-left: 5px;
    text-align: center;
    width: 32px;
}

.comment-count .count-bubble:before {
    position: absolute;
    top: -6px;
    left: 0;
    z-index: -1;
    color: #404040;
    font-size: var(--font-size-heading-large);
}

.comment-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.comment-container {
    position: relative;
    border-bottom: 1px dotted #ddd;
    margin-bottom: 40px;
    padding-bottom: 40px;
}

.comment-container .comment-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
}

.comment-container .comment-image img {
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.comment-container .comment-image:hover {
    background: none;
}

.comment-container .comment-content {
    margin-left: 75px;
}

.comment-container .comment-author {
    display: inline-block;
    font-size: var(--font-size-body-medium);
    color: #333;
    margin-bottom: 3px;
    line-height: normal;
    border: none;
}

.bypostauthor .comment-container .comment-author {
    color: #219b89;
}

.comment-container .is-author {
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    color: #aaa;
    line-height: 28px;
    margin-left: 10px;
}

.comment-container .comment-author:hover {
    color: var(--dark-gray-color);
    background: none;
}

.comment-container .comment-date {
    margin-bottom: 10px;
    font-size: 12px;
    color: #8b8b8b;
    font-style: italic;
    line-height: normal;
}

.comment-container .comment-body {
    color: var(--dark-gray-color);
    font-size: var(--font-size-body-extra-small);
    line-height: 24px;
    margin: 0;
}

.comment-container .reply-button {
    position: relative;
    display: none;
    font-size: 11px;
    color: var(--dark-gray-color);
    line-height: normal;
    margin-left: 11px;
    border-bottom: 1px solid transparent;
}

.comment-container .reply-button:before {
    content: " ";
    position: absolute;
    top: 5px;
    left: -8px;
    width: 4px;
    height: 4px;
    background: #ccc;
    border-radius: 100%;
}

.comment-container:hover .reply-button {
    display: inline-block;
}

.comment-container .reply-button:hover {
    color: #333;
    background: none;
    border-bottom: 1px solid #333;
}


/* Nested Comments */

.children {
    margin-left: 60px;
}


/* ==========================================================================
   Footer styles
   ========================================================================== */

.page-footer {
    position: relative;
    padding: 0px;
    background: #F1F4F5;
    color: #eee;
    display: block;

}

/*UP ARROW*/
.to-top {
    position: absolute;
    z-index: 9;
    left: 70%;
    background: #F1F4F5 !important;
    top: -37px;
    border-radius: 100%;
    text-align: center;
    padding: 32px 33px 32px 32px;
    cursor: pointer;
    font-size: 28px;
}

.to-top:before {
    position: relative;
    top: -15px;
}

.to-top:hover,
.to-top:active,
.to-top:visited {
    z-index: 9;
    background: #F1F4F5;
    color: var(--white-color);
    text-decoration: none;
}

.footer-contact address {
    font-size: var(--font-size-body-extra-small);
}

.footer-contact address label {
    color: #4c5c62;
    display: block;
}

.footer-menu a,
.footer-contact a {
    color: #4c5c62;
    border-color: var(--dark-gray-color);
    transition: all 0.1s ease-in;
}

.footer-contact a:hover {
    color: #253136 !important;
}

.footer-menu a:hover {
    color: #b9f0fc !important;
}

.footer-menu a img,
.footer-contact a img {
    background: #F1F4F5;
}

.footer-menu a {
    color: #f4f5f6;
}

.footer-menu a:hover {
    color: #ddd !important;
}

.disclaimer {
    margin-left: -25px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 0px;
    text-align: right;
    line-height: 28px;
    color: #4C5C62;
}

/*.footer-row {
    margin-right: 0%;
    padding-right: 0px;
    }*/

.footer-contact {
    padding: 30px 30px 0px 20px;
    background: #f4f5f6;
    right: 0px;
    z-index: 99;
    /*position: absolute;*/
    /*height: 202px;*/
    /*min-width: 360px;*/
}

.footer-menu {
    padding: 30px 30px 6px;
    background: #4C5C62;
    color: var(--white-color);
}


/* ==========================================================================
   Plugin styles
   ========================================================================== */




/* FlexSlider Necessary Styles
*********************************/

.flexslider {
    padding: 0 0 80px 0;
    margin: 0;
    position: relative;
}

.flexslider .slides>li {
    position: relative;
    display: none;
}


/* Hide the slides before the JS is loaded. Avoids image jumping */

.flexslider .slides img {
    width: 100%;
    display: block;
}

.flex-pauseplay span {
    text-transform: capitalize;
}


/* Clearfix for the .slides element */

.slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}


/* No JavaScript Fallback */


/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */

.no-js .slides>li:first-child {
    display: block;
}

.flex-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flex-container .slides {
    position: relative;
    z-index: 99;
}

.flex-container li iframe {
    width: 100%;
    height: 100%;
}

.flex-container .slides li .flex-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    background: black;
    background: rgba(0, 0, 0, 0.7);
    color: var(--white-color);
    padding: 10px;
}

.flex-direction-nav {
    position: absolute;
    left: 0;
    top: 40%;
    z-index: 999;
    width: 100%;
}

.flex-direction-nav li a {
    position: absolute;
    left: 25px;
    opacity: 0;
    visibility: hidden;
    display: block;
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: all 0.1s ease-in;
}

.flex-container:hover .flex-direction-nav li a {
    opacity: 0.6;
    visibility: visible;
}

.flex-direction-nav li a:hover {
    background: none;
    opacity: 1 !important;
}

.flex-direction-nav li a:before {
    font-family: 'icomoon';
    content: "\e2d1";
    color: var(--white-color);
    font-size: 36px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    transition: all 0.1s ease-in;
}

.flex-direction-nav li .flex-next {
    left: auto;
    right: 25px;
}

.flex-direction-nav li .flex-next:before {
    content: "\e2cf";
}

.flex-control-nav {
    position: absolute;
    bottom: 35px;
    left: 0;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.flex-control-nav li {
    display: inline-block;
    text-indent: -9999px;
}

.flex-control-nav li a {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #999;
    cursor: pointer;
    border: none;
    margin: 0 5px;
}

.flex-control-nav li .flex-active,
.flex-control-nav li a:hover {
    background: #219b89;
}


/* Tabs */

.tab-nav {
    margin: 0 0 5px 0;
    padding: 0;
    list-style: none;
}

.tab-nav li {
    display: inline-block;
}

.tab-nav li a {
    display: block;
    text-decoration: none;
    color: var(--dark-gray-color);
    background-color: #f4f4f4;
    padding: 8px 15px;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    margin: 0 5px 10px 0;
    line-height: normal;
}

.tab-nav .active a,
.tab-nav li a:hover {
    background-color: #219b89;
    color: var(--white-color);
}

.tab-content {
    background-color: #eee;
    border-radius: 10px;
    padding: 15px;
}

.tab-pane>*:first-child,
.tab-pane>*:first-child>*:first-child,
.tab-pane>*:first-child>*:first-child>*:first-child {
    margin-top: 0;
}

.tab-pane>*:last-child,
.tab-pane>*:last-child>*:last-child,
.tab-pane>*:last-child>*:last-child>*:last-child {
    margin-bottom: 0;
}


/* Vertical Tabs */

.vertical .tab-nav-container {
    text-align: right;
    border-right: 1px solid #eee;
    padding: 40px 0;
}

.vertical .tabs-title {
    text-transform: uppercase;
    margin-right: 13px;
    margin-top: 75px;
}

.vertical li:first-child h4 {
    margin-top: 0;
}

.vertical .tab-nav-container li {
    display: block;
}

.vertical .tab-nav-container a {
    display: block;
    background: none;
    font-weight: normal;
    color: #888;
    margin: 0;
    border-radius: 0;
}

.vertical .tab-nav-container .active a,
.vertical .tab-nav-container a:hover {
    background: #f5f5f5;
    color: #333;
}

.vertical .tab-nav-container .active a {
    font-weight: bold;
}

.vertical .tab-content {
    background: none;
    border-radius: 0;
    padding: 33px 0;
}


/* Collapsibles (toggle/accordion */

.collapsible {
    margin-bottom: 10px;
    border: 0;
    box-shadow: none;
}

.collapse-title {
    position: relative;
    font-size: var(--font-size-body-extra-small);
    color: #333;
    background: #f1f1f1;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 15px 35px 15px 15px;
    background-color: #219b89;
    color: var(--white-color);
    line-height: normal;
    transition: all 0.1s ease-in;
}

.collapse-title:after {
    font-family: 'icomoon';
    content: "\e25e";
    position: absolute;
    top: 32%;
    right: 15px;
    margin-left: 6px;
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    transition: all 0.1s ease-in;
}

.collapse-title.collapsed {
    background: #f1f1f1;
    color: #333;
}

.collapse-title.collapsed:after {
    content: "\e25d";
    color: var(--dark-gray-color);
}

.collapse-title:hover {
    background-color: #219b89;
    color: var(--white-color);
}

.collapse-title:hover:after {
    color: var(--white-color);
}

.collapsible-pane {
    padding: 15px;
    background: #fafafa;
}

.collapsible-pane>*:first-child,
.collapsible-pane>*:first-child>*:first-child,
.collapsible-pane>*:first-child>*:first-child>*:first-child {
    margin-top: 0;
}

.collapsible-pane>*:last-child,
.collapsible-pane>*:last-child>*:last-child,
.collapsible-pane>*:last-child>*:last-child>*:last-child {
    margin-bottom: 0;
}


/* Alerts */

.close:hover,
.close:focus {
    background: none;
}


/* Progress Bars */

.progress {
    height: 14px;
    border-radius: 7px;
    box-shadow: none;
    background: #eee;
}

.progress-default {
    background: #219b89;
}

.progress-bar {
    box-shadow: none;
}

.progress-bar:last-child {
    border-radius: 0 7px 7px 0;
}


/**** Isotope Filtering ****/

.portfolio-container .col-sm-3 {
    margin-right: -1px;
}

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}


/**** Isotope CSS3 transitions ****/

.isotope,
.isotope .isotope-item {
    transition: all 0.8s cubic-bezier(0.712, 0.0376, 0.392, 1);
}


/* Magnific Popup CSS */

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.9;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #cccccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #cccccc;
}

.mfp-preloader a:hover {
    color: var(--white-color);
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0px;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: var(--white-color);
    font-style: normal;
    font-size: 28px;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: var(--white-color);
    border-radius: 50%;
    line-height: 25px;
    margin-top: 5px;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: var(--white-color);
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #cccccc;
    font-size: 12px;
    line-height: 18px;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border-right: 17px solid var(--white-color);
    margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border-left: 17px solid var(--white-color);
    margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: black;
}


/* Main image in popup */

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}


/* The shadow behind the image */

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444444;
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

.partner-img {
    margin-bottom: 30px;
}

.partner-img .amplified {
    margin-top: 45px;
}

.partner-img .ctl {
    margin-top: 30px;
}

.partner-img .google {
    margin-top: 50px;
}

.partner-img .amplified {
    margin-top: 45px;
}

/* Media page css starts (By Vinod) - 13-10-16 */

#media-hero {
    min-height: 50%;
    width: 100%;
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/media-hero-img.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 20%;
    padding-bottom: 20%;
}

#media-hero .page-title {
    font-size: 60px;
    font-weight: 700;
}

.page-sub-title {
    color: var(--white-color);
    font-size: 48px;
    font-weight: 400;
    margin-top: 24px;
}

.page-section {
    padding: 70px 0 30px;
}

.page-section .blue-title {
    color: #7db6e5;
}

.media-content {
    background-color: #ebf0f4;
}

.page-section h2 {
    font-size: var(--font-size-heading-extra-large);
    margin-bottom: 40px;
    text-align: center;
}

.media-content p {
    font-size: var(--font-size-body-large);
    line-height: 36px;
}

.page-content .row {
    margin-right: 32px;
    margin-left: 32px;
}

.profile-content .row {
    margin-right: 32px;
    margin-left: 32px;
}

.profile-content [class*="col-"] {
    padding-right: 32px;
    padding-left: 32px;
}

.profile img {
    border-radius: 4px;
    margin-bottom: 28px;
    width: 100%;
}

.profile h4 {
    font-size: var(--font-size-heading-small);
    margin: 0 0 8px;
}

.profile h5 {
    font-size: var(--font-size-body-large);
    margin: 0 0 20px;
    font-weight: 400;
}

.img-holder {
    margin: 0 -10px;
    position: relative;
}

.img-holder [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.img-holder img {
    width: 100%;
}

.team-img1,
.team-img2,
.team-img3 {
    background-size: cover;
    background-position: center;
    float: left;
    width: 32%;
    height: 524px;
}

.team-img1 {
    /*background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/team-img2.webp);*/
    margin-right: 2%;
}

.team-img2 {
    /*background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/founders.webp);*/
    margin-right: 2%;
}

.team-img3 {
    /*background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/cl-people.webp);*/
    float: right;
}

.download-link {
    color: #666;
    font-size: var(--font-size-body-large);
    margin-bottom: 12px;
    display: block;
}

.download-link .ss {
    color: #67b7d8;
    font-size: var(--font-size-body-large);
    margin-left: 10px;
    display: inline-block;
}

.profile .fa {
    color: #999;
    font-size: var(--font-size-body-large);
}

.more-info {
    margin: 100px 0 0;
    text-align: center;
}

.more-info [class*="col-"] {
    margin-bottom: 50px;
}

.more-info .col-xs-12 {
    margin-bottom: 0;
}

.more-info .fa {
    color: #27b2df;
    font-size: 42px;
    border-radius: 50%;
    border: 2px solid #27b2df;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    text-align: center;
    margin-bottom: 50px;
}

.more-info h5 {
    font-size: var(--font-size-heading-small);
    line-height: 30px;
    margin-bottom: 14px;
}

.more-info p {
    font-weight: 400;
    font-size: var(--font-size-body-medium);
}

.timeline {
    padding-bottom: 20px;
}

.timeline h2 {
    margin-top: -20px;
}

.press-kit {
    background-color: #ebf0f4;
}

.white-box {
    /*width: 348px;*/
    height: 240px;
    background-color: var(--white-color);
    border: 2px solid #d0d7dd;
    position: relative;
    display: block;
}

.white-box:hover,
.white-box:focus {
    background-color: var(--white-color);
    border-color: #c1c6ca;
}

.white-box h4 {
    font-size: 26px;
    line-height: 34px;
    margin: 30px 10px 0 30px;
}

.white-box h5 {
    font-size: var(--font-size-body-medium);
    font-weight: 400;
    line-height: var(--line-height-md);
    margin: 14px 30px;
}

.white-box .ss {
    position: absolute;
    left: 45%;
    bottom: 20px;
    color: #3991ff;
    font-size: 30px;
}

.white-box .pink {
    color: #FD949C;
}

.white-box .green {
    color: #60B2A7;
}

.white-box .yellow {
    color: #DFB684;
}

.white-box .blue {
    color: #4CBDF7;
}

.press-kit .row {
    padding: 40px;
}

.press-kit [class*="col-"] {
    padding: 0 10px 70px 10px;
    /* padding: 0 20px 70px 20px; */
}

.media-enquiries {
    text-align: center;
    padding-bottom: 60px;
    padding-right: 10px;
    padding-left: 10px;
}

/* Media page css ends (By Vinod) - 13-10-16 */

/* Accomplishments 2016 css starts */
#accomplishments-hero {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/accomplishments-header.webp) no-repeat bottom right #83d5e1;
    padding: 12% 75px;
}

.page-heading {
    color: var(--white-color);
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 50px;
    line-height: 54px;
}

.page-heading div {
    margin-top: 24px;
}

.page-sub-text {
    font-size: var(--font-size-body-medium);
    line-height: 30px;
}

.page-heading img {
    display: inline;
}

.btn-white {
    border-radius: 3px;
    color: rgb(84, 178, 226);
    font-size: var(--font-size-body-extra-small);
    line-height: 24px;
    font-weight: 400;
    letter-spacing: normal;
    padding: 11px 28px;
    background-color: rgb(255, 255, 255);
    font-size: var(--font-size-body-small);
    width: auto;
}

.btn-white:hover,
.btn-white:focus {
    background-color: rgb(255, 255, 255);
    color: #54b2e2;
}

.white-wrap {
    border-radius: 4px;
    background-color: var(--white-color);
    text-align: center;
    margin: 0 60px 80px;
    padding-bottom: 40px;
}

.white-wrap .page-section {
    padding-top: 40px;
}

.white-wrap img {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.white-wrap h3 {
    color: #53b2e2;
    font-size: 30px;
    line-height: 34px;
    margin: 20px 0;
}

.accomplishments-wrapper {
    background-color: rgb(235, 236, 238);
    margin-bottom: -100px;
    padding-bottom: 100px;
}

.blue-btn-small {
    margin-top: 20px;
    border-radius: 3px;
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    line-height: 22px;
    font-weight: 400;
    padding: 11px 32px;
    background-color: rgb(83, 178, 226);
}

.blue-btn-small:hover,
.blue-btn-small:focus {
    background-color: rgb(83, 178, 226);
    color: var(--white-color);
}

.blue-border-btn {
    color: #53b2e2;
    border: 2px solid #53b2e2;
    background-color: transparent;
}

.blue-border-btn:hover,
.blue-border-btn:focus {
    color: var(--white-color);
    background-color: #53b2e2;
}

.white-border-btn {
    border: 2px solid var(--white-color);
    background-color: transparent;
}

.white-border-btn:hover,
.white-border-btn:focus {
    color: #53b2e2;
    background-color: var(--white-color);
}

.awards-wrap img {
    width: auto;
    max-width: 110px;
}

.padding-box {
    border-radius: 0;
    margin: 0;
    padding-bottom: 0;
    position: relative;
    text-align: left;
}

.padding-box .text-left {
    text-align: left;
}

.awards-wrap {
    text-align: center;
}

.padding-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.portal-img .col-sm-6 {
    background-color: #dee7eb;
}

.delegated-img .col-sm-6 {
    background-color: #a4b6bd;
}

.delegated-img .col-sm-6,
.self-serve-img .col-sm-6,
.delegated-img h2,
.self-serve-img h2 {
    color: var(--white-color);
}

.auditor-img .col-sm-6 {
    background-color: #dee7eb;
}

.self-serve-img .col-sm-6 {
    background-color: #a4b6bd;
}

.padding-box [class*="col-"] {
    padding: 80px 60px;
}

.awards-wrap [class*="col-"] {
    padding: 40px 15px;
}

.padding-box .page-section h2 {
    margin-top: 0;
    margin-bottom: 20px;
}

.padding-box .page-section p {
    font-size: var(--font-size-body-medium);
    line-height: 30px;
}

.padding-box .page-section {
    padding: 0;
}

.padding-box img {
    border-radius: 0;
}

/* Accomplishments 2016 css ends */


/* Home page css starts */
.right-text {
    text-align: right;
}

.awards-updates ul li {
    margin-right: 0;
    padding: 0 15px;
}

.awards-updates span {
    margin-top: 8px;
}

.awards-updates span {
    margin-top: 8px;
    font-family: var(--semi-bold-font);
    font-weight: 700;
}

.middle-img {
    margin-top: 22px;
}

.section-split.pre-footer {
    padding: 120px 40px 40px;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
}

.section-split.pre-footer:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/prefooter-bg.webp) top center no-repeat !important;
    background-size: cover !important;
}

.section-split.pre-footer img {
    max-height: 30px;
    max-width: 240px;
}

.pre-footer h4 {
    text-align: left;
    margin-bottom: 50px;
}

.pre-footer .ctl {
    max-height: 42px;
}

.pre-footer .google,
.pre-footer .wsg {
    margin-top: 8px;
}

.pre-footer .container-fluid>.row {
    margin-right: -50px;
    margin-left: -50px;
}

.pre-footer .container-fluid .col-md-6 {
    padding-right: 50px;
    padding-left: 50px;
}

.pre-footer .row {
    margin-right: -15px;
    margin-left: -15px;
}

.white-bg,
.sectionwise.white-bg {
    background-color: var(--white-color);
}

.white-bg h2,
.white-bg h3 {
    font-weight: 400;
}

.custom-page .section-title {
    margin-top: 0;
    color: #0079a1;
    font-size: 36px;
}

.custom-page .features .container {
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: transparent;
}

.custom-page .section-split {
    padding-top: 60px;
    padding-bottom: 60px;
}

.custom-page .our-schools {
    padding: 10px 0;
}

.custom-page .section-title:after {
    bottom: -40px;
    background: #37383c;
    left: 50%;
    width: 4%;
    margin-left: -2%;
}

.custom-page .feature-title {
    font-size: 30px;
    line-height: var(--line-height-md);
    margin: 0 0 14px;
    color: #37383c;
}

.custom-page .feature-title:hover,
.custom-page .feature-title:focus {
    color: #0079a1;
}

.custom-page p {
    font-size: var(--font-size-body-medium);
    line-height: 28px;
}

.custom-page .features .container {
    border-bottom: 1px solid #cfd5e1;
    padding-bottom: 60px;
}

.custom-page .features .container:last-child,
.custom-page .features .brdr-btm-nn,
.brdr-btm-nn {
    border-bottom: 0;
}

.hero-buttons a {
    color: var(--black-color);
}

.hero-buttons i {
    display: inline-block;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/hero-icons.webp);
    width: 42px;
    height: 46px;
    position: relative;
    top: 12px;
}

.homehero i.home-icon {
    background-position: -52px 0;
}

.homehero i.home-icon+span {
    margin-top: 24px;
}

.homehero i.bully-icon {
    background-position: -103px 0;
}

.homehero i.reports-icon {
    background-position: -155px 0;
}


.btn-hero.big-grey-button {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--white-color);
    background-color: #4C5C62;
    border-radius: 30px;
    border: 0;
    padding: 16px 36px;
    /*width: 340px;
    display: block;*/
    margin: 10px 20px 0 0;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
}

.btn-hero.big-grey-button:hover,
.btn-hero.big-grey-button:focus {
    color: var(--white-color);
    background-color: #253136;
}


.btn-hero.big-grey-button:active {
    box-shadow: none;
}

.homepage .schools {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage .schools div img {
    max-width: 100%;
    max-height: 80px;
}

.testimonials-wrapper {
    background-color: #f7f7f7;
    padding-bottom: 100px;
    margin-top: -100px;
}

.testimonials-container {
    min-height: 448px;
    margin: 0 auto;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/art-bg.webp) center no-repeat #f7f7f7;
}

.said-things {
    max-width: 400px;
    margin: 60px auto 0;
    position: relative;
    background-color: var(--white-color);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
    color: #333;
    padding: 30px 20px 20px;
    text-align: center;
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-xs);
    position: relative;
    top: 10px;
}

.said-things h4 {
    margin-bottom: 30px;
    font-size: var(--font-size-heading-small);
    line-height: var(--line-height-sm);
    font-weight: 400;
}

.homepage .said-things p {
    margin-bottom: 10px;
    line-height: var(--line-height-lg);
}

.circle-img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto 50px;
}

.quote-mark {
    position: absolute;
    top: 20px;
    left: -54px;
    max-width: 80px;
}

.page-content .middle-row .learn-more:last-child {
    margin-left: 0 !important;
}

.page-content .middle-row {
    border-bottom: 1px solid #cfd5e1;
    margin-top: 60px;
    padding-bottom: 60px;
}

.page-content .brdr-btm-nn .middle-row {
    border-bottom: 0;
}

.home-superslides h1,
.home-superslides h2 {
    margin-right: 10px;
}

.parallax-header {
    height: 700px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/BG-01.webp) top center no-repeat;
    background-size: cover;
    position: relative;
}

.scene {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    height: 700px;
}

.scene img {
    width: 100%;
}

.layer1,
.layer2,
.layer3 {
    position: absolute !important;
    bottom: 10% !important;
    top: auto !important;
    width: 33%;
}

.layer1 {
    left: 0 !important;
}

.layer2 {
    bottom: 0 !important;
    left: 30% !important;
}

.layer3 {
    left: auto !important;
    right: 0 !important;
    text-align: right;
}

/* Home page css ends */

/* About page css starts */

.spacing {
    margin: 60px 0 50px;
}

#investors {
    margin-bottom: 60px;
}

#owl-ventures {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/owl-ventures-logo.webp);
    max-width: 136px;
}

#y-combinator {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/y-combinator-logo.webp);
}

.board-members {
    text-align: left;
    width: 90%;
    margin: 0 auto;
}

.board-members img {
    max-width: inherit;
}

.board-members .media-body,
.board-members .media-left,
.board-members .media-right {
    display: table-cell;
    vertical-align: top;
}

.board-members .media-left,
.board-members .media>.pull-left {
    padding-right: 40px;
}

.board-members .media-body {
    width: 10000px;
}

/* About page css ends */

/* New About us page starts */

.about-footer img {
    width: 100%;
}

.founders-team .col-sm-4,
.dream-team .col-sm-4 {
    margin-bottom: 30px;
}

.box-profile.hover-actions,
.box-profile.hover-actions h4,
.box-profile.hover-actions .fa-linkedin-square {
    transition: all .2s linear;
}

/*.box-profile.hover-actions img,*/

.box-profile.hover-actions {
    background-color: transparent;
}

.box-profile img {
    width: 100%;
}

.box-profile {
    padding: 28px;
    color: #8b8b8b;
    font-size: var(--font-size-body-small);
    line-height: 24px;
    font-weight: 400;
}

.box-profile.hover-actions:hover {
    box-shadow: 6px 2px 26px rgba(0, 0, 0, 0.08);
}

.box-profile h3,
.box-profile h4 {
    font-weight: 400;
}

.box-profile h3 {
    color: #1d2d35;
    font-size: 25px;
    margin-bottom: 6px;
}

.box-profile h4 {
    margin-top: 0;
    color: #8b8b8b;
}

.dream-team .box-profile h4 {
    margin-bottom: 0;
}

.box-profile.hover-actions:hover h4 {
    color: #3590ed;
}

.box-profile.hover-actions:hover .fa-linkedin-square {
    color: #0274b3;
}

.box-profile .fa-linkedin-square {
    color: #8b8b8b;
    font-size: 26px;
    display: inline-block;
    margin-top: 20px;
}

.dream-team,
.founders-team,
.board-of-members {
    padding: 80px 0;
    background-color: #f1f2f3;
}

.founders-team,
.board-of-members {
    background-color: transparent;
}

.dream-team h2,
.founders-team h2,
.board-of-members h2,
#investors .spacing {
    margin-bottom: 60px;
    font-size: 42px;
}

#about-one {
    margin-top: 120px;
}

#about-one h2,
#how-we h2 {
    font-size: 42px;
    color: #1d2d35;
}

#about-one,
#how-we {
    color: #4c5c62;
}

.box-profile {
    background-color: var(--white-color);
}

.company-linkedin {
    margin-top: 30px;
}

.company-linkedin a {
    float: left;
}

.company-linkedin a.fa-linkedin-square {
    margin-top: 0;
}

.company-linkedin img {
    width: auto;
    margin-right: 10px;
}

.blue-design-footer {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-bottom-bg.svg') center bottom no-repeat;
    color: #4c5c62 !important;
    padding-top: 14%;
    padding-bottom: 14%;
    font-size: 19px;
    margin-top: 60px;
}

.blue-design-footer .container {
    position: relative;
    top: -180px;
}

.blue-design-footer:before {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-top-design.svg') center top no-repeat;
    background-size: cover;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.blue-design-footer h2 {
    color: #1d2d35;
    font-size: 42px;
}

.blue-design-footer p,
.blue-design-footer h3,
.blue-design-footer i {
    color: #4c5c62;
}

.blue-design-footer i,
.blue-design-footer .fa {
    color: #3590ed !important;
    font-size: 2.3em;
}

.margin-h3 {
    margin-top: 0;
}

.team-carousel .slick-slide {
    margin: 0 15px;
}

.team-carousel h5 {
    font-size: 15px;
    color: var(--dark-gray-color);
    margin-top: 12px;
    margin-bottom: 0;
}

.team-carousel h5 p {
    margin: 0;
}

.team-carousel .slick-dots {
    bottom: -50px;
}

.box-profile-inline {
    margin: 0 -15px;
}

.paddingTop0 {
    padding-top: 0;
}

.flex-column {
    display: flex;
    flex-wrap: wrap;
}

.morecontent span {
    display: none;
}

.morelink {
    display: inline-block;
    font-size: var(--font-size-body-extra-small);
    text-decoration: none;
}

.morelink:hover,
.morelink:focus {
    text-decoration: underline;
}

/* SecurlyHome page css starts */

.securlyhome-header {
    position: relative;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    min-height: calc(100vh - 104px);
    color: var(--white-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.securlyhome-header h1 p,
.securlyhome-header h3 p {
    margin-bottom: 0;
}

.securlyhome-header h1,
.securlyhome-header h2,
.securlyhome-header h3 {
    color: var(--white-color);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.securlyhome-header h1 {
    font-weight: 600;
    margin-top: 14px;
}

.securlyhome-header .securlyHome h1 {
    margin-bottom: 2px;
}

.securlyhome-header .securlyHome h2 {
    font-weight: 600;
    margin-top: 6px;
    font-size: var(--font-size-heading-large);
}

.securlyhome-header .securlyHome h3 {
    font-size: var(--font-size-body-large);
}

.securlyhome-header h3 {
    font-weight: 400;
    margin-bottom: 10px;
}

.securlyhome-header em {
    font-weight: 400;
}

.blur-btn {
    color: var(--white-color);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 17px 70px 17px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 60px;
    position: absolute;
    right: 60px;
    bottom: 50px;
}

.blur-btn:hover,
.blur-btn:focus {
    background-color: rgba(0, 0, 0, 0.37);
    color: var(--white-color);
}

.blur-btn .video-icon {
    border-color: #398eff;
    margin: 0;
    position: absolute;
    right: 0;
    top: -2px;
}

.blur-btn .video-icon,
.blur-btn .video-icon:after {
    transition: all 0.1s ease-in;
}

.blur-btn .video-icon:after {
    border-left: 10px solid #398eff;
}

.blur-btn:hover .video-icon:after {
    border-left: 10px solid #4C5C62;
}

.blur-btn:hover .video-icon {
    border-color: #4C5C62;
}

.blur-btn .video-icon:active {
    box-shadow: none;
}

.securlyhome-header .white-btn {
    border: 2px solid #3980ff;
    padding: 12px 40px 12px 30px;
    margin: 30px 30px 30px 0;
    line-height: 1.7;
}

.securlyhome-header .white-btn:hover,
.securlyhome-header .white-btn:focus {
    background-color: #f3f7ff;
}

.securlyhome-header .white-btn img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.securlyHome-inro {
    background-color: var(--white-color);
    padding-top: 80px;
    padding-bottom: 100px;
    color: #4c5c62;
    font-size: var(--font-size-body-large);
    line-height: 28px;
    margin-bottom: 46px;
    text-align: center;
}

.securlyHome-inro h1,
.inner-container h1 {
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: #1d2d35;
    margin-bottom: 26px;
}

.partners-intro h2 {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 500;
    line-height: var(--line-height-sm);
    color: var(--heading-text-color);
}

.partners-intro p {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-large);
    line-height: 30px;
    color: #454F5B;
}

.securlyhome-logo {
    text-align: center;
    width: 70px;
}

.securlyHome-inro p {
    width: 76%;
    margin-left: auto;
    margin-right: auto;
}

.school-devices-content {
    max-width: 94%;
    min-height: 617px;
    margin: 0 auto 46px;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.school-devices-content .inner-container {
    width: 26%;
    margin-left: 8%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #4c5c62;
    font-size: var(--font-size-body-large);
    font-weight: 400;
    line-height: 28px;
}

.two-containers {
    font-size: var(--font-size-body-small);
    line-height: 22px;
    width: 98%;
    margin: 0 auto 140px;
    display: flex;
}

.two-containers .col-sm-6 {
    background-color: var(--white-color);
    padding: 46px 26px;
    margin: 0 2%;
    width: 46%;
    position: relative;
}

.two-containers span {
    position: absolute;
    top: 4px;
    right: 0;
    display: block;
    text-align: right;
    margin: 14px 26px 0 0;
    color: #e26b7e;
    font-size: var(--font-size-heading-medium);
    font-weight: 700;
}

.two-containers .icon {
    max-width: 80px;
    max-height: 52px;
    margin: 0 auto;
    display: block;
}

.two-containers .pnp-hub-inner .icon {
    max-width: 92px;
    max-height: inherit;
}

.two-containers .inner-container {
    width: 70%;
    margin: 0 auto;
}

.white-btn.blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    margin-top: 24px;
    margin-right: 0;
    padding: 16px 40px;
    height: 44px;
}

.white-btn.blue-btn:hover {
    background-color: var(--blue-btn-hover);
}

.white-btn.blue-btn:focus,
.white-btn.blue-btn:active {
    background-color: var(--blue-btn-active);
}

.inner-container a {
    display: inline-block;
}

.inner-container a img {
    margin-top: 34px;
}

.inner-container a:first-child img {
    margin-right: 12px;
}

.solution-for-all-devices {
    position: relative;
    background-position: 0 0;
    background-color: #666;
}

.solution-for-all-devices .inner-container {
    color: var(--white-color);
    margin-left: 0;
    width: 26%;
    position: absolute;
    right: 8%;
}

.solution-for-all-devices .inner-container h1,
.solution-for-all-devices .inner-container h2,
.solution-for-all-devices .inner-container h3 {
    color: var(--white-color);
}

.school-devices-content .btn {
    margin-top: 26px;
}

.school-devices-content .white-btn {
    border: 2px solid #3980ff;
    color: #3980ff;
}

.school-devices-content .white-btn:hover,
.school-devices-content .white-btn:focus {
    background-color: #eff4ff;
}

.two-containers .pnp-device-container {
    background-color: transparent;
    padding: 0;
}

.pnp-device-image {
    border-radius: 50%;
    border: 14px solid #80c1e6;
    max-width: 90%;
}

.pnp-hub-info {
    display: block;
    margin-bottom: 70px;
}

.pnp-hub-info.securlyHome h3 {
    font-size: var(--font-size-heading-small);
}

.pnp-hub-info ul {
    margin: 0 0 0 28%;
    padding-left: 12px;
    list-style: none;
    text-align: left;
}

.pnp-hub-info .ul-container {
    text-align: center;
}

.pnp-hub-info .ul-container ul {
    display: inline-block;
    margin: auto;
    width: auto;
}

.pnp-hub-info li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/bullet-img.svg) 0 2px no-repeat;
    padding-left: 28px;
    background-size: 15px;
    margin-bottom: 1px;
}

.blue-text {
    color: #3980ff;
    margin-top: -6px;
}

.securlyHome-testimonials {
    background-color: var(--white-color);
    padding: 90px 0;
    margin-bottom: 46px;
}

.box-less-spacing {
    margin-bottom: 0;
    padding-bottom: 60px;
}

.securlyHome-testimonials .testimonial-block .school-img {
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border-radius: 50%;
}

.securlyHome-testimonials h1 {
    text-align: center;
    color: #1d2d35;
    font-weight: 600;
    margin: 0 20px 70px 20px;
}

.subscribe-wrapper {
    background-color: var(--white-color);
    width: 92%;
    padding: 90px 0;
    margin: 0 auto 100px;
    font-size: var(--font-size-body-small);
    line-height: 24px;
}

.subscribe-wrapper h1 {
    font-weight: 600;
    margin-bottom: 20px;
}

.subscribe-form {
    width: 490px;
    margin: 50px auto 20px;
    position: relative;
}

.subscribe-form .form-control {
    border-radius: 0;
    height: 57px;
    padding: 20px 60px 20px 20px;
    border-top: 1px solid #d7d8d9;
    border-right: 1px solid #d7d8d9;
    border-bottom: 1px solid #d7d8d9;
    border-left: 6px solid #3980ff;
    margin: 0 auto;
    font-size: var(--font-size-body-small);
    line-height: 20px;
    box-shadow: none;
}

.tick-submit-btn {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/subscribe-img.svg) 0 0 no-repeat;
    background-size: 70px;
    width: 70px;
    height: 70px;
    border: 0;
    position: absolute;
    top: -7px;
    right: -6px;
    border-radius: 0;
}

.tick-submit-btn:hover,
.tick-submit-btn:focus,
.tick-submit-btn:active {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/subscribe-img.svg) 0 0 no-repeat;
    border: 0;
}

.terms-container li,
.privacy-container li {
    margin-bottom: 12px;
}

/* Case studies page css starts */

.casestudy-header {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 54px);
    color: var(--white-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.casestudy-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 100%);
}

.casestudy-header .container {
    position: relative;
    z-index: 2;
}

.casestudy-header.leftTop {
    background-position: top left;
}

.casestudy-header.leftBottom {
    background-position: bottom left;
}

.casestudy-header.rightTop {
    background-position: top right;
}

.casestudy-header.rightBottom {
    background-position: bottom right;
}

.casestudy-header.center {
    background-position: center;
}

.casestudy-header h1,
.casestudy-header h2,
.casestudy-header h3 {
    color: var(--white-color);
}

.casestudy-header h1,
.casestudy-header h2 {
    font-weight: 600;
}

.school-data {
    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
}

.school-logo-name {
    float: left;
    display: flex;
    align-items: center;
}

.school-data img {
    float: left;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    margin-right: 50px;
}

.school-data h2 {
    font-family: var(--semi-bold-font);
    font-weight: 500;
    color: #1d2d35;
    margin: 13px 0;
}

.dataset .datasetright {
    float: right;
}

.dataset ul {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
}

.dataset li {
    float: left;
    width: 146px;
    margin-left: 20px;
    text-align: center;
    border-left: 1px solid #ebecec;
    padding-left: 20px;
}

.dataset li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}

.dataset h3 {
    font-size: 42px;
    color: #333;
    margin: 0 0 4px;
}

.dataset span {
    font-size: 21px;
    line-height: var(--line-height-sm);
    color: #808080;
}

.middle-casestudy {
    border-top: 1px solid #ebecec;
    padding: 30px 0;
}

.middle-casestudy .row {
    margin-right: -15px;
    margin-left: -15px;
}

.casestudy-content {
    font-size: var(--font-size-body-small);
    line-height: 24px;
    color: #666;
    margin-bottom: 100px;
}

.casestudy-content h1,
.casestudy-content h2 {
    font-family: var(--semi-bold-font);
    font-weight: 500;
    color: #1d2d35;
}

.casestudy-content h1,
.casestudy-content h2,
.casestudy-content h3,
.casestudy-content h4 {
    line-height: var(--line-height-md);
    margin-top: 50px;
    margin-bottom: 14px;
}

.casestudy-content h1 {
    font-size: 36px;
}

.casestudy-content h2 {
    font-size: 30px;
}

.casestudy-content h3 {
    font-size: 26px;
}

.casestudy-content h4 {
    font-size: var(--font-size-body-large);
}

.casestudy-testimonials {
    margin-bottom: 60px;
}

.casestudy-testimonials .new-testimonials {
    max-width: 380px;
    margin: 0 auto;
}

.casestudy-testimonials h1 {
    text-align: center;
    color: #1d2d35;
    font-weight: 600;
    line-height: var(--line-height-md);
    margin: 0 20px 70px 20px;
}

.casestudy-testimonials .testimonial-block .school-img {
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border-radius: 50%;
}

.white-btn.large-btn {
    font-size: var(--font-size-heading-medium);
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 100px;
}

@media only screen and (max-width: 1450px) {
    .school-data h2 {
        font-size: 30px;
    }

    .dataset h3 {
        font-size: 36px;
    }

    .dataset span {
        font-size: var(--font-size-body-medium);
    }

    .dataset li {
        width: 120px;
        padding-left: 13px;
        margin-left: 13px;
    }

    .school-data img {
        margin-right: 30px;
    }

    .school-data img {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 1199px) {
    .casestudy-header {
        min-height: calc(100vh - 54px)
    }

    .school-logo-name,
    .dataset .datasetright {
        float: none;
    }

    .casestudy-iframe {
        height: 260px;
    }

    .school-data {
        padding: 30px 0;
    }

    .white-btn.large-btn {
        font-size: var(--font-size-body-large);
        padding: 14px 36px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .dataset li:first-child {
        width: auto;
        padding-right: 13px;
        margin-right: 13px;
    }
}

@media only screen and (max-width: 767px) {
    .casestudy-header h1 {
        font-size: 28px;
    }

    .casestudy-header:after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.4) 100%);
    }

    .school-data {
        width: auto;
        padding: 20px 0 10px;
    }

    .school-data h2 {
        font-size: var(--font-size-heading-small);
    }

    .dataset h3 {
        font-size: 28px;
    }

    .dataset span {
        font-size: var(--font-size-body-small);
    }

    .dataset li {
        width: 48%;
        padding-left: 10px;
        margin-left: 10px;
        margin-bottom: 18px;
    }

    .dataset li:nth-child(3) {
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
    }

    .school-data img {
        margin-right: 20px;
    }

    .school-data img {
        width: 80px;
        height: 80px;
    }

    .casestudy-content,
    .casestudy-testimonials {
        margin-bottom: 30px;
    }

    .white-btn.large-btn {
        margin-bottom: 70px;
    }

    .casestudy-testimonials h1 {
        margin: 0 15px 40px 15px;
        font-size: 30px;
    }

    .casestudy-testimonials .testimonial-block {
        margin: 0;
    }

    .casestudy-content h1,
    .casestudy-content h2,
    .casestudy-content h3,
    .casestudy-content h4 {
        margin-top: 30px;
    }

    .casestudy-content h1 {
        font-size: 34px;
    }

    .casestudy-content h2 {
        font-size: 28px;
    }

    .casestudy-content h3 {
        font-size: var(--font-size-heading-medium);
    }

    .casestudy-content h4 {
        font-size: var(--font-size-body-medium);
    }

    .casestudy-iframe {
        height: 170px;
        margin-top: 20px;
    }
}

/* Case studies page css ends */

/* State landing page css starts */

.state-landing-header {
    min-height: inherit;
    height: calc(100vh - 219px);
    /* Below margin added for push below it as wonderbar added on all pages */
    margin-top: 70px;
}

.state-landing-header .container-outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.state-landing-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 109px);
    background-color: rgba(0, 0, 0, 0.4);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 100%);
}

.state-landing-header h3 {
    font-weight: 400;
    margin-bottom: 10px;
}

.state-landing-header h1 {
    font-weight: 600;
    margin-top: 14px;
}

.numbers-holder {
    text-align: center;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.state-school-data {
    margin: 0 0 0 -60px;
    padding: 0;
    list-style: none;
    display: inline-block;
    color: var(--white-color);
    font-size: 17px;
}

.state-school-data li {
    width: 170px;
    float: left;
}

.state-school-data li div {
    border-left: 1px solid rgba(255, 255, 255, 0.7);
    margin-left: 30px;
    padding-left: 30px;
}

.state-school-data li:first-child div,
.state-school-data li:nth-child(2) div {
    border-left: 0;
    padding-left: 0;
}

.state-school-data h3 {
    color: var(--white-color);
    font-size: var(--font-size-heading-extra-large);
    margin: 0 0 2px;
}

.state-school-data h4 {
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    font-weight: 400;
    text-align: right;
    margin: 0;
}

.state-school-data h4 p {
    margin-bottom: 0;
}

.state-intro-content {
    font-size: var(--font-size-body-large);
}

.state-intro-content h1 {
    font-weight: 600;
    color: #1d2d35;
    margin-bottom: 24px;
}

.state-intro-content .btn {
    margin-bottom: 60px;
}

.upcoming-events {
    background-color: #f1f2f3;
    padding: 50px 0;
}

.upcoming-events h1 {
    font-weight: 600;
    color: #1d2d35;
    margin-bottom: 70px;
}

.upcoming-events a.row {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #676767;
    font-size: var(--font-size-body-small);
    line-height: 22px;
    margin-bottom: 20px;
}

.upcoming-events a.row:hover,
.upcoming-events a.row:focus {
    background-color: var(--white-color);
}

.event-picture {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
}

.event-picture.leftTop,
.partners-header.leftTop,
.state-header-slider .leftTop,
.updates-header.leftTop {
    background-position: top left;
}

.event-picture.leftBottom,
.partners-header.leftBottom,
.state-header-slider .leftBottom,
.updates-header.leftBottom {
    background-position: bottom left;
}

.event-picture.rightTop,
.partners-header.rightTop,
.state-header-slider .rightTop,
.updates-header.rightTop {
    background-position: top right;
}

.event-picture.rightBottom,
.partners-header.rightBottom,
.state-header-slider .rightBottom,
.updates-header.rightBottom {
    background-position: bottom right;
}

.event-picture.center,
.partners-header.center,
.state-header-slider .center,
.updates-header.center {
    background-position: center;
}

.event-details {
    width: 70%;
    margin: 30px auto;
}

.event-details h2 {
    font: 500 30px/1.3 var(--semi-bold-font);
    color: #1d2d35;
}

.law-content {
    margin-top: 40px;
    margin-bottom: 90px;
    font-size: var(--font-size-heading-medium);
    line-height: 30px;
    font-weight: 400;
    color: #1d2d35;
    padding: 0;
}

.law-content .col-sm-6 {
    margin-bottom: 30px;
}

.law-content .inner-data h2 {
    color: #1d2d35;
    font-family: var(--semi-bold-font);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 16px;
}

.law-content .inner-data h2 p {
    margin-bottom: 16px;
}

.law-content .inner-data {
    background-color: #f1f2f3;
    padding: 30px 70px 90px 50px;
    color: #808080;
    font-size: var(--font-size-body-small);
    line-height: 22px;
}

.law-img {
    display: block;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    margin-bottom: 22px;
}

.law-content .btn {
    color: #3980ff;
    font-size: var(--font-size-body-medium);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.5px;
    background-color: transparent;
    padding: 8px 0;
    margin-right: 0;
    position: absolute;
    bottom: 20px;
    z-index: 2;
}

.law-content .btn:hover,
.law-content .btn:focus,
.law-content .btn:active {
    background-color: transparent;
    box-shadow: none;
    color: #3170e1;
}

.law-content .btn span {
    font-size: 30px;
    line-height: 1;
    display: inline-block;
    margin-left: 6px;
    position: relative;
    top: 2px;
}

.schools-logos {
    margin-top: 80px;
    text-align: center;
}

.schools-logos div {
    margin-left: -80px;
}

.schools-logos h1 {
    font-weight: 600;
    color: #1d2d35;
    margin-bottom: 80px;
}

.schools-logos img {
    display: inline-block;
    margin: 0 0 40px 80px;
}

.state-testimonials {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 60px;
}

.state-testimonials .testimonial-block .school-img {
    margin-bottom: 20px;
}

.state-testimonials .testimonial-block .school-img.schools-logo {
    width: auto;
    height: auto;
    max-width: 100%;
    border-radius: inherit;
}

.testimonial-block.video {
    padding-top: 0;
}

.testimonial-block.video iframe {
    margin-bottom: 20px;
}

.in-the-news h1,
.law-content h1 {
    font-weight: 600;
    color: #1d2d35;
    margin-bottom: 60px;
}

.in-the-news {
    margin-bottom: 100px;
}

.in-the-news.news-top-margin {
    margin-top: 50px;
}

.in-the-news img {
    display: inline-block;
    width: 250px;
    height: 142px;
    max-width: 250px;
    max-height: 142px;
    margin-right: 28px;
}

.news-holder span {
    font-size: var(--font-size-body-large);
    color: #808080;
}

.news-holder h2 {
    font-family: var(--semi-bold-font);
    font-size: 28px;
    font-weight: 500;
    color: #1d2d35;
    margin-top: 10px;
}

.news-holder {
    min-height: 150px;
    border: 1px solid #e7e8e9;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
    margin-bottom: 20px;
    padding: 26px;
    display: block;
}

.news-holder:hover,
.news-holder:focus {
    border-color: #e2e2e2;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.state-testimonials .testimonial-block {
    padding-top: 30px;
    position: relative;
}

.state-testimonials .new-testimonials {
    position: relative;
}

.state-testimonials .new-testimonials:before {
    content: "";
    position: absolute;
    top: -36px;
    left: 15px;
    z-index: 2;
    width: 126px;
    height: 94px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/qoute-1.svg) 0 0 no-repeat;
}



@media only screen and (max-width: 991px) {
    .state-school-data {
        margin-left: -28px;
    }

    .state-school-data li {
        width: 120px;
    }

    .state-school-data li div {
        margin-left: 14px;
        padding-left: 14px;
    }

    .state-school-data h3 {
        font-size: 30px;
    }

    .state-school-data h4 {
        font-size: var(--font-size-body-medium);
    }

    .state-school-data {
        font-size: 15px;
        line-height: 22px;
    }

    .law-content .inner-data {
        padding: 30px 40px 80px 30px;
    }

    .state-intro-content {
        margin-top: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .upcoming-events a.row {
        display: block;
    }

    .event-details {
        width: 90%;
    }

    .event-picture {
        min-height: 220px;
    }

    .state-landing-header .container-outer {
        left: auto;
        transform: translateY(-50%);
    }

    .law-content .inner-data {
        padding: 20px 20px 74px 20px;
        margin-bottom: 20px;
    }

    .state-intro-content .btn,
    .law-content {
        margin-bottom: 30px;
    }

    .law-content .btn {
        font-size: var(--font-size-body-small);
    }

    .law-content {
        font-size: var(--font-size-heading-small);
        line-height: 28px;
    }

    .schools-logos img {
        margin-left: 60px;
    }

    .schools-logos div {
        margin-left: -60px;
    }

    .upcoming-events {
        padding: 30px 0;
    }

    .upcoming-events h1 {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .upcoming-events a.row {
        background-color: var(--white-color);
    }

    .news-holder h2 {
        font-size: var(--font-size-heading-medium);
    }

    .news-holder h2,
    .news-holder h2 p {
        margin-bottom: 14px;
    }

    .news-holder span {
        font-size: var(--font-size-body-medium);
    }

    .state-intro-content {
        font-size: var(--font-size-body-medium);
        line-height: 24px;
    }

    .law-content {
        font-size: var(--font-size-body-large);
        line-height: 26px;
    }

    .law-content p {
        margin-bottom: 10px;
    }

    .numbers-holder {
        margin-top: 150px;
        border-bottom: 1px solid #ebecec;
        padding: 0 15px 15px;
    }

    .state-school-data h3,
    .state-school-data h4 {
        color: #1d2d35;
    }

    .state-school-data h4 {
        text-align: center;
        margin: 0 15px;
        min-height: 60px;
    }

    .state-school-data {
        color: #888;
        display: block;
    }

    .news-holder {
        padding: 16px;
    }

    .state-testimonials {
        padding-bottom: 40px;
    }

    .law-content .col-sm-6 {
        margin-bottom: 20px;
    }

    .state-school-data li {
        margin-bottom: 20px;
        width: 50%;
    }

    .state-school-data li.clearfix {
        float: none;
        width: auto;
        margin: 0;
    }

    .state-school-data li div,
    .state-school-data li:nth-child(2) div {
        border-left: 1px solid #ebecec;
    }

    .state-school-data li:nth-child(2) div {
        margin-left: 14px;
        padding-left: 14px;
    }

    .state-school-data li:nth-child(4) div,
    .state-school-data li:nth-child(6) div,
    .state-school-data li:nth-child(7) div,
    .state-school-data li:nth-child(9) div,
    .state-school-data li:nth-child(10) div {
        border-left: 0;
    }

    .state-intro-content {
        margin-top: 20px;
    }

    .schools-logos {
        margin-top: 30px;
    }

    .schools-logos h1,
    .in-the-news h1,
    .law-content h1 {
        margin-bottom: 40px;
    }

    .state-testimonials .new-testimonials:before {
        width: 96px;
        height: 64px;
    }
}

@media only screen and (max-width: 620px) {
    .in-the-news img {
        float: none;
        margin-bottom: 16px;
    }

    .news-holder h2 {
        font-size: var(--font-size-heading-small);
    }

    .news-holder h2,
    .news-holder h2 p {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .state-landing-header:after {
        box-shadow: inset 0 -30px 160px 80px rgba(0, 0, 0, 0.3);
    }
}

@media (min-width: 540px) {
    .state-testimonials .testimonial-block {
        min-height: 350px;
    }
}

/* State landing page css ends */

/* Partners page css starts */

.partners-header .down-pointer {
    z-index: 2;
}

.partners-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 104px);
    background-color: rgba(0, 27, 66, 0.5);
    background: linear-gradient(to right, rgba(0, 27, 66, 0.5) 30%, rgba(0, 27, 66, 0) 100%);
}

.partners-header .container {
    position: relative;
    z-index: 2;
}

.partners-intro {
    margin-bottom: 100px;
    padding-bottom: 0;
}

.shadow-boxes {
    color: #505e6b;
    font: 500 16px/22px var(--regular-font);
    margin-bottom: 100px;
}

.shadow-boxes .inner-container {
    background-color: var(--white-color);
    border: 1px solid #ebebec;
    padding: 30px 28px 30px;
    margin-bottom: 30px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.05);
}

.shadow-boxes .col-sm-4:nth-of-type(1) .inner-container {
    border-top: 3px solid #f5cd80;
}

.shadow-boxes .col-sm-4:nth-of-type(2) .inner-container {
    border-top: 3px solid #7bb2ad;
}

.shadow-boxes .col-sm-4:nth-of-type(3) .inner-container {
    border-top: 3px solid #80c1e6;
}

.shadow-boxes img {
    display: block;
    width: 56px;
    height: 56px;
    margin-bottom: 26px;
}

.shadow-boxes h2,
.partner-program h2.sub-h-title {
    font: 500 24px/32px var(--semi-bold-font);
    color: var(--heading-text-color);
}

.partner-program {
    background-color: #f7fafc;
    padding: 90px 0;
    color: #505e6b;
    font: 500 20px/28px var(--regular-font);
}

.ticklist {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
}

.ticklist li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/circle-tick-icon.svg) 0 6px no-repeat;
    padding-left: 30px;
    background-size: 16px;
    margin-bottom: 24px;
}

.partner-program h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.partner-program h4 {
    margin-top: 26px;
    margin-bottom: 20px;
    font: 400 var(--font-size-heading-medium)/1.3 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.partner-program .shadow-boxes {
    margin-top: 100px;
    margin-bottom: 0;
}

.partner-program .shadow-boxes img {
    width: 66px;
    height: 76px;
}

.partners-testimonials {
    margin: 100px 0;
}

.partners-testimonials h2 {
    margin-right: 15px;
    margin-left: 15px;
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.partners-testimonials .container {
    border: 1px solid #dbdcdd;
    box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.07);
    position: relative;
    margin-top: 90px;
    padding-top: 70px;
    padding-bottom: 70px;
    font: 400 var(--font-size-body-small)/24px var(--semi-bold-font);
    color: var(--heading-text-color);
}

.partners-testimonials .container:before {
    content: "";
    display: block;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/qoute-1.svg) 0 0 no-repeat;
    width: 128px;
    height: 94px;
    position: absolute;
    top: -66px;
    left: -2px;
}

.partners-testimonials img {
    display: block;
    max-height: 150px;
    max-width: 150px;
}

.partners-testimonials .container span {
    color: #808080;
    font: 400 var(--font-size-body-small)/1.2 var(--semi-bold-font);
}

.partners-testimonials .container strong {
    font-weight: 700;
}

.partners-testimonials .container span p {
    margin-bottom: 0;
}

.partners-testimonials .inner-testimonials {
    max-width: 800px;
    margin: 0 auto;
}

.btns-bar {
    background-color: #4c5c62;
    padding: 18px 0;
    width: 100%;
    /* position: fixed;*/
    left: 0;
    bottom: 0;
    z-index: 100;
    display: none;
}

@media only screen and (min-width: 768px) {
    .btns-bar {
        position: fixed;
    }
}

.btns-bar.activeOne {
    border-bottom: 1px solid #babec0;
    position: relative;
}

.btns-bar .col-sm-4 {
    text-align: center;
}

.btns-bar a {
    display: inline-block;
    width: 86%;
    margin-right: 0;
    padding: 13px 30px;
    font: 400 var(--font-size-body-medium)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.btns-bar a:hover,
.btns-bar a:focus {
    background-color: #313c40;
    color: var(--white-color);
}

.partners-header .white-btn {
    padding-right: 50px;
    padding-left: 50px;
}

.partners-row .right-bg-content section {
    width: 100%;
}

.partners-form .row {
    margin: 0 -25px;
}

.partners-form .col-sm-6 {
    padding-right: 25px;
    padding-left: 25px;
}

.partners-form .mui-select {
    padding-top: 24px;
}

.partners-form .mui-textfield--float-label>label {
    font-size: 15px;
    line-height: 30px;
}

.form-section .partners-form .mui-textfield input {
    height: 36px !important;
    font-size: 15px;
}

.partners-form .signup-button,
.white-popup-form .blue-color-button {
    margin-top: 20px;
    background-color: #3980ff;
    border-radius: 40px;
    font-size: var(--font-size-body-medium);
}

.partners-form .signup-button:hover,
.white-popup-form .blue-color-button:hover,
.partners-form .signup-button:focus,
.white-popup-form .blue-color-button:focus {
    background-color: #2771f6;
}

.partners-form textarea {
    resize: none;
}

.white-popup-form {
    position: relative;
    background: var(--white-color);
    padding: 40px 20px;
    border-radius: 8px;
    width: auto;
    max-width: 700px;
    min-height: 340px;
    margin: 20px auto;
    font: 400 var(--font-size-body-medium)/24px var(--regular-font);
    text-align: center;
    color: #898989;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-popup-form h3 {
    font-size: var(--font-size-heading-medium);
    font-weight: 600;
    margin-bottom: 20px;
}

.white-popup-form .inner-popup {
    width: 60%;
    margin: 0 auto;
}

.white-popup-form img {
    max-width: 50px;
    display: inline-block;
}

.white-popup-form p a {
    color: #1155cc;
    border-bottom: 1px solid #1155cc;
}

.white-popup-form p a:hover,
.white-popup-form p a:focus {
    text-decoration: none;
    border-bottom-style: dashed;
}

.white-popup-form .blue-color-button {
    width: auto;
    padding: 12px 50px;
}

.gray-tip {
    font-weight: 400;
}

.partners-row .gray-tip {
    color: #a0a0a0;
}

.partners-row .writer-info .data {
    margin-left: 0;
    font-weight: 400;
}

.partner-program .image-heading {
    margin-top: 0;
    color: #1d2d35;
    margin-bottom: 0;
}

.partner-pgm-image {
    width: 111px;
    padding-left: 0;
}

.read-more-text {
    font-size: var(--font-size-body-small);
    color: var(--blue-btn-hover);
}

.read-more-text:hover {
    color: #0096FF;
    text-decoration: underline;
}

.read-more-text:active {
    color: #0060A3;
    text-decoration: underline;
}

.partner-program .partner-lft-side {
    margin-top: 40px;
}

.inner-text {
    padding-left: 20px;
}

@media only screen and (max-width: 992px) {
    .partner-program .img-heading-mrg p {
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 1192px) {
    .partner-program .image-heading {
        font-size: var(--font-size-body-small);
    }

    .partner-pgm-image {
        width: 90px;
        padding-left: 0px;
        float: left;
    }
}

/* Signup flow css starts here */

.big-container {
    width: 90%;
    margin: 0 auto;
}

.partners-captcha {
    margin-top: 10px;
}

.partners-captcha .g-recaptcha {
    margin-bottom: 10px;
}

.partners-captcha .help-block {
    color: #F44336;
}

@media only screen and (max-width: 1450px) {
    .btns-bar {
        padding: 10px 0;
    }

    .btns-bar a {
        font-size: var(--font-size-body-small);
        width: 70%;
        padding: 9px 42px;
        border-radius: 40px;
    }

    .partners-header .white-btn {
        padding: 10px 34px;
    }
}

.top-header {
    margin: 20px 0;
}

.top-header .head-logo,
.top-header .head-logo:hover,
.top-header .head-logo:focus {
    height: 28px;
}

.head-logo {
    float: left;
}

.top-right {
    float: right;
}

@media (max-width: 400px) {

    .partners-captcha .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }

    .partners-captcha .g-recaptcha,
    .full-section .g-recaptcha {
        margin-bottom: 0;
    }

    .full-section .g-recaptcha {
        transform: scale(0.87);
        transform-origin: 0 0;
    }

    .partners-form .signup-button {
        margin-top: 10px;
    }
}

/* Partners page css ends */

.top-right span {
    display: inline-block;
    font-size: var(--font-size-body-extra-small);
    font-weight: 400;
    color: var(--blue-gray-color);
    font-family: var(--regular-font);
}

.small-blue-border {
    border-radius: 18px;
    border: solid 1px #0096ff;
    color: #0096ff;
    padding: 5px 30px;
    margin-left: 10px;
}

.small-blue-border:hover,
.small-blue-border:focus {
    color: var(--white-color);
    border-color: #0096ff;
    background-color: #0096ff;
}

.demo-container {
    background-color: var(--white-color);
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, .07);
    position: relative;
    margin-bottom: 40px;
}

.demo-container .col-md-6.hidden-sm {
    position: static;
}

.demo-container h1,
.demo-container h2,
.new-contact h2,
.new-contact .form-heading {
    color: var(--heading-text-color);
    margin-top: 60px;
    margin-bottom: 14px;
    font-size: var(--font-size-heading-extra-large);
    line-height: 1.33;
    font-family: var(--semi-bold-font);
    font-weight: 400;
}

.demo-container .form-title {
    margin-bottom: 0;
}

.demo-container h4 {
    font: 400 var(--font-size-body-medium)/1.44 var(--regular-font);
    color: var(--blue-gray-color);
    margin: 4px 0 10px;
}

.demo-container h5 {
    margin-bottom: 12px;
    margin-top: 0;
}

.demo-container .small-text {
    color: #5c6575;
    display: block;
    margin-bottom: 40px;
    font: 400 var(--font-size-body-extra-small)/28px var(--regular-font);
}

@media only screen and (max-width: 992px) {
    .pnp-hub-info ul {
        margin-left: 16%;
    }

    .two-containers .inner-container {
        width: auto;
    }

    .two-containers .col-sm-6:last-child a,
    .two-containers.securlyHome .col-sm-6:first-child a {
        display: block;
    }

    .two-containers.securlyHome .col-sm-6:last-child a {
        display: inline-block;
    }

    .two-containers.pnp-hub-info .col-sm-6:first-child a {
        display: inline-block;
    }

    .inner-container a img {
        margin-top: 0;
    }

    .inner-container a:first-child img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .securlyhome-header .container {
        width: 90%;
        position: relative;
    }

    .blur-btn {
        right: auto;
        left: 0;
        z-index: 2;
        bottom: -50px;
    }

    .securlyhome-header .white-btn {
        margin-right: 16px;
    }

    .securlyHome-inro {
        padding: 60px 0;
    }

    .school-devices-content {
        min-height: 400px;
    }

    .securlyHome.school-devices-content {
        min-height: 440px;
    }

    .securlyHome.school-devices-content .btn {
        margin-top: 0;
    }

    .securlyhome-header {
        background-position: top center;
        position: relative;
    }

    .securlyhome-header:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
    }

    .securlyhome-header .container {
        position: relative;
        z-index: 2;
        padding: 0;
    }

    .pnp-device-image {
        border-width: 8px;
    }

    .inner-container a:first-child img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .securlyhome-header .container {
        width: 90%;
        position: relative;
    }

    .blur-btn {
        right: auto;
        left: 0;
        z-index: 2;
        bottom: -50px;
    }

    .securlyhome-header .white-btn {
        margin-right: 16px;
    }

    .securlyHome-inro {
        padding: 60px 0;
    }

    .school-devices-content {
        min-height: 400px;
    }

    .securlyHome.school-devices-content {
        min-height: 440px;
    }

    .securlyHome.school-devices-content .btn {
        margin-top: 0;
    }

    .securlyhome-header {
        background-position: top center;
        position: relative;
    }

    .securlyhome-header:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
    }

    .securlyhome-header .container {
        position: relative;
        z-index: 2;
        padding: 0;
    }

    .pnp-device-image {
        border-width: 8px;
    }
}

.demo-container .small-text a {
    color: #0096ff;
    border-bottom: 1px solid transparent;
}

.demo-container .small-text a:hover,
.demo-container .small-text a:focus {
    color: #0096ff;
    text-decoration: none;
    border-bottom: 1px solid;
}

.demo-container h5,
.career-thanks h5 {
    color: var(--blue-gray-color);
    font-size: var(--font-size-body-medium);
    line-height: var(--line-height-md);
    font-family: var(--regular-font);
    font-weight: 400;
}


.demo-container h3 {
    font: 500 20px/1.3 var(--semi-bold-font);
    color: #454f5b;
}

.form-container {
    width: 396px;
    margin-bottom: 40px;
}

.styled-form .form-group {
    margin: 36px 0 28px 0;
}

.form-group-para {
    margin: 16px 0 19px;
}

.styled-form .form-control,
.styled-form #email {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--light-blue-btn-hover);
    box-shadow: none;
    font-size: var(--font-size-body-small);
    line-height: 22px;
    font-weight: 400;
    height: auto;
    padding: 8px 0;
    margin-bottom: 0;
    background-color: var(--white-color) !important;
    box-shadow: none !important;
    font-family: var(--regular-font);
}

.styled-form .bottom-para-text {
    max-width: 273px;
    background-color: #f2f9ff;
    color: var(--btn-color);
    font-size: 12px;
    line-height: 1.33;
    padding: 8px;
    font-family: 'MetropolisMedium', sans-serif;
}

.styled-form .bottom-para-text p {
    margin-bottom: 0;
}

.styled-form #phone {
    font-size: 15px;
}

.styled-form .form-control:active,
.styled-form .form-control:focus {
    border-color: #24d4bb !important;
}

.styled-form select.form-control {
    appearance: none;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/select-icon.webp) top 16px right 12px no-repeat;
    padding: 6px;
    font-size: 15px;
}

.styled-form .form-control::placeholder {
    color: #c9d5e1;
}

.styled-form label {
    font-size: 13px;
    line-height: var(--line-height-md);
    font-weight: 400;
    color: var(--blue-gray-color);
    margin-bottom: 0;
    display: block;
    font-family: var(--regular-font);
}

.styled-form .intl-tel-input .country-list {
    font-weight: 400;
    font-size: 15px;
    line-height: var(--line-height-xl);
}

.styled-form .highlight {
    font-weight: 400;
    color: var(--dark-gray-color);
}

.styled-form .intl-tel-input {
    display: block;
}

.couple-columns .col-sm-7:first-child,
.couple-columns .col-sm-5:first-child {
    padding-left: 0;
}

.couple-columns .col-sm-5:last-child,
.couple-columns .col-sm-7:last-child {
    padding-right: 0;
}

.both-columns .col-sm-6:first-child {
    padding-left: 0;
}

.both-columns .col-sm-6:last-child {
    padding-right: 0;
}

.left-illustration {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/signup-bg-final.svg) top -200px right 3px no-repeat;
    background-size: cover;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

/* Styled radio/checkbox button css starts */
.switch-field {
    display: flex;
    margin-top: 8px;
    /*overflow: hidden;*/
}

.switch-field input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field h6 {
    display: block;
    margin-top: 0;
    margin-bottom: 6px;
    color: var(--blue-gray-color);
    font-family: 'MetropolisMedium', sans-serif;
}

.switch-field label {
    color: var(--blue-gray-color);
    font-size: 13px;
    line-height: var(--line-height-md);
    text-align: center;
    padding: 10px;
    margin-right: -1px;
    border: 1px solid #bec9d3;
    transition: all 0.2s ease-in-out;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafbfc;
}

.switch-field label:hover {
    cursor: pointer;
    background-color: #edf1f5;
}

.switch-field input:checked+label {
    background-color: #707e8c;
    box-shadow: none;
    color: var(--white-color);
}

.switch-field label:first-of-type {
    border-radius: 5px 0 0 5px;
}

.switch-field label:last-of-type {
    border-radius: 0 5px 5px 0;
}

.switch-field-checkbox {
    display: block;
}

.switch-field-checkbox .checkbox-holder {
    float: left;
    width: 31%;
    margin: 0 0 2.5% 2%;
}

.switch-field-checkbox .checkbox-holder:first-of-type,
.switch-field-checkbox .checkbox-holder:nth-of-type(4) {
    margin-left: 0;
}

.switch-field-checkbox label {
    width: 100%;
    height: 80px;
    box-shadow: 0 2px 3px 0 rgba(190, 201, 211, 0.5);
    background-color: #fafbfc;
    padding: 10px 7px;
    color: var(--blue-gray-color);
    border-radius: 5px;
    text-align: left;
    display: inline-block;
    border: solid transparent 1px;
    position: relative;
}

.switch-field-checkbox label:hover,
.switch-field-checkbox label:focus,
.switch-field-checkbox input:checked+label {
    box-shadow: 0 2px 3px 0 rgba(190, 201, 211, 0.5);
    border: solid 1px #24d4bb;
    background-color: #fafbfc;
    color: var(--blue-gray-color);
}

.switch-field-checkbox label:after {
    display: none;
    content: "";
    position: absolute;
    right: 6px;
    top: 6px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/small-check.webp) 0 0 no-repeat;
    width: 10px;
    height: 9px;
}

.switch-field-checkbox input:checked+label:after {
    display: block;
}

.switch-field-checkbox p {
    font-size: 12px;
    line-height: var(--line-height-lg);
    color: #7a8896;
    margin-bottom: 0;
}

.switch-field-checkbox label:first-of-type {
    border-radius: 5px;
}

.switch-field-checkbox label:last-of-type {
    border-radius: 5px;
}

.checkbox-2-column {
    width: 246px;
}

.checkbox-2-column .checkbox-holder {
    width: 48%;
}

.checkbox-2-column .checkbox-holder,
.checkbox-2-column .checkbox-holder:nth-of-type(4) {
    margin-left: 4%;
}

.checkbox-2-column .checkbox-holder {
    margin-bottom: 3.5%;
}

.checkbox-2-column .checkbox-holder:nth-of-type(3) {
    margin-left: 0;
}

.checkcontainer {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

.checkcontainer.small-text {
    font-size: 12px;
    color: var(--blue-gray-color);
    margin-bottom: 0;
    font-family: var(--regular-font);
}

/* Hide the browser's default checkbox */
.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: block !important;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 2px #d0dde8;
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input~.checkmark {
    border-color: #bec9d3;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer input:checked~.checkmark {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/small-check.webp) 2px 3px no-repeat;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked~.checkmark:after {
    display: block;
}

/* Styled radio/checkbox button css ends */

.demo-container .small-text.p-gray-text {
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
    color: #9daab7;
    text-align: center;
    font-family: var(--regular-font);
}

.blue-btn.full-btn {
    width: 100%;
    display: block;
    background-color: var(--blue-color);
    margin-top: 36px;
    margin-bottom: 30px;
    padding: 10px 20px;
    font-size: var(--font-size-body-small);
    font-weight: 500;
    text-transform: none;
    border-radius: 50px;
    font-family: var(--semi-bold-font);
}

.blue-btn.full-btn:hover {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
}

.blue-btn.full-btn:active,
.blue-btn.full-btn:focus {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
}

.user-says {
    width: 70%;
    margin: 60px auto 30px;
    position: relative;
    color: var(--blue-gray-color);
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-lg);
    font-family: var(--regular-font);
}

.user-says strong {
    color: var(--body-text-color);
    letter-spacing: 0.5px;
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-body-extra-small);
    font-weight: 400;
}

.user-says span {
    color: var(--blue-gray-color);
    letter-spacing: 0.5px;
    display: block;
    margin-right: 30px;
    font-family: 'MetropolisMedium', sans-serif;
    font-size: var(--font-size-body-extra-small);
}

.user-says:before,
.user-says:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
}

.user-says:before {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/left_quote.svg) 0 0 no-repeat;
    top: -20px;
    left: -50px;
}

.user-says:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/right_quote.svg) 0 0 no-repeat;
    right: -30px;
    bottom: -14px;
}

.big-container .a.head-logo {
    position: static;
}

.gradient-bg {
    background-color: rgba(237, 241, 245, 1);
    background: linear-gradient(180deg, rgba(250, 251, 252, 1) 0%, rgba(237, 241, 245, 1) 100%);
    width: 100%;
}

.gradient-bg .top-header {
    margin: 0;
    padding: 20px 40px;
}

.gradient-bg .demo-container {
    background-color: transparent;
    box-shadow: none;
}

.gradient-bg .demo-container h2,
.gradient-bg .demo-container h1 {
    margin-top: 20px;
}

.small-hrline {
    width: 100px;
    height: 1px;
    display: inline-block;
    background-color: #d0dde8;
    margin-bottom: 20px;
}

.small-hrline.top-margin {
    margin-top: 20px;
    margin-bottom: 0;
}

.center-img {
    margin: 14px 0;
}

.glow {
    position: relative;
}

.glow:before,
.glow:after {
    content: "";
    position: absolute;
    top: -9px;
    left: auto;
    width: 30px;
    height: 60px;
}

.glow:before {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/left_glow.svg) 0 0 no-repeat;
    margin-left: -40px;
}

.glow:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/right_glow.svg) 0 0 no-repeat;
    right: auto;
    margin-left: 10px;
}

.gradient-bg .small-text.p-gray-text {
    margin-bottom: 20px;
}

.gradient-bg .small-text.bottom-padding {
    margin-bottom: 50px;
}

.demo-container .small-text.p-gray-text.box-tip {
    margin-top: 8px;
    margin-bottom: 0;
    text-align: left;
    font-size: 12px;
    font-family: var(--regular-font);
    color: #9db7d1;
}

.p-gray-text.top-margin {
    margin-top: 24px;
}

.splash-container {
    max-width: 720px;
    margin: 30px auto;
}

.splash-container .col-sm-6 {
    padding-right: 20px;
    padding-left: 20px;
}

.splash-container a.white-container {
    min-height: 424px;
}

.splash-container .white-container {
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(190, 201, 211, 0.5);
    background-color: var(--white-color);
    padding: 30px;
    min-height: 340px;
    display: block;
    transition: all 0.2s ease-in;
}

.splash-container a.white-container:hover,
.splash-container a.white-container:focus {
    box-shadow: 0 2px 2px 0 rgba(112, 126, 140, 0.05), 0 4px 4px 0 rgba(112, 126, 140, 0.05), 0 8px 8px 0 rgba(112, 126, 140, 0.05), 0 16px 16px 0 rgba(112, 126, 140, 0.05), 0 32px 32px 0 rgba(112, 126, 140, 0.05), 0 2px 2px 0 rgba(112, 126, 140, 0.05);
    margin-top: -10px;
}

.splash-container .white-container p {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    text-align: center;
    color: #76889b;
}

.splash-container .white-container .top-logo {
    margin-bottom: 20px;
}

.bl-brdr-btn,
.main-nav ul .border-btn-outer a {
    border-radius: 44px;
    border: solid 2px #0096ff;
    color: #0096ff;
    padding: 10px 40px;
    font-size: var(--font-size-body-small);
    font-weight: 600;
    line-height: var(--line-height-md);
}

.main-nav ul .border-btn-outer a {
    padding: 11px 20px;
    font-weight: 400;
    line-height: 1;
}

.main-nav ul .border-btn-outer a:hover,
.main-nav ul .border-btn-outer a:focus {
    border-color: #008df0;
    color: #008df0;
}

.bl-brdr-btn:hover,
.bl-brdr-btn:focus {
    color: var(--white-color);
    border-color: #0096ff;
    background-color: #0096ff;
}

.splash-container h4 {
    font: 500 20px/1.3 var(--semi-bold-font);
    color: #0096ff;
    margin: 12px 0;
}

.middle-bg {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/school-bg-final.svg) center no-repeat;
    background-attachment: fixed;
}

.styled-form .help-block {
    margin: 4px 0 0;
    font-size: 11px;
    line-height: 14px;
    color: #F44336;
    position: absolute;
}

.styled-form .form-control.has-error,
.styled-form .form-control.has-error#email {
    border-bottom-color: #F44336;
}

.styled-form .g-recaptcha {
    margin-left: 0;
}

.demo-img {
    width: 550px;
    margin: 28px 0;
}

.middle-while-box {
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(190, 201, 211, 0.5);
    background-color: var(--white-color);
    max-width: 546px;
    margin: 0 auto;
    padding: 56px;
    text-align: left;
}

.middle-while-box>img {
    display: block;
    margin: 0 auto 42px;
}

.middle-while-box h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 8px;
}

.middle-while-box h5 {
    font-size: var(--font-size-body-small);
    color: #9daab7;
}

.middle-while-box .enter-info {
    font-size: var(--font-size-body-small);
    font-weight: 500;
    color: #454f5b;
    margin-top: 40px;
    font-family: 'MetropolisMedium', sans-serif;
}

.out-btns .btn-social {
    float: left;
}

.out-btns .btn-google:hover,
.out-btns .btn-google:focus {
    border: 0;
}

.out-btns .btn-google {
    width: 186px;
}

.out-btns.single-btn .btn-google {
    width: 220px;
}

.out-btns h5 {
    float: left;
    font-size: 13px;
    color: #202b36;
    margin: 13px 10px;
}

.out-btns .btn-windows {
    width: 208px;
    color: #333;
    border-color: #939393;
}

.out-btns .btn-windows>span {
    color: #333;
}

.out-btns .btn-windows:hover,
.out-btns .btn-windows:focus {
    border-color: #939393;
    padding: 0;
}

.out-btns .btn-windows>img {
    height: auto;
}

.out-btns .btn-windows>span {
    margin-left: 0;
}

.tooltp-holder label {
    float: left;
}

.tooltp-holder {
    font-size: var(--font-size-body-extra-small);
}

.css-tooltip {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    margin-top: -6px;
}

.css-tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: #303e4c;
    color: #dbe6f0;
    text-align: left;
    border-radius: 3px;
    padding: 7px 8px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: -6px;
    font-size: 12px;
    line-height: var(--line-height-lg);
}

.css-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 14px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #303e4c transparent transparent transparent;
}

.css-tooltip:hover .tooltiptext,
.css-tooltip:focus-visible .tooltiptext {
    visibility: visible;
}

.email-msg {
    max-width: 680px;
    min-height: 64px;
    margin: 0 auto;
    border-radius: 8px;
    border: solid 1px #24d4bb;
    border-left-width: 5px;
    background-color: var(--white-color);
    text-align: left;
    padding: 12px 36px 10px 64px;
    color: #21c1aa;
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
    margin-top: -44px;
    margin-bottom: 50px;
    position: relative;
}

.first-img {
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    top: 20px;
    left: 20px;
}

.close-msg {
    display: block;
    height: 20px;
    width: 20px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-blue-default.svg) 3px 3px no-repeat;
    position: absolute;
    top: 12px;
    right: 8px;
}

.close-msg:hover,
.close-msg:focus {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-blue-default.svg) 3px 3px no-repeat;
}

.email-msg h5 {
    font: 500 14px/1.3 var(--regular-font);
    margin: 0 0 2px;
    color: #21c1aa;
}

.email-msg p {
    margin-bottom: 0;
}

.text-tip {
    font-size: var(--font-size-body-small);
    line-height: 22px;
    color: #454f5b;
    margin-top: 8px;
}

.text-tip h4 {
    font: 500 18px/1.3 var(--semi-bold-font);
    color: #202b36;
    margin: 0 0 8px;
}

@media only screen and (min-width: 768px) {
    .splash-container .white-container p {
        min-height: 144px;
    }
}

@media only screen and (max-width: 1100px) {
    .email-msg {
        max-width: 520px;
    }
}

@media only screen and (max-width: 991px) {
    .form-container {
        margin-right: auto;
        margin-left: auto;
    }

    .main-nav ul .border-btn-outer a,
    .main-nav .sign-up-btn {
        margin-bottom: 10px;
    }

    .email-msg {
        max-width: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .email-msg {
        max-width: inherit;
        margin-top: 0;
    }

    .demo-container h1,
    .demo-container h2 {
        font-size: var(--font-size-heading-medium);
        margin-top: 20px;
    }

    .demo-container h5,
    .demo-container h4 {
        font-size: var(--font-size-body-small);
    }

    .glow:before,
    .glow:after {
        display: none;
    }

    .splash-container .col-sm-6 {
        margin-bottom: 30px;
    }

    .splash-container {
        margin-bottom: 0;
    }

    .demo-container h3 {
        font-size: var(--font-size-body-medium);
    }

    .middle-while-box {
        padding: 30px;
    }

    .email-msg h5 {
        font-size: var(--font-size-body-extra-small);
    }

    .splash-container .white-container {
        min-height: 250px;
    }

    .blue-btn.full-btn {
        margin-top: 30px !important;
    }

    .middle-bg .demo-container {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .form-container {
        width: 100%;
    }

    .styled-form .form-group {
        margin-top: 20px;
    }

    .big-container {
        width: 94%;
    }

    .switch-field-checkbox .checkbox-holder {
        width: 48%;
    }

    .switch-field-checkbox .checkbox-holder:nth-of-type(3),
    .switch-field-checkbox .checkbox-holder:nth-of-type(5) {
        margin-left: 0;
    }

    .switch-field-checkbox .checkbox-holder,
    .switch-field-checkbox .checkbox-holder:nth-of-type(4) {
        margin: 0 0 4% 4%;
    }

    .switch-field {
        display: block;
    }

    .switch-field label {
        width: 50%;
        float: left;
    }

    .gradient-bg {
        width: 100%;
        background: #edf2f6;
    }

    .gradient-bg .demo-container {
        box-shadow: 0 5px 20px -5px #d0dde8;
        background-color: var(--white-color);
        margin: 0 15px;
    }

    .gradient-bg .top-header {
        padding: 10px 15px 20px;
    }

    .gradient-bg .top-header .s-logo {
        position: relative;
        top: 4px;
    }

    .switch-field-checkbox label {
        width: 100%;
        float: none;
    }

    .switch-field label {
        margin-top: -1px;
    }

    .switch-field label:first-of-type {
        border-radius: 0;
        border-top-left-radius: 5px;

    }

    .switch-field label:nth-of-type(2) {
        border-radius: 0;
        border-top-right-radius: 5px;
    }

    .switch-field label:nth-of-type(3) {
        border-radius: 0;
        border-bottom-left-radius: 5px;
    }

    .switch-field label:last-of-type {
        border-radius: 0;
        border-bottom-right-radius: 5px;
    }

    .switch-field-checkbox label {
        margin-top: 0;
        border-radius: 5px !important;
    }

    .middle-bg .demo-container {
        box-shadow: none;
        background-color: transparent;
        margin: 0 0 50px;
    }

    .splash-container .col-sm-6 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .styled-form .form-group {
        margin: 46px 0;
    }

    .demo-img {
        margin: 14px 0 18px;
    }

    .small-hrline {
        margin-bottom: 12px;
    }

    .out-btns .btn-social,
    .out-btns h5 {
        float: none;
    }

    .out-btns h5 {
        font-size: 13px;
    }

    .out-btns .btn-google {
        width: 208px;
    }

    .out-btns .btn-social {
        display: inline-block;
        margin: 0;
    }

    .middle-while-box .enter-info {
        font-size: 15px;
        line-height: 20px;
        margin-top: 20px;
    }

    .middle-while-box {
        padding: 30px 20px;
        border-radius: 0;
    }

    .p-gray-text.top-margin {
        margin-right: 10px;
        margin-left: 10px;
    }
}

/* Signup flow css ends here */

/* New parent login css starts here */

.full-big-container {
    width: auto;
}

.full-big-container .demo-container {
    background-color: transparent;
    box-shadow: none;
}

.full-big-container .demo-container .col-md-6 {
    padding: 0;
}

.full-big-container .demo-container h3 {
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    font-weight: 500;
    margin-top: 34px;
    margin-bottom: 20px;
}

.full-big-container .left-illustration {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/purple-bg.svg) top right no-repeat;
    background-size: cover;
    width: 84%;
    position: inherit;
}

.securlyHome-info {
    max-width: 50%;
    margin: 20px auto 0;
    color: var(--white-color);
}

.middle-border-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #dae5ee;
    line-height: 0.1em;
    margin: 10px 0 20px;
    color: #9daab7;
    font-size: var(--font-size-body-extra-small);
    font-weight: 500;
}

.middle-border-text span {
    background: var(--white-color);
    padding: 0 10px;
}

.icons-features {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    font-size: var(--font-size-body-small);
}

.icons-features li {
    font-weight: 400;
    margin-bottom: 16px;
    position: relative;
    padding: 0 0 0 46px;
    height: 40px;
    display: flex;
    align-items: center;
}

.icons-features img {
    position: absolute;
    top: 4px;
    left: 0;
}

.icons-features li p {
    margin: 0;
}

.family-pic {
    margin-top: 70px;
}

.securly-home-logo {
    margin-top: 100px;
    width: 266px;
    height: 40px;
}

.full-big-container .demo-container h2,
.full-big-container .demo-container h1 {
    margin-top: 48px;
}

.full-big-container .demo-container .parent-header-hei {
    margin-top: 40px;
}

.full-big-container .demo-container h5 {
    color: var(--blue-gray-color);
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    margin-bottom: 30px;
}

.back-link {
    display: inline-block;
    margin: 20px 0 24px 28px;
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
    font-weight: 400;
    color: var(--white-color);
}

.back-link img {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.back-link:hover,
.back-link:focus {
    text-decoration: none;
    color: var(--white-color);
}

.blue-btn.full-btn.blue-border {
    color: #0096ff;
    background-color: transparent;
    border: 2px solid #0096ff;
}

.blue-btn.full-btn.blue-border:hover,
.blue-btn.full-btn.blue-border:focus {
    background-color: #0096ff;
    border-color: #0096ff;
    color: var(--white-color);
}

.full-big-container .styled-form .form-group {
    margin: 18px 0 30px;
}

.full-big-container .styled-form .form-group.less-bottom-margin {
    margin-bottom: 14px;
}

.blue-btn.full-btn.less-top-margin {
    margin-top: 18px;
}

.plain-link {
    font-size: 13px;
    line-height: 20px;
    color: #0096ff;
    font-weight: 400;
}

.plain-link:hover,
.plain-link:focus {
    color: #0096ff;
}

.plain-link-lg {
    font-size: var(--font-size-body-small);
}

.magic-link-icon {
    margin-top: 80px;
}

.alert-msg {
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}

.alert-msg.alert-msg-success {
    background-color: #ebfbf8;
    color: #1eae9a;
}

.alert-msg.alert-msg-error {
    background-color: #feedee;
    color: #f53c48;
}

.demo-container .magic-screen h2 {
    font-size: var(--font-size-heading-medium);
    line-height: 32px;
    margin-top: 32px;
}

.demo-container .magic-screen h5 {
    font-size: var(--font-size-body-small);
    line-height: 24px;
}

.middle-hr {
    margin: 22px auto;
    display: block;
    width: 100px;
    height: 1px;
    border-top: solid 1px #dae5ee;
}

.magic-screen {
    font-size: 13px;
    line-height: var(--line-height-xl);
    color: #76889b;
    width: 400px;
}

.magic-screen h6 {
    font-size: var(--font-size-body-extra-small);
    line-height: 24px;
}

.magic-screen h6 a {
    color: #0096ff;
    font-weight: 700;
}

.magic-screen h6 a:hover,
.magic-screen h6 a:focus {
    color: #0096ff;
    text-decoration: underline;
}

#email-sent p {
    font-weight: 400;
}

#mobile-modal {
    display: none;
}

#mobile-modal .close-btn {
    background-color: var(--white-color);
    color: #becbda;
    width: 24px;
    padding: 0px;
    margin: 0;
    height: 24px;
    border: solid 1px #becbda;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
}

#mobile-modal .close-btn i {
    display: inline-block;
    vertical-align: middle;
    padding-top: 3px;
}

.white-popup-mobile .securlyHome-info {
    max-width: 384px;
    padding: 8px 12px 12px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(56, 73, 89, 0.05), 0 4px 4px 0 rgba(56, 73, 89, 0.05), 0 8px 8px 0 rgba(56, 73, 89, 0.05), 0 16px 16px 0 rgba(56, 73, 89, 0.05), 0 32px 32px 0 rgba(56, 73, 89, 0.05);
}

.white-popup-mobile .header-img {
    width: 272px;
}

.white-popup-mobile .icons-features {
    padding-top: 24px;
}

.white-popup-mobile h3,
.white-popup-mobile .icons-features li {
    color: #202b36;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    text-align: left;
}

.white-popup-mobile h3 {
    margin-bottom: 0;
}

.white-popup-mobile p {
    color: #454f5b;
}

.white-popup-mobile .download-apps img {
    width: 117.9px;
}

@media only screen and (max-width: 1200px) {
    .full-big-container .left-illustration {
        width: 88%;
    }

    .securlyHome-info {
        max-width: 60%;
    }

    .full-big-container .download-apps img {
        width: 116px;
    }
}

@media only screen and (max-width: 992px) {

    #mobile-modal {
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        display: block;
        z-index: 1000;
    }

    .family-pic {
        margin-top: 40px;
    }

    .magic-link-icon {
        margin-top: 20px;
    }

    .back-link {
        color: #76889b;
        margin-left: 0;
    }

    .back-link:hover,
    .back-link:focus {
        color: #76889b;
    }

    .securly-home-logo {
        margin-top: 18px;
    }

    .full-big-container {
        padding: 0 24px;
    }

    .full-big-container .demo-container h2,
    .full-big-container .demo-container h1 {
        font-size: 28px;
    }

    .full-big-container .demo-container .magic-screen h2 {
        font-size: var(--font-size-heading-small);
        line-height: 28px;
        margin-top: 30px;
    }

    .securly-home-logo {
        width: 266px;
        height: 40px;
    }

    .full-big-container .demo-container h2,
    .full-big-container .demo-container h1 {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 767px) {

    .family-pic.visible-sm.visible-xs,
    .back-link.visible-sm.visible-xs {
        display: block !important;
    }

    .magic-screen {
        width: auto;
    }
}

/* New parent login css ends here */

/* Responsive css starts from here */

@media only screen and (max-width: 1450px) {
    .btns-bar {
        padding: 10px 0;
    }

    .btns-bar a {
        font-size: var(--font-size-body-small);
        width: 70%;
        padding: 9px 42px;
        border-radius: 40px;
    }

    .partners-header .white-btn {
        padding: 10px 34px;
    }
}

@media only screen and (max-width: 1200px) {
    .shadow-boxes .inner-container {
        padding: 24px 20px;
    }

    .shadow-boxes h2 {
        margin: 14px 0;
    }

    .shadow-boxes img {
        margin-bottom: 20px;
    }

    .btns-bar a {
        width: 90%;
    }

    .partners-row .form-section {
        width: 80%;
    }
}

@media only screen and (max-width: 992px) {

    .partners-header:after {
        background: linear-gradient(to right, rgba(0, 27, 66, 0.5) 40%, rgba(0, 27, 66, 0) 100%) !important;
    }

    .btns-bar a {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .btns-bar {
        display: block;
        border-bottom: 0 !important;
        margin-bottom: 10px;
    }

    .btns-bar a {
        width: 90%;
        margin-bottom: 14px;
    }

    .securlyhome-header.partners-header {
        min-height: calc(100vh - 54px);
        padding-top: 60px;
    }

    .securlyhome-header.partners-header .white-btn {
        padding-right: 20px;
        padding-left: 20px;
    }

    .partners-header:after {
        background: linear-gradient(to right, rgba(0, 27, 66, 0.5) 30%, rgba(0, 27, 66, 0.5) 100%) !important;
    }

    .partner-program .col-sm-5 {
        margin-bottom: 40px;
    }

    .partners-testimonials .container {
        width: 90%;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    .partners-testimonials .container::before {
        width: 108px;
        height: 74px;
        top: -54px;
    }

    .partners-testimonials .inner-testimonials .col-sm-2 {
        margin-bottom: 20px;
    }

    .securlyHome-inro.partners-intro {
        margin-bottom: 10px;
    }

    .shadow-boxes {
        margin-bottom: 20px;
    }

    .partner-program {
        padding: 30px 0;
    }

    .shadow-boxes .col-sm-4,
    .shadow-boxes .col-sm-5 {
        padding-right: 0;
        padding-left: 0;
    }

    .partner-program .shadow-boxes {
        margin-top: 30px;
    }

    .partners-testimonials {
        margin: 40px 0;
    }

    .partners-row .form-section {
        width: 100%;
    }

    .partners-row .right-bg-content {
        padding: 28px;
    }

    .partners-row .right-bg-content section {
        margin-top: 26px;
    }

    .partners-form .signup-button {
        width: 170px;
        height: 44px;
    }

    .white-popup-form .inner-popup {
        width: 100%;
    }

    .white-popup-form {
        min-height: 200px;
    }
}

/* Partners page css ends */

/* Contact page css starts */
@media only screen and (min-width: 991px) {
    .contact-container {
        width: 96%;
    }

    .footer-ic {
        display: none;
    }
}

/* Contact page css ends */

@media only screen and (max-width: 1460px) {
    .two-containers .inner-container {
        width: 90%;
    }

    .pnp-hub-info ul {
        margin-left: 26%;
    }
}

@media only screen and (max-width: 1200px) {
    .inner-container a img {
        width: 156px;
    }

    .school-devices-content {
        position: relative;
    }

    .school-devices-content:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
    }

    .solution-for-all-devices:after {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 100%);
    }

    .school-devices-content .inner-container,
    .inner-container h1 {
        color: var(--white-color);
    }

    .school-devices-content .inner-container {
        position: relative;
        z-index: 2;
        width: 50%;
        margin-left: 6%;
    }

    .solution-for-all-devices .inner-container {
        position: absolute;
        right: 6%;
        margin-left: 0;
    }

    .pnp-hub-info ul {
        margin-left: 22%;
    }

}

@media only screen and (max-width: 992px) {
    .pnp-hub-info ul {
        margin-left: 16%;
    }

    .two-containers .inner-container {
        width: auto;
    }

    .two-containers .col-sm-6:last-child a {
        display: block;
    }

    .inner-container a img {
        margin-top: 0;
    }

    .inner-container a:first-child img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .securlyhome-header .container {
        width: 90%;
        position: relative;
    }

    .blur-btn {
        right: auto;
        left: 0;
        z-index: 2;
        bottom: -50px;
    }

    .securlyhome-header .white-btn {
        margin-right: 16px;
    }

    .securlyHome-inro {
        padding: 60px 0;
    }

    .school-devices-content {
        min-height: 400px;
    }

    .securlyhome-header {
        background-position: top center;
        position: relative;
    }

    .securlyhome-header:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
    }

    .securlyhome-header .container {
        position: relative;
        z-index: 2;
        padding: 0;
    }

    .pnp-device-image {
        border-width: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .pnp-hub-info ul {
        margin-left: 32%;
    }

    .securlyhome-header h1,
    .securlyHome-inro h1,
    .inner-container h1,
    .securlyHome-testimonials h1 {
        font-size: 30px;
        line-height: var(--line-height-sm);
        margin-bottom: 20px;
    }

    .securlyHome-inro p {
        width: 100%;
    }

    .securlyhome-logo {
        width: 60px;
    }

    .securlyhome-header {
        background-position: top right -230px;
        display: block;
        padding: 30px 0;
        min-height: calc(120vh - 54px);
    }

    .blur-btn {
        padding: 10px 60px 10px 20px;
        font-size: 15px;
        margin-left: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .blur-btn:hover,
    .blur-btn:focus {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .securlyhome-header .white-btn {
        border: 2px solid #3980ff;
        padding: 7px 30px 7px 20px;
        margin: 8px 8px 8px 0;
        font-size: 15px;
    }

    .securlyhome-header .white-btn img {
        width: 22px;
        height: 22px;
    }

    .securlyHome-inro {
        padding: 20px 0;
    }

    .securlyHome-inro {
        font-size: var(--font-size-body-medium);
        line-height: 24px;
        margin-bottom: 30px;
    }

    .school-devices-content .inner-container {
        width: auto;
        margin: 0 6%;
    }

    .school-devices-content {
        background-position: top right -30px;
        max-width: inherit;
    }

    .solution-for-all-devices {
        background-position: 0 0;
    }

    .solution-for-all-devices .inner-container {
        position: static;
    }

    .school-devices-content:after,
    .securlyhome-header:after,
    .solution-for-all-devices:after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.4) 100%);
    }

    .school-devices-content .btn {
        margin-top: 6px;
        font-size: 15px;
        padding: 10px 24px;
    }

    .two-containers {
        width: 100%;
        display: block;
        margin-bottom: 40px;
    }

    .two-containers .col-sm-6 {
        width: auto;
        margin: 0 0 30px;
        padding: 40px 20px 20px;
    }

    .two-containers .pnp-device-container {
        padding: 10px;
    }

    .two-containers span {
        top: 4px;
        margin: 10px 20px 0 0;
        font-size: var(--font-size-body-large);
    }

    .white-btn.blue-btn {
        margin-top: 24px;
        padding: 10px 30px;
        font-size: 15px;
    }

    .two-containers .icon {
        max-width: 60px;
    }

    .two-containers h3 {
        margin: 14px 0;
    }

    .two-containers .col-sm-6:last-child a,
    .two-containers.securlyHome .col-sm-6:first-child a {
        display: inline-block;
    }

    .inner-container a img {
        margin-top: 10px;
        width: 144px;
    }

    .inner-container a:first-child img {
        margin-right: 12px;
        margin-bottom: 0;
    }

    .pnp-device-container .inner-container {
        text-align: center;
    }

    .subscribe-wrapper {
        padding: 40px 0;
        margin-bottom: 70px;
    }

    .subscribe-form {
        width: auto;
        margin-top: 30px;
    }

    .subscribe-form .form-control {
        padding: 14px 60px 14px 14px;
        height: 50px;
    }

    .tick-submit-btn {
        background-size: 60px;
        width: 60px;
        height: 60px;
        top: -5px;
    }
}

@media only screen and (max-width: 450px) {
    .pnp-hub-info ul {
        margin-left: 10%;
        width: 90%;
    }

}

@media only screen and (max-width: 400px) {

    .two-containers .col-sm-6:last-child a,
    .two-containers.securlyHome .col-sm-6:first-child a {
        display: block;
    }

    .two-containers.securlyHome .col-sm-6:last-child a {
        display: inline-block;
    }

    .two-containers.pnp-hub-info .col-sm-6:first-child a {
        display: inline-block;
    }

    .inner-container a img {
        margin-top: 0;
    }

    .inner-container a:first-child img {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

/* SecurlyHome page css ends */


@media only screen and (max-width: 1200px) {
    .blue-design-footer .container {
        top: -100px;
    }
}

@media only screen and (max-width: 992px) {

    #about-one h2,
    #how-we h2,
    .dream-team h2,
    .founders-team h2,
    .board-of-members h2,
    #investors .spacing,
    .blue-design-footer h2 {
        font-size: var(--font-size-heading-large);
        margin-bottom: 40px;
    }

    .box-profile {
        padding: 14px;
    }

    .box-profile h3 {
        font-size: var(--font-size-heading-small);
    }

    .blue-design-footer {
        font-size: 17px;
    }

    /* Remove hover in touch screens */
    .box-profile.hover-actions img {
        filter: none;
    }

    .box-profile.hover-actions h4 {
        color: #3590ed;
    }

    .box-profile.hover-actions .fa-linkedin-square {
        color: #0274b3;
    }

    .box-profile.hover-actions:hover {
        box-shadow: none;
    }

    .box-profile.hover-actions,
    .box-profile.hover-actions h4,
    .box-profile.hover-actions img,
    .box-profile.hover-actions .fa-linkedin-square {
        transition: none;
    }
}

@media only screen and (max-width: 767px) {

    .dream-team,
    .founders-team,
    .board-of-members {
        padding: 20px 0;
    }

    .blue-design-footer {
        padding-bottom: 26%;
    }

    .blue-design-footer .container {
        top: 0;
    }

    #investors .col-sm-4 {
        margin-top: 30px;
    }

    #investors.text-center {
        margin-bottom: 0;
    }

    #about-one,
    #how-we {
        margin: 30px 10px !important;
    }

    #about-one h2,
    #how-we h2 {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .slick-dotted.slick-slider.team-carousel {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 460px) {

    .founders-team .col-xs-6,
    .dream-team .col-xs-6,
    .board-of-members .col-xs-6 {
        float: none;
        width: 100%;
        margin-bottom: 30px;
    }

    .margin-h3 {
        margin-top: 20px;
    }
}

/* New About us page ends */

/* Webinar page css starts */

#webinar-hero {
    min-height: 50%;
    width: 100%;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/webinar-hero-image.webp) no-repeat center;
    background-size: cover;
    padding-top: 6%;
    padding-bottom: 6%;
}

.webinar-hero {
    width: 90%;
    margin: 0 auto;
    color: var(--white-color);
    text-align: left;
}

.webinar-hero h1,
.webinar-hero h2,
.webinar-hero h3,
.webinar-hero h4,
.webinar-hero h5,
.webinar-hero h6 {
    color: var(--white-color);
    font-weight: 400;
}

.webinar-hero h1 {
    font-size: 58px;
    line-height: var(--line-height-sm);
    margin-bottom: 20px;
}

.webinar-hero h3 {
    font-size: var(--font-size-heading-large);
    line-height: var(--line-height-lg);
    margin-bottom: 20px;
}

.webinar-hero h4 {
    font-size: 37px;
    line-height: var(--line-height-lg);
    margin-bottom: 20px;
}

.webinar-hero h5 {
    font-size: 30px;
    font-weight: 700;
}

.webinar-hero h5 span {
    display: block;
    font-size: var(--font-size-body-large);
    font-weight: 400;
    margin-top: 10px;
}

.webinar-hero img {
    margin-bottom: 20px;
}

.webinar-hero .blue-btn-small,
.webinar-page .blue-btn-small {
    font-size: var(--font-size-body-large);
    background-color: #428bca;
    padding: 12px 43px;
    display: inline-block;
}

.blue-box {
    background-color: #5e9cd2;
    color: var(--white-color);
    font-size: var(--font-size-heading-medium);
    line-height: 36px;
    padding: 70px 40px;
    width: 90%;
    margin: 60px auto;
}

.webinar-details {
    background-color: #f7f7f7;
    margin-top: 60px;
    padding: 30px 0 100px;
    font-size: var(--font-size-heading-medium);
    line-height: 36px;
    color: #7e8890;
}

.webinar-details p {
    font-size: var(--font-size-heading-medium);
    line-height: 36px;
}

.webinar-details h2 {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}

.webinar-details h3,
.webinar-details h4 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin: 60px 0 20px;
}

.webinar-details h4 {
    font-size: 25px;
    margin-bottom: 10px;
}

#webinar-march-hero,
#webinar-march-new-hero {
    min-height: 50%;
    width: 100%;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/webinar-hero-image.webp) repeat center;
    background-size: cover;
    padding-top: 6%;
    padding-bottom: 1%;
}

#webinar-march-new-hero {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/webinar-bg.webp) repeat center;
    background-size: cover;
}

#webinar-march-hero img {
    margin-bottom: -24px;
}

#webinar-march-new-hero img {
    margin-bottom: -40px;
    max-width: 160px;
}

.webinar-schedule img {
    float: left;
    margin: 0 20px 10px 0;
}

.webinar-schedule div {
    float: left;
    color: #666;
    font-size: var(--font-size-body-large);
    line-height: 28px;
}

.webinar-schedule strong {
    display: block;
    font-size: var(--font-size-heading-small);
    margin-bottom: 4px;
}

.webinar-schedule:first-child {
    border-right: 1px solid #bbb;
}

.webinar-info {
    margin: 40px 0;
    border-bottom: 1px solid #bbb;
    padding-bottom: 20px;
}

.top-spacing {
    margin-top: 14px;
}

/* Webinar page css ends */

/* Securly notes css starts */

#securly-notes {
    min-height: 50%;
    width: 100%;
    background: #548fd1;
    background-size: cover;
    padding-top: 6%;
    padding-bottom: 6%;
}

#securly-notes h1 {
    margin-top: 50px;
}

.iframe-video {
    margin-top: -100px;
    text-align: center;
}

.iframe-video iframe {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
}

.arrow-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.notes-details {
    background-color: transparent;
    margin-top: 30px;
    padding-bottom: 50px;
}

.notes-details h2,
.notes-details h3,
.notes-details h4 {
    text-align: left;
}

.notes-details h2 {
    color: #4d5d62;
    font-size: 30px;
}

.notes-details h3 {
    margin: 40px 0;
    font-size: 26px;
}

.arrow-list {
    font-size: var(--font-size-body-large);
}

.arrow-list li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/bullet.webp) no-repeat 0 12px;
    padding-left: 30px;
    margin-bottom: 6px;
}

.notes-details .last-title {
    font-size: 34px;
}

.gray-color-bg {
    background-color: #dde2e5;
    padding: 40px 0;
    text-align: center;
}

.gray-color-bg h4 {
    font-size: var(--font-size-heading-medium);
    color: #4d5d62;
}

.gray-color-bg span {
    display: block;
    min-height: 103px;
    margin-bottom: 6px;
}

.gray-color-bg p {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.428571429;
}

.gray-color-bg .col-sm-4,
.gray-color-bg .col-sm-6 {
    margin-top: 30px;
}

.light-green-btn {
    margin: 40px 0;
    background-color: #5ec096;
    border-radius: 4px;
    padding: 16px 60px;
    display: inline-block;
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    line-height: 24px;
    font-weight: 400;
}

.light-green-btn:hover,
.light-green-btn:focus {
    background-color: #5ec096;
    color: var(--white-color);
}

/* Securly notes css ends */

/* Chromebook free css starts */
.wonderbar {
    color: var(--white-color);
    font-size: var(--font-size-body-extra-small);
    font-weight: 400;
    height: 88px;
    padding: 13px 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: #0073E6;
    background: radial-gradient(#0073E6, #0C274A);
}

.wonderbar>p {
    max-width: 680px;
    margin: 0 auto;
    text-align: center;
    font-family: var(--regular-font);
    font-size: var(--font-size-body-extra-small);
    font-weight: 400;
    line-height: var(--line-height-xl);
}

.wonderbar img {
    width: 20px;
    height: 24px;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.wonderbar .state-link {
    color: var(--white-color) !important;
    text-decoration: none;
    border-bottom: 1px solid var(--white-color);
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    font-family: var(--semi-bold-font);
}

.wonderbar .state-link:hover,
.wonderbar .state-link:focus {
    text-decoration: none;
}

.move-down {
    top: 50px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.wonderbar .white-border-btn {
    border-radius: 30px;
    color: var(--white-color);
    padding: 6px 24px;
    margin-left: 16px;
    border-width: 1px;
    position: relative;
    top: -1px;
}

.wonderbar .white-border-btn:hover,
.wonderbar .white-border-btn:focus {
    color: #d95493;
}

.superslides.chromebook-hero,
.superslides.chromebook-hero .slide-content {
    height: 80% !important;
}

.superslides.chromebook-hero .hero-img {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/chromebook-banner.webp) no-repeat right center #484963;
    height: 600px;
}

.chromebook-hero h1 {
    font-size: 46px;
    line-height: 60px;
    font-weight: 300;
    margin-bottom: 30px;
}

.blue-oval-btn {
    background-color: #54b1e1;
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    border-radius: 30px;
    padding: 13px 73px;
}

.blue-oval-btn:hover,
.blue-oval-btn:focus {
    background-color: #4daada;
    color: var(--white-color);
}

.page-brief {
    margin-bottom: 60px;
    text-align: center;
    color: #666;
}

.page-brief,
.page-brief p,
.features-list p {
    font-size: var(--font-size-body-small);
    line-height: 28;
    font-weight: 400;
}

.page-brief h2,
.features-list h2,
.chart-section h2 {
    font-size: 30px;
    margin-bottom: 40px;
    color: #111;
}

.features-list h2 {
    margin-bottom: 100px;
}

.features-list {
    margin-top: 50px;
    text-align: center;
}

.features-list h4 {
    font-size: var(--font-size-body-medium);
    line-height: 26px;
}

.features-list p {
    color: #97a0a3;
    font-size: var(--font-size-body-extra-small);
    line-height: 22px;
}

.features-list .row {
    margin-right: -80px;
    margin-left: -80px;
}

.features-list [class*="col-"] {
    margin-bottom: 80px;
    padding: 0 80px;
}

.landing-page-features {
    margin-bottom: 80px;
    margin-top: 30px !important;
}

.landing-page-features .row {

    margin-bottom: 40px;
}

.features-section {
    margin: 40px auto;
}

.with-parallax .features-section {
    margin: 0 auto;
}

.feature-icon {

    background-color: #d6f3f9;
    border-radius: 50%;
    position: relative;
    margin: 40px auto 40px;
    width: 165px;
    height: 165px;
    padding: 45px 30px 20px;
}

.feature-icon:after {
    overflow: hidden;
    position: absolute;
    content: "";
    width: 212px;
    height: 212px;
    top: -25px;
    left: -25px;
    background-color: rgba(214, 243, 249, 0.4);
    border-radius: 50%;
    z-index: -1;
}

.feature-img span {
    width: 71px;
    height: 71px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/features.webp) no-repeat 0 0;
}

.feature-img span.time-feature {
    background-position: 0 0;
}

.feature-img span.home-feature {
    background-position: 0 -81px;
}

.feature-img span.cyber-feature {
    background-position: 0 -162px;
}

.feature-img span.granular-feature {
    background-position: 0 -243px;
}

.feature-img span.parental-feature {
    background-position: 0 -314px;
}

.feature-img span.admin-feature {
    background-position: 0 -389px;
}

.chart-section {
    background-color: #f8f8fa;
    min-height: 500px;
    padding: 70px 0;
}

.chart-section h2 {
    text-align: center;
    margin-bottom: 70px;
}

.chart-table>thead>tr>th {
    border-bottom: 1px solid #dddde2;
    font-size: var(--font-size-body-small);
    color: #666;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: center;
}

.chart-table>tbody>tr>td {
    border-top: 0;
    font-size: var(--font-size-body-small);
    color: #666;
    vertical-align: middle;
    padding-top: 18px;
    padding-bottom: 18px;
    border-left: 1px solid #dddde2;
    text-align: center;
}

.chart-table>tbody>tr>td:first-child,
.chart-table>thead>tr>th:first-child {
    border-left: 0;
    text-align: left;
}

.chart-table h6 {
    font-size: var(--font-size-body-small);
    font-weight: 700;
}

.chart-table p {
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
    margin-bottom: 0;
}

.chart-table .ss {
    font-size: 34px;
}

.chart-table .ss-check {
    color: #3f91ec;
}

.chart-table .ss-delete {
    color: #ee8282;
}

.chart-table .blue-oval-btn {
    padding: 14px 34px;
    display: block;
    margin-bottom: 8px;

}

.chart-table .blue-oval-btn.trans-btn {
    background-color: transparent;
    border: 1px solid #54b1e1;
    color: #54b1e1;
}

.chart-table .blue-oval-btn.trans-btn:hover,
.chart-table .blue-oval-btn.trans-btn:focus {
    background-color: #f3f3f3;
}

.chart-table>tbody>tr.bottom-tr>td {
    border-top: 1px solid #dddde2;
    vertical-align: top;
}

.custom-page .securly-free-btm .our-schools {
    background: var(--white-color) !important;
    margin: 120px 0;
}

.custom-page .securly-free-btm .testimonials-container,
.custom-page .securly-free-btm .testimonials-wrapper {
    background-color: var(--white-color) !important;
}

.home-superslides .slide-content .page-title.normal-securly {
    margin-top: 100px;
}

/*#pricing-hero {
  margin-top: 70px;
  }*/

.address-item.icon-mail4.normal-securly {
    display: none !important;
}

.address-item.icon-que.securly-free {
    display: block !important;
}

.securly-free-btm .testimonials-container {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/chromebook-bg.webp);
}

.smiley {
    width: 20px;
    position: relative;
    top: -2px;
}

/* Chromebook free css ends */

/* Parent login - left design starts */

.left-app-design .parent-login-bg {
    background: #d3f0ff;
    color: #333;
    padding: 20px;
}

.left-app-design .left-bg-img h1 {
    color: #333;
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-md);
    font-weight: 600;
    margin: 26px 0 6px;
}

.left-app-design .left-bg-img h3 {
    color: #525252;
    font-size: var(--font-size-body-medium);
    line-height: var(--line-height-lg);
    margin: 0 0 26px;
}

.left-app-design .left-bg-img:after,
.left-app-design .logo {
    display: none;
}

.mobile-pic {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.download-apps a {
    margin-right: 14px;
    display: inline-block;
}

.middle-align-div {
    margin-top: 26%;
}

@media only screen and (max-width: 1020px) {
    .download-apps a {
        margin-right: 0;
    }
}

@media only screen and (max-width: 980px) {
    .middle-align-div {
        margin-top: 10%;
    }

    .download-apps a {
        margin-bottom: 14px;
    }
}

/* Parent login - left design ends */

@media only screen and (min-width: 768px) {

    /* Home page css starts */
    .page-content .middle-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .page-content .middle-row [class*="col-"] {
        padding-right: 32px;
        padding-left: 32px;
    }

    /* Home page css ends */

    /* Cms Css*/

    .desktop-float-left {
        float: left;
    }

    .desktop-float-right {
        float: right;
    }

    #signup-form input.date {
        width: 83.33333333333334%;
    }

    .parent-header,
    .feature-header {
        padding-top: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .parent-header .feature-title h1,
    .feature-header .feature-title h1 {
        margin: 0;
        position: relative;
        z-index: 2;
    }

    .parent-header .col-sm-6,
    .feature-header .col-sm-6 {
        position: static;
        padding-left: 50px;
    }

    .parent-header img,
    .feature-header img {
        max-height: 200px;
        position: absolute;
        bottom: -7px;
    }

    .news-header,
    .full-page-header {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

/* Pricing page css starts */

#pricing-hero {
    min-height: 50%;
    width: 100%;
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/pricing_heroimage.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 10%;
    padding-bottom: 10%;
}

#pricing-hero .page-title {
    font-size: 55px;
    margin-bottom: 0;
}

#pricing-hero .page-sub-title {
    font-size: var(--font-size-body-large);
    margin-top: 20px;
}

.calculate-section,
.pricing-plans-section {
    margin-top: -60px;
}

.main-container {
    margin: -86px auto 0;
    padding: 0;
    width: 90%;
    background-color: var(--white-color);
    position: relative;
    border: 1px solid rgb(214, 220, 226);
    border-radius: 5px;
}

.icon-heading {
    font-size: 26px;
    color: #111;
    margin-bottom: 30px;
}

.icon-heading i {
    color: var(--white-color);
    display: inline-block;
    border-radius: 50%;
    background-color: rgb(239, 138, 38);
    font-size: var(--font-size-heading-small);
    width: 40px;
    height: 40px;
    padding: 11px 8px;
    margin-right: 14px;
    position: relative;
    top: 3px;
}

.calculate-section h4,
.pricing-plans-section h4 {
    font-size: var(--font-size-body-large);
    margin-bottom: 30px;
    line-height: var(--line-height-md);
}

.form-footer .teal-btn {
    float: left;
    background-color: #36bec3;
    border: 2px solid transparent;
    font-size: var(--font-size-body-small);
    font-weight: 500;
    padding: 11px 50px;
    border-radius: 50px;
}

.form-footer .teal-btn:hover,
.form-footer .teal-btn:focus {
    background-color: var(--white-color);
    color: #36bec3;
    border-color: #36bec3;
}

.form-footer .btn {
    float: left;
}

.right-footer {
    float: right;
    color: #111;
    font-size: var(--font-size-body-small);
    line-height: 24px;
}

.gray-background {
    background-color: #f9f8f8;
    padding-bottom: 140px;
    margin-bottom: -20px;
}

.pricing-form input {
    border-radius: 3px;
    font-size: var(--font-size-body-large);
    border: 1px solid #c3c8ce;
    box-shadow: none;
    margin-bottom: 30px;
    height: 56px;
    padding: 10px 20px;
}

.pricing-form .ui-widget.custom-ui-design {
    display: block;
    margin-bottom: 30px;
}

.main-container [class*="col-"] {
    padding-right: 56px;
    padding-left: 56px;
}

.package-details {
    background-color: #f6fafd;
    margin-top: -84px;
    padding-bottom: 40px;
    color: #7b8888;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100vh;
}

.package-details p {
    font-size: var(--font-size-body-extra-small);
    line-height: 22px;
}

.package-details h2 {
    font-size: 28px;
    line-height: var(--line-height-lg);
    color: #546169;
    text-align: left;
    margin-top: 60px;
}

.includes {
    margin: 0 0 60px;
    padding: 0;
    list-style: none;
    color: #546169;
    font-size: var(--font-size-body-small);
    font-weight: 400;
}

.includes li {
    padding-left: 48px;
    position: relative;
    margin-bottom: 30px;
    line-height: 30px;
}

.includes i {
    font-size: 28px;
    position: absolute;
    top: 2px;
    left: 0;
}

.border-btn {
    font-size: var(--font-size-body-large);
    color: #50a0cd;
    border: 2px solid #54b2e2;
    padding: 10px;
    margin-bottom: 22px;
    white-space: inherit;
    border-radius: 52px;
}



.icon-heading i {
    color: var(--white-color);
    display: inline-block;
    border-radius: 50%;
    background-color: rgb(239, 138, 38);
    font-size: var(--font-size-heading-small);
    width: 40px;
    height: 40px;
    padding: 11px 8px;
    margin-right: 14px;
    position: relative;
    top: 3px;
}

.calculate-section h4,
.pricing-plans-section h4 {
    font-size: var(--font-size-body-large);
    margin-bottom: 30px;
    line-height: var(--line-height-md);
}

.form-footer .teal-btn {
    float: left;
    background-color: #36bec3;
    border: 2px solid transparent;
    font-size: var(--font-size-body-small);
    font-weight: 500;
    padding: 11px 50px;
    border-radius: 50px;
}

.form-footer .teal-btn:hover,
.form-footer .teal-btn:focus {
    background-color: var(--white-color);
    color: #36bec3;
    border-color: #36bec3;
}

.form-footer .btn {
    float: left;
}

.right-footer {
    float: right;
    color: #111;
    font-size: var(--font-size-body-small);
    line-height: 24px;
}

.gray-background {
    background-color: #f9f8f8;
    padding-bottom: 140px;
    margin-bottom: -20px;
}

.pricing-form input {
    border-radius: 3px;
    font-size: var(--font-size-body-large);
    border: 1px solid #c3c8ce;
    box-shadow: none;
    margin-bottom: 30px;
    height: 56px;
    padding: 10px 20px;
}

.pricing-form .ui-widget.custom-ui-design {
    display: block;
    margin-bottom: 30px;
}

.main-container [class*="col-"] {
    padding-right: 56px;
    padding-left: 56px;
}

.package-details {
    background-color: #f6fafd;
    margin-top: -84px;
    padding-bottom: 40px;
    color: #7b8888;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100vh;
}

.package-details p {
    font-size: var(--font-size-body-extra-small);
    line-height: 22px;
}

.package-details h2 {
    font-size: 28px;
    line-height: var(--line-height-lg);
    color: #546169;
    text-align: left;
    margin-top: 60px;
}

.includes {
    margin: 0 0 60px;
    padding: 0;
    list-style: none;
    color: #546169;
    font-size: var(--font-size-body-small);
    font-weight: 400;
}

.includes li {
    padding-left: 48px;
    position: relative;
    margin-bottom: 30px;
    line-height: 30px;
}

.includes i {
    font-size: 28px;
    position: absolute;
    top: 2px;
    left: 0;
}

.border-btn {
    font-size: var(--font-size-body-large);
    color: #50a0cd;
    border: 2px solid #54b2e2;
    padding: 10px;
    margin-bottom: 22px;
    white-space: inherit;
    border-radius: 52px;
}

.border-btn:hover,
.border-btn:active,
.border-btn.active {
    color: var(--white-color);
    background-color: #54b2e2;
    box-shadow: none;
}

.border-btn.active:hover,
.border-btn.active:active {
    color: #50a0cd;
    background-color: transparent;
}

.pricing-plans-section .icon-heading {
    float: left;
    color: #999999;
}

.pricing-plans-section .icon-heading i {
    background-color: #adadad;
}

.pricing-plans-section form {
    float: right;
    margin-top: 20px;
}

.pricing-plans-section input {
    box-shadow: none;
    border-color: #eee;
    color: #333;
    width: 220px;
    padding: 4px 40px 4px 10px;
    margin-bottom: 0;
    text-align: right;
}

.pricing-plans-section input:hover,
.pricing-plans-section input:focus {
    /*  border-color: #bbb;*/
    color: #777;
}

.info-txt {
    font-size: var(--font-size-body-small);
    color: #2c8bbc;
    display: block;
    text-align: center;
}

.hr-divider {
    margin: 20px -56px;
    border-bottom: 1px solid #d5dee3;
    height: 1px;
}

.pricing-table-section {
    margin: 40px 0;
}

.pricing-table-section a {
    padding: 20px 0;
}

.sales-section {
    text-align: left;
}

.sales-section a {
    font-size: var(--font-size-body-small);
    padding: 8px 12px;
    margin-bottom: 10px;
    min-width: 164px;
}

.sales-section strong {
    color: #ea4849;
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
}

.pricing-table-section .sales-section ol {
    font-size: 13px;
    margin-left: 13px;
}

.pricing-table-section .sales-section li {
    border-top: 0;
    padding: 1px 0;
}

.pricing-table-section .icon-heading i {
    font-size: var(--font-size-body-medium);
    padding: 12px 10px;
    top: -2px;
}

.pricing-table-section ul {
    margin: -28px 0 0 62px;
    padding: 0;
    list-style: none;
}

.pricing-table-section li {
    border-top: 1px solid #dbe8e8;
    padding: 20px 0;
    position: relative;
    line-height: 18px;
}

.pricing-table-section li:first-child {
    border-top: 0;
}

.pricing-table-section .price-side {
    float: right;
    margin-left: 10px;
    text-align: right;
}

.pricing-table-section h4 {
    font-size: var(--font-size-heading-medium);
    line-height: 1;
    font-weight: 400;
    color: #1db3aa;
    margin: 0;
}

.pricing-table-section h4 i {
    margin-top: 10px;
    color: #666;
    font-size: var(--font-size-body-medium);
    font-weight: 300;
    font-style: inherit;
    display: block;
}

.pricing-table-section .duration-side {
    width: 50%;
    float: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.pricing-table-section .duration-side h3 {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-md);
    color: #333;
    margin: 0 0 6px;
}

.pricing-table-section .duration-side span {
    display: block;
    font-size: var(--font-size-body-small);
    color: #ea4849;
}

.ui-widget.custom-ui-design {
    border: 0;
}

.ui-widget.custom-ui-design .ui-spinner-input {
    margin: 0;
}

.ui-button .ui-icon.custom-up-icon {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/top-bottom-arrow.webp);
    background-position: 0 6px;
}

.ui-button .ui-icon.custom-down-icon {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/top-bottom-arrow.webp);
    background-position: 0 -19px;
}

.ui-widget.custom-ui-design a.ui-spinner-button {
    background: none;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    width: 2.8em;
}

/* Loader icon css starts */

.sk-fading-circle {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    margin-top: 4px;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 14%;
    height: 14%;
    background-color: #333;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
    transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
    transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
    transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
    transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
    transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
    transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
    transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
    transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
    transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
    transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
    transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
    animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
    animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
    animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
    animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
    animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
    animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
    animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
    animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
    animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
    animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
    animation-delay: -0.1s;
}

.pricing-table-section .sales-section li {
    border-top: 0;
    padding: 1px 0;
}

.pricing-table-section .icon-heading i {
    font-size: var(--font-size-body-medium);
    padding: 12px 10px;
    top: -2px;
}

.not-found-bg .big-grey-button {
    font-size: var(--font-size-body-small);
    padding: 13px 48px;
}

.pricing-table-section ul {
    margin: -28px 0 0 62px;
    padding: 0;
    list-style: none;
}

.pricing-table-section li {
    border-top: 1px solid #dbe8e8;
    padding: 20px 0;
    position: relative;
    line-height: 18px;
}

.pricing-table-section li:first-child {
    border-top: 0;
}

.pricing-table-section .price-side {
    float: right;
    margin-left: 10px;
    text-align: right;
}

.pricing-table-section h4 {
    font-size: var(--font-size-heading-medium);
    line-height: 1;
    font-weight: 400;
    color: #1db3aa;
    margin: 0;
}

.pricing-table-section h4 i {
    margin-top: 10px;
    color: #666;
    font-size: var(--font-size-body-medium);
    font-weight: 300;
    font-style: inherit;
    display: block;
}

.pricing-table-section .duration-side {
    width: 50%;
    float: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.pricing-table-section .duration-side h3 {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-md);
    color: #333;
    margin: 0 0 6px;
}

.pricing-table-section .duration-side span {
    display: block;
    font-size: var(--font-size-body-small);
    color: #ea4849;
}

.ui-widget.custom-ui-design {
    border: 0;
}

.ui-widget.custom-ui-design .ui-spinner-input {
    margin: 0;
}

.ui-button .ui-icon.custom-up-icon {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/top-bottom-arrow.webp);
    background-position: 0 6px;
}

.ui-button .ui-icon.custom-down-icon {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/top-bottom-arrow.webp);
    background-position: 0 -19px;
}

.ui-widget.custom-ui-design a.ui-spinner-button {
    background: none;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    width: 2.8em;
}

/* Loader icon css starts */

.sk-fading-circle {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    margin-top: 4px;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 14%;
    height: 14%;
    background-color: #333;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
    transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
    transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
    transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
    transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
    transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
    transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
    transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
    transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
    transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
    transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
    transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
    animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
    animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
    animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
    animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
    animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
    animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
    animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
    animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
    animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
    animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
    animation-delay: -0.1s;
}

@keyframes sk-circleFadeDelay {

    0%,
    39%,
    100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

/* Loader icon css ends */

/* Pricing page css ends */

.mar-btm4 {
    margin-bottom: 4px;
}

.not-found-bg {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/404_bg.webp) bottom left no-repeat;
    width: 100%;
    min-height: 600px;
    background-size: cover;
    color: var(--white-color);
    position: relative;
}

.not-found-bg:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.not-found-bg .big-blue-button {
    font-size: var(--font-size-body-small);
    padding: 13px 48px;
}

.page-not-found {
    position: relative;
    z-index: 1;
}

.not-found-bg h1,
.not-found-bg h4 {
    color: var(--white-color);
    margin-bottom: 24px;
}

.not-found-bg h1 {
    margin-bottom: 10px;
}

.icon-and-text img,
.icon-and-text div {
    float: left;
}

.icon-and-text div {
    width: 74%;
    margin-left: 10px;
    text-align: left;
}

.mar-top-30 {
    margin-top: 30px;
}

.schools .auditor-title,
.schools .auditor-title p {
    font-size: 36px;
    line-height: var(--line-height-md);
}

.schools .auditor-title i {
    color: #6ec5fb;
}

.site-page-table {
    font-size: var(--font-size-body-small);
    line-height: 24px;
}

.site-page-table>thead>tr>th,
.site-page-table>tbody>tr>td {
    padding: 12px;
    vertical-align: top;
}

.site-page-table p {
    margin-bottom: 14px;
}

.news-line {
    border-top-color: #eee !important;
    margin: 30px 0;
}

.news-container .col-sm-12 {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.news-container {
    margin-bottom: 60px;
}

.news-container .btn {
    border-radius: 34px;
    padding: 6px 20px;
    font-weight: 500;
}

.awards-frontend {
    padding-top: 20px;
    padding-bottom: 20px;
}

.recent-awards.awards-frontend span {
    margin-top: 12px;
}

/* Signup - Floating labels */
.form-section .mui-textfield input {
    padding: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26) !important;
    height: 40px !important;
    margin-bottom: 0 !important;
}

.mui-textfield {
    margin-bottom: 15px;
}

.news-container .btn {
    border-radius: 34px;
    padding: 6px 20px;
    font-weight: 500;
}

.awards-frontend {
    padding-top: 20px;
    padding-bottom: 20px;
}

.recent-awards.awards-frontend span {
    margin-top: 12px;
}

/* ADA chat bot CSS starts */

.ada-chat-window {
    max-width: 350px !important;
    height: 348px !important;
    border-radius: var(--default-border-radius) !important;
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.28);
    bottom: 70px !important;
    right: 20px !important;
    position: relative;
}

.ada-chat-button {
    background-color: orange !important;
}

.ada-chat-window:before {
    content: "";
    position: absolute;
    top: -74px;
    left: 0;
    width: 350px;
    height: 80px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/orange-chat-header.webp) 0 0 no-repeat;
}

.custom-bot-close {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/delete-icon.svg) center no-repeat #f65c26;
    background-size: 17px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    float: right;
    margin: 6px 0 0;
}

.custom-bot-close:hover,
.custom-bot-close:focus {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/delete-icon.svg) center no-repeat #f65c26;
    border: 0;
    background-size: 17px;
}

body.ada-frozen {
    overflow-y: visible !important;
    pointer-events: inherit !important;
}

body.ada-frozen:after {
    display: none !important;
}

.MessageListSuggestions__suggested-response:hover {
    opacity: 10 !important;
}

@media (max-width: 460px) {
    .ada-chat-window {
        max-width: 300px !important;
        height: 300px !important;
        bottom: 10px !important;
        right: 10px !important;
    }

    .ada-chat-window:before {
        background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/mobile-orange-chat-header.webp);
        width: 300px;
        height: 69px;
        top: -64px;
    }
}

/* ADA chat bot CSS ends */

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

    /**
       * Remove all paddings around the image on small screen
       */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        transform-origin: 0;
    }

    .mfp-arrow-right {
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }

    /* Pricing page css starts */

    .main-container,
    .package-details {
        margin-top: -50px;
    }

    .main-container [class*="col-"] {
        padding-right: 20px;
        padding-left: 20px;
    }

    .calculate-section,
    .pricing-plans-section {
        margin-top: -30px;
    }

    .package-details h2 {
        margin-top: 40px;
    }

    .includes li {
        line-height: 26px;
    }

    .includes i {
        top: 8px;
    }

    .form-footer .btn,
    .right-footer {
        float: none;
    }

    .right-footer {
        margin-top: 20px;
    }

    #pricing-hero .page-sub-title {
        line-height: 24px;
        margin-top: 0;
    }

    #pricing-hero {
        padding-top: 14%;
        padding-bottom: 14%;
    }

    .hr-divider {
        margin-right: -20px;
        margin-left: -20px;
    }

    /* Pricing page css ends */

}

.mfp-ie7 .mfp-img {
    padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
    padding: 0;
}

.mfp-ie7 .mfp-content {
    padding-top: 44px;
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0;
}


/* overlay at start */

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
}


/* overlay animate in */

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}


/* overlay animate out */

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}


/* content at start */

.mfp-fade.mfp-wrap .mfp-content,
.mfp-fade.mfp-wrap .mfp-arrow {
    opacity: 0;
    transition: all 0.15s ease-out;
}


/* content animate it */

.mfp-fade.mfp-wrap.mfp-ready .mfp-content,
.mfp-fade.mfp-wrap.mfp-ready .mfp-arrow {
    opacity: 1;
}


/* content animate out */

.mfp-fade.mfp-wrap.mfp-removing .mfp-content,
.mfp-fade.mfp-wrap.mfp-removing .mfp-arrow {
    opacity: 0;
}


/* Google Maps */

.gm-style img {
    max-width: none;
    background: none;
}

.gm-style a {
    border: none;
}

.gm-style a:hover {
    background: none;
}


/* ==========================================================================
   Widget styles
   ========================================================================== */


/* Sidebar widgets*/

.sidebar .widget {
    margin-bottom: 70px;
}

.sidebar .widget-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    color: #f0f0f0;
    padding: 6px;
    margin: 0 0 15px 0;
    background: #1F2125;
}

.searchform input {
    margin: 0;
}

.searchform .input-field:before {
    font-size: 12px;
    right: 17px;
    top: 19px;
}


/* Latest posts */

.latest-posts,
.categories {
    line-height: normal;
}

.latest-posts ul,
.categories ul,
.latest-comments ul,
.links ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.latest-posts ul li,
.categories ul li,
.latest-comments ul li {
    margin-bottom: 15px;
}

.latest-posts em {
    font-size: 12px;
    color: #999;
}

.latest-posts a {
    font-weight: 700;
    color: var(--dark-gray-color);
    border: none;
}

.latest-posts a {
    font-weight: 700;
    color: var(--dark-gray-color);
    border: none;
}

.latest-posts a:hover,
.categories a:hover {
    color: var(--white-color);
}


/* Categories */

.categories a {
    color: var(--dark-gray-color);
    border: none;
}


/* Tags */

.tags a {
    display: inline-block;
    background: #888;
    color: var(--white-color);
    border: none;
    line-height: 1;
    padding: 12px;
    border-radius: var(--default-border-radius);
    font-size: 12px;
    margin: 0 3px 7px 0;
}

.tags a:hover {
    background: var(--white-color);
    color: #219b89;
}


/* Latest Comments */

.latest-comments li {
    line-height: 22px;
}

.latest-comments a {
    border: none;
}


/* Links */

.links li {
    margin-bottom: 15px;
}

.links a {
    border: none;
}

.links a:after {
    content: "\e2a1";
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: 10px;
    font-size: 8px;
    position: relative;
    top: -1px;
}


/* ==========================================================================
   Helper classes
   ========================================================================== */


/*
 * Image replacement
 */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}


/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}


/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}


/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden;
}


/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}


/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.left-bg-img,
.right-bg-content {
    margin-top: -68px;
    height: calc(100% + 68px) !important;
}

.white-color {
    color: var(--white-color);
}

.full-title {
    margin-top: 30px;
    float: left;
    width: 100%;
}

.recent-awards p {
    margin-bottom: 0;
}

.page-not-found {
    height: 70vh;
    padding-top: 30vh;
}

.hero-title.col-sm-12 {
    margin-bottom: 6px;
}

.pricing-header,
.contact-header {
    min-height: 250px;
    background: #d8d3e2;
    background: linear-gradient(#d0cde2, #e9dfe3);
}

.contact-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-header h1 {
    color: #333;
    margin: 0;
}

.pricing-header h1 {
    color: #333;
    margin: 60px 0 0;
}

.tab-page ul {
    padding: 0;
}

.resources-container {
    margin-bottom: 80px;
}

.news-container .col-sm-12 {
    margin-bottom: 30px;
}

.news-container h4 {
    margin-bottom: 4px;
}

.news-container p,
.news-item p {
    margin-bottom: 10px;
}

.news-container img {
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 10px;
}

.events-styling h4 {
    font-size: var(--font-size-heading-medium);
    color: #3994ff;
    margin-bottom: 10px;
}

.events-styling h4 a {
    color: #3994ff;
}

.events-styling span {
    display: block;
    font-size: 15px;
    color: #b9b9b9;
    line-height: 22px;
}

.events-styling span p {
    margin-bottom: 0;
}

.events-styling h5 {
    font-size: 15px;
    font-weight: 400;
    color: #313657;
    margin: 4px 0 0;
}

.news-container .events-styling a {
    display: inline-block;
}

.news-container .events-styling img {
    margin-top: 14px;
}

.events-styling .posted-on {
    font-size: 21px;
    font-weight: 400;
    color: #313657;
}

.posted-on {
    color: #999;
    font-size: 13px;
}

.more-btn {
    border-bottom: 1px solid;
    margin-top: 14px;
}

.news-item a {
    color: #4c8ec1;
}

.news-item a:hover,
.news-item a:active {
    color: #444;
}

.team-header img {
    bottom: 0;
}

.top-margin {
    margin-top: 20px;
}

.main-nav ul li a.noLink:hover {
    color: #333 !important;
}

/* Updates page css starts */

.updates-header {
    position: relative;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 250px;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.updates-header .container {
    z-index: 2;
}

.updates-header h1 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    font-weight: 600;
}

.updates-header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.updates-header * {
    color: var(--white-color);
}

.updates-content {
    margin-top: 40px;
    margin-bottom: 50px;
}

.updates-content ul {
    padding-left: 30px;
}

.updates-content h2 {
    font-weight: 600;
}

.updates-footer {
    position: relative;
    top: 1px;
}

@media only screen and (max-width: 767px) {
    .updates-header {
        height: 180px;
    }

    .updates-header h1 {
        font-size: 30px;
    }

    .updates-content {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .updates-footer {
        position: relative;
        top: -10px;
        object-fit: cover;
        object-position: left top;
        width: 100%;
        height: 110px;
    }
}

/* Updates page css ends */

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 1920px) {
    .page-title {
        margin-bottom: 20px;
    }

    .hero-img h2 {
        font-size: 44px;
    }

    .hero-list li span {
        font-size: var(--font-size-heading-medium) !important;
        font-weight: 700;
    }

    .superslides .slide-content .container {
        display: table-cell;
        padding: 15% 0px;
        vertical-align: inherit;
    }
}


/* Larger than desktop 1200 (devices and browsers) */

@media only screen and (min-width: 1200px) {
    .recent-awards span {
        font-size: 1em;
        margin-top: 20px;
    }

    .recent-awards.bottom-awards span {
        margin-top: 12px;
    }

    .tcrunch {
        margin-top: 1px;
    }

    .edsurge {
        margin-top: -7px;
    }

    .edweek {
        margin-top: -4px;
    }
}


/* Dekstop sizes (devices and browsers) */

@media only screen and (min-width: 1276px) {
    .head-logo {
        float: left;
    }

    .fa-bars {
        display: none !important;
    }

    .head-top .container {
        position: relative;
        display: table;
    }

    a.skip {
        padding: 6px;
        position: absolute;
        top: -100px;
        left: 0px;
        transition: top 1s ease-out;
        z-index: 99;
        font-weight: 400;
        background: #4C5C62;
        color: var(--white-color);
    }

    a.skip:focus,
    a.skip:active {
        position: absolute;
        left: 0px;
        top: 0px;
        transition: top .1s ease-in;
    }

    /* Main Navigation */
    .main-nav,
    .top-nav {
        text-align: right;
        display: table-cell;
        vertical-align: middle;
    }

    .main-nav>ul {
        display: inline-block;
    }

    .main-nav ul li,
    .top-nav ul li {
        position: relative;
        display: inline-block;
        text-align: left;
    }

    .main-nav ul li a,
    .top-nav ul li a {
        display: block;
        padding: 10px 15px;
        font-size: var(--font-size-body-small);
        font-weight: 400;
        color: #333;
        text-transform: uppercase;
        line-height: normal;
        border: none;
        transition: all 0.1s ease-in;
    }

    .main-nav.dark ul li a,
    .top-nav.dark ul li a {
        color: #333;
    }

    .main-nav.dark ul li.border-btn-outer a,
    .top-nav.dark ul li.border-btn-outer a {
        color: #008df0;
    }

    .main-nav.dark ul li ul li a,
    .top-nav.dark ul li ul li a {
        color: var(--white-color);
    }

    .main-nav ul li:hover>a:after,
    .top-nav ul li:hover>a:after {
        text-decoration: none;
        color: #777;
        transform: rotate(45deg);
    }

    .main-nav ul .has-subnav.active>a:after,
    .top-nav ul .has-subnav.active>a:after {
        top: 7px;
        color: #222;
    }

    .main-nav>ul>.active>a,
    .top-nav>ul>.active>a {
        color: var(--white-color);
        padding: 1px 12px 2px 12px;
        margin: 1px 8px 1px 8px;
        border: 1px solid var(--white-color);
        background: var(--white-color);
        color: #222;
        text-shadow: none;
        border-radius: 3px;
    }

    .main-nav>ul>.has-subnav.active>a,
    .top-nav>ul>.has-subnav.active>a {
        padding-right: 20px;
        margin-right: 0;
    }

    .main-nav ul li ul .active>a,
    .top-nav ul li ul .active>a {
        color: #219b89;
    }

    .main-nav ul li span.submenu-btn>i {
        padding-left: 3px;
        font-size: 0.8em;
    }

    /* subnav */
    /* .main-nav ul li ul,
    .top-nav ul li ul {
        position: absolute;
        top: 46px;
        right: 10px;
        white-space: nowrap;
        visibility: hidden;
        padding: 0;
        background: #3991ff;
        opacity: 0;
        transform: translate3d(-15px, 0, -300px);
        transform-style: preserve-3d;
    } */

    .main-nav ul li ul:after,
    .top-nav ul li ul:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 8px 8px;
        border-color: #3996ff transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -8px;
        right: 20px;
    }

    .main-nav ul .active ul,
    .top-nav ul .active ul {
        left: 8px;
        border-radius: 0 10px 10px 10px;
        transform: translate3d(-27px, 0, -300px);
    }

    .main-nav ul li:hover>ul,
    .top-nav ul li:hover>ul,
    .main-nav ul li.showsubmenu>ul {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }


    /* Tap as hover on touch devices DO NOT work with visibility, use display property instead */
    .touch .main-nav ul li ul,
    .touch .top-nav ul li ul {
        display: none;
    }

    .touch .main-nav ul li:hover>ul,
    .touch .top-nav ul li:hover>ul {
        display: block;
    }

    .main-nav ul li ul li,
    .top-nav ul li ul li {
        display: block;
    }

    .top-nav ul li ul li {
        padding: 0px;
    }

    .top-nav ul li ul {
        top: 60px;
        z-index: 99999;
    }

    .main-nav ul li ul li a,
    .top-nav ul li ul li a {
        display: block;
        text-transform: none;
        margin: 0;
        padding: 11px 36px;
        color: var(--white-color);
        font-size: var(--font-size-body-extra-small);
    }

    .main-nav ul li ul li a i,
    .top-nav ul li ul li a i {
        padding-right: 10px;
    }

    .main-nav ul li ul li a:hover,
    .top-nav ul li ul li a:hover {
        color: #219b89;
    }

    .main-nav ul li ul li a:focus,
    .top-nav ul li ul li a:focus {
        color: var(--white-color);
        background: #3996ff;
    }

    /* sub-subnav */
    .main-nav ul li ul li ul,
    .top-nav ul li ul li ul {
        left: auto;
        top: 0;
        right: -178px;
        padding: 0;
    }

    /* Animation */
    /*.csstransitions .animated {
        visibility: hidden;
        }*/
    .csstransitions .animated.visible {
        visibility: visible;
    }

    .no-js .animated {
        visibility: visible;
    }

    .csstransitions span.animated {
        display: inline-block;
    }
}

@media only screen and (min-width: 768px) {
    .recent-awards ul li img {
        filter: grayscale(1);
        transition: filter 0.3s;
    }

    .recent-awards ul li img:hover {
        filter: none;
    }

    /* News & Events page */
    .n-e-tabs a {
        display: inline-block;
        width: 200px;
    }

    .n-e-tabs li {
        margin-right: 16px;
    }
}


/* Smaller than desktop 1200 (devices and browsers) */

@media only screen and (max-width: 1200px) {

    .recent-awards span {
        font-size: 1em;
    }

    .recent-awards ul li img {
        padding: 8%;
    }

    #main-superslides,
    .superslides,
    .superslides .slide-content,
    .video-container video,
    .video-container video img {
        min-height: 100% !important;
        min-width: 100% !important;
    }

    .circle-stats {
        width: 170px;
    }

    .tcrunch {
        margin-top: 1px;
    }

    .edsurge {
        margin-top: -7px;
    }

    .edweek {
        margin-top: -4px;
    }

    .circle-stats .stat {
        font-size: 56px;
    }

    .circle-stats p {
        font-size: var(--font-size-body-medium);
    }

    .overlay-title,
    .overlay-subtitle {
        font-size: 28px;
    }

    .article-container .article-post {
        width: 45%;
        margin: 10px;
    }
}

/* Smaller than standard 960 (devices and browsers) */

@media only screen and (max-width: 1275px) {
    .col-xs-12.side-spacing {
        padding-right: 0;
        padding-left: 0;
    }

    .vcenter {
        max-width: 200px;
        margin-bottom: 30px;
    }

    .bio {
        text-align: center;
    }

    .bio p {
        margin-left: auto !important;
    }

    .bio-pic {
        text-align: center;
        padding-right: 0px;
    }

    .employee-name {
        font-size: var(--font-size-body-small);
    }

    .head-top .container {
        text-align: left;
    }

    .page-subtitle.feature {
        width: 60%;
    }

    .page-head.feature {
        height: 500px;
    }

    .fa-bars {
        display: block !important;
        margin-top: -5px;
    }

    .nav-trigger {
        display: inline-block;
    }


    .overlay-title,
    .overlay-subtitle {
        font-size: var(--font-size-heading-medium);
    }

    /* Main Navigation */
    .main-nav,
    .top-nav {
        opacity: 0;
        visibility: hidden;
        text-align: left;
        position: fixed;
        top: 68px;
        left: 0;
        width: 100%;
        max-height: 90%;
        background: var(--white-color);
        padding: 0 25px 15px 25px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        overflow-y: scroll;
        transform: translateY(-10px);
    }

    .securly-free-pages .main-nav,
    .securly-free-pages .top-nav {
        top: 68px;
    }

    .move-down .main-nav {
        top: 118px;
    }

    .shrink .main-nav {
        top: 60px;
    }

    .side-active .main-nav {
        left: -275px;
    }

    .trigger-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .main-nav ul li .submenu-btn {
        display: none;
    }

    .main-nav ul li a,
    .top-nav ul li a {
        display: block;
        color: #777;
        text-decoration: none;
        padding: 12px 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: var(--font-size-body-extra-small);
        pointer-events: auto;
        border-bottom: 1px solid #f0f0f0;
    }

    .main-nav ul li>ul,
    .top-nav ul li>ul {
        padding-left: 30px;
    }

    .main-nav>ul:hover>.active>a,
    .main-nav ul li:hover>a,
    .top-nav>ul:hover>.active>a,
    .top-nav ul li:hover>a {
        color: #219b89;
        background: none;
    }

    .main-nav ul .has-subnav>a:after,
    .top-nav ul .has-subnav>a:after {
        top: 40%;
        color: #bbb;
    }

    .main-nav ul .active>a,
    .main-nav ul .active>a:after,
    .top-nav ul .active>a,
    .top-nav ul .active>a:after {
        font-weight: bold;
        color: #219b89;
    }

    .main-nav ul .active a:after,
    .top-nav ul .active a:after {
        font-weight: normal;
    }

    .main-nav ul li:hover>a:after,
    .top-nav ul li:hover>a:after {
        text-decoration: none;
        color: #219b89;
        transform: rotate(45deg);
    }

    /* subnav
    .main-nav ul li > ul,
    .top-nav ul li > ul {
        display: none;
        }*/
    .main-nav .sign-up-btn,
    .main-nav ul .border-btn-outer a {
        margin-left: 0;
        /* width: fit-content; */
        margin-top: 12px;
        margin-bottom: 16px;
        padding: 11px 24px !important;
        border-radius: 46px;
        font-size: var(--font-size-body-extra-small);
        line-height: var(--line-height-md);
        min-width: 168px;
        text-align: center;
    }

    .main-nav ul .border-btn-outer a {
        margin-bottom: 4px;
        padding: 6px 24px !important;
    }

    .main-nav .sign-up-btn:hover {
        color: #253136 !important;
        background-color: #f8f8f8 !important;
    }

    .main-nav ul li:hover>ul,
    .top-nav ul li:hover>ul {
        display: block;
    }

    /* Footer */
    .page-footer .to-top {
        left: 45%;
    }

    /* Flexslider */
    .flex-direction-nav {
        display: none;
    }

    /* Media page css starts (By Vinod) - 14-10-16 */
    .profile {
        margin-top: 40px;
    }

    /* Media page css ends (By Vinod) - 14-10-16 */

    /* Home page css starts */

    .second-title {
        margin-top: 50px;
    }

    /* Home page css ends */

    /* Pricing page starts */

    .pricing-plans-section input,
    .ui-widget.custom-ui-design {
        width: 100%;
    }

    .pricing-plans-section .icon-heading {
        float: none;
    }

    .pricing-plans-section form {
        float: none;
        margin-top: 30px;
    }

    .package-details {
        height: auto;
    }

    /* Pricing page ends */

    /* Cms responsive */

    .hero-icon {
        margin-right: 6px;
    }

    .hero-list li {
        padding: 0px;
    }

    .leader {
        margin-bottom: 0;
    }

    .main-nav .fa,
    .main-nav .ss {
        margin-right: 8px;
    }

    .main-nav #parent-login-btn {
        border-bottom: 0;
    }

    /* Hamburger menu css */

    .ham-icon {
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 10px;
        float: right;
        top: 10px;
        border-radius: 0;
    }

    .ham-icon span,
    .ham-icon:before,
    .ham-icon:after {
        content: " ";
        display: block;
        width: 26px;
        height: 1px;
        background-color: #676767;
        transform: rotate(0);
        position: absolute;
        left: 10px;
        transition: all 300ms ease-in-out;
    }

    .ham-icon:before {
        top: 19px;
    }

    .ham-icon span {
        top: 26px;
        opacity: 1;
    }

    .ham-icon:after {
        bottom: 16px;
    }

    .ham-icon.active span {
        opacity: 0;
        top: 25px;
        transform: rotate(90deg);
    }

    .ham-icon.active:before {
        width: 24px;
        top: 25px;
        left: 12px;
        transform: rotate(45deg);
    }

    .ham-icon.active:after {
        width: 24px;
        bottom: 24px;
        left: 12px;
        transform: rotate(-45deg);
    }

    #about-one,
    #how-we {
        margin: 20px auto;
    }

    .skip {
        display: none;
    }

    /* Tablet Portrait size to standard 960 (devices and browsers) */

    .link-list {
        display: block;
    }

    .link-list li {
        flex: inherit;
        display: inline-block;
    }

}

/* Tablet Portrait size to standard 960 (devices and browsers) */


@media only screen and (min-width: 768px) and (max-width: 992px) {

    .main-nav ul li a:hover,
    .top-nav ul li a:hover {
        color: #ccc !important;
    }

    #normal-login {
        padding-bottom: 10px;
    }

    address .address-item {
        padding-left: 22%;
    }

    .portfolio-item .item-description {
        padding: 25% 20px;
    }

    .employee .employee-social a {
        padding: 0;
        display: block;
        border: none;
        margin-bottom: 10px;
    }

    .col-sm-3 .portfolio-item .inner-content {
        top: 20%;
    }

    .footer-contact {
        position: relative;
    }

    .partner-img {
        min-height: 130px;
    }

    /* Webinar page css starts */

    .webinar-hero h1 {
        font-size: 50px;
    }

    .webinar-hero h4 {
        font-size: 34px;
    }

    .webinar-hero h3,
    .webinar-hero h5 {
        font-size: 28px;
    }

    /* Webinar page css ends */
}

/* All Mobile Sizes (devices and browser) */

@media only screen and (max-width: 768px) {
    .bio p {
        text-align: center;
    }

    .head-top .container {
        margin-left: 20px;
    }

    .gmail-signup-btn {
        padding-left: 40px;
        margin: 30px auto;
        padding-top: 0px;
        font-size: 15px;
        display: block;
        width: 300px;
        height: 75px;
        border: none;
    }

    /*    .btn-hero {
            position: absolute;
            bottom: 80px;
            font-size: 26px;
            width: 300px;
            }*/
    .hero-img .down-pointer {
        margin-left: -45px;
    }

    .slide-content .page-title {
        margin-left: 10px;
        margin-top: 0px;
        font-size: var(--font-size-heading-extra-large);
        line-height: 40px;
    }

    .second-nav {
        visibility: hidden;
    }

    .parents .hero a {
        margin-left: 10px;
    }

    .gmail-btn {
        padding-left: 30px;
        padding-top: 10px;
        font-size: 15px;
        width: 200px;
        height: 44px;
    }

    .parents-page .head-top.shrink {
        background: none;
    }

    .superslides .slide-content .container {
        vertical-align: inherit;
        height: 100%;
        padding-top: 100px !important;
    }

    .parents-page .head-top.shrink h2 {
        visibility: hidden;
    }

    .sub-text {
        margin-left: 10px;
    }

    .slide-content h2 {
        font-size: var(--font-size-heading-medium);
        margin-left: 10px;
    }

    .slide-content .hero-buttons {
        margin-left: 10px;
    }

    .slide-content .hero-list {
        bottom: 150px;
        position: absolute;
    }

    .slide-content .hero-list li {
        margin-bottom: 0px;
    }

    .slide-content .hero-list li span {
        font-size: var(--font-size-heading-medium) !important;
    }

    .slide-content .hero-list li i {
        font-size: 30px !important;
    }

    .main-nav ul li a:hover {
        color: #333 !important;
    }

    .sign-up-btn {
        margin-left: 0px;
    }

    .superslides,
    .superslides .slide-content {
        height: 586px !important;
    }

    /*.input-selected {
        display: none
    }*/

    .footer-row.container {
        margin-left: 0px !important;
    }

    .footer-menu {
        padding: 50px 15px 28px;
    }

    #login {
        padding: 0px;
    }

    .feature-list-bottom {
        margin-bottom: 0px !important;
    }

    .login-btn {
        margin-left: 0px;
    }

    .press_logo {
        float: left;
        margin: 0 0 20px;
    }

    .press_title,
    .original_post_content {
        float: left;
    }

    .video-container video {
        margin-left: -90%;
    }

    .link-list {
        margin: 0;
        display: block;
    }

    .link-list li {
        display: block;
    }

    .testimonials:before {
        margin-top: -44px;
    }

    .tabs {
        margin-top: -80px;
        margin-bottom: 50px;
    }

    .big-right-arrow {
        visibility: hidden;
    }

    .screen-quote {
        display: none;
    }

    .mobile-quote {
        display: block;
        margin-top: 40px;
    }

    .quote-row div.left {
        padding-top: 20px;
        float: none;
        padding-right: 0px;
        text-align: center;
    }

    .quote-text {
        margin-bottom: 10px;
    }

    .get-quote {
        margin-top: 0px;
    }

    .mobile-quote .calculate-message {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .partners:before {
        margin-top: -20px;
    }

    .pricing-page {
        margin-top: -30px;
    }

    .quote-page {
        margin-top: -60px;
    }

    .signup-page {
        margin-top: -60px;
    }

    .safe-youtube img {
        margin-bottom: -50px
    }

    .feature-box {
        margin-top: 0;
        padding-left: 0;
    }

    .footer-contact {
        width: 100%;
        height: 107%;
    }

    .schools div {
        width: 50%;
        float: left;
    }

    .blue-section {
        margin-top: 30px;
    }

    .tcrunch {
        margin-top: 37px;
    }

    .edsurge {
        margin-top: 27px;
    }

    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .paragraph-title {
        font-size: var(--font-size-heading-small);
    }

    .paragraph-lead {
        font-size: var(--font-size-body-small);
        line-height: 26px;
    }

    .section-split {
        padding: 20px 0;
    }

    .hero {
        line-height: normal;
        padding: 0 5%;
    }

    .huge {
        font-size: 210px;
    }

    blockquote.pull-left,
    blockquote.pull-right {
        width: 60%;
    }

    .section-title.about {
        margin-bottom: 40px;
    }

    .overlay-title,
    .overlay-subtitle {
        font-size: var(--font-size-body-large);
    }

    .service-box:before {
        font-size: 48px;
    }

    address .address-item {
        padding-left: 35px;
    }

    .testimonial {
        text-align: center;
    }

    .section-split.stick {
        margin-bottom: -35px;
    }

    .icon-box {
        margin-bottom: 25px;
    }

    .article-meta {
        text-align: left;
    }

    .children {
        margin-left: 10px;
    }

    .circle-stats {
        width: 140px;
        margin: 40px 0;
    }

    .circle-stats .stat {
        font-size: 36px;
    }

    .circle-stats p {
        font-size: var(--font-size-body-extra-small);
    }

    .employee .employee-image {
        width: 80px;
    }

    .grid .portfolio-item {
        max-width: 50%;
    }

    /* Header Top Bar */
    .page-head {
        height: 300px;
    }

    .page-head .page-title {
        line-height: 38px;
    }

    .page-head .page-subtitle {
        font-size: var(--font-size-body-medium);
    }

    /* Superslides */
    .superslides .slides-pagination {
        bottom: 15px;
    }

    .superslides .slide-content .container {
        padding: 0 8%;
    }

    .page-title {
        line-height: normal;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .page-subtitle {
        font-size: var(--font-size-body-extra-small);
        line-height: normal;
        letter-spacing: 0;
    }

    .button-inverted {
        padding: 6px 15px;
    }

    .article-container .article-post {
        width: 43%;
    }

    .hero-title {
        margin-top: 4vh;
    }

    .schools .auditor-title,
    .schools .auditor-title p {
        font-size: 30px;
        line-height: var(--line-height-sm);
    }
}


/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    #login {
        margin: 20px;
    }

    .btn-hero {
        position: absolute;
        bottom: 70px;
        font-size: var(--font-size-body-medium);
        width: 230px;
    }

    .login-btn+ul li:first-of-type {
        display: none;
    }

    .small-mobile-only {
        display: block;
    }

    .mobile-only {
        display: block;
    }

    #normal-login {
        padding-bottom: 10px;
    }

    #normal-login.mobile-only {
        display: none;
    }

    .filter-slash {
        position: absolute;
        left: 110px;
        margin-top: -38px;
        width: 84px !important;
        max-height: 29px !important;
        min-height: 59px !important;
    }

    #normal-login h3 {
        padding-top: 60px;
    }

    .desktop-only {
        display: none;
    }

    .testimonials p {
        color: white !important;
        padding-top: 30px;
        font-size: 30px;
        line-height: 38px;
    }

    .circle-stats {
        width: 155px;
    }

    /* Footer */
    .page-footer .to-top {
        left: 43%;
    }

    .partner-img {
        min-height: 130px;
    }
}


/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */

@media only screen and (max-width: 479px) {
    .slide-content .hero-list li span {
        font-size: var(--font-size-heading-small) !important;
    }

    .slide-content .hero-list li i {
        font-size: var(--font-size-heading-small) !important;
    }

    .btn-hero {
        position: absolute;
        bottom: 70px;
        font-size: var(--font-size-body-medium);
        width: 230px;
    }

    .gmail-signup-btn {
        padding-left: 35px;
        margin: auto;
        padding-top: 0px;
        font-size: 11px;
        display: block;
        width: 200px;
        height: 50px;
        border: none;
    }

    .filter-slash {
        position: absolute;
        left: 95px;
        margin-top: -30px;
        width: 84px !important;
        max-height: 29px !important;
        min-height: 59px !important;
    }

    .small-mobile-only {
        display: block;
    }

    .login-btn+ul li:first-of-type {
        display: none;
    }

    .pagination .paging-link {
        display: none;
    }

    .all-features .features-title {
        margin-bottom: 20px;
    }

    #login {
        margin: 20px
    }

    #normal-login.mobile-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .portfolio-nav span {
        display: none;
    }

    .testimonials p {
        color: white !important;
        padding-top: 30px;
        font-size: var(--font-size-body-medium);
        line-height: 22px;
    }

    /* Footer */
    .page-footer .to-top {
        left: 35%;
    }

    .article-container .article-post {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .partner-img .ctl {
        width: 100px;
    }

    .partner-img .google {
        margin-top: 20px;

    }

    .partner-img .digwifi {
        width: 100px;
    }

    /* Home page css starts */

    .pre-footer {
        text-align: left;
    }

    .pre-footer h4 {
        margin-top: 20px;
    }

    .section-split.pre-footer {
        padding-right: 10px;
        padding-left: 10px;
    }

    .pre-footer .col-xs-4 {
        float: none;
        width: auto;
        margin-bottom: 40px;
    }

    .pre-footer .col-xs-4 img {
        max-width: 60%;
    }

    /* Home page css ends */
}

@media print,
(-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}


/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: var(--black-color) !important;
        /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
        /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}


/* Small devices (tablets, 768px and up) */

@media (max-width: 1200px) {
    .signup_container.col-sm-6 {
        width: auto;
        height: auto;
        float: none;
        margin: -10px auto 0;
    }

    .head-top .cm_container p {
        float: none !important;
        text-align: center !important;
    }

    .signup_container .signup.text {
        text-align: left;
    }

    .comingsoon_banner {
        height: 90px;
    }
}

@media (max-width: 767px) {
    .hero-image.center {
        max-width: 60% !important;
    }

    .hero-image.drop-right {
        bottom: 96px;
        max-width: 34% !important;
    }

    .hero-image.drop-left {
        bottom: 100px;
        max-width: 30% !important;
    }

    .recent-awards {
        padding-top: 40px;
    }

    .securly-free-pages #content.wonderbar-content {
        margin-top: 68px;
    }

    #content.wonderbar-content {
        margin-top: 128px;
    }

    #signup-form button {
        margin-left: 0;
    }

    .schools .monitoring {
        margin-bottom: 20px;
    }

    .signup_container .signup.text {
        width: 200px;
    }

    /* Media page css starts (By Vinod) - 14-10-16 */
    .profile-content .row {
        margin-left: 0;
        margin-right: 0;
    }

    .profile-content [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
    }

    #media-hero {
        padding: 16% 0;
    }

    #media-hero .page-title {
        font-size: var(--font-size-heading-extra-large);
        line-height: 44px;
    }

    #media-hero .page-title,
    .page-sub-title {
        margin-right: 10px;
        margin-left: 10px;
    }

    .page-sub-title {
        font-size: 30px;
        line-height: 38px;
        margin-top: 16px;
    }

    .page-section h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .media-content p {
        font-size: var(--font-size-body-medium);
        line-height: 30px;
        margin-bottom: 20px;
    }

    .page-section {
        padding: 20px 10px;
    }

    .profile img {
        margin-bottom: 20px;
    }

    .profile h5 {
        margin-bottom: 16px;
    }

    .img-holder {
        margin: 0;
        overflow: hidden;
    }

    .team-img1,
    .team-img2,
    .team-img3 {
        float: none;
        width: 100%;
        height: 0;
        margin: 10px 0 0;
        padding-top: 66.66%;
    }

    .team-img1 {
        width: 101%;
    }

    .more-info {
        margin-top: 50px;
    }

    .more-info [class*="col-"] {
        margin-bottom: 30px;
    }

    .more-info h5 {
        font-size: var(--font-size-body-medium);
        line-height: 26px;
    }

    .more-info p {
        font-size: var(--font-size-body-small);
        line-height: 26px;
    }

    .more-info .fa {
        font-size: 36px;
        width: 90px;
        height: 90px;
        padding: 26px 0;
        margin-bottom: 30px;
    }

    .press-kit .row {
        padding: 0px;
    }

    .press-kit [class*="col-"] {
        padding: 0 15px 30px;
    }

    .white-box {
        height: 200px;
    }

    .white-box h4 {
        margin: 15px 8px 0 15px;
        font-size: var(--font-size-heading-medium);
        line-height: 30px;
    }

    .white-box .ss {
        position: absolute;
        /* left: 15px; */
        bottom: 10px;
    }

    /* Media page css ends (By Vinod) - 14-10-16 */

    /* Accomplishments 2016 css starts */

    #accomplishments-hero {
        padding-right: 30px;
        padding-left: 30px;
        background-position: right 10px;
    }

    #accomplishments-hero img {
        max-width: 160px;
    }

    .accomplishments-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .white-wrap,
    .white-wrap img {
        border-radius: 0;
    }

    .page-heading {
        font-size: 30px;
        line-height: 34px;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
        margin-top: 50px;
    }

    .white-wrap {
        margin: 0;
        padding-bottom: 0;
    }

    .white-wrap .page-section {
        padding-top: 20px;
    }

    .awards-wrap .col-xs-6 {
        margin-bottom: 40px;
    }

    .awards-wrap img {
        max-width: 90px;
    }

    .white-wrap h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .padding-box [class*="col-"] {
        padding-right: 30px;
        padding-left: 30px;
    }

    .padding-box:after {
        width: 100%;
        height: 0;
        padding-top: 66.66%;
    }

    .padding-box .col-sm-6 {
        padding-top: 72%;
        padding-bottom: 50px;
    }

    .padding-box .page-section {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .padding-box.awards-wrap [class*="col-"] {
        padding-bottom: 0;
    }

    .awards-wrap {
        margin-bottom: 40px;
    }

    /* Animation */
    /*.csstransitions .animated {
        visibility: hidden;
        }*/
    .csstransitions .animated.visible {
        visibility: visible;
    }

    .no-js .animated {
        visibility: visible;
    }

    .csstransitions span.animated {
        display: inline-block;
    }
}

@media only screen and (min-width: 768px) {
    .recent-awards ul li img {
        filter: grayscale(1);
        transition: filter 0.3s;
    }

    .recent-awards ul li img:hover {
        filter: none;
    }

    /* News & Events page */
    .n-e-tabs a {
        display: inline-block;
        width: 200px;
    }

    .n-e-tabs li {
        margin-right: 16px;
    }
}


/* Smaller than desktop 1200 (devices and browsers) */

@media only screen and (max-width: 1200px) {

    .recent-awards span {
        font-size: 1em;
    }

    .recent-awards ul li img {
        padding: 8%;
    }

    #main-superslides,
    .superslides,
    .superslides .slide-content,
    .video-container video,
    .video-container video img {
        min-height: 100% !important;
        min-width: 100% !important;
    }

    .circle-stats {
        width: 170px;
    }

    .tcrunch {
        margin-top: 1px;
    }

    .edsurge {
        margin-top: -7px;
    }

    .edweek {
        margin-top: -4px;
    }

    .circle-stats .stat {
        font-size: 56px;
    }

    .circle-stats p {
        font-size: var(--font-size-body-medium);
    }

    .overlay-title,
    .overlay-subtitle {
        font-size: 28px;
    }

    .article-container .article-post {
        width: 45%;
        margin: 10px;
    }
}

/* Smaller than standard 960 (devices and browsers) */

@media only screen and (max-width: 1275px) {
    .col-xs-12.side-spacing {
        padding-right: 0;
        padding-left: 0;
    }

    .vcenter {
        max-width: 200px;
        margin-bottom: 30px;
    }

    .bio {
        text-align: center;
    }

    .bio p {
        margin-left: auto !important;
    }

    .bio-pic {
        text-align: center;
        padding-right: 0px;
    }

    .employee-name {
        font-size: var(--font-size-body-small);
    }

    .head-top .container {
        text-align: left;
    }

    .page-subtitle.feature {
        width: 60%;
    }

    .page-head.feature {
        height: 500px;
    }

    .fa-bars {
        display: block !important;
        margin-top: -5px;
    }

    .nav-trigger {
        display: inline-block;
    }


    .overlay-title,
    .overlay-subtitle {
        font-size: var(--font-size-heading-medium);
    }

    /* Main Navigation */
    .main-nav,
    .top-nav {
        opacity: 0;
        visibility: hidden;
        text-align: left;
        position: fixed;
        top: 68px;
        left: 0;
        width: 100%;
        max-height: 90%;
        background: var(--white-color);
        padding: 0 25px 15px 25px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        overflow-y: scroll;
        transform: translateY(-10px);
    }

    .securly-free-pages .main-nav,
    .securly-free-pages .top-nav {
        top: 68px;
    }

    .move-down .main-nav {
        top: 118px;
    }

    .shrink .main-nav {
        top: 60px;
    }

    .side-active .main-nav {
        left: -275px;
    }

    .trigger-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .main-nav ul li .submenu-btn {
        display: none;
    }

    .main-nav ul li a,
    .top-nav ul li a {
        display: block;
        color: #777;
        text-decoration: none;
        padding: 12px 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: var(--font-size-body-extra-small);
        pointer-events: auto;
        border-bottom: 1px solid #f0f0f0;
    }

    .main-nav ul li>ul,
    .top-nav ul li>ul {
        padding-left: 30px;
    }

    .main-nav>ul:hover>.active>a,
    .main-nav ul li:hover>a,
    .top-nav>ul:hover>.active>a,
    .top-nav ul li:hover>a {
        color: #219b89;
        background: none;
    }

    .main-nav ul .has-subnav>a:after,
    .top-nav ul .has-subnav>a:after {
        top: 40%;
        color: #bbb;
    }

    .main-nav ul .active>a,
    .main-nav ul .active>a:after,
    .top-nav ul .active>a,
    .top-nav ul .active>a:after {
        font-weight: bold;
        color: #219b89;
    }

    .main-nav ul .active a:after,
    .top-nav ul .active a:after {
        font-weight: normal;
    }

    .main-nav ul li:hover>a:after,
    .top-nav ul li:hover>a:after {
        text-decoration: none;
        color: #219b89;
        transform: rotate(45deg);
    }

    /* subnav
    .main-nav ul li > ul,
    .top-nav ul li > ul {
        display: none;
        }*/
    .main-nav .sign-up-btn {
        margin-left: 0;
        width: fit-content;
        margin-top: 12px;
    }

    .main-nav .sign-up-btn:hover {
        color: #253136 !important;
        background-color: #f8f8f8 !important;
    }

    .main-nav ul li:hover>ul,
    .top-nav ul li:hover>ul {
        display: block;
    }

    /* Footer */
    .page-footer .to-top {
        left: 45%;
    }

    /* Flexslider */
    .flex-direction-nav {
        display: none;
    }

    /* Media page css starts (By Vinod) - 14-10-16 */
    .profile {
        margin-top: 40px;
    }

    /* Media page css ends (By Vinod) - 14-10-16 */

    /* Home page css starts */

    .second-title {
        margin-top: 50px;
    }

    /* Home page css ends */

    /* Pricing page starts */

    .pricing-plans-section input,
    .ui-widget.custom-ui-design {
        width: 100%;
    }

    .pricing-plans-section .icon-heading {
        float: none;
    }

    .pricing-plans-section form {
        float: none;
        margin-top: 30px;
    }

    .package-details {
        height: auto;
    }

    /* Pricing page ends */

    /* Cms responsive */

    .hero-icon {
        margin-right: 6px;
    }

    .hero-list li {
        padding: 0px;
    }

    .leader {
        margin-bottom: 0;
    }

    .main-nav .fa,
    .main-nav .ss {
        margin-right: 8px;
    }

    .main-nav .parent-login-btn {
        border-bottom: 0;
    }

    /* Hamburger menu css */

    .top-header .s-logo {
        margin: 0;
    }

    .ham-icon {
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 12px;
        float: right;
        top: 6px;
        border-radius: 0;
        cursor: pointer;
    }

    .ham-icon span,
    .ham-icon:before,
    .ham-icon:after {
        content: " ";
        display: block;
        width: 30px;
        height: 2px;
        background-color: #949494;
        transform: rotate(0);
        position: absolute;
        left: 10px;
        transition: all 300ms ease-in-out;
    }

    .ham-icon:before {
        top: 19px;
    }

    .ham-icon span {
        top: 28px;
        opacity: 1;
    }

    .ham-icon:after {
        bottom: 11px;
    }

    .ham-icon.active span {
        opacity: 0;
        top: 25px;
        transform: rotate(90deg);
    }

    .ham-icon.active:before {
        width: 30px;
        top: 26px;
        left: 10px;
        transform: rotate(45deg);
    }

    .ham-icon.active:after {
        width: 30px;
        bottom: 22px;
        left: 10px;
        transform: rotate(-45deg);
    }

    #about-one,
    #how-we {
        margin: 20px auto;
    }

    .skip {
        display: none;
    }

    /* Tablet Portrait size to standard 960 (devices and browsers) */

    .link-list {
        display: block;
    }

    .link-list li {
        flex: inherit;
        display: inline-block;
    }

    .us-loves-us [class*="col-"],
    .awards-wrap [class*="col-"] {
        padding: 20px 10px;
    }

    /* Accomplishments 2016 css ends */

    /* Home page css starts */
    .right-text {
        text-align: center;
        margin-bottom: 20px;
    }

    .awards-updates [class*="col-"] {
        margin-bottom: 20px;
    }

    .recent-awards ul li img {
        max-width: 78px;
    }

    .said-things {
        width: 80%;
    }

    .homepage .row {
        margin-right: 0;
        margin-left: 0;
    }

    .testimonials-wrapper {
        margin-top: -50px;
        padding-bottom: 50px;
    }

    .page-content .middle-row {
        margin-top: 30px;
        padding-bottom: 30px;
    }

    .custom-page .features .container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .quote-mark {
        left: -20px;
        max-width: 50px;
    }

    .said-things {
        padding: 15px;
    }

    .custom-page .feature-title {
        font-size: 28px;
    }

    .custom-page .section-title {
        font-size: 30px;
    }

    .our-schools div {
        margin-bottom: 30px;
    }

    .circle-img {
        margin-bottom: 30px;
    }

    .said-things h4 {
        font-size: var(--font-size-body-medium);
        margin-bottom: 20px;
    }

    .custom-page p {
        font-size: var(--font-size-body-extra-small);
        line-height: var(--line-height-xl);
    }

    .homepage .section-split {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .homepage .schools {
        display: block !important;
    }

    .our-schools [class*="col-"] {
        width: 100%;
        float: none;
    }

    .homepage .section-title::after {
        width: 12%;
        margin-left: -6%;
        bottom: -20px;
    }

    .btn-hero.big-blue-button {
        position: static;
    }

    .slide-content .page-title {
        margin-bottom: 30px;
    }

    .homehero .hero-list {
        bottom: auto;
        position: static;
    }

    .hero-img .down-pointer {
        display: none;
    }

    .home-superslides,
    .home-superslides .slide-content {
        height: 800px !important;
    }

    .home-superslides {
        overflow: visible !important;
    }

    .btn-hero.big-blue-button {
        margin-top: 20px;
        padding: 11px 30px;
        font-size: var(--font-size-body-small);
    }

    /* Home page css ends */

    /* Pricing page css starts */

    .main-container {
        margin-top: -30px;
    }

    .package-details {
        margin-top: 60px;
        padding-top: 10px;
        border-radius: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .package-details h2 {
        margin-top: 20px;
    }

    .main-container {
        padding: 0;
    }

    .pricing-form .ui-widget.custom-ui-design {
        margin-bottom: 20px;
    }

    /* Pricing page css ends */

    /* Chromebook free css starts */
    .wonderbar {
        text-align: center;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
    }

    /*    .wonderbar img {
        display: none;
        }*/
    /*.wonderbar p span {
                display: inline-block;
        width: 60%;
        text-align: left;
        float: left;
        font-size: 15px;
        line-height: var(--line-height-md);
    }*/

    .wonderbar .white-border-btn {
        margin-top: 4px;
        margin-left: 0;
        display: inline-block;
        top: 0;
        padding: 4px 15px;
    }

    .move-down,
    .move-down .main-nav {
        top: 60px;
    }

    .page-head .page-title {
        margin-top: 100px;
    }

    .superslides.chromebook-hero .hero-img {
        background: #484963;
    }

    .chromebook-hero img {
        width: auto;
        min-height: inherit;
        margin: 50px 0 20px;
    }

    .superslides.chromebook-hero,
    .superslides.chromebook-hero .slide-content {
        height: 500px !important;
    }

    .chromebook-hero h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .superslides.chromebook-hero .hero-img {
        padding-top: 60px !important;
        text-align: center;
    }

    .page-brief {
        margin: 30px 0;
    }

    .features-list [class*="col-"] {
        margin-bottom: 50px;
        padding: 0 15px;
    }

    .chart-section h2 {
        margin: 10px 10px 40px;
    }

    .custom-page .securly-free-btm .our-schools {
        margin: 40px 0;
    }

    .features-list h2 {
        margin-bottom: 50px;
    }

    .chart-section {
        padding: 40px 0;
    }

    .chart-table .blue-oval-btn {
        padding: 10px;
        font-size: 13px;
    }

    .page-brief h2,
    .features-list h2,
    .chart-section h2 {
        font-size: var(--font-size-heading-medium);
    }

    .home-superslides .slide-content .page-title,
    .school-hero .page-title,
    .parents .page-title {
        margin-top: 108px;
    }

    .schools .school-hero {
        width: auto;
    }

    .parents .hero a,
    .schools .hero a {
        margin: 0 10px;
    }

    #pricing-hero {
        margin-top: 108px;
        padding-top: 20%;
        padding-bottom: 20%;
    }

    #pricing-hero .page-title {
        font-size: 30px;
    }

    .page-brief,
    .page-brief p {
        font-size: var(--font-size-body-small);
        line-height: 28;
        font-weight: 400;
    }

    .table-responsive>.chart-table>thead>tr>th,
    .table-responsive>.chart-table>tbody>tr>td {
        white-space: inherit;
    }

    .chart-table p,
    .chart-table h6 {
        line-height: var(--line-height-md);
    }

    .press-kit [class*="col-"] {
        padding: 0 15px 30px;
    }

    .white-box {
        height: 200px;
    }

    .white-box h4 {
        margin: 15px 8px 0 15px;
        font-size: var(--font-size-heading-medium);
        line-height: 30px;
    }

    .white-box h5 {
        margin: 12px 15px;
        font-size: var(--font-size-body-small);
    }

    .white-box .ss {
        position: absolute;
        /* left: 15px; */
        bottom: 10px;
    }

    /* Media page css ends (By Vinod) - 14-10-16 */

    /* Accomplishments 2016 css starts */

    #accomplishments-hero {
        padding-right: 30px;
        padding-left: 30px;
        background-position: right 10px;
    }

    #accomplishments-hero img {
        max-width: 160px;
    }

    .accomplishments-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .white-wrap,
    .white-wrap img {
        border-radius: 0;
    }

    .page-heading {
        font-size: 30px;
        line-height: 34px;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
        margin-top: 50px;
    }

    .white-wrap {
        margin: 0;
        padding-bottom: 0;
    }

    .white-wrap .page-section {
        padding-top: 20px;
    }

    .awards-wrap .col-xs-6 {
        margin-bottom: 40px;
    }

    .awards-wrap img {
        max-width: 90px;
    }

    .white-wrap h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .padding-box [class*="col-"] {
        padding-right: 30px;
        padding-left: 30px;
    }

    .padding-box:after {
        width: 100%;
        height: 0;
        padding-top: 66.66%;
    }

    .padding-box .col-sm-6 {
        padding-top: 72%;
        padding-bottom: 50px;
    }

    .padding-box .page-section {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .padding-box.awards-wrap [class*="col-"] {
        padding-bottom: 0;
    }

    .awards-wrap {
        margin-bottom: 40px;
    }

    .us-loves-us [class*="col-"],
    .awards-wrap [class*="col-"] {
        padding: 20px 10px;
    }

    /* Accomplishments 2016 css ends */

    /* Home page css starts */
    .right-text {
        text-align: center;
        margin-bottom: 20px;
    }

    .awards-updates [class*="col-"] {
        margin-bottom: 20px;
    }

    .recent-awards ul li img {
        max-width: 78px;
    }

    .said-things {
        width: 80%;
    }

    .homepage .row {
        margin-right: 0;
        margin-left: 0;
    }

    .testimonials-wrapper {
        margin-top: -50px;
        padding-bottom: 50px;
    }

    .page-content .middle-row {
        margin-top: 30px;
        padding-bottom: 30px;
    }

    .custom-page .features .container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .quote-mark {
        left: -20px;
        max-width: 50px;
    }

    .said-things {
        padding: 15px;
    }

    .custom-page .feature-title {
        font-size: 28px;
    }

    .custom-page .section-title {
        font-size: 30px;
    }

    .our-schools div {
        margin-bottom: 30px;
    }

    .circle-img {
        margin-bottom: 30px;
    }

    .said-things h4 {
        font-size: var(--font-size-body-medium);
        margin-bottom: 20px;
    }

    .custom-page p {
        font-size: var(--font-size-body-extra-small);
        line-height: var(--line-height-xl);
    }

    .homepage .section-split {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .homepage .schools {
        display: block !important;
    }

    .our-schools [class*="col-"] {
        width: 100%;
        float: none;
    }

    .homepage .section-title::after {
        width: 12%;
        margin-left: -6%;
        bottom: -20px;
    }

    .btn-hero.big-blue-button {
        position: static;
    }

    .btn-hero.big-grey-button {
        position: static;
    }

    .slide-content .page-title {
        margin-bottom: 30px;
    }

    .homehero .hero-list {
        bottom: auto;
        position: static;
    }

    .hero-img .down-pointer {
        display: none;
    }

    .home-superslides,
    .home-superslides .slide-content {
        height: 800px !important;
    }

    .home-superslides {
        overflow: visible !important;
    }

    .btn-hero.big-blue-button {
        margin-top: 20px;
        padding: 11px 30px;
        font-size: var(--font-size-body-small);
    }

    .btn-hero.big-grey-button {
        margin-top: 20px;
        padding: 11px 30px;
        font-size: var(--font-size-body-small);
    }

    /* Home page css ends */

    /* Pricing page css starts */

    .main-container {
        margin-top: -30px;
    }

    .package-details {
        margin-top: 60px;
        padding-top: 10px;
        border-radius: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .package-details h2 {
        margin-top: 20px;
    }

    .main-container {
        padding: 0;
    }

    .pricing-form .ui-widget.custom-ui-design {
        margin-bottom: 20px;
    }

    /* Pricing page css ends */

    /* Chromebook free css starts */
    .wonderbar {
        text-align: center;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /*    .wonderbar img {
        display: none;
    }
    .wonderbar p span {
        display: inline-block;
        width: 60%;
        text-align: left;
        font-size: 13px;
        line-height: var(--line-height-lg);
        float: left;
        }*/
    .wonderbar .white-border-btn {
        margin-top: 4px;
        margin-left: 0;
        display: inline-block;
        top: 0;
        padding: 4px 15px;
    }

    .move-down,
    .move-down .main-nav {
        top: 60px;
    }

    .page-head .page-title {
        margin-top: 100px;
    }

    .superslides.chromebook-hero .hero-img {
        background: #484963;
    }

    .chromebook-hero img {
        width: auto;
        min-height: inherit;
        margin: 50px 0 20px;
    }

    .superslides.chromebook-hero,
    .superslides.chromebook-hero .slide-content {
        height: 500px !important;
    }

    .chromebook-hero h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .superslides.chromebook-hero .hero-img {
        padding-top: 60px !important;
        text-align: center;
    }

    .page-brief {
        margin: 30px 0;
    }

    .features-list [class*="col-"] {
        margin-bottom: 50px;
        padding: 0 15px;
    }

    .chart-section h2 {
        margin: 10px 10px 40px;
    }

    .custom-page .securly-free-btm .our-schools {
        margin: 40px 0;
    }

    .features-list h2 {
        margin-bottom: 50px;
    }

    .chart-section {
        padding: 40px 0;
    }

    .chart-table .blue-oval-btn {
        padding: 10px;
        font-size: 13px;
    }

    .page-brief h2,
    .features-list h2,
    .chart-section h2 {
        font-size: var(--font-size-heading-medium);
    }

    .home-superslides .slide-content .page-title,
    .school-hero .page-title,
    .parents .page-title {
        margin-top: 108px;
    }

    .schools .school-hero {
        width: auto;
    }

    .parents .hero a,
    .schools .hero a {
        margin: 0 10px;
    }

    #pricing-hero {
        margin-top: 108px;
        padding-top: 20%;
        padding-bottom: 20%;
    }

    #pricing-hero .page-title {
        font-size: 30px;
    }

    .page-brief,
    .page-brief p {
        font-size: var(--font-size-body-small);
        line-height: 28;
        font-weight: 400;
    }

    .table-responsive>.chart-table>thead>tr>th,
    .table-responsive>.chart-table>tbody>tr>td {
        white-space: inherit;
    }

    .chart-table p,
    .chart-table h6 {
        line-height: var(--line-height-md);
    }

    /* Chromebook free css ends */

    .footer-menu {
        padding: 20px 10px 10px;
    }

    .footer-contact {
        height: auto;
        padding: 10px;
        font-size: 13px;
        text-align: center;
    }

    .footer-contact .col-xs-12 {
        margin-bottom: 14px;
    }

    .link-list {
        padding-bottom: 20px;
    }

    .link-list li {
        width: 50%;
        float: left;
        margin-bottom: 10px;
    }

    .disclaimer {
        margin: 0;
        text-align: center;
        line-height: 18px;
    }

    .pull-right.social-icons {
        float: none !important;
        margin-top: 10px;
        text-align: center;
    }

    .social-icons a {
        margin-right: 0;
    }

    .news-header h1,
    .full-page-header h1 {
        margin-top: 0 !important;
    }

    .news-header,
    .full-page-header {
        min-height: 120px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tab-page ul {
        margin: 0;
        padding: 0;
    }

    .tabs {
        margin: 0 0 20px;
        text-align: center;
    }

    .tabs li {
        margin: 0;
        display: block;
    }

    .tabs li a {
        display: block;
    }

    .tabs a.active {
        border-top: 0;
    }

    .feature-box h2 {
        width: 100%;
        float: left;
        margin-top: 30px;
    }

    .feature-title h1 {
        margin-top: 20px !important;
        text-align: center;
    }

    .page-head.pricing {
        height: 150px;
    }

    .page-head .page-title {
        margin-top: -30px;
    }

    .press_title {
        width: 100%;
    }

    .parent-header,
    .feature-header {
        position: relative;
        height: 200px;
        padding-top: 0;
    }

    #signup-form {
        margin-bottom: 20px;
    }

    #signup-form input {
        max-width: inherit;
        width: 100%;
        margin-left: 0;
    }

    .recent-awards {
        background-color: var(--white-color);
    }

    .recent-awards p {
        display: inline-block;
        margin-bottom: 20px;
    }

    .recent-awards.bottom-awards p {
        margin-bottom: 0;
    }

    .mobile-center {
        text-align: center;
    }

    .parent-header .col-sm-6,
    .feature-header .col-sm-6 {
        position: static;
    }

    .parent-header .feature-title h1,
    .feature-header .feature-title h1 {
        margin-top: 20px !important;
        text-align: center;
        position: absolute;
        top: 0;
        left: 50%;
        width: 80%;
        transform: translateX(-50%);
    }

    .parent-header .col-sm-6 img,
    .feature-header .col-sm-6 img {
        max-height: 140px;
        position: absolute;
        left: 50%;
        bottom: -7px;
        transform: translateX(-50%);
        margin: 0;
        width: calc(100% - 40px);
        object-fit: scale-down;
    }

    .team-header .col-sm-6 img {
        bottom: 0;
    }

    .page-not-found {
        height: 40vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }

    .pricing-header,
    .contact-header {
        min-height: 150px;
    }

    .pricing-header h1 {
        margin-top: 45px;
    }

    .page-content .contact-container .row {
        margin: 0;
    }

    .move-down .main-nav {
        top: 128px;
    }

    .securly-free-pages .main-nav,
    .securly-free-pages .top-nav {
        top: 70px;
    }

    .arrowBounce {
        bottom: auto;
        margin-top: 24px;
    }

    a.support-link h3,
    a.support-link h3 p {
        font-size: var(--font-size-body-large);
    }

    /* Events page */
    .news-container .events-styling img {
        margin-top: 0;
    }

    .events-styling h4 {
        font-size: var(--font-size-heading-small);
        margin-bottom: 8px;
    }
}

@media (max-width: 560px) {
    /* Pricing page css starts */

    .pricing-table-section .duration-side,
    .pricing-table-section .price-side {
        float: none;
    }

    .pricing-table-section .duration-side {
        position: inherit;
        transform: none;
    }

    .pricing-table-section .price-side {
        text-align: left;
        margin: 22px 0 0;
    }

    .pricing-plans-section .sk-fading-circle {
        float: right;
        margin-left: 10px;
    }

    .pricing-table-section ul {
        margin-left: 0;
    }

    .pricing-table-section h4 i {
        margin-top: 16px;
    }

    .pricing-table-section .duration-side {
        width: auto;
    }

    /* Pricing page css ends */

    /* About page css starts */

    .board-members .media-body {
        width: auto;
    }

    .board-members .media-body,
    .board-members .media-left,
    .board-members .media-right {
        display: block;
    }

    .board-members {
        text-align: center;
        width: auto;
    }

    .board-members .media-left,
    .board-members .media>.pull-left {
        padding-right: 0;
    }

    .board-members .media {
        margin-bottom: 60px;
    }

    #board-of-members {
        padding: 40px 0;
    }

    .vcenter,
    #owl-ventures {
        max-width: 170px;
    }

    .spacing {
        margin: 30px 0;
    }

    #about-hero .btn-hero {
        position: static;
    }

    /* About page css ends */

    /* Webinar page css starts */

    .webinar-hero h1 {
        font-size: 38px;
    }

    .webinar-hero h4 {
        font-size: 30px;
        margin-top: 40px;
    }

    .webinar-hero h3,
    .webinar-hero h5 {
        font-size: 26px;
    }

    .webinar-hero {
        width: auto;
        text-align: center;
    }

    .blue-box {
        width: auto;
        font-size: var(--font-size-body-large);
        line-height: 30px;
        padding: 30px;
        margin: 30px 0;
    }

    .webinar-details {
        margin-top: 30px;
    }

    .webinar-details h2 {
        font-size: var(--font-size-heading-large);
    }

    .webinar-details h3,
    .webinar-details h4 {
        font-size: var(--font-size-heading-medium);
        margin: 30px 0;
    }

    .webinar-details,
    .webinar-details p {
        font-size: var(--font-size-body-medium);
        line-height: 30px;
    }

    .page-content .webinar-hero .row {
        margin-right: 15px;
        margin-left: 15px;
    }

    #webinar-hero {
        padding: 10% 0;
    }

    .webinar-schedule:first-child {
        border-right: 0;
        border-bottom: 1px solid #bbb;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    #webinar-march-hero,
    #webinar-march-new-hero {
        padding-top: 14%;
    }

    #webinar-march-hero,
    #webinar-march-new-hero img {
        margin-top: 20px;
    }

    /* Webinar page css ends */

    /* Securly notes css starts */

    .iframe-video {
        margin-top: -30px;
    }

    .iframe-video iframe {
        width: 90%;
        height: 300px;
    }

    /* Securly notes css ends */
}

@media (max-width: 460px) {
    .hero-image.center {
        max-width: 80% !important;
    }

    .hero-image.drop-right {
        bottom: 100px;
        max-width: 36% !important;
    }

    .hero-image.drop-left {
        bottom: 90px;
        max-width: 40% !important;
    }

    .signup_container.col-sm-6 {
        width: auto;
        height: auto;
        float: none;
        margin: -15px auto 0;
    }

    .signup_container .signup.text {
        width: 150px !important;
        min-width: inherit;
    }

    .invited-button {
        padding: 10px 10px 0 10px;
    }

    .head-top .cm_container p {
        font-size: 1em;
    }

    .comingsoon_banner {
        height: 100px;
    }

    /* Media page css starts (By Vinod) - 14-10-16 */
    .profile-content .profile {
        width: 100%;
        margin-top: 20px;
    }

    .profile h4 {
        font-size: var(--font-size-body-large);
    }

    .profile h5 {
        font-size: var(--font-size-body-medium);
    }

    .more-info .fa {
        font-size: 28px;
        width: 70px;
        height: 70px;
        padding: 20px 0;
        margin-bottom: 14px;
    }

    .more-info h5 {
        font-size: var(--font-size-body-small);
        line-height: 22px;
    }

    .more-info [class*="col-"] {
        margin-bottom: 10px;
    }

    .press-kit [class*="col-"] {
        width: 100%;
        padding-bottom: 15px;
    }

    .white-box h4 {
        font-size: var(--font-size-body-large);
        line-height: 26px;
    }

    /* Media page css ends (By Vinod) - 14-10-16 */
}

/*Google login/signup button class that is aligned with google branding policies - WWWSCHOOLS-1645*/
.btnn {
    display: block;
    text-align: left;
    font-size: var(--font-size-body-small);
    color: var(--white-color) !important;
    border: 0;
    border-radius: 2px;
    width: 335px;
    padding: 1px;
    margin-bottom: 16px;
    position: relative;
    font-family: var(--regular-font) !important;
}

.btn-google {
    background-color: #4285F4 !important;
}

.btn-azure {
    background-color: #00abeb;
}

.btn-office365 {
    border: solid 1px #ea421b;
    background-color: var(--white-color);
    padding: 0;
}

.btn-google:hover,
.btn-google:focus {
    color: var(--white-color);
    background-color: #3367d6;
    font-family: var(--semi-bold-font);
}

.btn-office365:hover,
.btn-office365:focus,
.btn-office365:active {
    color: #ea421b;
    border: solid 1px #ea421b;
    padding: 2px;
}

.btn-azure:hover,
.btn-azure:focus {
    color: var(--white-color);
    background-color: #06a4df;
    font-family: var(--semi-bold-font);
}

.btn-azure>img,
.btn-google>img,
.btn-office365>img {
    padding-right: 24px;
    height: 50px;
}

.btn-office365>span {
    color: #ea421b;
}

.btn-social {
    width: 235px;
    height: 40px;
    margin: 0 auto 16px auto;
    font-size: var(--font-size-body-extra-small);
    object-fit: contain;
    margin-bottom: 0;
}

.btn-social>img {
    max-height: 100%;
    max-width: 100%;
    padding-right: 0px !important;
}

.btn-social>span {
    margin-left: 11px;
}

.btn-social>.o365-logo {
    background: #ea421b;
    margin: 0px;
}

.questions>a {
    color: inherit;
}

.onboard-box-err {
    overflow: hidden;
    position: absolute;
    width: 80%;
    top: 200px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
    background: var(--white-color);
    border-radius: 10px;
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.onboard-box-err>img {
    width: 38px;
    height: 30px;
    object-fit: contain;
    color: #ff7f7d;
}

div.err-txt {
    font-size: var(--font-size-body-small);
    line-height: 1.44;
    text-align: center;
    color: #ff7f7d;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
}

div.err-txt>p {
    margin: 0;
}

.btn-next {
    color: var(--white-color);
    width: 150px;
    height: 40px;
    border-radius: 35px;
    background-color: #3991ff;
    font-size: 12px;
    font-weight: bold;
}

.need-hlp {
    font-size: 12px;
    text-align: center;
    color: #6f8188;
}

.onboard-box-err>div.clearfix {
    margin-top: 20px;
}

.need-lhp.a {
    color: #3991ff;
}

.onboard-shade {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 98;
    opacity: 0.77;
    background-color: #000000;
}

/*code completed for  WWWSCHOOLS-1645*/

/* New contact page css starts 06-05-2019 */

.contact-main {
    margin: 70px auto;
}

.contact-main .box {
    background-color: transparent;
    padding: 0;
    text-align: left;
    min-height: 240px;
    margin-bottom: 60px !important;
}

.contact-main .detail-title {
    font-size: var(--font-size-body-small);
    margin: 16px 0 6px;
    color: #212b35;
    text-transform: inherit;
}

.contact-main .box div {
    margin: 0 14px;
}

.contact-main .box p {
    color: #637485;
    font-size: var(--font-size-body-extra-small);
    line-height: 18px;
}

.support-boxes .icon-box,
.contact-form {
    border: 1px solid #edf0f5;
    box-shadow: 8px 8px 14px rgba(0, 0, 0, 0.05);
    text-align: center;
}

.support-boxes .icon-box:hover,
.support-boxes .icon-box:focus {
    border: 1px solid #dcdcdc;
}

.support-boxes .icon-box h3,
.support-boxes .icon-box p {
    color: #212b35;
    text-align: center;
    font-size: var(--font-size-body-small);
    line-height: 20px;
}

.contact-form {
    text-align: left;
    margin-bottom: 40px;
    padding: 10px 50px 30px;
}

.contact-form .g-recaptcha {
    margin-left: 0;
}

.contact-form .form-group {
    margin: 34px 0;
}

.contact-form .form-control,
.contact-form #email {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #d0dde8;
    box-shadow: none;
    font-size: var(--font-size-body-small);
    font-weight: 400;
    height: 38px;
    padding: 6px 0;
    margin-bottom: 0;
    background-color: var(--white-color) !important;
    box-shadow: none !important;
}

.contact-form textarea.form-control {
    height: auto;
    resize: none;
}

.contact-form .form-control:active,
.contact-form .form-control:focus {
    border-color: #24d4bb !important;
}

.contact-form select.form-control {
    appearance: none;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/select-icon.webp) top 16px right 12px no-repeat;
    padding: 6px;
}

.contact-form .both-columns .col-sm-6:first-child {
    padding-left: 0;
}

.contact-form .both-columns .col-sm-6:last-child {
    padding-right: 0;
}

.contact-form .form-control::placeholder {
    color: #c9d5e1;
}

.contact-form label {
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-md);
    font-weight: 400;
    color: var(--body-text-color);
    margin-bottom: 0;
    display: block;
}

.contact-form .help-block {
    margin: 4px 0 0;
    font-size: 13px;
    line-height: 18px;
    color: #F44336;
    position: absolute;
}

.contact-form .form-control.has-error {
    border-bottom-color: #F44336;
}

.cnt-msg {
    max-width: 84%;
    min-height: 64px;
    margin: 0 auto;
    border-radius: 8px;
    border: solid 1px #24d4bb;
    border-left-width: 5px;
    background-color: var(--white-color);
    text-align: left;
    padding: 12px 36px 10px 64px;
    color: #21c1aa;
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
    margin-bottom: 20px;
    position: relative;
}

.cnt-msg .first-img {
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    top: 20px;
    left: 20px;
}

.cnt-msg .close-msg {
    display: block;
    height: 20px;
    width: 20px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-blue-default.svg) 3px 3px no-repeat;
    position: absolute;
    top: 12px;
    right: 8px;
}

.cnt-msg .close-msg:hover,
.cnt-msg .close-msg:focus {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-blue-default.svg) 3px 3px no-repeat;
}

.cnt-msg h5 {
    font: 400 var(--font-size-body-extra-small)/1.3 var(--semi-bold-font);
    margin: 0 0 2px;
    color: #21c1aa;
}

.cnt-msg p {
    margin-bottom: 0;
}

@media (max-width: 767px) {

    .contact-main .inside-row .col-sm-6,
    .contact-main .support-boxes .col-sm-6 {
        padding: 0;
    }

    .styled-form .form-group {
        margin: 46px 0;
    }

    .cnt-msg {
        max-width: 92%;
    }

    .contact-form {
        padding: 0 20px 20px;
    }

    .contact-main {
        margin: 30px 0;
    }

    .contact-main .box {
        margin-bottom: 30px !important;
    }
}

@media (max-width: 480px) {

    .contact-form .both-columns .col-sm-6:first-child,
    .contact-form .both-columns .col-sm-6:last-child {
        width: 100%;
        padding: 0;
    }

    .contact-form .both-columns .col-sm-6:first-child {
        margin-bottom: 34px;
    }

    .cnt-msg {
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    .contact-form .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}

/*  ChromeTools New Page css starts */

.chromebook-header {
    position: relative;
    /*background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/chrometools-hero.svg) bottom right no-repeat;*/
    background-size: cover;
    color: #202b36;
    min-height: 555px;
    margin-top: 69px;
}

.chromebook-header-without-bg {
    margin-top: 69px;
    padding-top: 40px;
}

.chromebook-header-without-bg .secondary-white-btn {
    background-color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    line-height: 1;
    padding: 12px 24px;
    font-family: var(--semi-bold-font);
    text-transform: inherit;
    border-radius: 22px;
    border: solid 2px var(--blue-color);
    margin-top: 24px;
    color: var(--blue-color);
    margin-left: 25px;
}

.chromebook-header-without-bg .secondary-white-btn:hover,
.chromebook-header-without-bg .secondary-white-btn:focus {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.chromebook-header-without-bg .secondary-white-btn:active {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
    border: solid 2px var(--blue-btn-active);
}

.chromebook-header-without-bg .additioal-text {
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 400;
    font-size: var(--font-size-body-small);
    color: #2a3138;
    line-height: var(--line-height-xl);
    margin-top: 32px;
    text-align: left;
}

.mcform label {
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-extra-small);
    color: var(--blue-gray-color);
    line-height: 1;
    margin-top: 22px;
    margin-bottom: 0;
    display: block;
    text-align: left;
}

.mcform input[type=submit] {
    background-color: var(--blue-color) !important;
}

.mcform {
    text-align: left !important;
}

.mcform .mc-email {
    border: 0;
    border-bottom: 1px solid #9db7d1;
    box-shadow: none;
    box-sizing: unset;
    padding: 0;
    height: 24px;
    width: 245px;
    padding-bottom: 8px;
    padding-right: 15px;
    font-family: var(--regular-font);
    font-size: var(--font-size-body-small);
    text-align: left;
}

.bottom-container .mcform .mc-email {
    background-color: transparent !important;
}

.mc-email::placeholder {
    color: var(--light-blue-btn-hover);
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-small);
    line-height: 1;
}

.mcform .mc-btn {
    margin-top: 0;
    padding: 21px 33px !important;
    line-height: 0 !important;
    font-size: var(--font-size-body-small);
    font-weight: 400;
    font-family: var(--semi-bold-font);

}

.chromebook-header-without-bg .additioal-text p {
    margin-bottom: 0;
}

.chromebook-header .image-container {
    padding-top: 50px;
}

.chromebook-header .white-btn.blue-btn,
.chromebook-header-without-bg .white-btn.blue-btn {
    background-color: var(--blue-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    line-height: 1;
    padding: 13px 33px;
    font-family: var(--semi-bold-font);
    text-transform: inherit;
    letter-spacing: 0;

}

.chromebook-header .white-btn.blue-btn:hover,
.chromebook-header .white-btn.blue-btn:focus,
.chromebook-header-without-bg .white-btn.blue-btn:hover,
.chromebook-header-without-bg .white-btn.blue-btn:focus {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
}

.chromebook-header .white-btn.blue-btn:active,
.chromebook-header-without-bg .white-btn.blue-btn:active {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
}

.chromebook-header h1,
.chromebook-header-without-bg h1 {
    font-family: var(--semi-bold-font);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
}

.chromebook-header h1,
.chromebook-header-without-bg h1 {
    font-size: 62px;
    color: var(--heading-text-color);
    margin-bottom: 8px;
    line-height: 1.13;
}

.chromebook-header-without-bg .header-img-right {
    float: right;
}

.chromebook-header h3,
.chromebook-header-without-bg h3,
.chromebook-header-without-bg .header-subheading,
.blue-color-header .header-h3 {
    font-size: var(--font-size-heading-medium);
    color: var(--blue-gray-color);
    line-height: 1.33;
    margin: 0;
    font-family: var(--regular-font);
    font-weight: 400;
}

.chromebook-header-without-bg h3 p,
.chromebook-header-without-bg h1 p {
    margin-bottom: 0;
}

.chromebook-header .rows {
    margin: 0 auto;
}

/*.chromebook-header .row img {
    float: right;
    padding-top: 26px;
    max-width: 70%;
}*/
.align-ul {
    text-align: left;
}

.p-l-0 {
    padding-left: 0;
}

.p-l-18 {
    padding-left: 18px;
}

.p-l-2p {
    padding-left: 2%;
}

.m-t-4p {
    margin-top: 4% !important;
}

.m-t-8p {
    margin-top: 8% !important;
}

@media screen and (max-width: 992px) {
    .blue-gradiant.key-adv.mheight-auto {
        min-height: auto;
    }
}

@media screen and (max-width: 768px) {
    .align-ul {
        display: inline-block;
        margin: 0 auto;
    }

    .min-height-10p {
        min-height: 10%;
    }

}

.dark-grey {
    color: var(--body-text-color);
}

.font-medium {
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 500;
}

.group-section .acc-heading {
    max-width: 900px;
    margin: 0 auto;
}

.chromebook-header .header-container {
    margin-top: 111px;
    max-width: 580px;
}

.chromebook-header-without-bg .header-container-top {
    display: flex;
    align-items: center;
}

.chromebook-intro {
    max-width: 924px;
    margin: 104px auto;
    font-size: var(--font-size-body-medium);
    letter-spacing: normal;
    text-align: center;
    color: var(--blue-gray-color);
    font-family: var(--semi-bold-font);
}

.chromebook-intro.larger-intro {
    max-width: 1008px;
}

.chromebook-intro h2,
.chromebook-intro h2 p,
.center-heading {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 400;
    color: var(--heading-text-color);
    line-height: 1.17;
    margin-bottom: 0;
}

.chromebook-intro h2 {
    margin-top: 0;
    margin-bottom: 14px;
}

.chromebook-intro p {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-large);
    font-weight: 400;
    color: #505e6b;
    line-height: var(--line-height-xl);
    margin-bottom: 0;
}

.chromebook-intro img {
    margin-bottom: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.last-part h2 p {
    margin-bottom: 0;
}

.last-part p {
    font-size: var(--font-size-body-medium);
    font-family: var(--regular-font);
    font-weight: 400;
    line-height: var(--line-height-xl);
    color: var(--body-text-color);
    margin-top: 0;
}

.bottom-container .onlydesktop {
    min-height: 600px;
}

.bottom-container .image-align,
.cloud360 .image-align,
.cloud360mid .image-align {
    display: flex;
    align-items: center;
}

.withoutacc .image-align {
    display: flex;
    align-items: center;
}

.bottom-container .last-section-blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    letter-spacing: 0px;
    padding: 14px 40px;
    border-radius: 44px;
    font-family: var(--semi-bold-font);
    line-height: 1;
    display: inline-block;
}

.bottom-container .last-section-blue-btn:hover {
    color: var(--white-color);
    background-color: var(--blue-btn-hover);
}

.bottom-container .last-section-blue-btn:active {
    color: var(--white-color);
    background-color: var(--blue-btn-active);
}

.blue-gradiant {
    position: relative;
    overflow: hidden;
    min-height: 500px;
    padding: 120px 0 70px;
    color: var(--white-color);
    text-align: center;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    background-color: #12b4dd;
    background-image: linear-gradient(109deg, #2693ff -24%, var(--blue-color) 122%);
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
}

.blue-gradiant:after,
.blue-gradiant:before {
    display: none;
    content: "";
    position: absolute;
    top: -34px;
    left: -4px;
    width: 104%;
    height: 70px;
    background-color: var(--white-color);
    transform: rotate(-2deg);
}

.blue-gradiant:after {
    top: auto;
    bottom: -34px;
}

.blue-gradiant sup {
    font-size: 50%;
    top: -.8em;
}

.blue-gradiant {
    margin-bottom: 80px;
    position: relative;
    overflow: hidden;
    min-height: 500px;
    padding: 120px 0 70px;
    color: var(--white-color);
    text-align: center;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    background-color: #12b4dd;
    background-image: linear-gradient(120deg, #24d4bb, #0096ff);
}

.blue-gradiant.key-adv {
    position: relative;
    overflow: hidden;
    min-height: 500px;
    padding: 120px 0 70px;
    color: var(--white-color);
    text-align: center;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    background-color: #2693ff;
    background-image: linear-gradient(109deg, #2693ff -24%, var(--blue-color) 122%);
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
    margin-bottom: 0;
}

.blue-gradiant.key-adv h2 p,
.blue-gradiant.key-adv h2 {
    font-family: var(--semi-bold-font);
    color: var(--white-color);
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 72px;
    line-height: 1.17;
}

.blue-gradiant.key-adv h2 {
    margin-top: 0;
}

.blue-gradiant.key-adv h5 p,
.blue-gradiant.key-adv h5 {
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    font-weight: 400;
    font-family: var(--semi-bold-font);
    line-height: var(--line-height-lg);
    margin-bottom: 12px;
    margin-top: 0;
}

.blue-gradiant.key-adv p {
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    font-family: var(--regular-font);
    line-height: var(--line-height-xl);
    margin: 0;
}

.blue-gradiant.key-adv .columns-4,
.blue-gradiant.key-adv .columns-6 {
    padding-right: 50px;
    padding-left: 50px;
    margin-bottom: 50px;
}

.blue-gradiant.key-adv img {
    margin-bottom: 20px;
    display: inline-block;
}

.blue-gradiant img:not(.about-stats img) {
    margin-bottom: 10px;
    display: inline-block;
    max-width: 62px;
    max-height: 62px;
}

.blue-gradiant .adv-align {
    margin: 0 auto;
    margin-right: 30px;
}

.group-section {
    margin-top: 60px;
}

.group-section .columns-5 {
    min-height: 600px;
    display: flex;
    align-items: center;
}

.group-section .columns-7 {
    display: flex;
    align-items: center;
}

.group-section .columns-6.hidden-xs img {
    display: none;
}

.group-section.collapseOne img.collapseOne,
.group-section.collapseTwo img.collapseTwo,
.group-section.collapseThree img.collapseThree,
.group-section.collapseFour img.collapseFour,
.group-section.collapseFive img.collapseFive,
.group-section.collapseSix img.collapseSix,
.group-section.collapseSeven img.collapseSeven,
.group-section.collapseEight img.collapseEight,
.group-section.collapseNine img.collapseNine,
.group-section.collapseTen img.collapseTen,
.group-section.collapseEleven img.collapseEleven,
.group-section.collapseTwelve img.collapseTwelve {
    display: block;
}

.group-section h2 {
    text-align: center;
    font-family: var(--semi-bold-font);
    color: var(--heading-text-color);
    font-size: 48px;
    font-weight: 400;
    line-height: 1.17;
}

.group-section h6 {
    text-align: center;
    font-family: var(--regular-font);
    color: #454f5b;
    font-size: var(--font-size-body-large);
    font-weight: 400;
    line-height: var(--line-height-xl);
    margin-bottom: 50px;
}

.group-section h2,
.group-section h6 {
    padding-right: 15px;
    padding-left: 15px;
}

/*.group-section .content {
    margin-top: 52px;
}*/
.group-section-images-left {
    padding-left: 148px;
}

.group-section-images-right {
    padding-right: 148px;
}

.custom-panel i {
    border: solid #b5c2ce;
    border-width: 0 2px 2px 0;
    display: block;
    float: right;
    padding: 5px;
    transform: rotate(45deg);
    position: relative;
    top: 6px;
}

.custom-panel .active i {
    transform: rotate(-135deg);
    top: 12px;
}

.custom-panel .panel {
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 3px 0 #dae5ee;
    background-color: var(--white-color);
    border-left: 3px solid transparent;
}

.custom-panel .panel.active {
    box-shadow: 0 2px 3px 0 #dae5ee;
    background-color: #fafbfc;
    border-left: 3px solid #2ea9ff;
}

.timeline-panel .panel.active {
    box-shadow: 0 2px 3px 0 #dae5ee;
    background-color: #fafbfc;
    border-left: 3px solid #bfdfff;
    position: relative;
    overflow: visible;
    pointer-events: auto;
}

.timeline-panel .panel.active .panel-title,
.timeline-panel .panel.active .panel-body {
    pointer-events: none;
}

.timeline-panel .panel.active::after {
    content: "";
    position: absolute;
    top: 0;
    left: -3px;
    width: 3px;
    height: 100%;
    background-color: #0096ff;
    z-index: 2;
    animation: borderCss 7s linear forwards;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.timeline-panel .panel.active:hover::after,
.paused .timeline-panel .panel.active::after,
.mfp-zoom-out-cur .timeline-panel .panel.active::after {
    background-color: transparent;
    animation: none;
}

@keyframes borderCss {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

.enlarge-image {
    text-align: center;
    display: block !important;
    font-size: 12px;
    line-height: 2;
    color: var(--blue-gray-color);
}

.enlarge-image img {
    display: inline-block;
}

.enlarge-image span {
    display: block;
}

.custom-panel .panel-default>.panel-heading {
    background-color: transparent;
    border: 0;
    padding: 0;
    box-shadow: 0 2px 3px 0 #dae5ee;
}

.custom-panel .panel-default.active>.panel-heading {
    background-color: #fafbfc;
    box-shadow: none;
}

.custom-panel .panel-heading+.panel-collapse .panel-body {
    border-top: 0;
    font-size: var(--font-size-body-medium);
    line-height: 28px;
    color: var(--blue-gray-color);
    padding: 0 24px;
}

.custom-panel .panel+.panel {
    margin-top: 16px;
}

.custom-panel .collapsing {
    transition: height .20s ease;
}

.custom-panel i,
.custom-panel .panel,
.custom-panel svg path,
.custom-panel svg g,
.custom-panel .active svg rect,
.custom-panel .active svg circle {
    transition: all .20s ease;
}

.custom-panel a:not(.tab-inline-link) {
    display: block;
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-sm);
    color: #202b36;
    padding: 24px;
    cursor: pointer;
}

.custom-panel svg {
    max-width: 36px;
    max-height: 36px;
}

.custom-panel svg,
.custom-panel span {
    float: left;
}

.custom-panel span {
    display: block;
}

.custom-panel svg path:not(.svg-flyer-path):not(.svg-records-path),
.custom-panel svg g,
.custom-panel .active svg circle {
    stroke: #BECBDA;
}

.custom-panel .active svg path:not(.svg-flyer-path):not(.svg-records-path),
.custom-panel .active svg g,
.custom-panel .active svg rect,
.custom-panel .active svg circle {
    stroke: #0096FF;
}

.custom-panel .active svg path.svg-flyer-path:first-child {
    stroke: #0096FF;
    fill: transparent;
}

.custom-panel .active svg path.svg-flyer-path:nth-child(3) {
    stroke: transparent;
    fill: var(--white-color);
}

.custom-panel .active svg path.svg-flyer-path,
.custom-panel .active svg path.svg-records-path {
    fill: #0096FF;
}

.custom-panel .active svg text {
    fill: #0096FF;
}

.chromebook-footer {
    background-image: none;
    background-color: #e7f5ff;
    min-height: inherit;
    padding: 40px 0 0;
}

.chromebook-footer .btm-content {
    margin: 30px 0 0 50px;
}

.chromebook-footer h3 {
    margin-bottom: 16px;
}

.chromebook-footer .btn {
    margin-top: 0;
    margin-bottom: 20px;
}

.chromebook-footer .container {
    display: flex;
}

.chromebook-footer .col-sm-6.col-sm-pull-6 {
    align-self: flex-end;
}

.content-container {
    padding-top: 50px;
}

/*.withoutacc {
    min-height: 600px;
}*/
.withoutacc h2 {
    font-size: var(--font-size-heading-extra-large);
    font-family: var(--semi-bold-font);
    font-weight: 400;
    line-height: var(--line-height-sm);
    color: var(--heading-text-color);
    margin-top: 20px;
    margin-bottom: 8px;
}

.withoutacc h6 {
    font-size: var(--font-size-body-medium);
    font-family: var(--regular-font);
    font-weight: 400;
    line-height: 1.56;
    color: #505e6b;
    margin: 0;
}

.withoutacc h2 p,
.withoutacc h2 p {
    margin-bottom: 0;
}

.withoutaccordion h6 {
    font-size: var(--font-size-body-large);
    font-family: var(--regular-font);
    font-weight: 400;
    line-height: var(--line-height-xl);
    text-align: center;
    color: #505e6b;
    margin-top: 0;
}

.withoutaccordion h2 {
    font-size: 48px;
    font-family: var(--semi-bold-font);
    font-weight: 400;
    line-height: 1.17;
    color: var(--heading-text-color);
    margin-bottom: 8px;
    margin-top: 0;
    text-align: center;
}

.last-part h2 p,
.last-part h2 {
    font-size: 48px;
    font-family: var(--semi-bold-font);
    font-weight: 400;
    line-height: 1.17;
    color: var(--heading-text-color);
    margin-bottom: 20px;
    margin-top: 0;
}

.withoutaccordion h6 p,
.withoutaccordion h2 p {
    margin-bottom: 0;
}

.withoutaccordion {
    max-width: 806px;
    margin: 0 auto;
    padding-bottom: 71px;
    padding-top: 104px;
}

.img-content-container {
    padding: 60px 0;
}

.img-content-container,
.last-part {
    display: flex;
    align-items: center;
}

.content-align h2,
.content-align h6 {
    text-align: left;
    padding-left: 0;
}

.cbfooter .onlymobile {
    display: none;
}

.bottom-container .onlymobile {
    display: none;
}

.withoutacc .formobile {
    display: none;
}

.withoutacc .withoutacc-margin {
    min-height: 600px;
}

.accheading p,
.accheading {
    margin-bottom: 0;
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xs);
    color: #202b36;
    padding: 5px 0 0 15px;

}

.accsubheading p {
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
    color: #454f5b;
}

.section-with-center-text h2 {
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-heading-large);
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: var(--heading-text-color);
    margin-bottom: 24px;
}

.section-with-center-text {
    min-height: 424px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    background-position: center;
}

.section-with-center-text .last-section-blue-btn {
    color: var(--blue-color);
    font-size: var(--font-size-body-small);
    font-weight: 500;
    padding: 12px 46px;
    border-radius: 44px;
    border: 2px solid var(--blue-color);
    font-family: var(--semi-bold-font);
    line-height: 1;
    display: inline-block;
}

.section-with-center-text .last-section-blue-btn:hover {
    color: var(--white-color);
    background-color: var(--blue-color);
}

.section-with-center-text .last-section-blue-btn:active {
    color: var(--white-color);
    background-color: var(--blue-btn-active);
}

.section-with-center-text .text-align {
    max-width: 800px;
    text-align: center;
    margin: auto;
}

/*.chromebook-header-without-bg .image-container {
    display: flex;
    justify-content: flex-end;
}*/
@media (max-width: 520px) {
    .chromebook-header-without-bg .secondary-white-btn {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .content-container {
        display: grid;
        padding: 0;
    }

    .content-container>div:first-child {
        grid-row: 2;
    }

    .content-container>div:last-child {
        grid-row: 1;
    }

    .custom-panel .panel.active::after {
        display: none;
    }
}

@media (max-width: 1300px) {
    .section-with-center-text {
        background-size: contain;
        display: inherit;
        min-height: 300px;
        background-position: center top;
        padding-top: 20px;
    }

    .section-with-center-text h2 {
        font-size: 26px;
    }

    .section-with-center-text .text-align {
        max-width: 400px;
        padding-right: 0;
    }
}

@media (max-width: 1400px) {
    .chromebook-header .header-container {
        margin-top: 8%;
        max-width: 364px;
    }

    /*.withoutacc {
        min-height: 300px;
    }*/
}

@media (max-width: 1200px) {
    .chromebook-header {
        min-height: 435px;
        background-position: bottom right -40px;
    }

    .chrometools-page .newGrid_container {
        margin: 0 29px;
    }

    .enlargeColumnsIpad .columns-5,
    .enlargeColumnsIpad .columns-7 {
        grid-column: span 6;
    }
}

@media only screen and (max-width: 1024px) {
    .chromebook-header-without-bg h1 p {
        margin-bottom: 0;
    }

    .chromebook-header-without-bg h3,
    .chromebook-header-without-bg .header-subheading,
    .blue-color-header .header-h3 {
        font-size: var(--font-size-body-large);
    }

    .chromebook-header-without-bg .header-container-c {
        text-align: center;
        margin: 0 auto;
        padding: 0 24px;
    }

    .chromebook-header-without-bg .image-container {
        width: auto;
        margin-top: 40px;
        height: auto;
        display: block;
        text-align: center;
    }

    .chromebook-header-without-bg .newGrid_container {
        margin: 0 24px;
    }

    .chromebook-intro h2 p,
    .chromebook-intro p,
    .center-heading {
        margin-bottom: 0;
    }

    .chromebook-intro {
        max-width: 720px;
    }

    .chromebook-intro p {
        font-size: var(--font-size-body-medium);
    }

    .chromebook-intro h2 p,
    .center-heading {
        font-size: var(--font-size-heading-extra-large);
    }

    .withoutaccordion {
        max-width: 720px;
        padding: 0;
    }

    .withoutaccordion h2 {
        font-size: var(--font-size-heading-extra-large);
    }

    .withoutaccordion h6 {
        font-size: var(--font-size-body-medium);
    }

    .content-align h2 {
        font-size: var(--font-size-heading-extra-large);
    }

    .content-align h6 {
        font-size: var(--font-size-body-large);
    }

    .last-part h2 p,
    .last-part h2 {
        font-size: var(--font-size-heading-extra-large);
        margin-bottom: 8px;
    }

    .last-part p {
        font-size: var(--font-size-body-large);
        margin-bottom: 0;
    }

    .group-section-images-left {
        padding-left: 40px;
    }

    .group-section-images-right {
        padding-right: 40px;
    }

    .bottom-container .image-align,
    .cloud360 .image-align,
    .cloud360mid .image-align {
        display: flex;
        align-items: center;
    }

    .withoutacc .image-align {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {

    .chromebook-header-without-bg .enlargeColumnsIpad .columns-5,
    .chromebook-header-without-bg .enlargeColumnsIpad .columns-7 {
        grid-column: span 8;
    }

    .blue-gradiant .rows.five-col {
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 0 16px;
    }

    .blue-gradiant .rows.four-col {
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 0 16px;
    }

    .blue-gradiant .rows .columns-3 {
        grid-column: span 2;
    }

    .blue-gradiant .rows .columns-4 {
        grid-column: span 3;
    }

    .blue-gradiant .rows .columns-6 {
        grid-column: span 5;
    }

    .chrometools-page .bottom-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .blue-gradiant .adv-align {
        margin-right: 30px;
        margin-left: 119px;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 40px;
    }

    .group-section .rows {
        display: flex;
    }

    .cbfooter .onlymobile {
        display: none;
    }

    .cbfooter .onlydesktop {
        display: flex;
    }

    .bottom-container .rows.onlymobile {
        display: none;
    }

    .bottom-container .onlydesktop {
        display: flex;
        min-height: 400px;
    }

    .bottom-container .rows {
        display: flex;
    }

    .last-part p {
        font-size: var(--font-size-body-medium);
    }

    .last-part {
        padding-left: 24px;
        padding-top: 20px;
    }

    .withoutacc .rows {
        display: flex;
    }

    .withoutacc .formobile {
        display: none;
    }

    .withoutacc .withoutacc-margin {
        min-height: 300px;
    }

    .chromebook-header .rows.withoutbgimg {
        display: flex;
    }

    .blue-gradiant.key-adv .columns-4,
    .blue-gradiant.key-adv .columns-6 {
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 50px;
    }

    .group-section .columns-5 {
        min-height: 530px;
    }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
    .custom-panel a {
        padding: 15px;
    }

    .accheading p {
        font-size: var(--font-size-body-medium);
    }
}

@media (max-width: 992px) {
    .chromebook-header .rows {
        width: 94%;
    }

    .chromebook-header {
        min-height: 370px;
    }

    .chromebook-header h1 {
        font-size: 44px;
        margin-bottom: 10px;
    }

    .chromebook-header h3 {
        font-size: var(--font-size-body-medium);
    }

    .chromebook-header .white-btn.blue-btn {
        margin-top: 14px;
    }

    .blue-gradiant .columns-4 {
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 10px;
    }

    .chromebook-header .mobile-img {
        max-width: 100%;
    }

    .chromebook-intro {
        margin: 50px auto;
        max-width: 640px;
    }

    .chromebook-intro p {
        font-size: var(--font-size-body-small);
    }

    .chromebook-footer .btm-content {
        margin: 20px 0 0 20px;
    }

    .withoutaccordion h2 {
        font-size: var(--font-size-heading-large);
    }

    .chromebook-intro h2 p,
    .center-heading {
        font-size: var(--font-size-heading-large);
    }

    .withoutaccordion {
        max-width: 640px;
        margin: 50px auto;
        padding: 0 24px;
    }

    .withoutaccordion h6 {
        font-size: var(--font-size-body-small);
    }

    .content-align h2 {
        font-size: var(--font-size-heading-large);
    }

    .content-align h6 {
        font-size: var(--font-size-body-medium);
    }

    .blue-gradiant.key-adv {
        clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
        padding: 60px 0 20px;
    }

    .blue-gradiant.key-adv h2 p,
    .blue-gradiant.key-adv h2 {
        font-size: var(--font-size-heading-large);
    }

    .withoutaccordion h2 {
        font-size: var(--font-size-heading-large);
    }

    .last-part h2 p,
    .last-part h2 {
        font-size: var(--font-size-heading-large);
        margin-bottom: 8px;
    }

    .last-part p {
        font-size: var(--font-size-body-medium);
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 866px) {
    .chromebook-header-without-bg h1 {
        font-size: 48px;
    }

    .chromebook-header-without-bg h3,
    .chromebook-header-without-bg .header-subheading,
    .blue-color-header .header-h3 {
        font-size: var(--font-size-body-large);
    }
}

@media (max-width: 767px) {
    .chromebook-header .white-btn.blue-btn {
        margin-top: 20px;
    }

    .blue-gradiant .rows .columns-3 {
        grid-column: span 4;
    }

    .blue-gradiant .rows .columns-4 {
        grid-column: span 4;
    }

    .blue-gradiant .adv-align {
        margin: 0 auto;
    }

    .custom-panel .collapse {
        display: block !important;
        height: auto !important;
    }

    .custom-panel i {
        display: none;
    }

    .custom-panel .panel,
    .custom-panel .panel.active,
    .custom-panel .panel-default.active>.panel-heading,
    .custom-panel .panel-default>.panel-heading {
        background-color: transparent;
        border-left: 0;
        box-shadow: none;
    }

    .custom-panel svg path,
    .custom-panel svg circle,
    .custom-panel svg rect {
        stroke: #0096FF;
    }

    .custom-panel svg text {
        fill: #0096FF;
    }

    .custom-panel a {
        font-size: var(--font-size-body-large);
    }

    .group-section .columns-6 {
        padding: 0;
    }

    .chromebook-header {
        background: #e7f5ff !important;
        text-align: center;
        padding-top: 30px;
    }

    .chromebook-header .header-container {
        margin-top: 4%;
        margin: 0 auto;
    }

    .chromebook-header .row {
        width: auto;
    }

    .chromebook-header .mobile-img {
        width: 100%;
        float: none;
        padding-top: 40px;
    }

    .chromebook-header h1 {
        font-size: 36px;
    }

    .chromebook-header h3 {
        line-height: var(--line-height-md);
    }

    .last-part {
        padding-right: 20px;
        padding-left: 20px;
        text-align: center;
        font-size: var(--font-size-body-small);
        line-height: var(--line-height-lg);
        margin-top: 0;
        margin-bottom: 40px;
        padding-top: 0;
    }

    .blue-gradiant {
        padding: 30px 0;
        background-image: linear-gradient(166deg, #24d4bb, #0096ff);
        margin-bottom: 40px;
    }

    .blue-gradiant h2 {
        font-size: 26px;
        margin-bottom: 40px;
    }

    .blue-gradiant::after,
    .blue-gradiant::before {
        width: 102%;
        height: 40px;
        left: -4px;
        overflow: hidden;
    }

    .group-section {
        margin: 30px 0;
    }

    .group-section h2 {
        font-size: 36px;
    }

    .group-section h6 {
        font-size: var(--font-size-body-small);
        margin-bottom: 20px;
    }

    .chromebook-footer .btm-content {
        margin: 0;
    }

    .chromebook-footer img {
        margin-top: 30px;
        width: 100%;
    }

    .chromebook-footer .col-sm-6.col-sm-pull-6,
    .chromebook-footer .container {
        padding: 0;
    }

    .group-section .container {
        margin-top: 30px;
    }

    .cbfooter .onlymobile {
        display: block;
    }

    .cbfooter .onlydesktop {
        display: none;
    }

    .bottom-container .onlymobile {
        display: block;
        padding: 30px 0;
    }

    .bottom-container .onlydesktop {
        display: none;
    }

    .withoutacc .formobile {
        display: block;
    }

    .withoutacc .formobile .columns-6 img {
        padding-bottom: 30px;
    }

    .withoutacc .formobile .columns-6 {
        text-align: center;
    }

    .withoutacc .fordesktop {
        display: none;
    }

    .chrometools-page .bottom-container {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .section-with-center-text {
        background-image: none !important;
        min-height: inherit;
        margin: 0;
        padding-top: 0;
    }

    .section-with-center-text h2 {
        font-size: 26px;
    }

    .section-with-center-text .text-align {
        padding: 50px 0;
    }

    .withoutacc .withoutacc-margin {
        padding: 50px 0;
    }

    .img-content-container,
    .last-part {
        display: block;
        text-align: center;
    }

    .img-content-container {
        padding-top: 40px;
    }

    .content-align h2 {
        font-size: var(--font-size-heading-medium);
        text-align: center;
    }

    .content-align h6 {
        font-size: var(--font-size-body-small);
        text-align: center;
    }

    .accheading {
        text-align: center;
        margin: 0 auto;
    }

    .accheading p img {
        display: block;
        margin: 0 auto !important;
        margin-bottom: 20px !important;
    }

    .accheading p {
        display: block !important;
    }

    .custom-panel span {
        float: none;
    }

    .accsubheading p {
        text-align: center;
        padding-top: 20px;
    }

    .group-section {
        min-height: inherit;
    }

    .chromebook-header-without-bg .header-img-right {
        float: none;
    }

    .chromebook-header-without-bg h1 {
        font-size: 34px;
    }

    .mcform .mc-email {
        width: 220px;
    }

}

/*.cbfooter .container {
    display: block;
}*/

/*  ChromeTools New Page css ends */

/* Sign up flow - Custom radio button css starts */

.switch-field-radio label:hover,
.switch-field-radio label:focus,
.switch-field-radio input:checked+label {
    box-shadow: 0 2px 3px 0 rgba(190, 201, 211, 0.5);
    border-color: #24d4bb;
}

.switch-field-radio input:checked+label .switch-field-radio label {
    border-color: #24d4bb
}

.switch-field-radio.checkbox-2-column {
    width: 322px;
}

.switch-field-radio label {
    padding: 12px 12px 12px 44px;
}

.switch-field-radio label {
    height: 88px;
}

.switch-field-radio input:checked+label:after {
    display: none;
}

.circle-radio+label span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    background: var(--white-color);
    border: 2px solid #d0dde8;
    position: absolute;
    top: 10px;
    left: 16px;
}

.circle-radio+label span::before {
    content: "";
    opacity: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0096ff;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
}

.circle-radio:checked+label span::before {
    opacity: 1;
}

.free-trial-thanks {
    text-align: center;
    margin: -10px 0 40px;
    padding: 0 20px;
}

.input-radio {
    margin-top: 16px;
}

.input-radio input[type="radio"] {
    display: none;
}

.input-radio input[type="radio"]+label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    margin-right: 40px;
    cursor: pointer;
    font-size: var(--font-size-body-small);
    line-height: 22px;
    transition: all 0.3s ease-in-out;
}

.input-radio input[type="radio"]+label:before,
.input-radio input[type="radio"]+label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 2px solid var(--light-blue-btn-hover);
    border-radius: 50%;
    transition: all .3s ease;
}

.input-radio input[type="radio"]+label:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--white-color);
}

.input-radio input[type="radio"]+label:hover:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.35em white, inset 0 0 0 1em var(--light-blue-btn-hover);
}

.input-radio input[type="radio"]:checked+label:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--blue-color);
}

.styled-form .form-group.margin-b-0 {
    margin-bottom: 0;
}

@media only screen and (max-width: 500px) {
    .switch-field-radio.checkbox-2-column {
        width: 270px;
    }

    .switch-field-radio label {
        padding-left: 34px;
    }

    .circle-radio+label span {
        left: 6px;
    }
}


/* Sign up flow - Custom radio button css ends */
/*  SecurlyHome New Page css starts */
.securlyhome .header-section {
    position: relative;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    color: #202b36;
    margin-top: 70px;
}

.securlyhome h1 {
    color: #202b36;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 24px;
    font-size: 64px;
    line-height: 72px;
    font-family: var(--semi-bold-font);
}

.securlyhome h2,
.securlyhome .h1-heading {
    color: #202b36;
    font-family: var(--semi-bold-font);
    font-size: 48px;
    line-height: 56px;
}

.securlyhome .h1-heading {
    margin-bottom: 20px;
}

.securlyhome h1~p {
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 400;
    font-size: var(--font-size-heading-medium);
    line-height: 32px;
    color: var(--blue-gray-color);
}

.securlyhome h2~p,
.securlyhome .subheading {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-large);
    line-height: 30px;
    color: #454F5B;
}

.securlyhome h3 {
    font-family: var(--semi-bold-font);
    font-weight: 400;
    font-size: var(--font-size-heading-extra-large);
    line-height: 48px;
    color: var(--blue-gray-color);
}

.securlyhome p {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-small);
    line-height: 24px;
    margin-bottom: 0;
}

.securlyhome .header-content {
    text-align: left;
    z-index: 2;
    position: relative;
    max-width: 820px;
}

.home_page_hero_container {
    padding: 4rem 0 20rem;
}

.securlyhome .download-apps {
    margin-top: 32px;
}

.securlyhome .download-apps img {
    width: 144px;
}

.securlyhome .securlyhome-intro {
    max-width: 800px;
    margin: 34px auto 100px;
    text-align: center;
}

.securlyhome .white-btn.blue-btn {
    background-color: var(--blue-color);
    text-transform: initial;
    font-size: var(--font-size-body-small);
    font-family: var(--semi-bold-font);
    font-weight: 400;
    line-height: 1;
    padding: 13px 40px;
    letter-spacing: normal;
    margin-top: 24px;
}

.securlyhome .white-btn.blue-btn:hover {
    background-color: var(--blue-btn-hover);
}

.securlyhome .white-btn.blue-btn:focus,
.securlyhome .white-btn.blue-btn:active {
    background-color: var(--blue-btn-active);
}

.securlyhome .grey-bg {
    background-color: #f3f6f8;
}

.securlyhome .testimonial-section p {
    color: var(--body-text-color);
}

.securlyhome .testimonial-section {
    border-top: solid 1px #c9d5e1;
    padding-top: 32px;
}

.securlyhome .feature-content {
    display: flex;
    align-items: center;
}

.illustration-parent {
    position: relative;
}

.illustration-child {
    position: absolute;
    width: 100%;
    height: 100%;
}

.illustration-child img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 28%;
    height: auto;
}

.securlyhome .top-section h2 {
    margin-top: 30px;
    margin-bottom: 16px;
}

.securlyhome .feature-content .text-container {
    max-width: 488px;
    padding-top: 80px;
    padding-bottom: 100px;
}

.securlyhome .feature-content p {
    margin-bottom: 10px;
}

.securlyhome .new-home-page .feature-content p {
    margin-bottom: 0;
}

.securlyhome-feature .sh-feature-icon {
    padding-top: 14px;
}

.securlyhome .center {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.securlyhome .bottom {
    display: flex;
    align-items: flex-end;
    vertical-align: middle;
}

.securlyhome .flagged-img-c img {
    margin-bottom: -30%;
}

.rows.securlyhome-feature {
    position: relative;
    display: flex;
    min-height: 664px;
    height: 664px;
    overflow: hidden;
}

.row-reverse {
    flex-direction: row-reverse !important;
}

.securlyhome .securlyhome-feature .testimonial-name {
    font-family: var(--semi-bold-font);
}

.securlyhome .btm-content-c {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.securlyhome .btm-content {
    text-align: center;
    margin-top: 104px;
    margin-bottom: 86px;
    max-width: 640px;
}

.securlyhome .download-apps a:first-child {
    margin-right: 8px;
}

.securlyhome .footer-app-banner {
    position: relative;
    background-image: linear-gradient(290deg, #24d4bb, #0096ff), linear-gradient(250deg, #6169ef, #0096ff);
}

.securlyhome .footer-section {
    position: relative;
    height: 100%;
}

.footer-section .app-banner-img {
    position: absolute;
    top: -419px;
    left: -4%;
    width: 616px;
    height: 100%;
}

.banner-text-c {
    padding: 120px 0 80px;
    display: flex;
    align-items: center;
}

.banner-text .logo {
    width: 324px;
}

.banner-text .download-apps a {
    margin-bottom: 10px;
}

.securlyhome .footer-app-banner h2 {
    margin-top: 26px;
    margin-bottom: 16px;
    color: var(--white-color);
}

.securlyhome .footer-app-banner h2~p {
    color: var(--white-color);
}

.hub-img-container {
    position: relative;
    height: 100%;
}

.hub-img-container img {
    position: absolute;
    bottom: 42px;
}

.hub-phone .desktop-img {
    position: absolute;
    bottom: 74px;
}

.hub-phone {
    position: relative;
    height: 100%;
    height: 100%;
    overflow: hidden;
}

.mobile-full-img {
    display: none;
}

@media screen and (min-width: 1441px) {
    .securlyhome .header-section {
        min-height: calc(100vh - 64px);
    }

    .securlyhome .new-home-page.header-section {
        min-height: inherit;
    }

    .home_page_hero_container {
        padding: 10rem 0 10rem;
    }

    .securlyhome .securlyhome-feature {
        max-height: 712px;
    }

    .securlyhome .footer-section .app-banner-img {
        left: 0;
    }

    .securlyhome .btm-content-c .btm-content {
        margin-top: 140px;
        /* margin-bottom: 20px; */
    }

    .securlyhome .footer-section {
        padding-bottom: 0px;
    }

    .hub-phone .desktop-img {
        bottom: 47px;
    }

    .hub-img-container img {
        bottom: 7px;
    }
}

@media screen and (max-width: 1440px) {
    .home_page_hero_container {
        padding: 5.4rem 0 10rem;
    }

    .hub-phone .desktop-img {
        bottom: 80px;
    }
}

@media (max-width:1332px) and (min-width:1118px) {
    .hub-phone .desktop-img {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .footer-section .app-banner-img {
        position: absolute;
        top: -426px;
        left: -4%;
        width: 560px;
        height: 100%;
    }

    .footer-section .app-banner-img img {
        width: 100%;
    }

    .securlyhome .flagged-img-c img {
        margin-bottom: -15%;
    }

    .banner-text .download-apps img {
        height: auto;
    }

    .securlyhome .footer-app-banner .columns-5 {
        width: 100%;
    }

    .home_page_hero_container {
        padding: 4rem 0 4rem;
    }

    .header-content-c.columns-12 {
        margin-left: 0px;
    }

    .securlyhome .footer-app-banner .banner-text {
        margin-left: -60px;
    }
}

@media screen and (max-width: 1024px) {
    .securlyhome h1 {
        font-size: 48px;
        line-height: 56px;
    }

    .securlyhome h2 {
        font-size: var(--font-size-heading-extra-large);
        line-height: 48px;
    }

    .securlyhome h1~p {
        font-size: var(--font-size-body-large);
        line-height: 30px;
    }

    .securlyhome h2~p {
        font-size: var(--font-size-body-medium);
        line-height: 28px;
    }

    .securlyhome .securlyhome-intro {
        max-width: 720px;
    }

    .securlyhome .securlyhome-feature {
        max-height: 664px;
    }

    .securlyhome .header-content {
        max-width: 640px;
    }

    .home_page_hero_container {
        padding: 2rem 0 2rem;
    }

    .securlyhome .feature-content .text-container {
        padding-top: 40px;
        padding-bottom: 77px;
    }

    .securlyhome .footer-app-banner .newGrid_container {
        margin-left: 2.7%;
    }

    .securlyhome .footer-app-banner p {
        font-size: var(--font-size-body-small);
    }

    .footer-section .app-banner-img {
        position: absolute;
        top: -329px;
        left: -4%;
        width: 432px;
        height: 100%;
    }

    .securlyhome .footer-app-banner .banner-text-c {
        min-height: auto;
        padding: 40px 0 40px;
        margin-left: 1%;
    }

    .securlyhome .hub-img-container {
        align-self: center;
        vertical-align: middle;
    }

    .hub-phone {
        display: flex;
        align-items: center;
    }

    .hub-phone img {
        margin: 0;
        padding: 0;
    }
}

@media (max-width:1199px) and (min-width:993px) {
    .footer-section .app-banner-img {
        top: -266px;
        left: -4%;
        width: 390px;
    }
}

@media (max-width:1023px) and (min-width:768px) {
    .securlyhome .footer-app-banner .banner-text {
        margin-left: 0;
    }

    .securlyhome .bottom {
        align-items: center;
    }

    .securlyhome .flagged-img-c img {
        margin: 0;
    }

    .securlyhome .btm-content {
        margin-bottom: 8px;
    }

    .securlyhome .rows .columns-3 {
        grid-column: span 8;
    }

    .securlyhome .btm-content-c {
        grid-column: span 8;
    }

    .securlyhome .footer-app-banner .rows .columns-5,
    .securlyhome .footer-app-banner .rows .columns-7 {
        grid-column: span 4;
    }

    .securlyhome .hub-img-container {
        display: none;
    }

    .hub-phone .desktop-img {
        display: none;
    }

    .securlyhome .footer-section .mobile-full-img {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0 0 40px;
        width: 520px;
    }
}

@media screen and (max-width: 992px) {
    .footer-section .app-banner-img {
        position: absolute;
        top: -245px;
        left: -5%;
        width: 360px;
        height: auto;
    }

    .on-stretch-center {
        display: flex;
        align-items: center;
    }

    .securlyhome .footer-app-banner .banner-text-c {
        margin-left: 0;
    }

    .securlyhome .download-apps img {
        width: auto;
        height: 40px;
    }

    .home_page_hero_container {
        padding: 2rem 0 0;
    }

    .header-section {
        min-height: 370px;
    }

    .banner-text-c {
        min-height: auto;
        padding: 3rem 0;
    }

    .banner-text-c .download-apps {
        margin-bottom: 0;
    }

    .banner-text {
        max-width: 488px;
        padding: 0;
    }

    .banner-text .logo {
        width: 224px;
    }

    .securlyhome h1 {
        color: #202b36;
        font-weight: 500;
        margin-bottom: 16px;
        margin-top: 24px;
        font-size: 48px;
        line-height: 1.13;
        font-family: var(--semi-bold-font);
    }

    .securlyhome h2,
    .securlyhome .h1-heading {
        color: #202b36;
        font-family: var(--semi-bold-font);
        font-size: var(--font-size-heading-large);
        line-height: 1.25;
    }

    .securlyhome h1~p {
        font-family: 'MetropolisMedium', sans-serif;
        font-weight: 400;
        font-size: var(--font-size-body-large);
        line-height: var(--line-height-xl);
        color: var(--blue-gray-color);
    }

    .securlyhome h2~p {
        font-family: var(--regular-font);
        font-size: var(--font-size-body-small);
        line-height: var(--line-height-xl);
        color: #454F5B;
    }

    .securlyhome h3 {
        font-family: var(--semi-bold-font);
        font-weight: 400;
        font-size: var(--font-size-heading-extra-large);
        line-height: 30px;
        color: var(--blue-gray-color);
    }

    .securlyhome .banner-text h2,
    .securlyhome .banner-text h2~p {
        color: var(--white-color);
    }

    .securlyhome p {
        font-family: var(--regular-font);
        font-size: var(--font-size-body-small);
        line-height: 24px;
    }

    .securlyhome .securlyhome-intro {
        max-width: 640px;
    }

}

@media (max-width:904px) and (min-width:768px) {
    .footer-section .app-banner-img {
        position: absolute;
        top: -238px;
        left: -4%;
        width: 350px;
        height: auto;
    }

    .securlyhome .footer-app-banner .banner-text {
        margin-left: 20px;
    }

}

@media only screen and (max-width: 866px) {
    .securlyhome .header-section .mobile-only {
        display: block;
    }

    .securlyhome .header-section .mobile-only img {
        width: 100%;
    }

    .securlyhome .header-section .download-apps {
        margin-bottom: 18px;
    }

    .securlyhome .header-section {
        background: none !important;
    }

    .securlyhome .header-section .header-content {
        text-align: center;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 768px) {
    .securlyhome .rows .columns-3 {
        display: grid;
        grid-column: span 8;
    }

    .securlyhome .rows div[class~="columns-6"] {
        display: grid;
        grid-column: span 8;
    }

    .securlyhome .footer-app-banner .banner-text {
        margin-left: 0;
    }

    .securlyhome .footer-app-banner .newGrid_container {
        margin: 0 24px;
    }

    .home_page_hero_container {
        padding: 0px;
    }

    .securlyhome .testimonial-section {
        display: none;
    }

    .header-content-c.columns-12 {
        margin: 0 auto;
    }

    .securlyhome-feature .feature-img-container {
        margin: 20px auto;
        max-width: 520px;
    }

    .padding-bottom {
        padding-bottom: 16px;
    }

    .header-section br {
        display: none;
    }

    .securlyhome .securlyhome-intro {
        max-width: 800px;
        margin: 34px auto 40px;
        text-align: center;
    }

    .securlyhome .banner-text {
        padding-top: 40px;
        text-align: center;
    }

    .securlyhome .footer-app-banner .banner-text-c {
        padding-top: 0;
        padding-bottom: 0;
        justify-content: center;
    }

    .securlyhome .banner-text-c .download-apps {
        margin-bottom: 6px;
        margin-top: 24px;
    }

    .hangingleg-img {
        margin: 0 auto;
        width: 60%;
    }

    .banner-text .logo {
        width: 272px;
    }

    .securlyhome .footer-app-banner h2 {
        margin-top: 24px;
        margin-bottom: 8px;
    }

    .header-section .header-content {
        text-align: center;
        padding-top: 32px;
    }

    .securlyhome .feature-content .text-container {
        padding: 0;
    }

    .securlyhome .feature-content .top-section p {
        margin-bottom: 0;
    }

    .securlyhome .feature-content .top-section {
        padding-top: 30px;
    }

    .securlyhome .btm-content-c {
        max-width: 488px;
        padding: 0;
        margin: 0 auto;
    }

    .securlyhome .btm-content {
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .securlyhome .header-section {
        background-image: none;
    }

    .securlyhome .top-section,
    .securlyhome .footer-section img {
        text-align: center;
    }

    .securlyhome .footer-section .mobile-full-img {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0 0 40px;
    }

    .rows.securlyhome-feature {
        max-height: 720px;
        display: grid;
        height: auto;
        min-height: auto;
    }

    .securlyhome .footer-app-banner {
        height: auto;
    }

    .securlyhome .feature-content {
        justify-content: center;
    }

    .securlyhome .hub-img-container {
        display: none !important;
    }
}



@media only screen and (max-width: 767px) {
    .securlyhome .rows div[class~="columns-6"] {
        grid-column: span 4;
    }

    .securlyhome .rows .columns-3 {
        display: grid;
        grid-column: span 4;
    }
}

@media only screen and (max-width: 320px) {
    .securlyhome .download-apps a:first-child {
        margin-right: 0;
    }

    .securlyhome .rows div[class~="columns-6"] {
        grid-column: span 4;
    }

    .securlyhome .white-btn.blue-btn {
        width: 100%;
        padding: 13px 0;
    }

    .securlyhome-logo-img {
        width: 243px;
    }

    .new-home-page .securlyhome-logo-img {
        width: 100%;
    }

    .securlyhome .center-image {
        margin: 0 auto;
    }

    .get-started-today .newGrid_container {
        margin: 0 20px;
    }
}

/* SecurlyHome admin page css starts */

.securlyhome .kalam-heading {
    font-size: 48px;
    font-weight: normal;
    line-height: var(--line-height-md);
    color: #202b36;
    text-align: center;
    margin: 0;
    padding: 10px 20px 20px;
    background-color: var(--white-color);
    top: 70px;
    z-index: 2;
}

.securlyhome .kalam-heading.without-number {
    text-align: left;
}

.sticky-position,
.first-col a {
    position: sticky;
}

.securlyhome .kalam-heading em {
    display: inline-block;
    width: 72px;
    height: 72px;
    border: solid 2px #0096ff;
    font-family: 'Metropolis';
    font-size: var(--font-size-heading-extra-large);
    font-style: normal;
    font-weight: 600;
    line-height: 1.8;
    color: #0096ff;
    text-align: center;
    border-radius: 50%;
}

.affix.fixed h2:first-child {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 80px 20px 20px;
    z-index: 2;
    background: var(--white-color);
    margin: 0;
}

.activity-image {
    position: relative;
    top: 180px;
}

.opt-in-image {
    position: relative;
    top: 220px;
}

.v-center-image {
    display: flex;
    align-items: center;
}

.feature-section {
    margin-bottom: 80px;
}

.securlyhome .get-started-today {
    background-color: #fafbfc;
    padding-top: 20px;
}

.form-white-container {
    padding: 40px 80px;
    border-radius: 16px;
    box-shadow: 0 8px 24px 0 #dae5ee;
    background-color: var(--white-color);
}

.get-started-today .top-section .kalam-heading {
    margin: 0 0 20px;
    padding: 0;
    background-color: transparent;
}

.footer-hub {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-color: #e7f5ff;
    min-height: 536px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-hub .btm-content {
    margin: auto;
}

.get-started-today .rows.securlyhome-feature {
    min-height: 800px;
}

.get-started-today .kalam-heading {
    background-color: transparent;
}

.securlyhome .get-started-today .text-container {
    padding-bottom: 0;
}

.form-white-container .cnt-msg {
    max-width: inherit;
}

.form-white-container .cnt-msg h5 {
    font-size: var(--font-size-body-small);
}

.securlyhome .form-white-container p {
    font-size: var(--font-size-body-extra-small);
}

.securlyhome .form-white-container .styled-form p {
    font-size: 12px;
    line-height: var(--line-height-lg);
}

.get-started-today-content {
    margin-bottom: 100px;
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .securlyhome .get-started-today .bottom {
        align-items: flex-end;
    }
}

@media only screen and (max-width: 866px) {
    .securlyhome .header-section .mobile-only img {
        margin-top: 30px;
    }

    .footer-hub {
        background-position: bottom center;
        background-size: 100% auto;
        min-height: 400px;
        padding: 20px 20px 170px;
    }

    .form-white-container {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .securlyhome .kalam-heading em {
        font-size: var(--font-size-heading-small);
        line-height: 2;
        width: 44px;
        height: 44px;
    }

    .securlyhome .kalam-heading {
        font-size: var(--font-size-heading-medium);
        padding: 10px;
        top: 69px;
    }

    .securlyhome .kalam-heading.without-number {
        text-align: center;
    }

    .get-started-today .rows.securlyhome-feature {
        max-height: inherit;
    }

    .securlyhome .get-started-today {
        background-color: var(--white-color);
        padding-top: 0;
    }

    .form-white-container {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .get-started-today .form-white-container {
        padding: 0 20px;
    }

    .opt-in-image,
    .activity-image {
        top: 0;
    }

    .get-started-today-content {
        margin-bottom: 0;
    }

    .footer-hub {
        background-position: bottom 10px center;
    }

    .feature-section {
        margin-bottom: 40px;
    }
}

@media (max-width: 390px) {
    .get-started-today .g-recaptcha {
        transform: scale(0.87);
        transform-origin: 0 0;
    }

    .get-started-today .blue-btn.full-btn {
        margin-top: 10px !important;
        padding-left: 0;
        padding-right: 0;
        width: 70%;
    }
}

/* SecurlyHome admin page css ends */

/* New nav menu starts */

.arrow-ic {
    display: inline-block;
    width: 11px;
    margin-left: 7px;
    top: -1px;
    position: relative;
}

.head-top {
    background-color: var(--white-color) !important;
    box-shadow: 0 3px 3px 0 rgba(112, 126, 140, 0.09);
    height: 70px;
    padding: 0 !important;
}

.main-nav ul li a,
.green-btn {
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-body-extra-small);
    font-weight: 400;
    padding: 28px 15px;
    color: var(--body-text-color);
}

.head-top .ss-navigatedown:before,
.head-top .ss-navigatedown.right:after {
    font-size: 17px;
    color: var(--body-text-color);
}

.nav-col {
    width: 33.33%;
    float: left;
    border-right: 1px solid var(--light-blue-btn);
}

.products-submenu .nav-col {
    width: 50%;
}

.nav-col.other-solutions {
    width: 30%;
}

.nav-col.all-solutions {
    width: 40%;
}

.nav-col:last-child {
    border-right: 0;
}

.nav-col svg {
    width: 48px;
    height: 48px;
}

.inside-nav a:hover svg circle,
.inside-nav a:hover svg path,
.logo-links svg circle,
.logo-links svg path {
    transition: all 0.1s ease-in;
}

.inside-nav a:hover svg circle,
.inside-nav a:active svg circle,
.inside-nav a:focus svg circle {
    fill: var(--blue-color);
}

.inside-nav a:hover svg path,
.inside-nav a:active svg path,
.inside-nav a:focus svg path {
    fill: var(--white-color);
}

.inside-nav a:hover svg.path circle,
.inside-nav a:active svg.path circle,
.inside-nav a:focus svg.path circle,
.inside-nav a:hover .auditor-svg,
.inside-nav a:active .auditor-svg,
.inside-nav a:focus .auditor-svg,
.inside-nav a:hover svg.classroom-svg g g,
.inside-nav a:active svg.classroom-svg g g,
.inside-nav a:focus svg.classroom-svg g g {
    stroke: var(--white-color) !important;
}

.inside-nav a:hover svg.path path,
.inside-nav a:active svg.path path,
.inside-nav a:focus svg.path path,
.inside-nav a:hover svg.classroom-svg path,
.inside-nav a:active svg.classroom-svg path,
.inside-nav a:focus svg.classroom-svg path {
    fill: transparent;
    stroke: var(--white-color);
}

.nav-blue-bg .inside-nav svg circle {
    fill: var(--white-color);
}

.inside-nav svg,
.inside-nav div {
    float: left;
}

.inside-nav svg {
    margin-right: 16px;
}

.nav-col h4 {
    font-family: var(--regular-font);
    font-size: 13px;
    line-height: var(--line-height-md);
    font-weight: 400;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: var(--blue-gray-color);
    margin: 32px 10px 16px 32px;
}

.nav-col li h4 {
    margin-top: 24px;
}

.nav-col li h4.middle-heading {
    margin-top: 14px;
    margin-bottom: 10px;
}

.main-nav ul li .inside-nav a {
    display: flex;
    min-height: 83px;
    padding: 14px 32px;
    color: var(--heading-text-color);
}

.main-nav ul li .inside-nav a.compare-btn {
    border-radius: 4px;
    background-color: #f7fafc;
    display: flex;
    align-items: center;
    padding: 14px 32px;
    min-height: inherit;
}

.inside-nav strong {
    display: block;
    color: var(--heading-text-color);
    font: 400 var(--font-size-body-small)/1.2 var(--semi-bold-font);
}

.inside-nav p,
.inside-nav a p {
    font-family: var(--regular-font);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--blue-gray-color);
    margin-bottom: 0;
}

.products-submenu .inside-nav p,
.products-submenu .inside-nav a p {
    font-size: var(--font-size-body-extra-small);
}

.square-submenu .inside-nav p {
    font-size: var(--font-size-body-extra-small);
    color: var(--body-text-color);
}

.inside-nav a:hover strong,
.inside-nav a:active strong,
.inside-nav a:focus strong,
.solutions-submenu .inside-nav a:hover p,
.solutions-submenu .inside-nav a:active p,
.solutions-submenu .inside-nav a:focus p {
    color: var(--blue-color);
}

.main-nav ul li .inside-nav a:hover,
.main-nav ul li .inside-nav a:active,
.main-nav ul li .inside-nav a:focus {
    background: #f7fafc !important;
}

.main-nav ul li .nav-blue-bg .inside-nav a:hover,
.main-nav ul li .nav-blue-bg .inside-nav a:active,
.main-nav ul li .nav-blue-bg .inside-nav a:focus {
    background: var(--white-color) !important;
}

.main-nav ul li span.submenu-btn>i {
    padding-left: 4px;
    position: relative;
    top: 2px;
}

.main-nav {
    float: right;
    /* margin-left: 18%; */
}

.main-nav ul li:hover a {
    color: var(--blue-color) !important;
}

.main-nav>ul>li:first-child {
    margin-left: 0;
}

.main-nav li.border-btn-outer {
    margin-left: 18px;
}

.main-nav li.border-btn-outer a {
    color: var(--blue-color);
    border-color: var(--blue-color);
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-sm);
    font-weight: 600;
    padding: 6px 18px;
}

.main-nav li.border-btn-outer a:hover {
    background-color: var(--blue-color);
    border-color: var(--blue-color);
    color: var(--white-color) !important;
}

.main-nav li.border-btn-outer a:active,
.main-nav li.border-btn-outer a:focus {
    background-color: var(--blue-btn-active);
    border-color: var(--blue-btn-active);
    color: var(--white-color) !important;
}

@media only screen and (min-width: 1276px) {
    .min-ht-div {
        min-height: 900px;
    }

    .main-nav .top-margin-align {
        margin-top: 64px;
    }

    .main-nav ul li ul:after,
    .top-nav ul li ul:after {
        display: none;
    }

    .main-nav ul li,
    .top-nav ul li:hover,
    .main-nav ul li,
    .top-nav ul li:active {
        color: var(--blue-color);
    }

    .main-nav ul .submenu-outer {
        display: flex;
        position: absolute;
        top: 70px;
        left: 0;
        white-space: nowrap;
        visibility: hidden;
        display: none;
        padding: 0;
        background: #3991ff;
        opacity: 0;
        /* transform: translateY(-0.5em); */
        z-index: -1;
        /* transition: all 0.09s ease-in-out 0s, visibility 0s linear 0.09s, z-index 0s linear 0.09s; */
        border-radius: 0 0 4px 4px;
        box-shadow: inset 0 3px 3px 0 rgba(112, 126, 140, 0.09), 0 2px 2px 0 rgba(157, 171, 186, 0.05), 0 4px 4px 0 rgba(190, 203, 218, 0.05), 0 8px 8px 0 rgba(157, 171, 186, 0.05), 0 16px 16px 0 rgba(157, 171, 186, 0.05);
        background-color: var(--white-color);
        width: 866px;
    }

    .main-nav ul .submenu-outer .inside-nav {
        padding-bottom: 32px;
    }

    .main-nav ul .school-submenu {
        left: 0;
    }

    .nav-blue-bg {
        background-color: #f7fafc;
    }

    .main-nav ul .home-submenu {
        width: 576px;
        left: 0;
    }

    /* .school-submenu .nav-col {
        min-height: 478px;
    } */
    .home-submenu .nav-col {
        width: 50%;
    }

    .main-nav ul .login-submenu {
        width: 288px;
        left: -94px;
    }

    .login-submenu .nav-col {
        width: 100%;
    }

    .main-nav ul li a,
    .top-nav ul li a {
        text-transform: inherit;
    }

    .main-nav ul>li:hover>.submenu-outer {
        /* .main-nav ul>li#products>.submenu-outer { */
        display: flex;
        visibility: visible;
        opacity: 1;
        z-index: 1;
        /* transform: translateY(0%);
        transition-delay: 0s, 0s, 0.09s; */
    }

    /* This is written twice because IE doesnt support :focus-within css */
    .main-nav ul>li:focus-within>.submenu-outer {
        display: flex;
        visibility: visible;
        opacity: 1;
        z-index: 1;
        /* transform: translateY(0%);
        transition-delay: 0s, 0s, 0.09s; */
    }

    .main-nav li.border-btn-outer {
        position: relative;
        top: 0;
    }
}

@media only screen and (max-width: 1275px) {
    .head-top .newGrid_container {
        margin: 0;
        max-width: inherit;
    }

    .head-top .s-logo {
        margin-left: 20px;
    }

    .mobile-border-0 {
        border-bottom: 0;
    }

    .submenu-outer {
        display: none;
        overflow-y: scroll;
        max-height: 229px;
        background-color: var(--white-color);
        width: auto !important;
    }

    .main-nav ul li:hover a {
        color: var(--blue-gray-color) !important;
    }

    .main-nav,
    .top-nav {
        box-shadow: none;
        margin-left: 0;
    }

    .main-nav ul li #home {
        box-shadow: inset 0 2px 3px 0 #dae5ee;
    }

    .main-nav>ul>li,
    .main-nav li.login-nav {
        margin-left: 0;
    }

    .main-nav ul li a,
    .top-nav ul li a {
        text-transform: inherit;
    }

    .main-nav ul li .submenu-btn {
        display: inline-block;
    }

    .head-top .container {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    a.head-logo {
        margin-left: 24px;
    }

    .main-nav,
    .top-nav {
        padding: 0;
        overflow-y: auto;
        background-color: #f7fafc;
        box-shadow: 0 2px 3px 0 #dae5ee;
        float: none;
        position: relative;
        top: 20px !important;
    }

    .head-top {
        box-shadow: none;
        border-bottom: 1px solid #ecf2fa;
    }

    .main-nav li.border-btn-outer {
        margin: 0 24px 12px;
        position: relative;
    }

    .locked {
        overflow: hidden;
    }

    .nav-col,
    .products-submenu .nav-col {
        width: 100%;
        float: none;
        border-right: 0;
        margin-top: 0;
    }

    .nav-col svg {
        width: 32px;
        height: 32px;
        position: relative;
        top: -1px;
    }

    .inside-nav p,
    .inside-nav a p {
        display: none;
    }

    .square-submenu .inside-nav p,
    .square-submenu .inside-nav a p {
        display: block;
    }

    .main-nav ul li .inside-nav a {
        min-height: 24px;
        padding: 8px 24px;
    }

    .main-nav ul li .inside-nav a {
        border-top: 1px solid var(--light-blue-btn);
    }

    .main-nav ul li .inside-nav a:last-child {
        border-bottom: 0;
    }

    .inside-nav {
        border-bottom: 1px solid var(--light-blue-btn);
    }

    .nav-col h4 {
        margin: 16px 24px;
    }

    .inside-nav div {
        margin-top: 8px;
    }

    .main-nav ul li a {
        border-bottom: 1px solid #ecf2fa;
        padding: 14px 24px;
        color: var(--blue-gray-color);
    }

    .main-nav ul li .inside-nav a.compare-btn {
        margin: 0;
        border-radius: 0;
        padding: 8px 24px;
    }

    .main-nav ul li .inside-nav a.compare-btn div {
        margin-top: 3px;
    }

    .main-nav ul li.active>a {
        box-shadow: 0 2px 3px 0 #dae5ee;
        background-color: #f7fafc !important;
        font-weight: 400;
        color: var(--blue-color) !important;
        /* position: fixed; */
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        position: sticky;
    }

    .menu-box-shadow,
    .login-nav.active {
        position: relative;
    }

    .menu-box-shadow:after,
    .login-nav.active:after {
        content: "";
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(218, 229, 238, 1) 100%);
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        z-index: 10;
        top: -4px;
    }

    .login-nav.active:after {
        top: auto;
        bottom: 0;
    }

    .main-nav ul li.active span.submenu-btn {
        transform: rotate(180deg);
        position: relative;
        left: -3px;
        top: -2px;
    }

    .main-nav ul li.active .arrow-ic {
        transform: rotate(180deg);
        top: -2px;
    }

    .main-nav ul li.active span.submenu-btn>i {
        top: 0;
    }

    .main-nav ul li:active a {
        color: var(--blue-gray-color) !important;
    }

    .head-top {
        overflow: hidden;
    }

    .head-top.active {
        overflow: inherit;
    }
}

/* New nav menu ends */

/* New Home and stats */
.new-home-page .header-content {
    max-width: inherit;
}

.home-stats {
    background-color: #2693ff;
    background-image: linear-gradient(120deg, #2693ff, var(--blue-color));
    min-height: inherit;
    padding: 80px 0;
    margin-bottom: 0;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
}

.home-stats:after,
.home-stats:before {
    display: none;
}

.home-stats img:not(.about-stats img) {
    width: 64px;
    height: 64px;
    display: inline-block;
}

.home-stats h1,
.statsTitle {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 500;
    line-height: var(--line-height-sm);
    text-align: center;
    color: var(--white-color);
    margin: 20px 20px 60px;
}

.home-stats h2:not(.statsTitle) {
    font-family: var(--semi-bold-font);
    font-size: 64px;
    font-weight: 500;
    line-height: var(--line-height-sm);
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--white-color);
}

.home-stats h5,
.home-stats .h5-heading {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    line-height: var(--line-height-xl);
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    color: var(--white-color);
}

.h2-heading.Metropolis-font {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 500;
    line-height: var(--line-height-sm);
    color: var(--heading-text-color);
}

/* .new-home-page .home_page_hero_container {
    padding: 36px 0 104px;
} */
@media screen and (min-width: 1441px) {
    .new-home-page .home_page_hero_container {
        padding: 63px 0;
        margin-bottom: 40px;
    }

    /* .securlyhome .header-section {
        min-height: 300px;
        margin-bottom: 30px;
    } */
}

@media screen and (min-width: 1024px) {
    .new-home-page .securlyhome-logo-img.hidden-xs {
        float: right;
        max-height: 450px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1275px) {
    .new-home-page .securlyhome-logo-img.hidden-xs {
        max-width: 86%;
    }
}

@media screen and (max-width: 1440px) {
    .new-home-page .home_page_hero_container {
        padding: 2rem 0 5rem;
    }
}

@media screen and (max-width: 1023px) {
    .new-home-page .header-content {
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .new-home-page .rows .columns-5,
    .new-home-page .rows .columns-7 {
        grid-column: span 12;
        text-align: center;
    }

    .new-home-page img.hidden-xs {
        max-width: 70%;
        margin-top: 30px;
    }

    .new-home-page .header-content-c {
        display: block;
    }

    .home-stats .rows {
        display: flex;
    }
}

@media screen and (max-width: 992px) {

    .home-stats h1,
    .statsTitle {
        font-size: 34px;
    }

    .home-stats h2:not(.statsTitle) {
        font-size: 54px;
    }

    .home-stats {
        clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
        padding: 60px 0 20px;
    }

    .head-top .container {
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {

    .home-stats h1,
    .statsTitle {
        font-size: 30px;
        display: none;
    }

    .home-stats h5,
    .home-stats .h5-heading {
        margin-bottom: 50px;
    }

    .new-home-page .home_page_hero_container {
        padding: 0 0 50px;
    }

    .home-stats .rows {
        display: grid;
    }

    .new-home-page .header-content {
        padding: 0 20px;
    }

    .new-home-page .header-content-c {
        margin-top: 20px;
    }

    .new-home-page .newGrid_container {
        margin: 0;
    }

    .new-home-page img.visible-xs {
        padding: 0 24px;
    }
}

/* New Home and Menu ends */

/* Request a demo page bundle radio code starts */
.switch-field-checkbox.new-radio-holder {
    margin-top: 0;
}

.switch-field-checkbox.new-radio-holder label {
    box-shadow: none;
    background-color: transparent;
    border: 0;
    height: auto;
    padding: 8px 8px 8px 30px;
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-md);
    font-weight: 400;
    color: var(--blue-gray-color);
    margin-bottom: 0;
    display: block;
}

.switch-field-checkbox.new-radio-holder .circle-radio+label span {
    top: 6px;
    left: 0;
}

.switch-field-checkbox.new-radio-holder label:hover,
.switch-field-checkbox.new-radio-holder label:focus,
.switch-field-checkbox.new-radio-holder input:checked+label {
    box-shadow: none;
    border: 0;
    background-color: transparent;
}

.styled-form .radio-form-group {
    margin: 20px 0;
}

.big-container a.head-logo {
    top: 2px;
}

@media only screen and (max-width: 992px) {
    .big-container a.head-logo {
        margin-left: 0;
    }
}

/* Request a demo page bundle radio code ends */

/* Wonderbar removed */
.wonderbar,
.wonderbar-bottom {
    display: none !important;
}

.move-down {
    top: 0 !important;
}

/* Comparison page starts */

.comparison-intro {
    padding: 70px 0 0;
}

.comparison-table-outer {
    color: var(--heading-text-color);
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
    margin-bottom: 120px;
}

.comparison-table {
    table-layout: fixed;
}

.comparison-table th,
.comparison-table tr {
    width: 25%;
    text-align: center;
}

.comparison-table tr td:first-child {
    text-align: left;
    background-color: transparent;
}

.comparison-table tr td:last-child,
.comparison-table tr th:last-child {
    padding-right: 0;
}

.comparison-table tr td {
    /*background-color: rgba(230, 242, 255, 0.25);*/
    height: 92px;
}

.th-btn {
    background-color: #00264d;
    color: var(--white-color);
    padding: 16px 4px;
    display: block;
    width: 100%;
    cursor: auto;
}

.th-btn:hover,
.th-btn:active,
.th-btn:focus {
    background-color: #00264d;
    color: var(--white-color);
}

.th-btn img {
    margin-right: 8px;
}

.comparison-table>thead>tr>th,
.comparison-table>tbody>tr>th,
.comparison-table>tbody>tr>td,
.comparison-table>tfoot>tr>td {
    border-top: 0;
    border-bottom: 0;
    padding: 13px 20px;
    line-height: var(--line-height-md);
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-medium);
    vertical-align: middle;
}

.comparison-table>thead>tr>th {
    padding-top: 0;
    padding-bottom: 0;
    position: sticky;
    top: 70px;
}

.comparison-table>tbody>tr>td p {
    font-size: var(--font-size-body-extra-small);
    margin: 4px 0 0;
    color: #454f5b;
}

/*.comparison-table>tbody>tr:nth-child(odd)>td, .comparison-table>tbody>tr:nth-child(odd)>th {
    background-color: #f7fafc;
}*/
.comparison-table h6 {
    font: 400 12px/1.2 var(--regular-font);
    color: var(--blue-gray-color);
    margin: 0;
}

.white-btn.blue-btn.primary-blue-btn {
    background-color: var(--blue-color);
    text-transform: initial;
    font-size: var(--font-size-body-small);
    font-family: var(--semi-bold-font);
    font-weight: 400;
    line-height: 1;
    padding: 13px 39px;
    letter-spacing: normal;
    margin-top: 14px;
}

.white-btn.blue-btn.primary-blue-btn:hover {
    background-color: var(--blue-btn-hover);
}

.white-btn.blue-btn.primary-blue-btn:active,
.white-btn.blue-btn.primary-blue-btn:focus {
    background-color: var(--blue-btn-active);
}

a.link-text {
    font-size: var(--font-size-body-small);
    font-family: var(--semi-bold-font);
    font-weight: 400;
    text-align: center;
    color: var(--blue-color);
    display: block;
    margin-top: 22px;
}

a.link-text:hover {
    color: var(--blue-btn-hover);
}

a.link-text:active,
a.link-text:focus {
    color: var(--blue-btn-active);
}

.comparison-table tr:last-child td {
    background-color: var(--white-color) !important;
    vertical-align: top;
}

.comparison-table img[src*="check-dollar"] {
    margin-left: 7px;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .white-btn.blue-btn.primary-blue-btn {
        padding-right: 22px;
        padding-left: 22px;
    }
}

@media only screen and (max-width: 992px) {
    .comparison-intro {
        padding-top: 10px;
    }

    .comparison-table>tbody>tr>td {
        width: 100%;
        display: none;
    }

    .comparison-table>tbody>tr>td:first-child,
    .comparison-table>tbody>tr>td:nth-child(2) {
        display: table-cell;
    }

    .comparison-table-outer,
    .comparison-table>thead>tr>th,
    .comparison-table>tbody>tr>th,
    .comparison-table>tbody>tr>td,
    .comparison-table>tfoot>tr>td {
        font-size: var(--font-size-body-small);
    }

    .mobile-margin-none {
        margin: 0;
    }

    .th-btn img {
        display: none;
    }

    .th-btn {
        padding: 16px 30px;
        line-height: var(--line-height-xl);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white-color);
        color: var(--heading-text-color);
        border: solid 1px var(--light-blue-btn-hover);
        min-height: 82px;
        border-right: 0;
        cursor: pointer;
    }

    .comparison-table>thead>tr>th:nth-child(2) .th-btn {
        border-left: 0;
    }

    .th-btn:hover,
    .th-btn:active,
    .th-btn:focus,
    .th-btn.active {
        background-color: #00264d;
        color: var(--white-color);
    }

    .comparison-table>thead>tr>th,
    .comparison-table>tbody>tr>th,
    .comparison-table>tbody>tr>td,
    .comparison-table>tfoot>tr>td {
        padding: 16px 20px;
    }

    .comparison-table>thead>tr>th {
        width: 33.33%;
        padding: 0;
        /* position: static; */
    }

    .comparison-table tr td {
        background-color: transparent;
    }

    .comparison-table tr td:last-child {
        padding-right: 20px;
    }

    .comparison-table tr td {
        height: 80px;
    }

    .comparison-table h6 {
        display: none;
    }
}

/* Comparison page ends */

/* 360 cloud New page css starts */
.cloud360-header {
    /*position: relative;
    background: no-repeat;
    background-position: bottom right;
    min-height: 511px;*/
    margin-top: 69px;
}

.sup-text {
    font-size: 12px;
    color: var(--heading-text-color);
    line-height: 1;
    font-family: var(--regular-font);
    top: -3.5em;
}

.cloud-page-header-container {
    padding-top: 40px;
}

.cloud-page-header-container .header-width-c {
    display: flex;
    align-items: center;
}

.cloud360 h1,
.cloud360 h2 {
    font-size: 64px;
    font-weight: 400;
    color: var(--heading-text-color);
    margin-bottom: 8px;
    line-height: 1.13;
    font-family: var(--semi-bold-font);
}

.cloud360 h1 {
    margin-bottom: 20px;
}

/*.header-width {
    max-width: 384px;
    position: relative;
}*/
.cloud360 h1~p,
.static-pages .content-right-c .big-p-tag,
.element-styling {
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: var(--blue-gray-color);
    margin-bottom: 0;
}

/*.header-width .header-blue-btn {
    margin-top: 24px;
}*/
/*.cloud360 .visible-none {
    display: flex;
    justify-content: flex-end;
}*/
.cloud360-header .image-right {
    float: right;
}

.cloud360 .cloud360-intro {
    max-width: 800px;
    text-align: center;
    margin: 104px auto;
}

.cloud360 h2 {
    font-size: 48px;
    font-weight: 400;
    color: var(--heading-text-color);
    margin-bottom: 14px;
    margin-top: 24px;
    line-height: 1.17;
    font-family: var(--semi-bold-font);
}

.cloud360 .img-size {
    max-width: 100%;
}

.cloud360 .for-mobile {
    display: none;
}

.cloud360 .for-desktop {
    display: grid;
}

.cloud360 h2~p {
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: #505e6b;
    margin-bottom: 0;
}

/*.cloud360 .row-container .rows {
    display: ;
}*/

.middle-menu {
    text-align: center;
    vertical-align: center;
    padding: 14px;
    width: 100%;
    background: var(--white-color);
    position: sticky;
    top: 69px;
    z-index: 2;
}

.sticky-part {
    position: sticky;
    top: 70px;
}

.middle-nav li {
    display: inline;
    list-style: none;
}

.cloud360 nav ul li a.current {
    background: transparent;
    color: var(--blue-color);
    border-bottom: 4px solid var(--blue-color);
    padding: 0 0 22px 0;
    transition: none;
    margin: 0 40px;
}

.cloud360 .nav-menu a {
    padding: 0 40px;
    font-size: var(--font-size-body-medium);
    font-weight: 400;
    line-height: 1;
    color: #778b9e;
    font-family: 'MetropolisMedium', sans-serif;
}

.cloud360 .holder {
    padding: 104px 0;
    min-height: 600px;
}

.cloud360 .content {
    text-align: left;
    padding: 74px 0 0 103px;
}

.cloud360 h4 {
    font-size: var(--font-size-body-medium);
    font-weight: 400;
    color: #505e6b;
    line-height: 1.56;
    margin-bottom: 0;
    font-family: var(--regular-font);
}

.content-blue-btn {
    color: var(--blue-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    padding: 12px 32px;
    border-radius: 44px;
    border: 2px solid var(--blue-color);
    font-family: var(--semi-bold-font);
    line-height: 1;
    display: inline-block;
    margin-top: 24px;
}

.uk-site .holder .content-blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.uk-site .holder .content-blue-btn:hover,
.uk-site .holder .content-blue-btn:active,
.uk-site .holder .content-blue-btn:focus {
    background-color: var(--blue-btn-hover);
    border-color: var(--blue-btn-hover);
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.cloud360 .content-right {
    text-align: left;
    display: flex;
    align-items: center;
}

.cloud360 .holder h3 p {
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-heading-extra-large);
    font-weight: 400;
    line-height: var(--line-height-sm);
    margin-bottom: 8px;
    color: var(--heading-text-color);
}

.cloud360 .holder h3 p {
    margin-bottom: 0;
}

.cloud360 .holder p {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-medium);
    font-weight: 400;
    line-height: 1.56;
    color: #505e6b;
    margin-bottom: 0;
}

.cloud360 .blue-padding {
    padding: 85px 0 73px 0;
}

.cloud360mid .footer-text {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 400;
    color: var(--heading-text-color);
    line-height: 1.17;
    margin-bottom: 0;
}

.cloud360mid .footer-text p {
    margin-bottom: 0;
}

.cloud360mid .footer-container {
    display: flex;
    align-items: center;
}

.cloudpage-mid-conatiner .footer-img-align {
    padding: 52px 0 52px 100px;
}

.cloud360mid .footer-blue-btn,
.blue-rounded-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: 500;
    letter-spacing: 1px;
    padding: 12px 31px;
    border-radius: 44px;
    border: 2px solid var(--blue-color);
    font-family: var(--semi-bold-font);
    line-height: 1;
    display: inline-block;
    margin-top: 24px;
}

.footer-box-allign .blue-rounded-btn {
    margin-top: 0;
}

.cloud360mid .footer-grey-bg {
    background-color: #f7fafc;
}

.cloud360mid .footer-align {
    padding: 104px 0;
    text-align: center;
}

.cloud360mid .footer-box {
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
}

.cloud360mid .footer-box-margin {
    margin: 0 104px;
}

.cloud360mid .last-section-heading {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: var(--heading-text-color);
    line-height: 1.17;
    font-family: var(--semi-bold-font);
}

.cloud360mid .last-section-heading p {
    margin-bottom: 0;
}

.cloud360mid .box-header-text {
    font-size: var(--font-size-body-small);
    font-weight: 400;
    font-family: var(--semi-bold-font);
    line-height: 1;
    text-align: center;
    color: var(--blue-btn-active);
    padding-top: 32px;
}

.cloud360mid .box-header-text p {
    margin-bottom: 0;
}

.cloud360mid .box-sub-text {
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-heading-extra-large);
    font-weight: 400;
    line-height: var(--line-height-sm);
    text-align: center;
    color: var(--heading-text-color);
}

.footer-grey-bg .img-align {
    margin-top: 16px;
}

.cloud360mid .box-para-text {
    font-size: var(--font-size-body-small);
    font-weight: 400;
    font-family: var(--regular-font);
    text-align: center;
    color: #505e6b;
    line-height: var(--line-height-xl);
    margin-bottom: 24px;
    margin-top: 8px;
}

.cloud360mid .box-sub-text p,
.cloud360mid .box-para-text p {
    margin-bottom: 0;
}

.cloud360mid .footer-box-right {
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
    width: 488px;
    height: 400px;
    margin: 58px 32px 0 0;
}

.cloud360mid .footer-box-allign {
    margin: 76px 0 0 0;
}

.cloud360mid .footer-box-btn {
    background-color: var(--white-color);
    color: var(--blue-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    letter-spacing: 1px;
    padding: 14px 40px;
    border-radius: 44px;
    border: 2px solid var(--blue-color);
    font-family: var(--semi-bold-font);
    line-height: 1;
    text-align: center;
    display: inline-block;
}

.cloud360mid .footer-link {
    font-family: var(--semi-bold-font);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    text-align: center;
    line-height: var(--line-height-xl);
    color: var(--blue-color);
    padding: 24px 0 32px 0;
}

.cloud360mid .footer-link a {
    color: var(--blue-color);
}

.cloud360 .header-blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    font-weight: 400;
    padding: 14px 40px;
    border-radius: 44px;
    font-family: var(--semi-bold-font);
    line-height: 1;
    display: inline-block;
    margin-top: 24px;
}

.cloud360 .header-blue-btn:hover {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
}

.cloud360 .header-blue-btn:active,
.cloud360 .header-blue-btn:focus {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
}

.cloud360 .static-line {
    border-bottom: 1px solid var(--light-blue-btn);
}

.cloud360mid .footer-blue-btn:hover,
.blue-rounded-btn:hover {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
    border-color: var(--blue-btn-hover);
}

.cloud360mid .footer-blue-btn:active,
.cloud360mid .footer-blue-btn:focus,
.blue-rounded-btn:active,
.blue-rounded-btn:focus {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
    border-color: var(--blue-btn-active);
}

.content-blue-btn:hover {
    color: var(--white-color);
    background-color: var(--blue-color);
    border: 2px solid var(--blue-color);
}

.content-blue-btn.dark-blue-border-btn:hover {
    border-color: var(--dark-blue-color);
}

.content-blue-btn:active,
.content-blue-btn:focus {
    color: var(--white-color);
    background-color: var(--blue-btn-active);
    border: 2px solid var(--blue-btn-active);
}

.cloud360mid .footer-box-btn:hover {
    color: var(--white-color);
    background-color: var(--blue-color);
}

.cloud360mid .footer-box-btn:active {
    color: var(--white-color);
    background-color: var(--blue-btn-active);
}

.cloud360mid .footer-link a:hover {
    color: var(--blue-btn-hover);
}

.cloud360mid .footer-link a:active,
.cloud360mid .footer-link a:focus {
    color: var(--blue-btn-active);
}

.cloud360 .nav-menu a:active {
    color: #4c8ec1;
}

.cloud360 .nav-menu a:hover {
    color: var(--blue-color);
}

.sticky {
    position: sticky;
    top: 0;
}

.cloud360 .mobile-visible {
    display: none;
}

.cloud360 .img-size-left {
    max-width: 100%;
    margin-right: 136px;

}

.cloud360 .img-align-right {
    float: right;
    max-width: 100%;
}

.cloud360 .img-align {
    margin-left: 136px;
}

.cloud360 h3 {
    font-family: var(--semi-bold-font);
    font-size: 48px;
    font-weight: 500;
    line-height: 1.25;
    text-align: left;
    margin-bottom: 8px;
}

.cloud360 .mobileonly {
    display: none;
}

@media screen and (max-width: 1400px) {
    .cloud360mid .footer-box-margin {
        margin: 0 40px;
    }

    .cloud360mid .footer-align {
        padding: 40px 0 40px 0;
        text-align: center;
    }

    .cloud360mid .footer-box-allign {
        margin: 40px 0 0 0;
    }

    .cloud360mid .footer-box {
        width: auto;
        height: auto;
    }

    .cloud360mid .footer-box-left {
        margin-right: 20px;
    }

    .cloud360 .holder {
        min-height: 300px;
    }
}

@media screen and (max-width: 1024px) {
    .cloud360 h2 {
        font-size: var(--font-size-heading-extra-large);
    }

    .cloud360 h2~p {
        font-size: var(--font-size-body-medium);
    }

    .cloud360 .cloud360-intro {
        max-width: 720px;
    }

    .cloud360 .for-desktop {
        display: flex;
    }

    .cloud360 h3 {
        font-size: var(--font-size-heading-extra-large);
        margin: 0;
    }

    .cloud360 h4 {
        font-size: var(--font-size-body-small);
    }

    .cloud360 .img-align {
        margin-left: 70px;
        height: auto;
        width: auto;
    }

    .cloud360 .img-align-right {
        height: auto;
        width: auto;
    }

    .cloud360 .img-size-left {
        margin-right: 70px;
        height: auto;
        width: auto;
    }

    .cloud360 .middle-menu {
        padding-top: 20px;
    }

    .cloud360 nav ul li a.current {
        padding-bottom: 16px;
    }

    .cloud360 .content-right {
        padding: 0;
    }

    .cloud360mid .footer-text {
        font-size: var(--font-size-heading-extra-large);
    }

    .cloud360mid .last-section {
        display: flex;
    }

    .cloud360mid .box-section {
        display: flex;
    }

    .cloud360mid .blue-padding {
        padding: 40px 0 40px 0;
    }

    .cloud360mid .footer-container {
        padding: 0;
    }

    .cloud360mid .footer-img-align {
        margin-top: 0;
    }

    .cloud360 .visible-none {
        margin-top: 40px;
        width: auto;
        text-align: center;
        height: auto;
        display: block;
    }

    .sup-text {
        top: -2.5em;
    }
}

@media only screen and (max-width: 992px) {
    .cloud360 h2 {
        font-size: var(--font-size-heading-large);
    }

    .cloud360 h2~p {
        font-size: var(--font-size-body-small);
    }

    .cloud360 .cloud360-intro {
        max-width: 640px;
        margin: 34px auto;
    }

    .cloud360 .middle-menu {
        top: 70px;
        padding-top: 20px;
    }

    .cloud360 h3 {
        font-size: var(--font-size-heading-large);
    }

    .cloud360mid .footer-text {
        font-size: var(--font-size-heading-large);
        padding: 0 15px;
    }

    .cloud360mid .last-section-heading {
        font-size: var(--font-size-heading-extra-large);
    }

    .cloud360mid .box-sub-text {
        font-size: var(--font-size-heading-large);
    }
}

@media only screen and (min-width: 866px) and (max-width: 1024px) {
    .cloud-page-header-container {
        padding-top: 25px;
        text-align: center;
    }

    .cloud360 .header-width {
        text-align: center;
        margin: 0 auto;
    }

    .cloud360 .cloud360-mobile-img {
        width: 100%;
        margin-top: 40px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 866px) {
    .cloud360 .cloud360-header {
        background-image: none !important;
        margin-top: 0;
    }

    .cloud-page-header-container {
        padding-top: 3.5rem;
    }
}

@media only screen and (max-width: 866px) {
    .cloud360 .mobileonly {
        display: block;
    }

    .cloud360 .header-width {
        margin: auto;
        text-align: center;
    }

    /*.cloud360 .visible-none {
        display: none;
    }*/
    /*.cloud360 .cloud360-mobile-img {
        width: 100%;
        margin-top: 40px;
    }*/
    .cloud360 h1,
    .cloud360 h2 {
        margin-bottom: 8px;
        font-size: 48px;
        line-height: 1.13;
        text-align: center;
        margin-top: 24px;
    }

    .cloud360 h1~p {
        font-size: var(--font-size-body-large);
        font-weight: 500;
        text-align: center;
        line-height: var(--line-height-xl);
    }

    .cloud360 h1 p {
        margin-bottom: 0;
    }

    /*.cloud360 .cloud360-header {
        background-image: none !important;
        margin-top: 0;
    }*/
}

@media only screen and (max-width: 767px) {
    .cloud360mid .last-section {
        display: block;
    }

    .cloud360mid .box-section {
        display: block;
    }

    .cloud360mid .blue-padding {
        padding-bottom: 0;
    }

    .cloud360mid .footer-box {
        width: auto;
        height: auto;
        margin-bottom: 40px;
        margin-right: 0;
    }

    .cloud360mid .last-section-heading {
        font-size: var(--font-size-heading-large);
    }

    .cloud360mid .box-sub-text {
        font-size: 30px;
    }

    .cloud360mid .blue-rounded-btn {
        padding: 12px 26px;
    }

    .cloud360mid .footer-box-margin {
        margin: 0 20px;
    }

    .cloud360 .cloud360-header {
        margin-top: 69px;
    }

    .cloud360 .holder {
        padding: 40px 0;
        min-height: inherit;
    }

    .cloud360mid .footer-img-align {
        margin-top: 40px;
        padding: 0;
    }

    .cloud360 .nav-menu a {
        font-size: var(--font-size-body-extra-small);
    }

    .cloud360 .content-right-c {
        text-align: center;
    }

    .cloud360 .content-blue-btn {
        margin-bottom: 32px;
    }

    .cloud360mid .footer-container {
        padding: 0;
        text-align: center;
    }

    .cloud360mid .blue-padding {
        margin: 0;
    }

    .cloud360mid .blue-padding .footer-container-c {
        margin: 0 auto;
    }

    .cloud360 .box-sub-text {
        font-size: var(--font-size-heading-large);
    }

    .cloud360 .cloud-page-header-container {
        padding: 0;
        text-align: center;
    }

    .cloud360 .mobile-visible {
        display: block !important;
    }

    .cloud360 .footer-box {
        width: auto;
        height: auto;
    }

    .cloud360 h5 {
        margin: 0 12px 40px 12px;
    }

    .cloud360 .cloud360-intro {
        /*margin-top:56px;
        margin-bottom: 0px;*/
        max-width: 640px;
        text-align: center;
        margin: 34px auto;
    }

    .cloud360 h2 {
        font-size: var(--font-size-heading-large);
        line-height: 1.25;
        text-align: center;
        margin-bottom: 8px;
        margin-top: 24px;
    }

    .cloud360 h3 {
        font-size: var(--font-size-heading-medium);
        line-height: 1.25;
        text-align: center;
        margin-bottom: 8px;
    }

    .cloud360 h2~p {
        font-size: var(--font-size-body-small);
        font-weight: 400;
        text-align: center;
        line-height: var(--line-height-xl);
    }

    .cloud360 .newGrid_container {
        margin: 0 24px;
    }

    .cloud360 .middle-menu {
        padding: 20px;
        padding-bottom: 6px;
    }

    .cloud360 .nav-menu {
        font-family: 'Metropolis';
        font-size: var(--font-size-body-extra-small);
        font-weight: 500;
        line-height: 1;
        text-align: center;
    }

    .cloud360 .nav-menu a {
        margin: 0 20px;
        padding: 0;
    }

    .cloud360 nav ul li a.current {
        margin: 0 20px;
    }

    .cloud360 .middle-nav ul {
        padding-left: 0;
    }

    .cloud360 .feature {
        padding: 32px 0;
    }

    .cloud360 .content {
        text-align: center;
        padding: 0;
    }

    .cloud360 .content-right {
        text-align: center;
        padding: 0 0 32px 0;
    }

    .cloud360 .sticky-container {
        display: grid;
    }

    .sticky-container>div:first-child {
        grid-row: 2;
    }

    .sticky-container>div:last-child {
        grid-row: 1;
    }

    .for-mobile {
        display: block !important;
    }

    .for-desktop {
        display: none !important;
    }

    .middle-menu {
        position: sticky;
        top: 6px;
    }

    .cloud360 h4 {
        font-family: 'Metropolis';
        font-size: var(--font-size-body-small);
    }

    .cloud360mid .footer-align {
        margin: 0 20px;
    }

    .footer-box-margin .content-blue-btn {
        margin-top: 0;
    }

    .cloud360-header .image-right {
        float: none;
    }
}

@media only screen and (max-width: 400px) {
    .cloud360 nav ul li a.current {
        margin: 0px 10px;
    }

    .cloud360 .nav-menu a {
        margin: 0 10px;
        padding: 0;
    }
}

/* 360 cloud New page css ends */

/*ssl-20 page css starts*/

.ssl-20 .ssl-20-header {
    margin-top: 72px;
}

.ssl-20 .ssl-20-header-container {
    padding: 160px 0 104px 0;
    max-width: 800px;
    margin: 0 auto;
}

.ssl-20 .header-text {
    font-size: 64px;
    font-weight: 400;
    line-height: 1.13;
    text-align: center;
    color: var(--heading-text-color);
    font-family: var(--semi-bold-font);
}

.ssl-20 .ssl-20-header-container h1,
.ssl-20 .ssl-20-header-container h3 {
    margin: 0;
}

.ssl-20 .sub-text {
    font-size: var(--font-size-heading-medium);
    font-weight: 400;
    line-height: 1.33;
    text-align: center;
    color: var(--blue-gray-color);
    letter-spacing: -0.2px;
    font-family: 'MetropolisMedium', sans-serif;
    padding-top: 20px;
}

.ssl-20-middle .ssl-step-img {
    max-width: 280px;
    max-height: 240px;
}

.ssl-20-middle .step-text {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: var(--font-size-heading-medium);
    font-weight: 400;
    text-align: center;
    line-height: 1.33;
    color: var(--heading-text-color);
    font-family: var(--semi-bold-font);
}

.ssl-20-middle .img-para-text {
    margin: 0;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    letter-spacing: -0.5px;
    text-align: center;
    color: #505e6b;
    font-weight: 400;
    font-family: var(--regular-font);
}

.ssl-20-middle .image-container {
    text-align: center;
    max-width: 280px;
    margin: 0 auto;
}

.ssl-20-middle {
    padding-bottom: 208px;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .ssl-20-middle .rows {
        grid-template-columns: repeat(9, 1fr);
    }

    .rows .columns-4 {
        grid-column: span 3;
    }

    .ssl-20-middle .image-container {
        max-width: 200px;
    }

    .ssl-20-middle .ssl-step-img {
        max-width: 200px;
    }

    .ssl-20-middle {
        padding-bottom: 100px;
    }

    .ssl-20 .ssl-20-header-container {
        padding: 24px 0 64px 0;
    }
}

@media only screen and (max-width: 992px) {
    .ssl-20 .header-text {
        font-size: 56px;
    }
}

@media only screen and (max-width: 768px) {
    .ssl-20 .header-text {
        font-size: 48px;
        line-height: 1.17;
    }

    .ssl-20 .sub-text {
        font-size: var(--font-size-body-large);
        line-height: var(--line-height-xl);
    }

    .ssl-20-middle .img-para-text {
        margin-bottom: 64px;
    }

    .ssl-20-middle {
        padding-bottom: 24px;
    }

    .ssl-20-middle .image-container {
        max-width: 280px;
    }

    .ssl-20 .ssl-20-header-container {
        padding: 24px 0 64px 0;
    }
}

/*ssl-20 page css ends*/

/* Company docs pages start */

.blue-top-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 304px;
    color: var(--white-color);
    background-color: #2693ff;
    background-image: linear-gradient(282deg, var(--blue-btn-hover), #2693ff);
}

.blue-top-banner h1 {
    color: var(--white-color);
    font-weight: 400;
    font-size: 48px;
    line-height: 1.17;
    font-family: var(--semi-bold-font);
    margin-bottom: 0;
}

.main-content {
    max-width: 840px;
    padding: 0 20px;
    margin: 103px auto;
    color: var(--heading-text-color);
    font-size: var(--font-size-body-small);
    line-height: 24px;
    font-family: var(--regular-font);
    font-weight: 400;
}

.main-content p {
    margin-bottom: 24px;
}

.main-content h2 {
    font-weight: 400;
    font-size: var(--font-size-heading-medium);
    line-height: 32px;
    font-family: var(--semi-bold-font);
    margin: 40px 0 10px;
}

.main-content strong {
    font-weight: 400;
    font-family: var(--semi-bold-font);
}

.main-content ul {
    margin: 0 0 24px 14px;
    padding: 0;
}

.main-content ol {
    margin: 0 0 24px 20px
}

.main-content a {
    color: var(--blue-color);
    text-decoration: underline;
}

.main-content a:hover {
    color: var(--blue-btn-hover);
    text-decoration: underline;
}

.main-content a:active,
.main-content a:focus {
    color: var(--blue-btn-active);
    text-decoration: underline;
}

@media only screen and (max-width: 767px) {
    .main-content {
        margin: 40px 0 80px;
    }

    .blue-top-banner {
        height: auto;
        padding: 40px 10px;
    }

    .blue-top-banner h1 {
        font-size: 34px;
    }

    .main-content h2 {
        margin-top: 32px;
    }

    .main-content p {
        margin-bottom: 20px;
    }
}

/* Company docs pages end */

/* new footer css starts */
.new-footer {
    background-color: #2a3138;
    height: auto;
    padding: 48px 0 48px 0;
}

.new-footer #social-ntwk-icon a {
    line-height: 0;
    font-size: 0;
    color: transparent;
}

.new-footer .footer-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: var(--font-size-body-extra-small);
    font-family: var(--regular-font);
    line-height: 22px;
}

.new-footer .footer-nav strong {
    padding-bottom: 15px;
}

.new-footer .footer-nav strong a,
.new-footer .footer-nav strong {
    font-size: var(--font-size-body-large);
    font-family: var(--semi-bold-font);
    line-height: var(--line-height-xl);
    color: var(--white-color);
    margin: 0;
    display: block;
}

.new-footer .footer-nav strong a {
    cursor: default;
}

.new-footer .footer-nav strong a .arrow-icon {
    display: none;
}

.new-footer .footer-nav li {
    color: var(--white-color);
}

.new-footer .footer-nav li.light-title {
    color: #9DB7D1;
}

.new-footer .footer-nav li a {
    list-style-type: none;
    margin: 0;
    color: var(--white-color);
    display: inline-block;
    padding: 2px 0;
}

.new-footer .footer-nav li a:hover,
.new-footer .footer-nav li a:focus,
.new-footer .footer-nav li a:active {
    color: var(--light-blue-btn-hover);
    text-decoration: underline;
}

.new-footer .footer-nav li:hover .beta,
.new-footer .footer-nav li:focus .beta,
.new-footer .footer-nav li:active .beta {
    color: var(--heading-text-color);
}

.new-footer .footer-nav.company-section {
    padding-top: 40px;
}

.new-footer .footer-nav li span {
    font-family: var(--semi-bold-font);
}

.new-footer .footer-nav.company-section label {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--white-color);
}

.new-footer .footer-nav.company-section .web-email {
    border-radius: 8px !important;
    /*background-color: var(--body-text-color);*/
    height: 56px !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    border: none !important;
    font-size: var(--font-size-body-extra-small) !important;
    font-family: var(--regular-font) !important;
    line-height: 1.57 !important;
    color: #8aa1b8 !important;
    width: 280px !important;
    padding: 8px 8px 8px 16px !important;
}

.new-footer .footer-nav.company-section .web-email::placeholder {
    color: var(--blue-gray-color);
    font-size: var(--font-size-body-extra-small);
}

.new-footer .footer-nav.company-section input[type="email"]:hover {
    background-color: var(--white-color) !important;
    border-radius: 8px;
    border: none !important;
}

.new-footer .footer-nav.company-section input[type="email"]:focus,
.new-footer .footer-nav.company-section input[type="email"]:active {
    background-color: var(--white-color);
    color: var(--blue-gray-color);

}

.new-footer .footer-nav.company-section img:not(.footer-ic) {
    width: 41px;
    height: 40px;
    position: absolute;
    right: 23px;
    top: 40px;
}

.new-footer .footer-nav.company-section .email-box-posn {
    position: relative;
    padding-bottom: 24px;
}

.new-footer .footer-nav.company-section .email-box-posn .svg-button {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/send-button.svg");
    width: 41px !important;
    height: 40px !important;
    background-color: #2a3138 !important;
    border: none !important;
    margin: 0 !important;
    position: absolute;
    right: 8px;
    top: 8px;
    box-shadow: 0px 2px 4px -2px #959fa7;
    background-position: top;
    border-radius: 5px !important;
}

.new-footer .footer-nav.company-section .email-box-posn .svg-button:hover {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/Send-Button-Hover.svg");
    background-color: var(--white-color) !important;
    box-shadow: 0px 2px 4px -2px #959fa7;
}

.new-footer .footer-nav.company-section .email-box-posn .svg-button:active {
    background-image: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/arrow-hover-new.svg");
    background-color: var(--light-blue-btn) !important;
    box-shadow: 0px 2px 4px -2px #959fa7;
}

.new-footer .footer-nav.company-section .email-box-posn #mc_embed_signup form {
    padding: 0;
    height: 56px;
    width: 280px;
    margin: 0;
    position: relative;
    text-align: left;
}

.new-footer .footer-nav.company-section .email-box-posn a:hover {
    background-color: var(--white-color);
}

.new-footer .footer-nav.company-section ul#social-ntwk-icon li {
    display: inline;
}

.new-footer ul#social-ntwk-icon a:hover svg g,
.new-footer ul#social-ntwk-icon a:hover svg path {
    fill: var(--light-blue-btn-hover);
}

.new-footer .footer-nav.company-section ul#social-ntwk-icon .social-icons :active {
    fill: #778B9E;
}

.post-footer {
    background-color: var(--body-text-color);
    height: 86px;
}

.post-footer ul {
    padding: 0;
    margin: 0;
}

.post-footer ul#post-footer-menu li {
    display: inline;
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--white-color);
    font-family: var(--regular-font);
}

.post-footer ul#post-footer-menu li a {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--white-color);
    font-family: var(--regular-font);
}

.post-footer ul#post-footer-menu li a:hover,
.post-footer ul#post-footer-menu li a:focus {
    color: var(--light-blue-btn-hover);
    text-decoration: underline;
}

.post-footer ul#post-footer-menu li a:active {
    color: #778B9E;
}

.post-footer ul#post-footer-menu li#privacy {
    padding: 0 56px 0 125px;
}

.post-footer ul#post-footer-menu li#termsofservice {
    padding-left: 50px;
}

.post-footer ul#post-footer-menu li#childrensprivacy {
    padding: 0 50px 0 50px;
}

.post-footer ul#post-footer-menu li a img#securly-icon {
    width: 138px;
    height: 32px;
    float: right;
}

.post-footer {
    padding: 27px 0;
}

.new-footer .footer-nav ul h4 a {
    color: var(--white-color);
    cursor: not-allowed;
    text-decoration: none;
    pointer-events: none;
}

@media screen and (max-width: 1445px) and (min-width: 1200px) {
    .post-footer ul#post-footer-menu li#childrensprivacy {
        padding: 0 50px 0 50px;
    }

    .post-footer ul#post-footer-menu li {
        font-size: 12px;
    }

    .post-footer ul#post-footer-menu li a {
        font-size: 11px;
    }
}

@media screen and (max-width: 1100px) and (min-width: 992px) {
    .post-footer {
        height: auto;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .post-footer ul#post-footer-menu li#privacy {
        padding: 0 50px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
    .new-footer .footer-nav.company-section .web-email {
        width: 230px !important;
    }

    .new-footer .footer-nav.company-section .email-box-posn .svg-button {
        right: 58px;
    }

    .new-footer .footer-nav ul#social-ntwk-icon {
        width: max-content;
    }

    .post-footer ul#post-footer-menu li a {
        font-size: 11px;
    }

    .post-footer ul#post-footer-menu li#privacy,
    .post-footer ul#post-footer-menu li#childrensprivacy {
        padding: 0 30px;
    }

    .post-footer ul#post-footer-menu li {
        font-size: 11px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 993px) {
    .new-footer .rows .columns-3 {
        grid-column: span 2;
    }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
    .new-footer .columns-3 {
        grid-column: span 5;
    }

    .new-footer .rows {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (max-width: 1100px) {
    .post-footer ul#post-footer-menu li a img#securly-icon {
        float: none;
    }

    .post-footer ul#post-footer-menu li.securly-logo {
        display: block;
    }
}

@media screen and (max-width: 1100px) and (min-width: 993px) {
    .post-footer ul#post-footer-menu li.securly-logo {
        padding-top: 16px;
    }
}

@media only screen and (max-width: 992px) {
    .footer-nav .productsoln-outerdiv {
        display: none;
    }

    .footer-nav .cmpnysecn-outerdiv,
    .footer-nav .products-outerdiv,
    .footer-nav .resource-outerdiv {
        display: none;
    }

    .footer-nav .arrow-mob-icon {
        transform: rotate(180deg);
        top: 6px !important;
    }

    .footer-nav .mob-view {
        overflow-y: scroll;
        max-height: 229px;
        width: auto !important;
        border-bottom: 1px solid #505e6b;
    }

    .footer-nav .contact-outer-div li,
    .footer-nav .stayconnected-outerdiv li {
        padding: 0 24px;
    }

    .footer-nav .stayconnected-outerdiv li #social-ntwk-icon {
        padding-bottom: 40px;
    }

    .footer-nav .stayconnected-outerdiv li #social-ntwk-icon li {
        padding: 0 8px 0 0;
    }

    .footer-nav .mob-view li {
        background-color: var(--body-text-color);
    }

    .footer-nav .mob-view li a {
        padding: 10px 24px;
        display: block;
    }

    .footer-nav .mob-view li a:hover {
        background-color: var(--blue-gray-color);
    }

    .new-footer {
        height: auto;
        padding: 0;
    }

    .new-footer .footer-nav.company-section {
        padding: 0;
    }

    .new-footer .footer-nav strong#contactus {
        padding-top: 56px;
    }

    .new-footer .footer-nav strong#stayconnected {
        padding-top: 40px;
    }

    .new-footer .footer-nav.company-section img:not(.footer-ic) {
        right: 263px;
    }

    .new-footer .newGrid_container {
        margin: 0;
    }

    .new-footer .footer-nav strong {
        font-size: var(--font-size-body-medium);
        line-height: 1.33;
        padding: 16px 24px;
        margin: 0;
    }

    .new-footer .footer-nav strong a {
        padding-bottom: 0;
        font-size: var(--font-size-body-medium);
        line-height: 1.33;
    }

    .new-footer .footer-nav strong {
        border-bottom: 1px solid #505e6b;
    }

    .new-footer .footer-nav strong#contactus,
    .new-footer .footer-nav strong#stayconnected {
        border-bottom: 0;
    }

    .new-footer .footer-nav strong a {
        pointer-events: auto;
        cursor: auto;
    }

    .new-footer .footer-nav strong a span.arrow-icon {
        display: inline-block;
        position: relative;
        top: 2px;
    }

    .new-footer .footer-nav .footer-ic {
        float: right;
        position: relative;
        top: 7px;
        width: 16px;
        height: 10px;
        object-fit: cover;
    }

    /* .new-footer .footer-nav .rmv-bdr {
        border-bottom: 0;
    } */

    .post-footer ul> :first-child {
        display: block !important;
    }

    .post-footer ul#post-footer-menu li#childrensprivacy {
        padding: 16px 32px 0 0;
    }

    .post-footer {
        height: auto;
        padding: 32px 24px 32px 24px;
    }

    .post-footer .newGrid_container {
        margin: 0;
    }

    .post-footer ul#post-footer-menu li#privacy {
        padding: 32px 32px 16px 0;
        display: inline-block;
    }

    .post-footer ul#post-footer-menu li#terms {
        padding-right: 32px;
    }

    .new-footer .footer-nav .company-section .email-box-posn .svg-button {
        right: 8px;
        top: 8px;
    }

    .post-footer ul#post-footer-menu li#gdpr {
        padding: 0 32px 16px 0;
        display: inline-block;
    }

    .post-footer ul#post-footer-menu li#termsofservice {
        padding: 0 0 32px 0;
        display: inline-block;
    }

}

@media only screen and (max-width: 824px) {
    .post-footer ul#post-footer-menu li.securly-logo {
        display: block !important;
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .new-footer .columns-3 {
        grid-column: span 3;
    }

    .new-footer .rows {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
    }
}

@media only screen and (max-width: 503px) {
    .post-footer ul#post-footer-menu li#childrensprivacy {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        display: inline-block;
    }
}

/* new footer css ends */

/* new terms and conditions page css starts */

.terms .tab {
    position: relative;
    padding: 24px 0;
    text-align: center;
    margin-bottom: 0;
    border-bottom: 1px solid var(--light-blue-btn);
}

.terms .tab li {
    display: inline;
}

.terms .tab-padding {
    padding-right: 56px;
}

.terms .tab-padding:last-child {
    padding-right: 0;
}

.terms .tab a {
    font-size: var(--font-size-body-medium);
    line-height: 1;
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 400;
    color: var(--blue-color);
    padding-bottom: 25px;
    padding-top: 24px;
}

.terms .tab a:hover,
.terms .tab a:focus {
    color: var(--blue-color);
}

.terms .tab a.active {
    color: var(--blue-btn-hover);
    border-bottom: 4px solid #2977ef;
    transition: none;
    padding-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .terms .tab-padding {
        padding-right: 0;
    }

    .terms .tab li {
        display: block;
        margin-bottom: 20px;
    }

    .terms .tab a.active {
        padding-bottom: 2px;
        border-bottom-width: 2px;
    }
}

/* new terms and conditions page css ends */

/* Comparison pages css starts */
.bundle-comparison-outer {
    margin-top: 82px;
    margin-bottom: 82px;
}

.blue-bg-titles,
.div-table,
.com-chart-section,
.div-table-inner,
.div-table-toggle {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.blue-bg-titles {
    background-color: var(--btn-color);
    height: 56px;
    text-align: left;
    padding: 0 28px;
}

.blue-bg-titles h5 {
    font: 400 var(--font-size-body-medium)/24px var(--semi-bold-font);
    margin: 0;
    color: var(--white-color);
    width: 12%;
}

.blue-bg-titles h5:nth-child(2) {
    margin-left: 40px;
    width: 12%;
}

.div-table {
    text-align: center;
    color: #505e6b;
    font-size: 15px;
    line-height: 22px;
    flex-wrap: wrap;
    position: relative;
}

.div-table>.first-col,
.div-table>div {
    width: 12%;
    padding: 20px 14px;
}

.div-table .first-col {
    text-align: left;
    color: #2a3138;
    font: 400 15px/22px var(--semi-bold-font);
    width: 15.5%;
    display: flex;
    position: absolute;
    z-index: 2;
    height: 100%;
    border-bottom: 0;
    cursor: pointer;
    transition: none;
    background-color: var(--white-color);
}

.first-col a {
    color: #2a3138;
    display: block;
    top: 272px;
    height: 42px;
    width: 100%;
}

.first-col a:after {
    content: "";
    background: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-drop-down.svg") 0 0;
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 3px;
    right: 4px;
    transition: .2s ease;
    z-index: 1;
}

.div-table .div-table-inner>.second-col {
    width: 30%;
    padding: 20px 16px 20px 60px;
    text-align: left;
    min-height: 84px;
}

.tp-icon {
    display: inline-block;
    width: 11px;
    height: 11px;
    border: 1px solid var(--blue-color);
    color: var(--blue-color);
    border-radius: 50%;
    text-align: center;
    padding: 1px;
    font-size: 9px;
    line-height: 1;
    font-style: normal;
    cursor: help;
    vertical-align: text-top;
}

.com-chart-section {
    padding-top: 20px;
    align-items: start;
}

.com-chart-section:before,
.com-chart-section:after {
    content: "";
    position: absolute;
    top: 0;
    width: 10px;
    height: 100%;
    background-color: var(--white-color);
    left: -10px;
}

.com-chart-section:after {
    left: auto;
    right: -10px;
}

.com-chart-section h2 {
    color: #505e6b;
    font: 400 var(--font-size-heading-large)/38px var(--semi-bold-font);
    margin: 4px 0 0;
    width: 40%;
    align-self: center;
}

.com-chart-section>div {
    width: 13%;
    text-align: center;
}

.com-chart-section img {
    margin-right: 0;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.com-chart-section span {
    display: block;
    color: #505e6b;
    font-size: var(--font-size-body-small);
    margin-top: 4px;
}

.bundle-comparison-outer .sticky-position {
    top: 70px;
    z-index: 3;
    background: var(--white-color);
}

.div-table-wrapper {
    box-shadow: 0 2px 4px 0 rgba(216, 216, 216, 0.4);
}

.div-table .css-tooltip {
    float: none;
    margin: 0 0 0 -9px;
    width: 16px;
    height: 16px;
}

.div-table .css-tooltip .tooltiptext {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    background-color: #2a3138;
    color: #eef1ff;
    line-height: 18px;
    z-index: 4;
    width: 330px;
    padding: 12px;
    bottom: 140%;
    left: auto;
    right: -14px;
}

.div-table .css-tooltip .tooltiptext::after {
    border-top-color: #2a3138;
    left: auto;
    right: 16px;
}

.div-table .css-tooltip .tooltiptext strong {
    font-family: var(--semi-bold-font);
    font-weight: 400;
}

.div-table h6 {
    margin: 0 5px 0 19px;
    display: inline-block;
    position: relative;
    top: 2px;
    font: 400 var(--font-size-body-small)/30px var(--semi-bold-font);
}

.div-table:nth-child(odd) .first-col {
    background-color: #f2f9ff;
}

.div-table .div-table-inner {
    width: 100%;
    margin-left: 0;
    padding: 0;
    border-bottom: none;
}

.div-table:last-child .div-table-toggle .div-table-inner:last-child {
    border-bottom: 0;
}

.div-table>.div-table-inner {
    margin-left: 12%;
}

.div-table .div-table-inner>div {
    width: 14%;
}

.div-table>.div-table-toggle {
    width: 100%;
    border-bottom: 0;
    padding: 0;
    margin-left: 12%;
    flex-wrap: wrap;
}

.div-table-toggle {
    display: none;
}

.first-col.active a:after {
    transform: rotate(180deg);
}

.div-table .na-icon {
    margin-left: 30px;
}

/*.div-table .tick-icon {
    margin-left: 15px;
}
.div-table img[src="https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/checkmark-green-table.svg"] {
    margin-left: 14px;
}*/
.mo-controls,
.only-first-col a:after {
    display: none;
}

.div-table .only-first-col,
.div-table .only-first-col a {
    cursor: default;
}

.div-table .only-first-col a {
    height: auto;
}

.two-comparison .com-chart-section>div {
    width: 34%;
}

.two-comparison .div-table .div-table-inner>div:not(.second-col) {
    width: 39%;
}

@media screen and (max-width: 1460px) and (min-width: 1200px) {

    .div-table .first-col,
    .blue-bg-titles h5 {
        width: 17%;
    }

    .div-table>.div-table-inner,
    .div-table>.div-table-toggle {
        margin-left: 17%;
    }

    .div-table .div-table-inner>.second-col {
        width: 40%;
        padding-left: 26px;
    }

    .div-table .div-table-inner>div {
        width: 16%;
    }

    .com-chart-section h2 {
        width: 45%;
    }

    .com-chart-section div {
        width: 11%;
    }

    .blue-bg-titles h5:nth-child(2) {
        margin-left: 9px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {

    .div-table .first-col,
    .blue-bg-titles h5 {
        width: 19%;
    }

    .div-table>.div-table-inner,
    .div-table>.div-table-toggle {
        margin-left: 19%;
    }

    .div-table .div-table-inner>.second-col {
        width: 32%;
        padding-left: 26px;
    }

    .div-table .div-table-inner>div {
        width: 19%;
    }

    .com-chart-section h2 {
        width: 40%;
    }

    .com-chart-section div {
        width: 12%;
    }

    .blue-bg-titles h5:nth-child(2) {
        margin-left: 9px;
    }

    .div-table>.first-col,
    .div-table>div {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .bundle-comparison-outer {
        margin: 40px 24px 0;
    }

    .pages-comparison-intro {
        padding-top: 40px;
    }

    .com-chart-section h2 {
        font-size: 28px;
        line-height: 34px;
    }

    .blue-bg-titles {
        padding: 0 24px;
    }

    .blue-bg-titles h5:nth-child(2) {
        margin-left: 11px;
    }
}

@media only screen and (max-width: 767px) {
    .pages-comparison-intro .newGrid_container {
        margin-right: 24px;
        margin-left: 24px;
    }

    .pages-comparison-intro {
        padding-top: 10px;
    }

    .bundle-comparison-outer {
        margin: 20px 0 71px;
    }

    .div-table>.div-table-inner,
    .div-table>.div-table-toggle {
        margin-left: 0;
    }

    .div-table-inner.visible-xs {
        display: flex !important;
    }

    .div-table .first-col {
        width: 100%;
        height: 46px;
        top: 0;
        position: static;
    }

    .div-table>.first-col,
    .div-table>div,
    .div-table .div-table-inner>.second-col {
        padding: 14px 18px;
    }

    .div-table .div-table-inner>.second-col {
        padding-right: 0;
    }

    .first-col:after {
        top: 14px;
        right: 14px;
    }

    .div-table {
        border-bottom: 1px solid var(--white-color);
    }

    .div-table:nth-child(even) .first-col,
    .div-table:nth-child(odd) .first-col {
        background-color: #f7fafc;
    }

    .div-table .css-tooltip .tooltiptext {
        width: 174px;
    }

    .mo-controls {
        margin: 30px 0 0 0;
    }

    .mo-controls h5 {
        font: 400 var(--font-size-body-medium)/22px var(--semi-bold-font);
        color: var(--blue-btn-active);
        margin: 0;
        padding-left: 18px;
        align-self: flex-end;
    }

    .com-chart-section span {
        font-size: var(--font-size-body-extra-small);
        line-height: 20px;
        color: #2a3138;
    }

    .mo-controls {
        padding-top: 10px;
        background-color: var(--white-color);
        display: flex;
        top: 70px;
        z-index: 3;
        overflow: hidden;
        position: sticky;
    }

    .mo-controls .slick-prev,
    .mo-controls .slick-next,
    .mo-controls .slick-prev:hover,
    .mo-controls .slick-prev:focus,
    .mo-controls .slick-next:hover,
    .mo-controls .slick-next:focus {
        width: 24px;
        height: 24px;
        top: 22%;
        background: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/previous-slide.svg") no-repeat;
    }

    .mo-controls .slick-next,
    .mo-controls .slick-next:hover,
    .mo-controls .slick-next:focus {
        background: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/next-slide.svg") no-repeat;
    }

    .slick-arrow.slick-disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

    .com-chart-section .mo-carousel {
        padding: 0 40px 20px 40px;
    }

    .mo-controls h5,
    .com-chart-section>div,
    .com-chart-section .mo-carousel,
    .div-table .div-table-inner>.second-col,
    .div-table .div-table-inner>div,
    .two-comparison .com-chart-section>div,
    .two-comparison .div-table .div-table-inner>div:not(.second-col) {
        width: 33.33%;
    }

    .mo-carousel .slick-track {
        left: -14px;
    }

    .first-col br,
    .fifth-col,
    .sixth-col,
    .seventh-col,
    .com-chart-section:before,
    .com-chart-section:after {
        display: none;
    }

    .first-col a,
    .first-col.active a {
        height: auto;
    }

    .only-first-col a:after {
        display: block;
    }

    .div-table .first-col {
        height: 64px;
        padding-top: 22px;
    }

    .first-col a::after {
        top: 2px;
    }

    .div-table-wrapper {
        box-shadow: none;
    }

    .second-col {
        font-size: var(--font-size-body-extra-small);
        line-height: 18px;
    }

    .div-table .css-tooltip:hover .tooltiptext {
        visibility: hidden;
    }

    .div-table .css-tooltip:hover .tooltiptext.active {
        visibility: visible;
    }

    .mo-controls .slick-prev {
        left: -20px;
    }

    .mo-controls .slick-next {
        right: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .com-chart-section .mo-carousel {
        padding: 0;
    }
}

/* Comparison pages css ends */

/* SEO pages css starts */

.static-hero {
    margin-top: 105px;
    background-image: linear-gradient(288deg, #0096ff, #6169ef);
}

.static-pages,
.static-pages .holder p,
.static-pages ul {
    font: 400 var(--font-size-body-medium)/1.75 var(--regular-font);
    color: var(--body-text-color);
}

.static-hero h1,
.static-hero h2 {
    max-width: 1038px;
    margin: 0 auto;
    padding: 15px 20px;
}

.static-pages {
    margin-bottom: 30px;
}

.static-pages .holder {
    display: grid;
    place-items: center;
}

.static-pages .image-align img {
    max-height: 480px;
}

.static-pages h3 {
    font-size: var(--font-size-heading-extra-large);
    line-height: var(--line-height-sm);
    color: var(--heading-text-color);
}

.static-listing {
    margin: 50px 0 30px;
    color: #505e6b;
    line-height: 24px;
}

.static-listing h5 {
    font: 400 var(--font-size-body-small)/22px var(--semi-bold-font);
    color: var(--body-text-color);
    margin: 14px 0 10px;
}

.static-listing ul {
    margin: 0 0 0 16px;
    padding: 0;
}

.blue-bg-section {
    box-shadow: inset 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: #f6fafc;
}

.content-listing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 0 16px;
    padding: 0;
}

.content-listing-row {
    display: block;
}

.seo-header .image-right {
    position: relative;
    z-index: -1;
}

.static-pages .get-started-today {
    background-color: #fafbfc;
    padding-top: 10px;
    margin-bottom: -31px;
}

.static-pages .securlyhome .feature-content .text-container {
    padding-bottom: 0;
    padding-right: 50px;
}

.static-pages .securlyhome .kalam-heading {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
}

.cloud360 .holder.image-to-bottom {
    padding-bottom: 0;
    min-height: inherit;
}

.cloud360 .holder.image-to-bottom .content-right-c {
    margin-bottom: 30px;
}

.cloud360 .holder.image-to-bottom .img-align {
    align-items: flex-end;
}

.blue-gradiant.key-adv.gr-blue-box {
    background-image: linear-gradient(288deg, #0096ff, #6169ef);
}

.pos-rel {
    position: relative;
    left: 50%;
}

.grouping-text {
    color: var(--heading-text-color);
}

.grouping-text h4 {
    font: 400 var(--font-size-heading-medium)/1.33 var(--regular-font);
    color: var(--heading-text-color);
    margin-top: 56px;
    margin-bottom: 10px;
}

.grouping-text h5 {
    color: var(--heading-text-color);
    font: 400 var(--font-size-body-small)/1.2 var(--semi-bold-font);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.grouping-text img {
    margin-right: 10px;
    align-self: flex-start;
}

.grouping-text ul {
    margin: 0 0 40px 18px;
    padding: 0;
    max-width: 860px;
}

.static-pages .holder .grouping-text p {
    line-height: 22px;
}

.chromebook-intro .larger-title,
.grouping-text h5.larger-title {
    font: 400 var(--font-size-heading-large)/40px var(--regular-font);
    color: #505e6b;
}

.chromebook-intro .larger-title {
    margin-top: 40px;
}

.grouping-text h5.larger-title {
    font-size: var(--font-size-heading-medium);
    line-height: 32px;
    max-width: 980px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
}

.static-pages .holder p.larger-p {
    font-size: var(--font-size-body-large);
    line-height: 28px;
    margin-bottom: 12px;
}

.cloud360 h1.smaller-font {
    font-size: 54px;
}

.chromebook-intro p.smaller-font {
    font-size: var(--font-size-body-small);
}

.cloud360 .holder.height-inherit {
    min-height: inherit;
}

@media screen and (max-width: 1023px) {
    .static-pages h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .static-pages .content-blue-btn {
        margin-bottom: 30px;
    }

    .order-change {
        order: -1;
    }

    .static-pages.cloud360 .img-align,
    .static-pages.cloud360 .img-size-left {
        margin-right: 0;
        margin-left: 0;
        float: none;
    }

    .cloud360 .holder.image-to-bottom .content-right-c {
        margin-bottom: 0;
    }

    .remote-page .image-align {
        margin-top: 30px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .rows.static-listing {
        grid-template-columns: repeat(12, 1fr);
    }

    .rows.static-listing .columns-4,
    .grouping-text .rows .columns-3,
    .grouping-text .rows .columns-4 {
        grid-column: span 4;
    }

    .grouping-text .rows .columns-3,
    .grouping-text .rows .columns-4 {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 992px) {
    .static-hero {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 767px) {
    .static-hero {
        padding: 40px 0;
    }

    .static-hero h1,
    .static-hero h2 {
        display: block;
    }

    .static-hero {
        margin-top: 60px;
    }

    .static-pages h3 {
        font-size: var(--font-size-heading-medium);
        line-height: 1.25;
        margin-top: 24px;
        margin-bottom: 14px;
    }

    .rows.static-listing {
        text-align: center;
    }

    .rows.static-listing .columns-4 {
        margin-bottom: 20px;
    }

    .rows.static-listing .columns-4:last-child {
        margin-bottom: 0;
    }

    .content-listing {
        grid-template-columns: 1fr;
    }

    .static-listing ul,
    .content-listing {
        list-style: inside;
        line-height: var(--line-height-xxl);
    }

    .static-listing {
        margin-top: 30px;
    }

    .static-pages,
    .static-pages .holder p {
        line-height: var(--line-height-xxl);
    }

    .cloud360 .smaller-mobile,
    .cloud360 h1.smaller-font {
        font-size: 36px;
    }

    .static-pages .get-started-today {
        background-color: transparent;
        margin-bottom: 0;
    }

    .static-pages .securlyhome .kalam-heading {
        font-size: var(--font-size-heading-medium);
    }

    .grey-bg.get-started-today .cnt-msg h5 {
        margin: 0 0 4px;
    }

    .securlyhome .form-white-container .styled-form p {
        line-height: var(--line-height-md);
    }

    .static-pages .securlyhome .feature-content .text-container {
        padding-right: 0;
    }

    .pos-rel {
        position: static;
    }

    .chromebook-intro .larger-title,
    .grouping-text h5.larger-title {
        font-size: var(--font-size-heading-small);
        line-height: 30px;
    }

    .grouping-text h5 {
        margin: 0 0 10px;
    }

    .grouping-text .rows .columns-3,
    .grouping-text .rows .columns-4 {
        margin-bottom: 20px;
        grid-column: span 12;
    }
}

@media screen and (max-width: 768px) and (min-width: 321px) {
    .grey-bg.get-started-today .newGrid_container {
        margin: 0 20px;
    }
}

@media screen and (max-width: 350px) {
    .grey-bg.get-started-today .form-white-container .cnt-msg {
        max-width: 270px;
        margin-left: 0;
    }
}

/* SEO pages css ends */

/* About us page css starts */

.about-hero {
    position: relative;
    margin-top: 70px;
    font: 400 var(--font-size-body-large)/30px var(--regular-font);
    color: var(--body-text-color);
    z-index: 2;
}

.about-hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/about-bg.svg) top -100px center no-repeat;
    background-size: cover;
}

.about-hero h1 {
    font: 400 36px/50px var(--semi-bold-font);
    color: var(--heading-text-color);
    margin-top: 74px;
}

.story-grid {
    margin-top: 70px;
}

.story-grid img {
    margin-bottom: 20px;
    border-radius: 10px;
    max-height: 700px;
    object-fit: cover;
}

.story-grid .rows {
    grid-gap: 20px;
}

.story-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.story-block div {
    width: 54%;
    margin-left: 6%;
    margin-right: 4%;
}

.team-pic {
    margin-top: 20px;
    width: 100%;
}

.story-block img {
    margin-bottom: 0;
    width: 40%;
}

.story-block h2,
.story-block h3 {
    font: 400 36px/46px var(--semi-bold-font);
    color: #202b36;
    margin-top: 0;
    margin-bottom: 12px;
}

.story-block h3 {
    color: var(--body-text-color);
    font-size: var(--font-size-heading-small);
    line-height: 28px;
    margin-bottom: 20px;
}

.quote {
    margin: 24px 0 0 10px;
    padding: 30px 0 0 30px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/quotes.webp) 0 0 no-repeat;
    color: var(--body-text-color);
    font: italic 400 28px/38px var(--regular-font);
}

.quote span {
    color: #505e6b;
    font: normal 400 var(--font-size-body-large)/28px var(--regular-font);
    margin-top: 30px;
    display: block;
}

.aw-wrap {
    margin-top: 110px;
    display: flex;
    justify-content: space-between;
}

.aw-wrap img {
    margin-left: 40px;
}

.aw-wrap h3 {
    font: 400 30px/40px var(--semi-bold-font);
    color: #505e6b;
    margin: 0;
    align-self: center;
}

.profile-wrap {
    background-color: var(--white-color);
    color: #454f5b;
    font: 400 var(--font-size-body-small)/22px var(--regular-font);
    text-align: center;
    padding: 100px 0;
}

.profile-wrap img {
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(80, 94, 107, 0.26);
    width: 116px;
    height: 116px;
    object-fit: cover;
}

.profile-wrap p {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    margin-bottom: 50px;
}

.profile-wrap a {
    display: block;
    margin-bottom: 10px;
}

.profile-wrap a h4 {
    font: 400 var(--font-size-body-small)/22px var(--semi-bold-font);
    color: var(--blue-color);
    margin: 20px 0 8px;
}

.profile-wrap a h5 {
    font: 400 var(--font-size-body-small)/22px var(--regular-font);
    color: #454f5b;
    max-width: 90%;
    margin: 0 auto;
}

.large-heading {
    font: 400 48px/56px var(--semi-bold-font);
    color: var(--heading-text-color);
    margin-top: 0;
}

.profile-popup {
    border-radius: 8px;
    box-shadow: 0 15px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1);
    background-color: var(--white-color);
    padding: 46px;
    text-align: left;
    max-width: 810px;
    margin: 40px auto;
    position: relative;
    font: 400 var(--font-size-body-extra-small)/24px var(--regular-font);
    color: #505e6b;
}

.mfp-bg.mfp-zoom-in {
    background-color: rgba(216, 216, 216, 0.7);
    opacity: 10;
    z-index: 99999;
}

.profile-popup .inner-popup {
    display: flex;
    align-items: flex-start;
}

.profile-popup .popup-pic {
    margin-right: 34px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-popup .large-heading {
    font-size: var(--font-size-heading-large);
    line-height: 40px;
    margin-bottom: 6px;
}

.profile-popup h5 {
    font-size: var(--font-size-body-medium);
    line-height: 24px;
    color: #505e6b;
    margin: 0 0 24px;
}

.profile-popup a {
    display: inline-block;
    padding: 4px;
}

.left-aligned {
    background-color: var(--white-color);
    margin-top: 0;
    text-align: left;
}

.left-aligned .large-heading {
    font-size: var(--font-size-heading-medium);
}

.left-aligned a h4 {
    font-size: var(--font-size-body-medium);
}

.left-aligned a h5 {
    max-width: inherit;
    font-size: var(--font-size-body-extra-small);
    line-height: 20px;
}

.left-aligned img {
    width: 50px;
    height: 50px;
    box-shadow: 0 1px 2px 0 rgba(80, 94, 107, 0.26);
}

.board-rows {
    margin-bottom: 40px;
}

.meet-group {
    background-color: #f7fafc;
    padding: 74px 0;
    text-align: center;
}

.meet-logos {
    max-width: 1000px;
    margin: 74px auto 0;
    padding: 0 30px;
}

.locations-group {
    text-align: center;
    margin: 74px 0 40px;
}

.sub-heading {
    font: 400 var(--font-size-body-large)/28px var(--regular-font);
    color: #505e6b;
    margin: 24px 0 40px;
}

.locations-group .locations-slider {
    margin: 0 -3%;
    padding: 0;
    list-style: none;
    text-align: left;
    color: #454f5b;
    font: 400 var(--font-size-body-small)/20px var(--regular-font);
    display: flex;
    flex-wrap: wrap;
}

.locations-group .slide-div {
    width: 29.33%;
    padding: 16px;
    margin: 0 2% 38px;
}

.locations-group figure {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    padding: 14px;
    min-height: 402px;
    position: relative;
}

.locations-group figure>img {
    border-radius: var(--default-border-radius);
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: 0 0;
}

.locations-group h5,
.locations-group .h5-heading {
    font: 400 var(--font-size-body-small)/22px var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 10px 0;
    padding: 0;
}

.locations-group p {
    margin-bottom: 40px;
}

.locations-group a {
    font: 400 var(--font-size-body-small)/22px var(--semi-bold-font);
    color: var(--body-text-color);
    display: inline-block;
    position: absolute;
    bottom: 30px;
}

.locations-group a img {
    vertical-align: top;
    margin-right: 2px;
}

.extra-margin {
    margin-top: 10px;
}

.mfp-close-btn-in .profile-popup .mfp-close {
    color: #9db7d1;
    opacity: 10;
    font: 400 var(--font-size-heading-extra-large)/50px var(--regular-font);
}

.mfp-close-btn-in .profile-popup .mfp-close:hover,
.mfp-close-btn-in .profile-popup .mfp-close:focus {
    opacity: .8;
}

body.magnificpopupnoscroll {
    overflow-y: hidden !important;
}

.board-group {
    margin-top: 74px;
}

.board-group .large-heading {
    margin-bottom: 40px;
}


/**
       * Fade-zoom animation for first dialog
       */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    transform: scale(0.8);
    opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.7;
    background-color: #d8d8d7;
    z-index: 99999;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}


@media only screen and (max-width: 1200px) {
    .about-hero h1 {
        font-size: var(--font-size-heading-large);
        line-height: 44px;
    }

    .quote {
        font-size: 26px;
        line-height: 36px;
        margin-top: 14px;
    }

    .large-heading {
        font-size: 46px;
        line-height: 52px;
    }

    .aw-wrap h3 {
        font-size: 28px;
        line-height: 36px;
    }

    .aw-wrap img {
        margin-left: 30px;
        height: 80px;
    }

    .meet-logos img {
        height: auto;
        object-fit: scale-down;
    }

    .locations-group .slide-div {
        width: 31.33%;
        padding: 16px;
        margin: 0 1% 38px;
    }

    .story-block {
        align-items: flex-start;
    }
}

@media only screen and (max-width: 992px) {

    .about-hero h1,
    .story-grid {
        margin-top: 54px;
    }

    .about-hero h1 {
        font-size: 30px;
        line-height: 38px;
    }

    .quote {
        font-size: var(--font-size-heading-medium);
        line-height: 34px;
        padding: 20px 0 0 20px;
        margin: 0;
    }

    .quote span {
        font-size: var(--font-size-body-medium);
        line-height: 26px;
        margin-top: 20px;
    }

    .aw-wrap {
        text-align: center;
        margin-top: 70px;
        display: block;
    }

    .aw-wrap img {
        height: 70px;
        margin-left: 0;
        margin-right: 30px;
    }

    .aw-wrap h3 {
        font-size: var(--font-size-heading-medium);
        line-height: 32px;
        margin-bottom: 30px;
    }

    .story-block h2,
    .story-block h3 {
        font-size: 30px;
        line-height: 38px;
    }

    .profile-wrap {
        margin-top: 80px;
        padding: 50px 0;
    }

    .story-grid img {
        max-height: 300px;
    }

    .story-block div {
        margin: 0;
    }

    .large-heading {
        font-size: var(--font-size-heading-extra-large);
        line-height: 48px;
    }

    .profile-wrap p {
        margin-bottom: 30px;
    }

    .left-aligned {
        margin-top: 20px;
    }

    .meet-group {
        padding: 40px 0;
    }

    .locations-group .locations-slider {
        font-size: var(--font-size-body-extra-small);
    }

    .locations-group p,
    .sub-heading {
        margin-bottom: 30px;
    }

    .locations-group .slide-div {
        margin-bottom: 18px;
        width: 32.33%;
        margin: 0 .5% 38px;
    }

    .locations-group figure {
        min-height: 340px;
    }

    .locations-group figure div {
        padding: 16px 0;
    }

    .locations-group {
        margin-top: 40px;
    }

    .meet-logos {
        display: flex;
    }

    .meet-logos img {
        width: 160px;
    }

}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .about-hero .rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .about-hero .rows .columns-4 {
        grid-column: span 4;
    }

    .profile-wrap .rows {
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 32px;
    }

    .profile-wrap .rows .columns-3 {
        grid-column: span 3;
    }
}

@media only screen and (max-width: 767px) {
    .about-hero h1 {
        font-size: var(--font-size-body-small);
        line-height: 24px;
        margin-top: 30px;
    }

    .about-hero h1,
    .story-grid {
        margin-top: 20px;
        text-align: center;
    }

    .story-block div {
        width: auto;
    }

    .about-hero {
        font-size: var(--font-size-body-extra-small);
        line-height: 22px;
    }

    .story-block h3 {
        font-size: var(--font-size-body-large);
        line-height: 26px;
    }

    .story-grid img {
        max-height: inherit;
        width: 100%;
    }

    .quote {
        font-size: var(--font-size-body-medium);
        line-height: 26px;
        background-size: 70px;
        padding: 22px 0 0;
    }

    .profile-wrap {
        background-color: transparent;
        margin-top: 32px;
        padding: 30px;
    }

    .aw-container {
        margin: 32px 0 0;
        padding: 32px 0 20px;
        background-color: #f7fafc;
    }

    .aw-wrap {
        width: 320px;
        margin: 0 auto;
    }

    .aw-wrap img {
        margin-bottom: 20px;
        height: 62px;
    }

    .aw-wrap img:nth-child(5) {
        margin-left: 30px;
    }

    .aw-wrap h3 {
        font-size: var(--font-size-body-medium);
        line-height: 26px;
    }

    .profile-wrap .rows {
        margin-top: 40px;
    }

    .large-heading {
        font-size: var(--font-size-heading-medium);
        line-height: 32px;
        margin-bottom: 10px;
        padding: 0 10px;
    }

    .profile-wrap p {
        font-size: var(--font-size-body-extra-small);
        line-height: 22px;
        padding: 0 10px;
    }

    .profile-wrap a {
        margin-bottom: 20px;
        text-align: center;
    }

    .left-aligned .large-heading {
        text-align: center;
    }

    .left-aligned {
        display: none;
        margin: 32px 20px;
    }

    .meet-group {
        background-color: #e6f2ff;
        padding: 32px 0 10px;
    }

    .meet-logos {
        display: block;
        padding: 0;
    }

    .meet-logos img {
        width: 100px;
        height: inherit;
    }

    .locations-group .slide-div {
        width: auto;
        margin: 0;
    }

    .locations-group ul div {
        padding: 10px 0;
    }

    .locations-group a {
        position: static;
    }

    .locations-group .slick-dots {
        bottom: -20px;
        text-align: center;
    }

    .locations-group .slick-dots li.slick-active button::before {
        background-color: transparent;
        border: 1px solid #d8d8d8;
    }

    .locations-group .slick-dots li button::before {
        background-color: #d8d8d8;
        width: 12px;
        height: 12px;
    }

    .locations-group .slick-dots li {
        margin: 0 2px;
    }

    .locations-group .newGrid_container {
        margin: 0 20px;
    }

    .sub-heading {
        font-size: var(--font-size-body-small);
        line-height: 22px;
        margin: 0;
    }

    .locations-group h5,
    .locations-group a {
        font-size: var(--font-size-body-extra-small);
        line-height: 20px;
    }

    .locations-group figure {
        min-height: inherit;
    }

    .locations-group .locations-slider {
        font-size: 13px;
        width: 100%;
    }

    .locations-group p {
        margin-bottom: 18px;
    }

    .locations-group a img {
        height: 18px;
    }

    .auto-logo img {
        width: auto;
    }

    .meet-group .large-heading {
        margin-bottom: 20px;
    }

    .meet-logos .auto-logo:last-child img {
        margin-right: 0;
    }

    .aw-wrap img:nth-child(4) {
        margin-right: 0;
    }

    .profile-popup {
        padding: 20px;
        margin: 20px 0;
    }

    .profile-popup .inner-popup {
        display: block;
    }

    .profile-popup .popup-pic {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
    }

    .profile-popup .large-heading {
        font-size: 26px;
        line-height: 32px;
        padding: 0;
    }

    .profile-popup h5 {
        margin-bottom: 18px;
    }

    .profile-wrap .newGrid_container {
        margin: 0;
    }

    .profile-wrap .board-group {
        margin-top: 30px;
    }

    .members-btn {
        font: 400 var(--font-size-body-medium)/24px var(--semi-bold-font);
        color: var(--blue-color);
        display: inline-block;
        text-align: center;
        margin: 20px auto 40px;
        max-width: 200px;
    }

    .members-btn i {
        display: block;
        font-size: 34px;
        margin-top: 12px;
    }

    .mo-top-margin {
        margin-top: 30px;
    }
}

/* About us page css ends */

/* Demo new flow form css starts */
.full-section {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/signup-illustration-new.svg) bottom left no-repeat;
}

.full-section.partners-full-section {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/securly-website-form-illustration-1220-x-600-x-2-0121@2x.webp) bottom left no-repeat;
    background-size: 42% auto;
}

.full-section .demo-container {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
    min-height: 1030px;
}

.full-section .left-illustration {
    width: auto;
    background: none;
    position: static;
}

.full-section .form-container {
    width: 532px;
    background-color: var(--white-color);
    padding: 1px 64px 34px;
    /* padding: 44px 64px 34px; */
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(157, 171, 186, 0.05), 0 4px 4px 0 rgba(190, 203, 218, 0.05), 0 8px 8px 0 rgba(157, 171, 186, 0.05), 0 16px 16px 0 rgba(157, 171, 186, 0.05), 0 32px 32px 0 rgba(157, 171, 186, 0.05);
}

.full-section .user-says {
    margin-left: 36px;
    width: 490px;
}

.full-section .demo-container .small-text {
    margin-bottom: 30px;
}

.full-section .demo-container .small-text a {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 0;
    color: var(--blue-color);
}

.full-section .demo-container .small-text a:hover,
.full-section .demo-container .small-text a:focus {
    text-decoration: underline;
}

.full-section .styled-form label {
    font-size: var(--font-size-body-extra-small);
    color: var(--body-text-color);
}

.styled-form label em {
    color: #778b9e;
}

.thanks {
    text-align: center;
    padding: 64px 0 30px 0;
}

.thanks h2 {
    margin-top: 28px;
}

.thanks a {
    color: var(--blue-color);
    font: 400 var(--font-size-body-small)/1.2 var(--semi-bold-font);
    margin-top: 50px;
    display: block;
}

.full-section .light-blue-radio label {
    border-color: var(--light-blue-btn-hover);
    height: 44px;
    width: 33.33%;
    background-color: var(--white-color);
    font-size: 12px;
    color: #557efe;
}

.light-blue-radio label:hover {
    background-color: #f7fafc;
    color: #778b9e;
}

.light-blue-radio input:checked+label {
    background-color: var(--light-blue-btn);
    color: #778b9e;
}

.light-blue-radio label:first-of-type {
    border-radius: 4px 0 0 4px;
}

.hidden-div {
    display: none;
}

.light-blue-radio label:last-of-type {
    border-radius: 0 4px 4px 0;
}

.filter-thanks {
    margin-top: 60px;
    padding: 64px 34px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(157, 171, 186, 0.05), 0 4px 4px 0 rgba(190, 203, 218, 0.05), 0 8px 8px 0 rgba(157, 171, 186, 0.05), 0 16px 16px 0 rgba(157, 171, 186, 0.05), 0 32px 32px 0 rgba(157, 171, 186, 0.05);
}

.styled-form .form-control#stateNonUS {
    padding-top: 4px;
}

@media only screen and (min-width: 1550px) {
    .full-section.partners-full-section {
        background-size: 34% auto;
    }
}

@media only screen and (max-width: 1300px) {
    .full-section .user-says {
        width: 360px;
    }

    .full-section .form-container {
        width: 440px;
        padding: 0 30px 20px;
        float: right;
    }

    .full-section .demo-container h2,
    .full-section .demo-container h1 {
        margin-top: 30px;
    }

    .full-section .col-md-6:last-child {
        padding-right: 0;
    }
}

@media only screen and (max-width: 992px) {
    .filter-thanks {
        box-shadow: none;
    }

    .full-section .demo-container {
        min-height: inherit;
    }

    .full-section,
    .full-section.partners-full-section {
        background: none;
    }

    .full-section .form-container {
        max-width: 512px;
        width: auto;
        float: none;
        padding: 20px 20px 10px;
    }

    .full-section .demo-container h2,
    .full-section .demo-container h1 {
        margin-top: 10px;
    }

    .full-section .styled-form .form-group:first-of-type {
        margin-top: 30px;
    }

    .full-section .col-md-6 {
        padding: 0;
    }

    .thanks {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 460px) {

    .full-section .couple-columns .col-sm-7,
    .full-section .couple-columns .col-sm-5 {
        width: 100%;
        padding: 0;
    }

    .full-section .couple-columns .col-sm-5 {
        margin-top: 46px;
    }
}

/* Demo new flow form css ends */

/* Resources css starts */

.default-header {
    text-align: center;
    background: center / cover url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/resources-hero-img.svg") no-repeat;
    min-height: 304px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.default-header * {
    margin: 0;
}

.default-header h1 {
    font: 400 64px/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.default-header h3 {
    font: 400 var(--font-size-body-medium)/1.3 var(--regular-font);
    color: #505e68;
    margin-top: 10px;
}

.customized-tabs {
    text-align: center;
    font: 400 var(--font-size-body-medium)/1.3 var(--regular-font);
    border-bottom: 1px solid var(--light-blue-btn);
    padding-bottom: 18px;
    margin-bottom: 102px;
    padding-left: 0;
}

.customized-tabs li {
    margin-right: 60px;
}

.customized-tabs a {
    color: var(--blue-gray-color);
    border-bottom-width: 4px;
    padding-bottom: 18px;
    border-bottom-color: transparent;
}

.customized-tabs a:hover,
.customized-tabs a:focus {
    color: var(--blue-color);
}

.customized-tabs a.active {
    color: var(--blue-color);
}

.box-grid a {
    display: block;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
    color: var(--blue-gray-color);
    font: 400 var(--font-size-body-extra-small)/22px var(--regular-font);
    padding-bottom: 40px;
    margin-bottom: 44px;
}

.box-grid a:hover,
.box-grid a:focus {
    box-shadow: 0 2px 2px 0 rgba(56, 73, 89, 0.05), 0 4px 4px 0 rgba(56, 73, 89, 0.05), 0 8px 8px 0 rgba(56, 73, 89, 0.05), 0 16px 16px 0 rgba(56, 73, 89, 0.05), 0 32px 32px 0 rgba(56, 73, 89, 0.05);
}

.box-grid img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 24px;
    width: 100%;
    max-height: 358px;
    min-height: 196px;
    object-fit: cover;
}

.box-grid a div {
    padding: 0 20px;
}

.box-grid span {
    font-size: 12px;
    line-height: 18px;
    color: var(--blue-gray-color);
}

.resources-container .box-grid span {
    display: none;
}

.box-grid h2 {
    font: 400 var(--font-size-body-large)/28px var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 4px 0 16px;
}

.mb-tab {
    text-align: center;
    margin: 20px 0;
}

.mb-tab select {
    border: 0;
    appearance: none;
    font: 400 var(--font-size-body-small)/24px var(--semi-bold-font);
    color: var(--blue-color);
    padding: 10px 24px;
    background: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/dropdown-down-arrow.svg") center right no-repeat;
    background-size: 18px;
}

.page-wrapper .latest-case-studies h2 {
    margin: 0 0 20px;
    max-width: inherit !important;
    font-size: var(--font-size-body-large);
}

@media only screen and (max-width: 1250px) {
    .box-grid .rows {
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 32px;
    }

    .latest-case-studies .rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .default-header h1 {
        font-size: 54px;
    }
}

@media only screen and (max-width: 992px) {
    .customized-tabs li {
        display: inline-block;
    }

    .default-header {
        min-height: 250px;
        background-position: left;
    }

    .default-header h1 {
        font-size: 46px;
    }

    .customized-tabs {
        margin-bottom: 60px;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .default-header {
        background: none;
        min-height: inherit;
        padding: 0 20px;
    }

    .default-header h1 {
        font-size: var(--font-size-heading-medium);
        line-height: 32px;
        margin-top: 32px;
    }

    .box-grid .rows {
        grid-gap: 0;
    }

    .box-grid .rows .columns-3 {
        grid-column: span 12;
    }

    .box-grid {
        margin: 0 24px;
    }

    .resources-container {
        margin-bottom: 40px;
    }

}

/* Resources css ends */

/* Hub page starts */
.hub-hero {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/securly-eol-banner-x-2-0220@2x.webp') center bottom no-repeat;
    background-size: cover;
    min-height: 350px;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hub-hero h1,
.announcement h2,
.announcement h3 {
    color: var(--heading-text-color);
    font: 400 64px/72px var(--semi-bold-font);
}

.announcement .normal-font {
    font-family: var(--regular-font);
    font-weight: 400;
    margin-top: 40px;
}

.hub-hero h1 {
    margin: 0;
    max-width: 690px;
    text-align: center;
}

.announcement h2 {
    font-size: var(--font-size-heading-large);
    line-height: 40px;
    margin: 0 0 20px;
}

.announcement h3 {
    font-size: var(--font-size-heading-medium);
    line-height: 32px;
    margin: 0 0 10px;
}

.announcement .top-space {
    margin-top: 30px;
}

.announcement {
    font: 400 var(--font-size-body-medium)/26px var(--regular-font);
    color: var(--heading-text-color);
    margin-top: 74px;
    margin-bottom: 74px;
}

.announcement ul {
    margin: 0 0 22px 0;
    padding: 0;
    list-style: none;
}

.announcement li {
    padding-left: 24px;
    position: relative;
}

.announcement li::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #778b9e;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 7px;
}

.announcement p {
    margin-bottom: 30px;
}

.announcement a {
    color: var(--blue-color);
}

.announcement a:hover,
.announcement a:focus {
    color: var(--blue-btn-active);
}

@media only screen and (max-width: 1024px) {
    .announcement {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .hub-hero {
        min-height: 250px;
    }

    .hub-hero h1 {
        font-size: 50px;
        line-height: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .hub-hero {
        background: #e6f2ff;
        min-height: 144px;
    }

    .hub-hero h1 {
        font-size: var(--font-size-heading-large);
        line-height: 40px;
        margin: 0 20px;
    }

    .announcement {
        font-size: var(--font-size-body-small);
        line-height: 24px;
        margin: 32px 22px;
    }

    .announcement h2 {
        font-size: var(--font-size-body-large);
        line-height: 28px;
    }

    .announcement h3 {
        font-size: var(--font-size-body-medium);
        line-height: 26px;
    }

    .mb-center {
        text-align: center;
    }

    .announcement ul {
        margin-bottom: 14px;
    }

    .announcement .top-space,
    .announcement .normal-font {
        margin-top: 20px;
    }

    .announcement p {
        margin-bottom: 20px;
    }
}

/* Hub page ends */

/* Classroom/MDM form css starts */

.s-hidden {
    visibility: hidden;
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.styledSelect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/select-icon.webp) top 16px right 12px no-repeat;
    padding: 10px 6px;
    font-size: var(--font-size-body-small);
}

.options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    padding: 0;
    margin: 8px 0 0;
    list-style: none;
    border: 1px solid #d2e4f7;
    background-color: var(--white-color);
    color: var(--heading-text-color);
    border-radius: 4px;
}

.options li {
    padding: 12px 16px;
    border-top: 1px solid var(--light-blue-btn);
}

.options li:first-child {
    display: none;
}

.options li:nth-child(2) {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.options li:last-child {
    border-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.options li:hover {
    background-color: var(--light-blue-btn);
}

.styled-form select.form-control.about-select {
    padding: 0;
}

.medium-text {
    font: 400 var(--font-size-body-medium)/1.2 var(--regular-font);
    color: #778b9e;
    display: block;
    margin-bottom: 38px;
}

.thanks .white-btn.blue-btn {
    display: inline-block;
    padding: 12px 34px;
    margin-top: 10px;
}

h5.mr-top-24 {
    margin-top: 24px;
}

/* Classroom/MDM form css ends */

/* Products header css starts */

.chromebook-header-without-bg.new-header {
    background-color: #f2f9ff;
    padding: 40px 0 74px;
}

.chromebook-header-without-bg.new-header h1 {
    font-size: 56px;
    line-height: 1.14;
}

.header-column {
    display: flex;
    align-items: center;
    font: 400 12px/18px var(--regular-font);
    color: #505e6b;
    margin-top: 40px;
}

.header-column p {
    margin: 0 0 0 24px;
}

.header-top-logo {
    height: 36px;
    margin-bottom: 14px;
}

.old-header {
    display: none;
}

@media screen and (max-width: 1023px) {
    .header-column {
        place-content: center;
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .chromebook-header-without-bg.new-header h1 {
        font-size: 42px;
    }

    .chromebook-header-without-bg.new-header {
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 440px) {
    .header-column {
        text-align: center;
        flex-direction: column;
        margin-top: 30px;
    }

    .header-column p {
        margin: 20px 0 0;
    }
}

/* Products header css ends */

/* Securly anywhere header css starts */
.static-hero.small-size {
    padding: 60px 0;
}

.static-hero.small-size h1,
.static-hero.small-size h2 {
    font-size: 36px;
    line-height: 1.53;
}

.static-pages .dark-blue-color {
    background-color: var(--light-blue-btn);
}

@media screen and (max-width: 767px) {
    .static-hero.small-size {
        padding: 30px 0;
    }

    .static-hero.small-size h1,
    .static-hero.small-size h2 {
        font-size: 28px;
        line-height: var(--line-height-md);
    }
}

/* Securly anywhere header css ends */

/* Careers page redesign css starts */
.items-center {
    align-items: center;
}

.careers-header {
    background: center / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/careers-page-4-x-2-1@2x.webp) no-repeat;
    min-height: 380px;
}

.careers-header p {
    max-width: 904px;
    text-align: center;
    margin-top: 12px;
    padding: 0 20px;
    font: 400 var(--font-size-body-medium) / 1.3 var(--regular-font);
    color: #505e68;
}

.career-section {
    font: 400 var(--font-size-body-medium)/1.44 var(--regular-font);
    color: var(--body-text-color);
    margin-top: 74px;
    margin-bottom: 74px;
}

.career-section .rows>.columns-6 {
    padding-right: 10%;
}

.career-section h2 {
    font: 400 48px/1.17 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 14px;
}

.perks {
    font: 400 var(--font-size-body-medium)/1.44 'MetropolisMedium', sans-serif;
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
}

.perks li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/checkmark-icon.svg) 0 4px no-repeat;
    padding-left: 36px;
    margin-bottom: 8px;
}

.perks-section {
    background-color: #f7fafc;
    padding: 74px 0;
}

.perks-section .career-section {
    margin-top: 0;
    margin-bottom: 0;
}

.center-form {
    background: top center url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/careers_page_4_clouds.svg) no-repeat;
    text-align: center;
    margin-top: 74px;
    padding-bottom: 118px;
    overflow: hidden;
}

.center-form .form-white-container::after,
.center-form .form-white-container::before {
    content: "";
    background: top center url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/careers_page_4_girl.svg) no-repeat;
    width: 300px;
    height: 420px;
    position: absolute;
    bottom: -118px;
    left: -220px;
    z-index: -1;
}

.center-form .form-white-container::before {
    background: top center url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/careers_page_4_guy.svg) no-repeat;
    left: auto;
    right: -272px;
    width: 374px;
    height: 414px;
    bottom: -126px;
}

.center-form .form-white-container {
    margin: 0 auto;
    max-width: 690px;
    padding: 32px 62px 74px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(157, 171, 186, 0.05), 0 4px 4px 0 rgba(190, 203, 218, 0.05), 0 8px 8px 0 rgba(157, 171, 186, 0.05), 0 16px 16px 0 rgba(157, 171, 186, 0.05), 0 32px 32px 0 rgba(157, 171, 186, 0.05);
    background-color: var(--white-color);
    position: relative;
}

.center-form form {
    padding: 0 62px;
}

.center-form h2,
.title-div h2,
.title-div div,
.glassdoor-review h3 {
    font: 400 var(--font-size-heading-extra-large)/1.3 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.title-div img {
    vertical-align: text-top;
}

.center-form h2 {
    margin: 0 0 10px;
}

.center-form .styled-form label {
    font-size: var(--font-size-body-small);
    color: var(--body-text-color);
}

.center-form h3 {
    font: 400 var(--font-size-heading-medium)/1.3 var(--regular-font);
    margin: 0 0 40px;
}

.glassdoor-review {
    padding: 46px 0 54px;
    background-color: #f7fafc;
    position: relative;
}

.glassdoor-review .title-div h2 {
    margin: 0 50px 0 0;
}

.glassdoor-review h3 {
    font-size: 26px;
    text-align: center;
    margin: 22px 0;
}

.rows {
    font-family: var(--regular-font);
    color: var(--body-text-color);
}

.approval,
.circle,
.recommended,
.level,
.glassdoor-review .title-div {
    display: flex;
    align-items: center;
}

.approval img {
    margin-right: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

.glassdoor-review .title-div {
    margin-bottom: 40px;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid var(--blue-btn-active);
    font: 400 var(--font-size-body-large)/1 var(--semi-bold-font);
    color: var(--body-text-color);
    margin-right: 20px;
    justify-content: center;
    flex-shrink: 0;
}


.approval h3,
.gray-heading {
    font: 400 var(--font-size-heading-small)/1.36 'MetropolisMedium', sans-serif;
    color: #161b20;
    margin: 0;
    text-align: left;
}

.approval span {
    font-family: var(--regular-font);
    display: block;
    margin-top: 4px;
}

.recommended img {
    margin-right: 20px;
}

.difficulty {
    margin-right: 30px;
}

.difficulty h4,
.difficulty .gray,
.difficulty h3 {
    font: 400 var(--font-size-body-medium)/1 'MetropolisMedium', sans-serif;
    color: var(--heading-text-color);
    margin: 0 0 6px;
}

.difficulty .gray {
    color: var(--body-text-color);
}

.difficulty h3 {
    font-size: 50px;
}

.level ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-body-extra-small);
}

.level li {
    position: relative;
    padding-left: 20px;
    line-height: var(--line-height-xxl);
}

.level li::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--btn-color);
    position: absolute;
    left: 0;
    top: 5px;
}

.level li:nth-child(2)::before {
    background-color: #73b9ff;
}

.level li:nth-child(3)::before {
    background-color: #d2e4f7;
}

.glassdoor-review .newGrid_container {
    position: relative;
}

.glassdoor-review .glassdoor {
    position: absolute;
    top: -14px;
    right: 0;
}

.bottom-spacing {
    margin-bottom: 74px;
}

.bottom-spacing .aw-wrap h3 {
    color: var(--heading-text-color);
}

.bottom-spacing .aw-wrap {
    margin-top: 102px;
    align-items: center;
}

#job-board {
    padding-top: 20px;
}

.career-thanks h2 {
    margin-top: 28px;
}

.center-form .blue-btn.full-btn {
    margin: 0;
}

.styled-form label.actual-btn {
    font: 400 var(--font-size-body-small)/1 var(--semi-bold-font);
    height: 44px;
    padding: 10px 38px;
    border-radius: 22px;
    border: solid 2px #0073E6;
    color: #0073E6;
    cursor: pointer;
    display: inline-block;
    margin-top: 14px;
}

.actual-btn img {
    vertical-align: bottom;
    margin-right: 4px;
}

#resume {
    display: none;
}

#file-chosen {
    margin-left: 8px;
    color: #334955;
    font: 400 var(--font-size-body-small)/1.3 var(--regular-font);
}

.styled-form .below-border {
    border-bottom: 1px solid var(--light-blue-btn-hover);
    padding-bottom: 16px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1500px) {
    /* .center-form::before {
        right: 70px;
    }

    .center-form::after {
        left: 160px;
    } */

    .center-form .form-white-container {
        padding: 32px 32px 54px;
    }
}

@media screen and (max-width: 1300px) {
    .center-form {
        background-image: none;
    }

    .glassdoor-review .columns-4 {
        grid-column: span 4;
    }

    /* .center-form::before {
        right: 0;
    }

    .center-form::after {
        left: 66px;
    } */

    .center-form form {
        padding: 0 30px;
    }
}

@media screen and (max-width: 1024px) {
    .glassdoor-review .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 32px;
    }

    .glassdoor-review .glassdoor {
        position: static;
        float: right;
        margin-top: 20px;
    }

    .approval h3,
    .gray-heading {
        font-size: var(--font-size-body-medium);
    }

    .recommended img,
    .approval img {
        width: 80px;
    }

    .circle {
        font-size: 15px;
        border-width: 8px;
        width: 80px;
        height: 80px;
    }

    .center-form .form-white-container::before,
    .center-form .form-white-container::after {
        display: none;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .career-section .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 32px;
    }

    .career-section h2 {
        font-size: var(--font-size-heading-extra-large);
    }

    .latest-case-studies .rows {
        grid-template-columns: repeat(9, 1fr);
    }
}


@media screen and (max-width: 767px) {
    .careers-header {
        min-height: inherit;
        background: #e6f2ff;
        padding: 48px 24px;
    }

    .careers-header h1 {
        font-size: var(--font-size-heading-large);
        margin: 0 0 10px;
    }

    .career-section {
        text-align: center;
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .career-section h2 {
        font-size: var(--font-size-heading-large);
        margin: 16px 0 20px;
    }

    .career-section .rows>.columns-6 {
        margin-right: 0;
    }

    .career-section .rows>.columns-6:first-child {
        order: 2;
    }

    .perks-section .career-section .rows>.columns-6:first-child {
        order: 0;
    }

    .perks-section {
        padding: 48px 0;
    }

    .perks {
        text-align: left;
    }

    .career-section .rows>.columns-6 {
        padding-right: 0;
    }

    .center-form {
        background: none;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .center-form .form-white-container {
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
    }

    .center-form h2,
    .title-div h2,
    .title-div span,
    .glassdoor-review h3 {
        font-size: var(--font-size-heading-large);
        line-height: var(--line-height-sm);
    }

    .center-form h3 {
        font-size: var(--font-size-body-small);
        margin-bottom: 20px;
    }

    .center-form form {
        padding: 0;
    }

    .bottom-spacing .aw-wrap {
        margin-top: 20px;
    }

    .bottom-spacing {
        background-color: transparent;
        margin-bottom: 0;
    }

    .approval h3,
    .gray-heading {
        font-size: var(--font-size-body-medium);
    }

    .glassdoor-review .rows {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
    }

    .glassdoor-review .glassdoor {
        width: 100px;
    }

    .approval,
    .recommended {
        justify-content: left;
    }

    .bottom-spacing {
        margin-top: 0;
    }

    .glassdoor-review {
        padding: 32px 0;
    }

    .glassdoor-review .title-div {
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .glassdoor-review .title-div h2 {
        margin: 0 0 12px;
    }

    .approval {
        margin-bottom: 14px;
    }

    .center-form .form-white-container {
        padding-top: 0;
    }

    .careers-header .white-btn.blue-btn {
        padding: 13px 20px;
    }

    .center-form .blue-btn.full-btn {
        margin: 0 !important;
    }

    .bottom-spacing .aw-wrap {
        width: auto;
    }
}

@media screen and (max-width: 500px) {
    #file-chosen {
        display: block;
        margin-top: 10px;
    }
}

/* Careers page redesign css ends */

/* 404 page css starts */

.not-found {
    margin-top: 100px;
    margin-bottom: 30px;
    min-height: calc(100vh - 130px);
    justify-content: center;
}

.not-found .in-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.not-found,
.not-found .in-container {
    display: flex;
    align-items: center;
}

.not-found h1 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin-top: 0;
}

.not-found h3 {
    font: 400 var(--font-size-heading-medium)/1.17 var(--regular-font);
    color: var(--body-text-color);
    margin-top: 6px;
}

.not-found .white-btn.blue-btn {
    padding: 9px 36px;
    font-size: var(--font-size-body-small);
    margin-top: 40px;
}

@media screen and (max-width: 767px) {
    .not-found {
        min-height: inherit;
    }

    .not-found .in-container {
        padding: 0 10px;
    }

    .not-found .white-btn.blue-btn {
        width: auto;
    }

    .not-found h1 {
        font-size: 30px;
    }

    .not-found h3 {
        font-size: var(--font-size-heading-small);
    }
}

/* 404 page css ends */

/* Customer training css starts */

.white-btn.common-btn {
    font: 400 var(--font-size-body-small)/1.3 var(--semi-bold-font);
    padding: 11px 31px;
    letter-spacing: 0.5px;
}

.cloud360 .full-container {
    padding-bottom: 0;
    min-height: inherit;
    text-align: center;
    position: relative;
    border-top: 10px solid #0073e5;
}

.cloud360 .full-container::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #fd5264;
}

.full-container div {
    max-width: 902px;
    padding: 0 20px;
}

.full-container h3 {
    text-align: center;
}

.small-font h1 {
    font-size: 50px;
    line-height: var(--line-height-md);
}

.small-font h1~p {
    font-size: var(--font-size-body-large);
}

.small-font h2 {
    font-size: var(--font-size-heading-extra-large);
    margin-bottom: 14px;
}

.small-font h2~p {
    font-size: var(--font-size-body-medium);
    line-height: 1.44;
}

.static-hero.regular-font h1 {
    font: 400 var(--font-size-heading-large)/1.2 'MetropolisMedium', sans-serif;
    max-width: 1098px;
}

@media screen and (max-width: 1024px) {

    .static-pages .image-align img,
    .static-pages .image-align source {
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .static-hero.regular-font h1 {
        font-size: 28px;
    }
}

/* Customer training css ends */

/* Securly home changes start */

.small-label {
    font: 400 10px/1 'MetropolisMedium', sans-serif;
    display: inline-block;
    border-radius: 20px;
    background-color: var(--blue-color);
    color: var(--white-color);
    padding: 7px 13px 5px;
    margin-bottom: 12px;
}

.label-container .sh-feature-icon {
    display: block;
}

.securlyhome h2~p.tiptext {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.43;
    color: #778b9e;
    margin-top: 18px;
}

.page-popup {
    padding: 42px 64px 0 50px;
    text-align: left;
    max-width: 874px;
    min-height: 517px;
    margin: 40px auto;
    position: relative;
    font: 400 var(--font-size-body-extra-small)/24px var(--regular-font);
    color: #505e6b;
}

.bubble-design {
    max-width: 889px;
    min-height: 541px;
}

.light-blue-design {
    max-width: 950px;
    min-height: 510px;
}

.inner-popup-container {
    display: flex;
    color: #454f5b;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
}

.inner-popup-container div {
    margin-right: 32px;
    width: 50%;
}

.inner-popup-container img {
    width: 50%;
    margin-top: 40px;
}

.inner-popup-container~.mfp-close {
    font-size: 0;
    background: url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/icon-24-px-close.svg") 10px 10px no-repeat;
    top: 14px;
    right: 14px;
    display: block;
}

.inner-popup-container~.mfp-close:active {
    top: 14px;
}

.inner-popup-container h2 {
    font: 400 48px/1.17 var(--semi-bold-font);
    color: #202b36;
    margin: 10px 0;
}

.inner-popup-container .tiptext {
    font-size: 12px;
    color: #778b9e;
}

.rows.offline-schedules {
    min-height: 564px;
    height: 564px;
}

@media screen and (max-width: 767px) {
    .inner-popup-container {
        flex-direction: column;
        text-align: center;
    }

    .inner-popup-container div,
    .inner-popup-container img {
        margin-right: 0;
        width: auto;
    }

    .inner-popup-container img {
        margin-top: 20px;
    }

    .page-popup {
        padding: 26px 20px 0;
        min-height: 424px;
    }

    .inner-popup-container~.mfp-close {
        top: 10px;
        right: 6px;
    }

    .rows.offline-schedules {
        min-height: auto;
        height: auto;
    }

    .rows.securlyhome-feature {
        max-height: inherit;
    }

    .label-container .sh-feature-icon {
        margin: 0 auto;
    }

    .mobile-order {
        order: -1;
    }
}

/* Securly home changes end */

/* New Contact page css starts */

.new-contact-header {
    background: top left / cover url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/contact-us-page-0120@2x.webp") no-repeat;
    font-size: var(--font-size-body-medium);
    line-height: 1.44;
    color: #5f7486;
}

.contact-boxes {
    margin-top: 102px;
}

.contact-boxes .rows {
    color: #5f7486;
}

.contact-boxes h2 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 42px 0 16px;
}

.contact-boxes img {
    width: 142px;
    height: 142px;
    object-fit: cover;
    border-radius: 50%;
}

.contact-boxes .columns-6 {
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    padding: 40px;
}

.contact-boxes .content-blue-btn {
    margin-top: 4px;
}

.contact-holder {
    position: relative;
    padding-bottom: 64px;
}

.contact-holder:after {
    content: "";
    background: 0 0 / cover url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/contact-us-page-spot-notmikeorjosh-0421@2x.webp") no-repeat;
    width: 394px;
    height: 300px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: none;
}

.contact-layout {
    margin-top: 56px;
}

.contact-layout h4 {
    font: 400 var(--font-size-body-medium)/1.44 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 16px 0 8px;
}

.contact-layout .rows {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--blue-gray-color);
}

.contact-layout p {
    margin-bottom: 8px;
}

.c-link {
    font-family: var(--semi-bold-font);
    color: var(--blue-color);
}

.c-link:hover {
    color: var(--blue-btn-hover);
    text-decoration: none;
}

.c-link:active,
.c-link :focus {
    color: var(--blue-btn-active);
}

.contact-layout .columns-3,
.contact-layout .columns-4 {
    margin-bottom: 30px;
}

.gray-layout {
    background-color: #f7fafc;
    margin-bottom: 0;
}

.gray-layout h2 {
    font: 400 48px/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    text-align: center;
    margin: 0;
    padding: 74px 20px 40px;
}

.gray-layout a {
    color: var(--blue-color);
}

.gray-layout h4 {
    color: var(--blue-btn-hover);
    margin-top: 0;
}

.gray-layout p {
    color: #454f5b;
}

.gray-layout .rows {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #454f5b;
}

.gray-layout .contact-layout {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 44px;
}

.new-contact {
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(157, 171, 186, 0.05), 0 4px 4px 0 rgba(190, 203, 218, 0.05), 0 8px 8px 0 rgba(157, 171, 186, 0.05), 0 16px 16px 0 rgba(157, 171, 186, 0.05), 0 32px 32px 0 rgba(157, 171, 186, 0.05);
    border: 0;
    padding: 0 64px 52px;
    max-width: 512px;
}

.new-contact p {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #505e6b;
}

.contact-img {
    align-self: center;
}

.new-contact h2,
.new-contact .form-heading {
    margin-bottom: 8px;
}

.contact-msg {
    max-width: 472px;
    margin: 0 20px 30px;
}

@media only screen and (max-width: 1024px) {
    .contact-img {
        display: none;
    }

    .cn-wrapper {
        max-width: 512px;
        margin: 0 auto;
    }

    .cn-wrapper .columns-6 {
        grid-column: span 12;
    }

    .new-contact h2,
    .new-contact .form-heading {
        font-size: var(--font-size-heading-medium);
        margin: 8px 0 12px;
    }

    .contact-main {
        margin: 40px auto 20px;
    }

    .new-contact {
        padding: 0 20px 20px;
        margin: 0 20px 30px;
    }
}


@media screen and (max-width: 1023px) and (min-width: 767px) {
    .contact-boxes h2 {
        font-size: 34px;
        margin-top: 30px;
    }

    .contact-boxes .rows,
    .contact-holder .rows,
    .gray-layout .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 16px;
    }

    .contact-boxes .columns-6 {
        grid-column: span 6;
    }

    .contact-boxes .columns-6 {
        padding: 30px;
    }

    .contact-holder .columns-4,
    .contact-layout .columns-4 {
        grid-column: span 4;
    }

    .contact-holder::after {
        width: 344px;
        height: 250px;
    }

    .gray-layout .columns-3 {
        grid-column: span 4;
    }
}


@media only screen and (max-width: 767px) {
    .new-contact-header {
        background: #e6f2ff;
        padding-bottom: 32px;
    }

    .contact-holder::after {
        display: none;
    }

    .contact-boxes {
        margin: 30px 24px;
        text-align: center;
    }

    .contact-boxes h2 {
        font-size: var(--font-size-heading-medium);
        margin: 20px 0 10px;
    }

    .contact-boxes img {
        width: 120px;
        height: 120px;
    }

    .contact-boxes .columns-6 {
        padding: 20px 20px 30px;
    }

    .contact-boxes .columns-6:first-child {
        margin-bottom: 30px;
    }

    .contact-boxes .content-blue-btn {
        padding: 12px 28px;
    }

    .contact-holder {
        padding-bottom: 30px;
    }

    .gray-layout h2 {
        font-size: var(--font-size-heading-medium);
        padding: 30px;
    }

    .contact-layout {
        margin: 20px;
    }

    .contact-holder {
        padding-bottom: 10px;
        text-align: center;
    }

    .gray-layout .contact-layout {
        text-align: left;
    }

    .contact-holder .columns-4,
    .contact-layout .columns-4 {
        grid-column: span 12;
    }

}

@media only screen and (max-width: 560px) {
    .gray-layout .rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .gray-layout .columns-3 {
        grid-column: span 12;
        margin-bottom: 10px;
    }
}

/* New Contact page css ends */

/* 24 page css starts */
.light-bg {
    position: relative;
    background-position: center;
}

.light-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-color: #f7fafc;
}

.light-bg .form-white-container {
    margin-top: 72px;
    padding-top: 62px;
}

.light-bg h2,
.light-bg h3,
.light-bg .cnt-msg {
    text-align: left;
    margin-left: 62px;
    margin-right: 62px;
}

.light-bg h3 {
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
    color: var(--blue-gray-color);
}

@media screen and (max-width: 1300px) {

    .light-bg h2,
    .light-bg h3,
    .light-bg .cnt-msg {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media screen and (max-width: 767px) {
    .light-bg .form-white-container {
        margin-top: 30px;
        padding-top: 30px;
    }

    .light-bg h2,
    .light-bg h3,
    .light-bg .cnt-msg {
        margin-left: 0;
        margin-right: 0;
    }
}

/* 24 page css ends */

/* C landing pages start */
.sub-tag {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-lg);
    color: var(--blue-gray-color);
    margin: 14px 0 0;
}

.medium-intro h2 {
    font: 400 var(--font-size-heading-large)/1.25 var(--semi-bold-font);
    color: #202b36;
    margin-bottom: 18px;
}

.medium-intro p {
    font-size: var(--font-size-body-medium);
    line-height: 1.44;
    color: #202b36;
}

.form-content h2 {
    padding: 0;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 1.33;
    color: var(--heading-text-color);
}

.securlyhome .form-white-container .form-content p {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #505e6b;
    margin-bottom: 30px;
}

.securlyhome .feature-content .text-container.big-center-img {
    padding-top: 0;
    max-width: inherit;
}

.centered-form {
    max-width: 512px;
    justify-content: flex-end;
}

.static-pages .padding-layout {
    padding: 74px 0;
}

.padding-layout .rows.securlyhome-feature {
    min-height: inherit;
    max-height: inherit;
    height: auto;
    overflow: visible;
}

.edge-layout {
    justify-content: space-between;
}

.form-white-container .thanks.hidden-div h5 {
    color: var(--blue-gray-color);
    font: 400 var(--font-size-body-medium)/1.3 var(--regular-font);
}

.form-white-container .thanks.hidden-div h2 {
    margin-bottom: 14px;
}

.form-white-container .thanks.hidden-div {
    padding-top: 30px;
}

@media screen and (max-width: 1024px) {
    .blue-bg-section {
        box-shadow: none;
    }
}

@media screen and (max-width: 767px) {
    .static-pages .padding-layout {
        padding: 0;
    }

    .grey-bg.padding-layout .form-white-container {
        padding: 0 20px;
    }

    .grey-bg.padding-layout .form-white-container form {
        padding: 0;
    }

    .grey-bg.padding-layout .newGrid_container {
        margin: 0;
    }

    .form-content {
        margin: 0 10px;
    }

    .form-content h2 {
        font-size: var(--font-size-heading-medium);
    }
}

@media screen and (max-width: 768px) and (min-width: 321px) {
    .grey-bg.padding-layout .newGrid_container {
        margin: 0;
    }
}

/* C landing pages end */

/* T and C page css starts */

.t-and-c-header {
    background: 50%/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/Securly_website_trust_compliance_hero_desktop_X1_0221.webp) no-repeat;
    min-height: 350px;
}

.t-and-c-header h3 {
    max-width: 68%;
    margin: 20px auto 0;
}

.grid-section {
    margin-top: 102px;
}

.grid-section .columns-4 {
    display: flex;
    margin-bottom: 70px;
}

.shadow-box {
    padding: 32px 15px 73px 32px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    line-height: 1.57;
    font-size: var(--font-size-body-extra-small);
    color: var(--blue-gray-color);
}

.shadow-box img {
    margin-bottom: 30px;
    height: 100px;
}

.shadow-box h2 {
    font: 400 var(--font-size-body-large)/1.3 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 10px;
}

.toggle-section strong {
    font-family: var(--semi-bold-font);
}

.toggle-section li {
    margin-bottom: 10px;
}

.shadow-box a,
.toggle-section .panel-collapse a {
    font-family: var(--semi-bold-font);
    color: var(--blue-color);
}

.shadow-box a:hover,
.toggle-section .panel-collapse a:hover {
    color: var(--blue-btn-hover);
}

.shadow-box a:focus,
.shadow-box a:active,
.toggle-section .panel-collapse a:focus,
.toggle-section .panel-collapse a:active {
    color: var(--blue-btn-active);
}

.toggle-section {
    margin-bottom: 60px;
}

.toggle-section h1 {
    font-size: 48px;
    line-height: 1.17;
    text-align: center;
    color: #202b36;
    margin: 10px 20px 74px 20px;
}

.toggle-section .panel,
.toggle-section .panel-heading {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.toggle-section .panel-group .panel-heading+.panel-collapse .panel-body {
    border: 0;
    padding: 0 46px 24px 32px;
}

.toggle-section .panel-heading a {
    display: block;
    padding: 24px 90px 24px 32px;
    border-radius: 4px;
    background-color: #f7fafc;
    font: 24px/1.33 var(--semi-bold-font);
    position: relative;
}

.toggle-section .panel-heading a::after {
    content: "";
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-drop-down.svg) 0 0;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 24px;
    right: 46px;
    background-size: 28px;
    z-index: 1;
    transition: all .2s ease;
}

.toggle-section .panel-heading a.collapsed::after {
    transform: rotate(180deg);
}

.toggle-section .panel-collapse {
    background-color: #f7fafc;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #202b36;
}

.toggle-section ul ul {
    margin-bottom: 20px;
}

.toggle-section .panel-group .panel+.panel {
    margin-top: 12px;
}

.shadow-box p {
    margin-bottom: 0;
}

.t-c-footer img {
    width: 100%;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .rows {
        grid-template-columns: repeat(9, 1fr);
    }

    .shadow-box {
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .shadow-box {
        padding: 30px 20px;
        text-align: center;
    }

    .toggle-section {
        margin: 20px;
    }

    .toggle-section .panel-heading a {
        font-size: var(--font-size-body-medium);
        padding: 18px 66px 12px 24px;
    }

    .toggle-section .panel-heading a:after {
        top: 12px;
        right: 24px;
    }

    .toggle-section .panel-group .panel-heading+.panel-collapse .panel-body {
        padding: 10px 24px 18px;
    }

    .t-and-c-header {
        min-height: inherit;
        padding-bottom: 30px;
    }

    .grid-section .columns-4 {
        margin-bottom: 30px;
    }

    .toggle-section h1 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .grid-section {
        margin-top: 30px;
    }
}

/* T and C page css ends */

/* UK - Persona page css starts */
.box-grid .box-title {
    font-family: var(--regular-font);
    font-size: var(--font-size-body-large);
    text-align: center;
    color: #454f5b;
    margin: 0 0 30px;
}

.centered-box {
    margin-bottom: 84px;
}

.centered-box .rows {
    max-width: 816px;
    margin: 0 auto;
}

.centered-box h2 {
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    margin: 0 0 6px;
}

.centered-box a {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: var(--body-text-color);
    margin-bottom: 32px;
    box-shadow: 0 2px 3px 0 #dae5ee;
}

.centered-box a:hover,
.centered-box a:focus {
    box-shadow: 0 4px 8px 0 #dae5ee;
}

.centered-box p {
    margin-bottom: 0;
}

.centered-box img {
    margin-bottom: 32px;
}

.centered-box div {
    padding: 0 32px;
}

.new-footer .footer-nav.company-section.p-top-0 {
    padding-top: 0;
}

.new-footer .footer-nav.company-section.p-top-20 {
    padding-top: 20px;
}

.about-intro p {
    margin-bottom: 20px;
}

.uk-static-page .holder p {
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
}

.uk-static-page .holder p.larger-p {
    font-size: var(--font-size-heading-medium);
    margin-bottom: 12px;
    line-height: 1.33;
    color: #202b36;
}

.static-pages .holder .grouping-text .columns-4 p {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
}

.fr-intro {
    max-width: 1008px;
    margin: 0 auto;
}

.fr-intro .larger-title {
    font: 400 var(--font-size-heading-medium)/1.33 var(--regular-font);
    text-align: center;
}

.fr-intro .white-btn.blue-btn {
    font-size: var(--font-size-body-small);
    padding: 10px 32px;
}

.sh-table {
    table-layout: fixed;
    border-collapse: separate;
    margin-bottom: 64px;
}

.sh-table h2 {
    color: #505e6b;
    font: 400 var(--font-size-heading-large)/38px var(--semi-bold-font);
    margin: 4px 0 0;
}

.sh-table tbody {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
}

.uk-static-page {
    margin-bottom: 0;
}

.sh-table>tbody>tr>td {
    border-top-color: #e6f2ff;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #505e6b;
    padding: 18px 24px;
}

.sh-table>thead>tr>th,
.sh-table>tbody>tr>td {
    text-align: center;
    vertical-align: middle;
}

.sh-table>thead>tr>th {
    font: 400 var(--font-size-heading-medium)/1.67 var(--semi-bold-font);
    color: var(--heading-text-color);
    border-bottom: 0;
    padding: 18px 24px;
}

.sh-table>thead>tr>th:first-child {
    padding-left: 0;
}

.sh-table>thead>tr>th:first-child,
.sh-table>tbody>tr>td:first-child {
    text-align: left;
}

.sh-table>tbody>tr:first-child>td:first-child {
    border-top-left-radius: 8px;
}

.sh-table>tbody>tr:first-child>td:last-child {
    border-top-right-radius: 8px;
}

.sh-table>tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 8px;
}

.sh-table>tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 8px;
}

.about-intro {
    margin-top: 0;
}

.testimonial-outer {
    max-width: 800px;
    text-align: center;
    margin: 50px auto 0;
}

.testimonial-outer img {
    display: inline-block;
}

.testimonial-outer h4 {
    font: italic 400 var(--font-size-heading-medium)/1.33 var(--regular-font);
    color: var(--heading-text-color);
    margin: 28px 0;
}

.testimonial-outer strong {
    font: 400 var(--font-size-heading-medium)/1.33 var(--semi-bold-font);
    font-weight: 400;
    color: var(--blue-gray-color);
    display: block;
    margin-bottom: 4px;
}

.testimonial-outer span {
    font-size: var(--font-size-body-extra-small);
    display: block;
    color: var(--blue-gray-color);
}

.blue-bg-section-bottom {
    background-color: #e6f2ff;
}

.white-btn.blue-btn {
    padding: 10px 34px;
    font-size: var(--font-size-body-small);
}

.uk-static-page .blue-bg-section-bottom p {
    color: var(--heading-text-color);
}

.above-spacing {
    margin-top: 70px;
}

.custom-anchor {
    font: 400 var(--font-size-body-small)/1.3 var(--semi-bold-font);
    display: block;
    color: var(--blue-btn-hover);
    text-decoration: underline;
    margin-top: 24px;
}

.custom-anchor:active,
.custom-anchor:focus {
    color: var(--blue-btn-active);
}

.custom-anchor:hover {
    color: var(--blue-btn-hover);
}

.uk-static-page h3 {
    margin-top: 0;
    color: var(--heading-text-color);
}

@media only screen and (max-width: 1024px) {
    .uk-home-banner {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1250px) {
    .centered-box .rows {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .centered-box .columns-6 {
        grid-column: span 12;
    }

    .centered-box {
        margin-bottom: 30px;
    }

    .centered-box div {
        padding: 0;
    }

    .centered-box img {
        margin-bottom: 20px;
    }

    .centered-box a {
        padding-bottom: 20px;
    }

    .uk-home-banner {
        padding: 0 20px;
    }

    .uk-static-page .holder p.larger-p,
    .fr-intro .larger-title {
        font-size: var(--font-size-body-large);
    }

    .sh-table h2,
    .sh-table>thead>tr>th {
        font-size: var(--font-size-body-medium);
    }

    .sh-table h2 {
        line-height: var(--line-height-sm);
    }

    .sh-table>thead>tr>th,
    .sh-table>tbody>tr>td {
        padding: 10px;
        border-radius: 0 !important;
    }

    .sh-table tbody {
        box-shadow: none;
    }

    .sh-table>thead>tr>th:first-child {
        padding-left: 10px;
    }

    .fr-intro .larger-title {
        margin-bottom: 0;
    }

    .sh-table {
        margin-bottom: 30px;
    }

    .cloud360 .ft-section {
        margin: 0;
    }

    .sh-table>tbody>tr>td {
        font-size: var(--font-size-body-extra-small);
        line-height: var(--line-height-md);
    }

    .testimonial-outer h4 {
        font-size: var(--font-size-body-large);
    }

    .testimonial-outer strong {
        font-size: var(--font-size-heading-small);
    }

    .lost-mode svg g {
        stroke: #0096ff;
    }
}

/* UK - Persona page css ends */

/* Large district css starts */

.cloud360 .holder p.margin-p {
    margin-bottom: 16px;
}

/* Large district css ends */

/* Accessibility css starts */

.new-footer .footer-nav.company-section .web-email {
    color: var(--blue-gray-color) !important;
}

/* Accessibility css ends */

/* Solutions pages css starts */

.blue-color-header {
    background-color: #f2f9ff;
    min-height: 576px;
}

.header-sup {
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: #202b36;
}

.blue-color-header .image-right {
    position: static;
    max-height: 520px;
}

.cloud-page-header-container {
    padding: 28px 0;
}

.blue-color-header .white-btn.blue-btn {
    margin-top: 6px;
}

.light-blue-bg-section {
    background-color: #f7fafd;
}

.product-listing .chromebook-intro {
    margin-bottom: 72px;
}

.product-listing {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: var(--blue-gray-color);
    margin-bottom: 72px;
}

.product-listing a p {
    color: var(--blue-gray-color);
}

.product-listing img {
    margin-bottom: 16px;
}

.slide-link {
    color: var(--blue-btn-hover);
    font-weight: 700;
    display: inline-block;
    line-height: var(--line-height-sm);
}

.slide-link:hover,
.clickable:hover .slide-link,
.box-highlighter a:hover .slide-link {
    text-decoration: none;
}

.slide-link:hover,
.clickable:hover .slide-link,
.box-highlighter a:hover .slide-link,
.slide-link:active,
.slide-link:focus,
.clickable:active .slide-link,
.clickable:focus .slide-link,
.box-highlighter a:active .slide-link,
.box-highlighter a:focus .slide-link {
    color: var(--blue-btn-active);
}

.slide-link:after {
    content: "";
    width: 0;
    height: 1px;
    background-color: var(--blue-btn-hover);
    display: block;
    transition: width 0.3s;
}

.slide-link:hover:after,
.clickable:hover .slide-link:after,
.box-highlighter a:hover .slide-link:after {
    width: 100%;
}

.product-listing .columns-3,
.product-listing .columns-4,
.product-listing .columns-6 {
    margin-bottom: 40px;
}

.highlighter {
    background-color: #f2f9ff;
    padding: 104px 20px;
}

.highlight-box {
    max-width: 1008px;
    margin: 0 auto;
    display: flex;
    align-self: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #505e6b;
}

.highlight-box h4 {
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: var(--heading-text-color);
}

.highlight-box .purple {
    background-color: #9999e5;
}

.highlight-box .blue {
    background-color: #4ea6ff;
}

.highlight-box .orange {
    background-color: #ffe3be;
}

.highlight-box .light-blue {
    background-color: #bedffe;
}

.highlight-box img {
    max-width: 320px;
    max-height: 248px;
}

.highlight-box div:first-child {
    padding: 32px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlight-box div:last-child {
    padding: 66px 32px;
    width: 50%;
}

.page-testimonial {
    max-width: 850px;
    margin: 72px auto 0;
    text-align: center;
    font-size: var(--font-size-heading-medium);
    font-style: italic;
    line-height: 1.33;
    color: var(--blue-gray-color);
}

.page-testimonial img {
    margin-bottom: 24px;
}

.page-testimonial strong:not(.ib-strong) {
    font-size: var(--font-size-body-large);
    font-weight: 600;
    line-height: var(--line-height-lg);
    color: var(--heading-text-color);
    font-style: normal;
    display: block;
    margin-top: 24px;
}

.page-testimonial .small-font {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    font-style: normal;
    color: var(--heading-text-color);
}

.white-btn.blue-btn.styled {
    padding: 10px 24px;
    font-weight: 400;
    font-family: var(--semi-bold-font);
}

.side-by-side {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--blue-gray-color);
}

.side-by-side figure {
    width: 176px;
    margin-right: 32px;
    position: relative;
}

.side-by-side figure img.hover-image,
.regular-nav figure img.hover-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
}

.side-by-side:hover img.hover-image,
.regular-nav .inside-nav a:hover img.hover-image {
    opacity: 1;
}

.side-by-side:hover img.default-image,
.regular-nav .inside-nav a:hover img.default-image {
    opacity: 0;
}

.side-by-side h6 {
    font-size: var(--font-size-body-medium);
    line-height: var(--line-height-lg);
    color: var(--heading-text-color);
    margin: 0 0 8px;
}

.side-by-side p {
    margin-bottom: 24px;
}

.side-by-side .slide-link {
    font-size: var(--font-size-body-small);
}

.medium-rows {
    max-width: 1008px;
    margin: 0 auto;
}

.header-video {
    max-width: 100%;
    max-height: 520px;
}

.clickable {
    display: block;
}

@media screen and (min-width: 1300px) {
    .side-by-side h6 {
        max-width: 56%;
    }

    .side-by-side p {
        max-width: 86%;
    }
}

@media screen and (max-width: 1300px) {
    .more-options .rows {
        display: block;
    }
}

@media screen and (min-width: 1023px) {
    .header-video {
        float: right;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {

    .blue-color-header .rows .columns-5,
    .blue-color-header .rows .columns-7 {
        grid-column: span 12;
    }

    .medium-rows {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media only screen and (min-width: 767px) and (max-width: 866px) {
    .blue-color-header .cloud-page-header-container {
        padding-top: 6.5rem;
    }

    .blue-color-header h1 {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .header-sup {
        margin-top: 30px;
    }

    .blue-color-header {
        padding-bottom: 20px;
    }

    .blue-color-header h1 {
        margin-bottom: 20px;
    }

    .highlight-box {
        display: block;
    }

    .highlighter {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .highlight-box div:first-child,
    .highlight-box div:last-child {
        width: auto;
    }

    .highlight-box div:first-child {
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
    }

    .highlight-box div:last-child {
        padding: 30px 20px;
    }

    .page-testimonial {
        margin-top: 30px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .white-btn.blue-btn.styled {
        font-size: var(--font-size-body-extra-small);
    }

    .product-listing,
    .product-listing .chromebook-intro {
        margin-bottom: 40px;
    }

    .chromebook-intro h2.center-heading {
        margin-bottom: 20px;
        font-size: 28px;
    }

    .product-listing img {
        height: 30px;
    }

    .side-by-side figure {
        width: 140px;
        height: auto;
        margin-right: 14px;
    }

    .highlight-box img {
        max-width: 220px;
    }

    .side-by-side p {
        margin-bottom: 14px;
    }

    .side-by-side figure img {
        height: auto;
    }
}

/* Solutions pages css ends */

/* Solutions menu starts */

.regular-nav>ul>li>a:not(.no-dropdown) {
    position: relative;
}

.regular-nav>ul>li>a:not(.no-dropdown):after {
    content: '';
    display: block;
    position: absolute;
    top: 27px;
    right: -3px;
    width: 8px;
    height: 8px;
    background: transparent;
    border-left: 1px solid var(--body-text-color);
    border-bottom: 1px solid var(--body-text-color);
    transform: rotate(-45deg);
    transition: all .1s ease-in;
}

.regular-nav .nav-col svg {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.regular-nav .products-submenu .nav-col img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    flex-shrink: 0;
    margin-right: 16px;
}

.regular-nav .inside-nav svg circle,
.regular-nav .inside-nav svg path,
.logo-links svg circle,
.logo-links svg path,
.clickable svg circle,
.clickable svg path {
    transition: all 0.1s ease-in;
}

.regular-nav .inside-nav a svg path,
.logo-links a svg path,
.clickable svg path {
    fill: #0073E6;
}

.regular-nav .inside-nav a svg.r-icon path {
    fill: transparent;
}

.regular-nav .inside-nav a:hover svg circle,
.regular-nav .inside-nav a:active svg circle,
.regular-nav .inside-nav a:focus svg circle,
.logo-links a:hover svg circle,
.logo-links a:active svg circle,
.logo-links a:focus svg circle,
.clickable:hover svg circle,
.clickable:active svg circle,
.clickable:focus svg circle {
    fill: #0073E6;
}

.regular-nav .inside-nav a:hover svg path,
.regular-nav .inside-nav a:active svg path,
.regular-nav .inside-nav a:focus svg path,
.regular-nav .inside-nav a:hover svg g circle,
.regular-nav .inside-nav a:active svg g circle,
.regular-nav .inside-nav a:focus svg g circle,
.logo-links a:hover svg path,
.logo-links a:active svg path,
.logo-links a:focus svg path,
.logo-links a:hover svg g circle,
.logo-links a:active svg g circle,
.logo-links a:focus svg g circle,
.clickable:hover svg path,
.clickable:active svg path,
.clickable:focus svg path,
.clickable:hover svg g circle,
.clickable:active svg g circle,
.clickable:focus svg g circle,
.regular-nav .inside-nav a:hover .aichat-icon .circle,
.regular-nav .inside-nav a:hover .aichat-icon .circle,
.regular-nav .inside-nav a:hover .aichat-icon .circle {
    fill: var(--white-color);
}

.regular-nav .inside-nav a:hover svg.r-icon g circle {
    fill: var(--blue-color);
}

.regular-nav .inside-nav a:hover svg.r-icon g g {
    stroke: var(--white-color);
}

.regular-nav .inside-nav a:hover svg.r-icon path {
    fill: transparent;
}

.regular-nav .login-submenu .inside-nav a:active svg g circle,
.regular-nav .login-submenu .inside-nav a:active svg path,
.regular-nav .login-submenu .inside-nav a:focus svg g circle,
.regular-nav .login-submenu .inside-nav a:focus svg path,
.regular-nav .login-submenu .inside-nav a:hover svg g circle,
.regular-nav .login-submenu .inside-nav a:hover svg path {
    fill: transparent;
    stroke: var(--white-color);
    stroke-width: 1.4px;
}

.regular-nav .login-submenu .link-devider .inside-nav li a:hover svg.visitor-svg path {
    fill: var(--white-color);
    stroke: transparent;
}

.regular-nav .arrow-ic {
    display: none;
}

.regular-nav strong {
    transition: all .1s ease-in;
}

.regular-nav .nav-col {
    border-right: 0;
}

.regular-nav ul li .inside-nav a {
    padding: 20px;
}

.regular-nav .menu-note {
    margin: 24px 0 0;
    padding: 24px 0;
    border-radius: 8px;
    background-color: #e6f2ff;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    color: #505e6b;
    text-align: center;
}

.regular-nav .menu-note strong {
    font-weight: 600;
    color: var(--heading-text-color);
}

.regular-nav .menu-note p {
    margin-bottom: 0;
}

.regular-nav .menu-note a {
    display: inline-block;
    padding: 0;
    font-family: var(--regular-font);
    font-size: var(--font-size-body-small);
    font-weight: 600;
    color: var(--blue-color);
}

.regular-nav .menu-note a:hover {
    color: var(--blue-btn-hover);
}

.regular-nav .menu-note a:active,
.regular-nav .menu-note a:focus {
    color: var(--blue-btn-active);
}

.regular-nav ul li .inside-nav a:active,
.regular-nav ul li .inside-nav a:focus,
.regular-nav ul li .inside-nav a:hover {
    background: #f2f9ff !important;
}

.regular-nav ul .section-submenu figure {
    width: 144px;
    height: 96px;
    padding: 16px 21px;
    border-radius: 8px;
    margin-bottom: 16px;
    background-color: #ffe78f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.regular-nav ul .section-submenu figure img {
    max-height: 64px;
    object-fit: contain;
}

.regular-nav ul .section-submenu figure.green {
    background-color: #afefe6;
}

.regular-nav ul .section-submenu figure.red {
    background-color: #fa9da0;
}

.regular-nav ul .section-submenu figure.purple {
    background-color: #c2c5f8;
}

.regular-nav ul .section-submenu figure.blue {
    background-color: #9cf;
}

.regular-nav ul .section-submenu figure.orange {
    background-color: #ffb973;
}

.regular-nav .inside-nav a p,
.regular-nav .inside-nav p {
    line-height: var(--line-height-xl);
}

.regular-nav li.login-nav>a:hover::before {
    content: "";
    width: calc(100% + 140px);
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
}

.regular-nav ul[role="menu"]>li:hover>a:not(.no-dropdown):after {
    border-left: 1px solid var(--blue-color);
    border-bottom: 1px solid var(--blue-color);
}

.regular-nav .nav-col h4 {
    margin-top: 16px;
    font-size: 12px;
}

.regular-nav .square-submenu .nav-col h4 {
    margin: 10px 10px 10px 24px;
    font-size: var(--font-size-body-extra-small);
}

.regular-nav .square-submenu picture {
    min-height: 40px;
}

.regular-nav figure {
    width: 102px;
    position: relative;
}

.logo-links {
    list-style: none;
    margin: 40px 0 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo-links li:not(:first-child) {
    margin-left: 22px;
}

.logo-links svg {
    transform: scale(0.8);
}

.logo-links span {
    display: block;
}

.logo-links a {
    color: var(--heading-text-color);
    display: block;
    font-size: 11px;
    line-height: var(--line-height-md);
    text-align: center;
}

.logo-links a:hover {
    color: var(--blue-color);
}

.logo-links a:active,
.logo-links a:focus {
    color: var(--blue-btn-hover);
}

@media only screen and (min-width: 1276px) {
    .regular-nav ul .login-submenu {
        width: 288px;
        left: -30px;
    }

    .regular-nav .square-submenu .nav-col h4.login-devider {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(99, 116, 132, 0.32);
    }

    .regular-nav ul .submenu-outer .inside-nav {
        padding-bottom: 0;
    }

    .regular-nav ul .submenu-outer {
        padding: 16px;
        border-radius: 0 0 8px 8px;
        white-space: normal;
    }

    .regular-nav ul .school-submenu {
        width: 922px;
        left: -90px;
    }

    .regular-nav ul .products-submenu {
        width: 812px;
        left: -10px;
    }

    .regular-nav ul .section-submenu {
        width: 1244px;
        left: -550px;
    }

    .regular-nav ul .uk-section-submenu {
        left: -310px;
    }

    .regular-nav ul .uk-section-submenu-other {
        left: -426px;
    }

    .regular-nav .block-submenu .nav-col {
        width: 100%;
    }

    .regular-nav .block-submenu .inside-nav {
        display: flex;
    }

    .regular-nav .resources-nav .inside-nav {
        flex-wrap: wrap;
    }

    .regular-nav .block-submenu .nav-col div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
    }

    .regular-nav .block-submenu .nav-col div strong {
        margin-bottom: 4px;
        line-height: var(--line-height-xl);
    }

    .regular-nav .block-submenu .nav-col .menu-note,
    .regular-nav .section-submenu .nav-col div {
        margin-left: 0;
    }

    .regular-nav ul .block-submenu .inside-nav {
        padding-bottom: 0;
    }

    .regular-nav ul li ul li {
        flex: 1 1 0;
    }

    .regular-nav ul li .square-submenu ul li {
        flex: 0;
    }

    .regular-nav ul li .resources-nav ul li {
        flex: 33.33% 0 0;
    }

    .regular-nav ul li .section-submenu .inside-nav a,
    .regular-nav .section-submenu .nav-col div {
        display: block;
    }

    .regular-nav ul li .rounded-nav .inside-nav a {
        display: flex;
    }

    .main-nav ul li .inside-nav a {
        border-radius: 8px;
    }

    .main-nav ul li .square-submenu .inside-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .main-nav ul li .square-submenu .inside-nav a {
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100px;
        height: 90px;
        min-height: inherit;
        padding: 7px;
        color: var(--heading-text-color);
    }

    .main-nav ul li .square-submenu .inside-nav div {
        float: none;
    }

    .main-nav ul li#products .inside-nav a {
        min-height: 111px;
    }

    .main-nav ul li#products .products-submenu .inside-nav a {
        min-height: inherit;
        padding: 8px 12px;
    }

    .login-menu ul .login-submenu {
        width: 910px;
        left: auto;
        right: -254px;
    }

    .login-menu ul .square-submenu {
        /* width: 436px;
        right: -184px; [if 4 rows] */
        width: 534px;
        right: -232px;
        left: auto;
    }

    .login-menu .login-submenu .nav-col {
        width: 33.33%;
        float: left;
    }

    .login-menu .square-submenu .nav-col {
        width: auto;
        float: none;
    }

    .login-menu .login-submenu .link-devider {
        margin: 47px 16px 0 0;
        padding-right: 16px;
        position: relative;
        width: calc(33.33% - 16px);
    }

    .login-menu .login-submenu .link-devider:after {
        content: "";
        width: 1px;
        height: calc(100% + 36px);
        position: absolute;
        top: -36px;
        right: 0;
        border-right: solid 1px rgba(99, 116, 132, 0.32);
    }

    .regular-nav ul .solutions-submenu {
        display: flex !important;
        width: 750px;
        left: -200px;
    }

    .solutions-submenu .inside-nav a {
        flex-direction: column !important;
        align-items: center;
        padding: 15px !important;
    }

    .regular-nav .solutions-submenu .inside-nav a p {
        text-align: center;
        font-size: var(--font-size-body-extra-small);
        color: var(--body-text-color);
    }

    .regular-nav .solutions-submenu .inside-nav a:hover p,
    .regular-nav .solutions-submenu .inside-nav a:focus p {
        color: var(--blue-color);
    }

    .regular-nav .solutions-submenu strong {
        font-weight: bold;
        text-align: center;
        color: var(--body-text-color);
    }

    .solutions-submenu .all-solutions figure {
        width: 150px;
        height: 150px;
        margin-bottom: 30px;
    }

    .solutions-submenu figure {
        width: 82px;
        height: 79px;
        margin-bottom: 10px;
    }

    .solutions-submenu img {
        height: 100%;
    }
}

@media screen and (max-width: 1023px) {
    .content-blue-btn.longer-btn {
        margin-bottom: 0;
    }

    .logo-links {
        margin: 30px 0 20px;
    }

    .below-content {
        margin-top: 20px;
    }
}

@media screen and (max-width: 1275px) {
    .regular-nav>ul>li>a:not(.no-dropdown):after {
        top: 17px;
        right: 24px;
        width: 10px;
        height: 10px;
    }

    .regular-nav>ul>li.active>a:after {
        transform: rotate(136deg);
        top: 23px;
    }

    .regular-nav>ul>li,
    .regular-nav li.border-btn-outer,
    .regular-nav li.login-nav {
        margin-left: 0;
    }

    .regular-nav li.login-nav>a:hover::before {
        display: none;
    }

    .regular-nav>ul>li.border-btn-outer {
        margin-left: 24px;
        margin-right: 24px;
    }

    .regular-nav .nav-col svg {
        transform: scale(0.64);
    }

    .regular-nav ul li .inside-nav a {
        height: 48px;
    }

    .regular-nav ul li .school-submenu .inside-nav a {
        padding: 24px 18px;
    }

    .regular-nav ul li .products-submenu .inside-nav a {
        padding: 10px 20px;
    }

    .regular-nav .products-submenu .nav-col img,
    .regular-nav .square-submenu .nav-col img {
        width: 22px;
        height: 22px;
        margin-right: 12px;
        object-fit: contain;
    }

    .regular-nav .square-submenu picture {
        min-height: 22px;
    }

    .regular-nav .home-submenu .inside-nav a {
        padding: 0 24px;
        height: 72px;
    }

    .global-menu .products-submenu .menu-title {
        font-size: 15px;
        margin: 16px 0 16px 20px;
    }

    .regular-nav .products-submenu strong {
        font-size: var(--font-size-body-extra-small);
        margin-bottom: 2px;
    }

    .regular-nav .products-submenu .external-link {
        margin-top: 30px;
    }

    .products-submenu .inside-nav a p,
    .products-submenu .inside-nav p {
        font-size: 12px;
        display: block;
    }

    .regular-nav .products-submenu .inside-nav a {
        align-items: flex-start;
        height: 54px;
    }

    .regular-nav .home-submenu .inside-nav br {
        display: none;
    }

    .regular-nav figure {
        margin-right: 16px;
        width: 60px;
    }

    .regular-nav .inside-nav svg {
        margin-right: 6px;
    }

    .regular-nav .inside-nav a {
        align-items: center;
        margin-top: 0;
    }

    .regular-nav .inside-nav div,
    .regular-nav .inside-nav strong {
        margin: 0;
    }

    .regular-nav .section-submenu .inside-nav a {
        padding: 0 24px;
        height: 80px;
    }

    .regular-nav ul .section-submenu figure {
        width: 84px;
        height: 56px;
        margin: 0 16px 0 0;
        padding: 10px;
    }

    .regular-nav .section-submenu .inside-nav img {
        width: 54px;
    }

    .regular-nav .mobile-border-0 li:first-child a,
    .regular-nav .school-submenu .nav-col:nth-child(3) li:first-child a {
        border-top: 0;
    }

    .regular-nav .menu-note {
        margin: 24px;
        padding: 24px;
        text-align: left;
        font-size: 12px;
    }

    .regular-nav .menu-note strong {
        margin-bottom: 2px;
    }

    .regular-nav .menu-note strong,
    .regular-nav .menu-note a {
        display: block;
        font-size: var(--font-size-body-small);
    }

    .regular-nav ul li:hover .slide-link {
        color: var(--blue-color) !important;
    }

    .regular-nav .menu-note a {
        margin-top: 18px;
    }

    .regular-nav .menu-note a:after {
        display: none;
    }

    .regular-nav .submenu-outer {
        max-height: 242px;
    }

    .regular-nav .section-submenu .inside-nav p {
        display: block;
        font-size: 11px;
        line-height: var(--line-height-md);
        margin-top: 4px;
    }

    .regular-nav ul>li>a,
    .global-menu>ul>li.top-search {
        border-bottom: 1px solid #d7dde4;
    }

    .login-menu ul li .link-devider .inside-nav li:first-child a {
        border-top: 0;
    }

    .solutions-submenu figure {
        width: 45px;
        height: 42px;
    }

    .solutions-submenu a {
        height: 80px !important;
    }

    .solutions-submenu .all-solutions figure {
        width: 45px;
        height: 45px;
    }

    .nav-col.other-solutions {
        width: 100%;
    }

    .nav-col.all-solutions {
        width: 100%;
    }

    .all-solutions .inside-nav p,
    .other-solutions .inside-nav p {
        display: inline;
    }

    .other-solutions .inside-nav p {
        display: block;
        color: var(--heading-text-color);
        font-family: var(--semi-bold-font);
        font-size: var(--font-size-body-small);
        line-height: var(--line-height-sm);
        font-weight: 400;
    }

    .regular-nav .square-submenu .nav-col h4 {
        margin-top: 20px;
    }

    .regular-nav ul li .square-submenu .inside-nav a {
        height: 30px;
    }

    .regular-nav .square-submenu .inside-nav a p,
    .regular-nav .square-submenu .inside-nav p {
        line-height: 1;
    }
}

@media screen and (max-width: 767px) {
    .logo-links {
        margin: 20px 0 0;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px 30px;
    }

    .logo-links li {
        margin-top: 22px;
    }

    .logo-links li:not(:first-child) {
        margin-left: 0;
    }

    .content-blue-btn.longer-btn {
        padding-right: 14px;
        padding-left: 14px;
        line-height: var(--line-height-md);
    }
}

/* Solutions menu ends */

/* Home selection css starts */

.home-content {
    max-width: 816px;
    margin: 0 auto;
    padding: 0 32px;
    text-align: center;
}

.home-content img {
    margin-top: 64px;
}

.home-content p {
    margin: 24px auto 40px;
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: var(--blue-gray-color);
    max-width: 80%;
}

.short-hr {
    margin: 0 auto 40px;
    width: 200px;
    height: 1px;
    border-top: 1px solid #e2ebf5 !important;
}

.home-box {
    margin-bottom: 60px;
}

.home-box .box-title {
    font-family: var(--semi-bold-font);
}

.home-box .rows {
    max-width: 840px;
    padding: 0 20px;
}

.home-bottom .footer-app-banner {
    background: linear-gradient(90deg, rgba(38, 147, 255, 1) 0%, rgba(0, 115, 230, 1) 100%);
    background-color: rgba(0, 115, 230, 1);
    max-width: 800px;
    margin: 0 auto 80px;
    border-radius: 8px;
}

.home-bottom .banner-text-c {
    padding: 0;
}

.home-bottom .align-image {
    align-self: flex-end;
    justify-self: flex-end;
}

.home-bottom .align-image img {
    margin: -38px 10px 0 0;

}

.home-bottom .footer-app-banner h2 {
    font-size: var(--font-size-heading-medium);
    line-height: 1.25;
    margin: 10px 50px 10px 0;
}

.home-bottom .download-apps {
    margin-top: 24px;
}

.home-bottom h2~p {
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
}

@media screen and (max-width: 1200px) {
    .home-bottom .footer-app-banner .banner-text {
        margin-left: 0;
    }
}

@media screen and (max-width: 1024px) {
    .home-bottom .footer-app-banner .banner-text-c {
        padding: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .home-bottom .rows {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .home-bottom .align-image {
        order: 2;
        justify-self: center;
        margin-top: 30px;
    }

    .home-bottom .footer-app-banner {
        margin-bottom: 0;
        border-radius: 0;
    }

    .home-content p,
    .short-hr {
        max-width: inherit;
        margin-bottom: 20px;
    }

    .securlyhomeselection .header-section {
        min-height: inherit;
        margin-top: 40px;
    }

    .home-box {
        margin-bottom: 10px;
    }

    .home-bottom .footer-app-banner h2 {
        margin-right: 0;
    }

    .home-box .rows {
        padding: 0;
    }
}

@media only screen and (max-width: 320px) {
    .home-bottom .download-apps a:first-child {
        margin-right: 8px;
    }
}

/* Home selection css ends */

/* New home updates css starts */

.index-intro {
    margin-bottom: 72px;
}

.index-solutions-listing .side-by-side {
    display: block;
}

.index-solutions-listing .side-by-side figure {
    width: 112px;
}

.index-solutions-listing .side-by-side h6 {
    font-size: var(--font-size-body-small);
}

.centered-btn {
    margin: 70px auto 100px;
    text-align: center;
}

.index-products-listing {
    background-color: #f2f9ff;
    padding: 104px 0;
}

.index-products-listing .center-heading {
    text-align: center;
    margin-bottom: 72px;
}

.index-products-listing svg {
    flex-shrink: 0;
    margin-right: 24px;
}

.home-stats.updated-stats {
    clip-path: none;
}

.index-products-listing {
    padding-bottom: 50px;
}

.index-products-listing .product-listing {
    margin-bottom: 0;
}

.only-heading {
    text-align: center;
    margin: 104px auto;
    padding: 0 20px;
}

.index-solutions-listing .columns-3 {
    position: relative;
    padding-bottom: 20px;
}

.index-solutions-listing .slide-link {
    position: absolute;
    bottom: 0;
}

@media only screen and (min-width: 1300px) {
    .index-hero .header-width {
        max-width: 90%;
    }

    .index-solutions-listing .side-by-side h6,
    .index-solutions-listing .side-by-side p {
        max-width: inherit;
    }

    .only-heading {
        margin-right: auto;
        margin-left: auto;
        max-width: 40%;
    }
}


@media only screen and (max-width: 1090px) {
    .home-stats h2:not(.statsTitle) {
        font-size: 54px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .index-solutions-listing .rows {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media only screen and (max-width: 767px) {

    .index-solutions-listing .columns-3,
    .index-products-listing .center-heading {
        margin-bottom: 30px;
    }

    .centered-btn {
        margin-top: 0;
    }

    .only-heading {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .index-products-listing {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .index-products-listing .columns-4 {
        margin-bottom: 20px;
    }

    .index-products-listing svg {
        margin-right: 0;
    }

    .side-by-side {
        display: block;
        text-align: center;
    }

    .side-by-side figure {
        margin: 0 auto;
    }

    .index-solutions-listing .slide-link {
        position: static;
    }

    .product-listing {
        text-align: center;
    }

    .section-title.school-heading {
        font-size: 30px;
        margin-bottom: 30px;
    }
}

/* New home updates css ends */

/* News page css starts */
@media screen and (max-width: 992px) and (min-width: 767px) {
    .img-v-center .tabs li {
        margin-right: 16px;
    }

    .img-v-center .tabs li,
    .img-v-center .tabs li a {
        display: inline-block;
    }
}

@media only screen and (min-width: 768px) {
    .img-v-center .col-sm-12 {
        position: relative;
    }

    .img-v-center img {
        margin-bottom: 0;
    }

    .img-v-center .col-sm-3 {
        position: absolute;
        top: 40%;
        right: 0;
        transform: translateY(-50%);
    }
}

/* News page css ends */

/* Partners page css starts */
.partners-full-section .form-container {
    width: 592px;
    margin-bottom: 104px;
}

.partners-full-section .demo-container h1 {
    line-height: 1;
    margin-bottom: 8px;
}

.partners-full-section .demo-container .small-text {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: #202b36;
}

@media only screen and (max-width: 1300px) {
    .partners-full-section .form-container {
        width: 440px;
    }
}

@media only screen and (max-width: 992px) {
    .partners-full-section .form-container {
        width: auto;
        margin-bottom: 40px;
    }
}

/* Partners page css ends */

/* SEL page css starts */
.box-highlighter {
    max-width: 1008px;
    margin: 104px auto;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
}

.box-highlighter .rows {
    margin: 0 7.7777777778%;
}

.box-highlighter h2 {
    text-align: center;
    margin-bottom: 48px;
}

.box-highlighter p {
    margin-bottom: 24px;
}

.box-highlighter a {
    color: #505e6b;
    display: block;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgba(176, 204, 235, 0.5);
    background-color: var(--white-color);
}

.box-highlighter a:hover,
.box-highlighter a:focus {
    box-shadow: 0 2px 2px 0 rgb(56 73 89 / 5%), 0 4px 4px 0 rgb(56 73 89 / 5%), 0 8px 8px 0 rgb(56 73 89 / 5%), 0 16px 16px 0 rgb(56 73 89 / 5%), 0 32px 32px 0 rgb(56 73 89 / 5%);
}

.box-highlighter img {
    display: inline-block;
    margin-bottom: 16px;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .box-highlighter .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 32px;
    }
}

@media only screen and (max-width: 767px) {
    .box-highlighter {
        margin: 40px 0 0;
    }

    .box-highlighter a {
        margin-bottom: 30px;
    }

    .besides-btn {
        margin-right: 10px;
    }

    .box-highlighter h2 {
        text-align: center;
        margin: 0 20px 30px;
    }
}

/* SEL page css ends */

/* Support page css starts */
.blue-color-header .header-h3 {
    margin-bottom: 20px;
}

.page-carousel {
    margin: 104px auto 130px;
}

.page-carousel strong {
    margin-bottom: 16px;
}

.page-testimonials-slider .slick-dots li button:before {
    background-color: #d2e4f7;
}

.page-testimonials-slider .slick-dots li.slick-active button:before {
    background-color: var(--blue-color);
}

.page-carousel img {
    margin-bottom: 0;
}

@media only screen and (max-width: 1024px) {
    .blue-color-header .header-h3 {
        text-align: center;
    }
}

@media only screen and (max-width: 992px) {
    .page-carousel {
        margin: 72px auto;
        padding: 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .page-carousel {
        font-size: var(--font-size-body-large);
    }

    .page-carousel strong {
        font-size: var(--font-size-body-medium);
    }

    .page-carousel img {
        margin-bottom: 20px;
    }

    .blue-color-header {
        min-height: inherit;
    }
}

/* Support page css ends */

/* Middle east website starts */
.arabic-site .securlyhome h1~p,
.arabic-site .chromebook-intro p,
.arabic-site .home-stats h5,
.arabic-site .home-stats .h5-heading,
.arabic-site .uk-static-page .holder p.larger-p,
.arabic-site .uk-static-page .holder p,
.arabic-site .grouping-text h4,
.arabic-site .grouping-text h5,
.arabic-site .fr-intro .larger-title,
.arabic-site .sh-table>tbody>tr>td {
    /* font-family: "Conv_Geeza-Pro-Regular", MetropolisRegular, sans-serif; */
    direction: rtl;
    unicode-bidi: plaintext;
}

.arabic-site .chromebook-intro h2 p,
.arabic-site .statsTitle,
.arabic-site .cloud360 h3,
.arabic-site .home-stats h2:not(.statsTitle),
.arabic-site .sh-table h2,
.arabic-site .white-btn.blue-btn {
    /* font-family: "Conv_Geeza-Pro-Bold", MetropolisRegular, sans-serif; */
    font-weight: 700;
    direction: rtl;
}

.arabic-site .chromebook-intro h2 p,
.arabic-site .statsTitle,
.arabic-site .sh-table h2 {
    direction: ltr;
}

.arabic-site .home-stats h5,
.arabic-site .home-stats .h5-heading {
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-lg);
}

.arabic-site .cloud360 .content-right,
.arabic-site .cloud360 h3,
.arabic-site .grouping-text h4,
.arabic-site .static-pages .holder p {
    text-align: right;
}

.arabic-site .grouping-text img {
    margin-left: 16px;
    margin-right: 0;
}

.arabic-site .static-pages .holder .grouping-text .columns-4 p {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
}

.arabic-site .grouping-text h5 {
    font-size: var(--font-size-body-large);
}

.arabic-site .sh-table>tbody>tr>td:first-child {
    text-align: right;
}

/* Middle east website ends */

/* Visitor page css starts */
.video-holder {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    text-align: center;
}

.video-holder iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 16px;
    box-shadow: 0 1px 1px 0 rgba(112, 126, 140, 0.05), 0 3px 3px 0 rgba(112, 126, 140, 0.05), 0 5px 5px 0 rgba(112, 126, 140, 0.05), 0 11px 11px 0 rgba(112, 126, 140, 0.05), 0 22px 22px 0 rgba(112, 126, 140, 0.05);
}

.introduction-container {
    background-color: #F7FAFC;
    padding: 104px 0 84px;
    margin-top: 104px;
}

.introduction-container .chromebook-intro {
    margin-block: 0 40px;
}

.introduction-container .page-testimonial {
    margin-top: 80px;
}

.withoutacc .image-align.top-align-image {
    align-items: flex-start;
}

.withoutacc .image-align.bottom-align-image {
    align-items: flex-end;
}

@media only screen and (min-width: 768px) {
    .video-width {
        width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 767px) {
    .withoutacc .formobile .columns-6 img.no-bottom-spacing {
        padding-bottom: 0;
    }
}

/* Visitor page css ends */

/* Notify page css starts */
.notify .chromebook-header-without-bg h1 {
    font-size: 54px;
}

.notify .chromebook-header-without-bg h3 {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-lg);
}

.notify .static-pages .holder p,
.notify .static-pages ul.section-list {
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
}

.notify .static-pages ul.section-list {
    margin-top: 8px;
    padding-left: 32px;
}

.notify .static-pages ul.section-list.strong {
    font-family: var(--semi-bold-font);
    font-weight: 500;
}

.notify .static-pages .holder p.larger-font {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: var(--heading-text-color);
    margin-bottom: 16px;
}

.notify .static-pages .holder.bottom-bg p:not(.larger-font) {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
}

@media only screen and (max-width: 767px) {
    .notify .static-pages ul.section-list {
        list-style-position: inside;
    }

    .notify .chromebook-header-without-bg h1 {
        font-size: 38px;
    }
}

/* Notify page css ends */

/* Additional-section css starts */
.static-pages .holder .additional-section {
    border-top: 1px solid var(--blue-gray-color);
    padding-top: 24px;
    margin-top: 24px;
}

.static-pages .holder .additional-section p {
    font-size: var(--font-size-body-extra-small);
    font-style: italic;
    line-height: 1.57;
    color: var(--blue-gray-color);
    margin-bottom: 16px;
}

.static-pages .holder .additional-section strong {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--body-text-color);
    display: block;
}

.static-pages .holder .additional-section span {
    font-size: 11px;
    line-height: 1.73;
    color: #505e6b;
    display: block;
}

.slide-link.margin-top-20 {
    margin-top: 20px;
}

.page-testimonial-wrapper {
    background-color: #f2f9ff;
}

.page-testimonial-wrapper .page-carousel {
    padding: 104px 0 130px;
    margin: 0 auto;
}

.inline-btns {
    margin-top: 24px;
}

.inline-btns .longer-btn {
    margin: 0 0 0 20px;
}

@media only screen and (min-width: 767px) and (max-width: 1100px) {
    .bottom-container .inline-btns a {
        display: block;
        text-align: center;
        margin: 20px 0 0;
    }
}

@media only screen and (max-width: 992px) {
    .page-testimonial-wrapper .page-carousel {
        padding: 72px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .inline-btns .longer-btn {
        padding: 12px 40px;
        line-height: 1;
    }
}

@media only screen and (max-width: 550px) {

    .inline-btns .last-section-blue-btn,
    .inline-btns .longer-btn {
        display: block;
    }

    .inline-btns .longer-btn {
        margin: 20px 0 0;
    }
}

/* Additional-section css ends */

/* Events page css starts */
.default-header.page-header {
    background: #f2f9ff;
    margin: 0;
    min-height: 292px;
}

.default-header.page-header h1 {
    font-size: 48px
}

.default-header.page-header h3 {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-lg);
    color: #505e6b;
    margin: 6px auto 0;
    max-width: 840px;
    padding: 0 20px;
}

.events-section {
    padding: 104px 0 80px;
}

.events-section h2 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    text-align: center;
    margin: 0 0 48px;
}

.events-section .highlight-box {
    box-shadow: 0 2px 8px 0 rgba(176, 204, 235, 0.5);
}

.events-section .highlight-box span {
    border-radius: 20px;
    background-color: #bbfaec;
    font: 400 var(--font-size-body-extra-small)/1.4 var(--semi-bold-font);
    color: var(--heading-text-color);
    padding: 7px 22px 5px 32px;
    display: inline-block;
    border-radius: 0 32px 32px 0;
    margin-left: -32px;
}

.events-section .highlight-box small {
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-md);
    color: var(--blue-gray-color);
    display: block;
    margin-top: 10px;
}

.events-section .highlight-box div:last-child {
    padding: 32px;
    min-height: 316px;
}

.events-container {
    background-color: #f7fafc;
}

.events-container h3 {
    font: 400 var(--font-size-heading-large)/1.25 var(--semi-bold-font);
    color: var(--heading-text-color);
    max-width: 800px;
    margin: 0 auto 64px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}

.events-details-section .customized-tabs {
    margin-bottom: 0;
}

.events-container {
    padding: 88px 0 40px;
}

.events-container .box-grid .columns-3 {
    display: flex;
    flex-direction: column;
}

.events-container .box-grid a div {
    padding: 0 24px;
}

.events-container .box-grid a {
    position: relative;
    box-shadow: 0 1px 2px 0 #dae5ee;
    color: #505e6b;
    padding-bottom: 26px;
    margin-top: 64px;
    margin-bottom: 0;
    flex: 1 0 auto;
}

.events-container .box-grid a:focus,
.events-container .box-grid a:hover {
    box-shadow: 0 2px 2px 0 rgba(202, 224, 246, 0.05), 0 4px 4px 0 rgba(56, 73, 89, 0.05), 0 8px 8px 0 rgba(56, 73, 89, 0.05), 0 16px 16px 0 rgba(56, 73, 89, 0.05), 0 32px 32px 0 rgba(56, 73, 89, 0.05);
}

.events-container .box-grid a img {
    min-height: 180px;
    max-height: 180px;
    height: 100%;
}

.events-container .slide-link {
    font: 400 var(--font-size-body-small)/1.1 var(--semi-bold-font);
    color: var(--blue-color);
}

.events-container .box-grid small {
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-sm);
    color: var(--blue-gray-color);
}

.events-container .i-e-grid p {
    min-height: inherit;
}

.events-container .box-grid h2 {
    font-family: var(--regular-font);
    margin-bottom: 8px;
}

.events-container .box-grid a:after {
    background-image: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/box-caret-play.svg');
    content: "";
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    display: block;
    z-index: 2;
}

.events-slider-container {
    max-width: 1008px;
    margin: 0 auto;
    overflow: hidden;
}

.events-section .slick-initialized .slick-slide {
    display: flex;
    margin: 0 20px;
}

.events-section .slick-list {
    overflow: visible;
}

.events-section .slick-dotted.slick-slider {
    margin-top: 0;
    margin-bottom: 70px;
}

.events-section .slick-dots {
    bottom: -70px;
}

.events-section .slick-track {
    top: 6px;
}

.events-section .slick-dots li button:before {
    background-color: #d2e4f7;
}

.events-section .slick-dots li.slick-active button:before {
    background-color: #778b9e;
}

.events-section .slick-slide img {
    display: block;
}

.events-slider-container .highlight-box .sv-img {
    padding: 0;
}

.events-slider-container .highlight-box .sv-img img {
    max-width: 100%;
    max-height: 360px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.events-container .i-e-grid a {
    color: var(--blue-gray-color);
}

.events-container .i-e-grid figure {
    background-color: #4da6ff;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.events-container .i-e-grid figure img {
    width: 100%;
    margin-bottom: 0;
}

.events-container .i-e-grid a:after {
    display: none;
}

.events-container .i-e-grid h2 {
    border-bottom: 1px solid var(--light-blue-btn-hover);
    padding-bottom: 6px;
}

.events-slider-container .i-e-slider .highlight-box .sv-img {
    background-color: #fff199;
}

/* .events-slider-container .i-e-slider .highlight-box .sv-img img {
    max-width: 250px;
    max-height: 76px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: inherit;
    object-fit: contain;
} */

.i-e-slider .highlight-box h4 {
    border-bottom: 1px solid var(--light-blue-btn-hover);
    padding-bottom: 6px;
}

.i-e-slider .highlight-box p {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    color: var(--blue-gray-color);
    margin-bottom: 16px;
}

.events-details-section .tab-pane {
    display: none;
}

.events-details-section .tab-pane.active {
    display: block;
}

.events-details-section .tab-pane> :last-child> :last-child> :last-child {
    margin-bottom: 64px;
}

@media only screen and (max-width: 767px) {
    .default-header.page-header h1 {
        font-size: 30px;
        line-height: var(--line-height-sm);
    }

    .events-section .slick-initialized .slick-slide {
        flex-direction: column;
    }

    .default-header.page-header {
        min-height: 120px;
        padding-bottom: 30px;
    }

    .events-section {
        padding: 40px 0 20px;
    }

    .events-container {
        padding: 40px 0 0;
    }

    .events-section h2,
    .events-container h3 {
        font-size: var(--font-size-heading-medium);
        margin: 0 20px 30px;
    }

    .events-container .box-grid a,
    .events-details-section .tab-pane> :last-child> :last-child> :last-child {
        margin-top: 40px;
    }

    .events-container .box-grid a {
        height: inherit !important;
    }

    .default-header.page-header h3 {
        font-size: var(--font-size-body-medium);
        line-height: var(--line-height-md);
        margin-top: 10px;
    }

    .events-section .slick-initialized .slick-slide {
        margin: 0 20px;
    }

    .events-slider-container .highlight-box .sv-img img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        max-height: 200px;
    }

    .events-slider-container .i-e-slider .highlight-box .sv-img {
        min-height: 200px;
    }

    /* .events-slider-container .i-e-slider .highlight-box .sv-img img {
        max-width: 200px;
        max-height: 66px;
    } */

    .events-section .highlight-box div:last-child {
        padding: 24px;
        min-height: inherit;
    }

    .events-section .highlight-box h4 {
        font-size: var(--font-size-body-large);
    }

    .events-section .highlight-box span {
        font-size: 11px;
        margin-left: -24px;
        padding-left: 24px;
    }

    .events-details-section .customized-tabs {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 520px) {
    .events-details-section .customized-tabs li {
        margin-right: 0;
        margin-top: 6px;
        width: 100%;
        display: block;
    }
}

/* Events page css ends */

/* Wellness popup css starts */
.popup-book {
    background: 0 0 / cover no-repeat url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/popup-design1.webp");
}

.bubble-design {
    background: 0 0 / cover no-repeat url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/popup-design3.webp");
}

.light-blue-design {
    background: 0 0 / cover no-repeat url("https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/popup-design2.webp");
}

.popup-book .inner-popup-container {
    align-items: center;
}

.popup-book .inner-popup-container h2 {
    font: 400 36px/1.16 var(--semi-bold-font);
    color: var(--white-color);
    margin-top: 160px;
}

.bubble-design .inner-popup-container h2 {
    margin-top: 120px;
}

.popup-book .inner-popup-container h3 {
    font: 400 28px/1.32 var(--regular-font);
    color: var(--white-color);
    margin-top: 20px;
}

.light-blue-design .inner-popup-container h3 {
    font-size: var(--font-size-heading-medium);
    margin-bottom: 0;
}

.light-blue-design .inner-popup-container h2,
.light-blue-design .inner-popup-container h3 {
    color: var(--heading-text-color);
}

.light-blue-design .inner-popup-container h2 {
    font-size: var(--font-size-heading-large);
    margin-top: 30px;
}

.popup-book .inner-popup-container p {
    margin-bottom: 8px;
}

.popup-book .white-btn.blue-btn {
    font-family: var(--semi-bold-font);
    font-weight: 400;
    background-color: var(--white-color);
    border: 2px solid var(--blue-color);
    color: var(--blue-color);
    padding: 10px 40px;
    font-size: var(--font-size-body-medium);
    margin-top: 22px;
}

.popup-book .white-btn.blue-btn:hover {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
    border-color: var(--blue-btn-hover);
}

.popup-book .white-btn.blue-btn:active,
.popup-book .white-btn.blue-btn:focus {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
    border-color: var(--blue-btn-active);
}

.light-blue-design .white-btn.blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.popup-book .inner-popup-container img {
    width: 288px;
    margin: 0 0 0 10px;
}

.bubble-design .inner-popup-container img {
    width: 317px;
    margin: 110px 0 0 20px;
}

.light-blue-design .inner-popup-container img {
    width: 323px;
    margin: 0 0 0 20px;
}

.inner-popup-container div {
    width: 56%;
    margin: 0 0 0 54px;
}

.light-blue-design .inner-popup-container div {
    margin: 0 0 0 34px;
}

@media only screen and (max-width: 767px) {
    .popup-book {
        background-position: top center;
    }

    .popup-book .inner-popup-container img {
        display: none;
    }

    .popup-book .inner-popup-container h2 {
        margin-top: 150px;
        margin-bottom: 20px;
        font-size: var(--font-size-heading-small);
        line-height: var(--line-height-md);
    }

    .light-blue-design .inner-popup-container h2 {
        margin-top: 80px;
    }

    .bubble-design .inner-popup-container h2 {
        margin-top: 110px;
    }

    .popup-book .inner-popup-container h3 {
        font-size: var(--font-size-body-small);
        line-height: var(--line-height-md);
    }

    .inner-popup-container div {
        width: 80%;
        margin: 0 auto;
        text-align: left;
    }

    .popup-book .white-btn.blue-btn {
        margin-bottom: 40px;
        padding: 8px 24px;
        font-size: var(--font-size-body-small);
        margin-top: 22px;
    }
}

/* Wellness popup css ends */

/*UK case study styles start */

.case-study-page .bullets-block p {
    margin: 0;
}

.case-study-page .holder.case-study-intro {
    padding-top: 170px;
    background-color: #f7fafd;
}

.case-study-page .holder.blue-bg {
    background-color: #e6f2ff;
    padding: 50px 0 20px;
}

.case-study-page .holder.blue-bg .sub-heading {
    margin-top: 0;
}

.case-study-page .holder.blue-bg .columns-6 {
    margin: auto;
}

.case-study-page p,
.case-study-page ul {
    font-size: var(--font-size-body-extra-small);
    color: var(--body-text-color);
}

.case-study-page ul {
    padding-left: 14px;
}

.case-study-intro .center-heading {
    margin-top: 54px;
}

.case-study-page .mb-24 {
    margin-bottom: 24px;
}

.case-studies-read-more-block .grid-item-centered a,
.case-study-page .box-grid span {
    color: var(--blue-color);
}

.case-studies-read-more-block h3 {
    font-size: var(--font-size-heading-extra-large);
}

.case-studies-read-more-block {
    padding-top: 100px;
    padding-bottom: 100px;
}

.case-studies-read-more-block .box-grid.columns-8 {
    grid-column: 2/12;
}

.case-study-page .box-grid a:hover span,
.case-studies-read-more-block .grid-item-centered a:hover {
    color: var(--blue-btn-hover);
}


.case-studies-read-more-block .grid-item-centered a {
    font-size: var(--font-size-body-small);
    font-weight: bold;
}

.box-grid .case-study-cards-bottom {
    position: relative;
}

.case-study-cards-bottom span {
    position: absolute;
    bottom: 20px;
}

.case-study-page .box-grid span {
    font-size: var(--font-size-body-small);
    font-weight: bold;
    padding: 0 24px;
}

.case-study-page .holder {
    padding: 96px 0;
}

.case-study-page .colored-block {
    background-color: rgba(0, 115, 230, 0.05);
    border-radius: 8px;
    padding: 42px;
}

.case-study-page .colored-block p {
    margin: 0;
}

.case-study-page .colored-block .sub-heading,
.case-study-page .bullets-block .sub-heading {
    margin-bottom: 24px;
}

.case-study-page .colored-block.light-green-bg {
    background-color: rgba(0, 191, 147, 0.05);
}

.case-study-page .static-pages {
    margin-bottom: 0px;
}

.case-study-page .d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.case-study-page p.grey-text {
    color: var(--blue-gray-color);
    margin-bottom: 0;
}


@media screen and (min-width: 1600px) {
    .case-studies-read-more-block .box-grid.columns-8 {
        grid-column: 3/11;
    }
}

@media screen and (min-width: 1200px) {
    .case-study-page .cs-grid-gap-93 {
        grid-gap: 93px;
    }
}

@media screen and (max-width: 1400px) {

    .case-studies-read-more-block .box-grid.columns-8 {
        grid-column: span 12;
    }

    .case-study-page .columns-8.box-grid .rows {
        grid-template-columns: repeat(9, 1fr);
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .bubble-design .inner-popup-container h2 {
        margin-top: 90px;
        font-size: 30px;
    }

    .light-blue-design .inner-popup-container h2 {
        font-size: 30px;
    }

    .bubble-design .inner-popup-container h3,
    .light-blue-design .inner-popup-container h3 {
        font-size: var(--font-size-heading-small);
    }

    .bubble-design .inner-popup-container img {
        margin-top: 140px;
    }

    .light-blue-design {
        padding-right: 30px;
        padding-left: 30px;
    }

    .case-study-page .holder {
        padding: 26px 0;
    }

    .case-study-page .rows .columns-6 {
        grid-column: span 9;
    }

    .case-study-intro {
        text-align: center;
        padding-top: 20px;
    }

    .case-studies-read-more-block {
        grid-column: span 9;
    }

    .case-studies-read-more-block .box-grid.columns-8 {
        grid-column: span 12;
    }


}

@media screen and (max-width: 767px) {
    .case-study-page .holder {
        padding: 46px 0;
    }

    .case-study-page .cs-grid-gap-93 {
        grid-gap: 20px;
    }

    .case-studies-read-more-block .box-grid.columns-8 {
        grid-column: span 4;
    }

    .case-study-page .holder.case-study-intro {
        text-align: center;
        padding-top: 100px;
    }

    .case-study-intro .center-heading {
        margin-top: 24px;
    }

    .case-studies-read-more-block h3 {
        font-size: var(--font-size-heading-medium);
        line-height: 1.25;
    }

    .case-studies-read-more-block {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .box-grid .rows .columns-4 {
        grid-column: span 12;
    }

    .case-study-page .box-grid {
        margin: 20px 0 0 0;
    }
}

/* UK case study styles ends */

/* New global menu css starts */
.top-search input {
    height: 32px !important;
    padding: 6px 12px 6px 34px !important;
    border-radius: 4px;
    background-color: #f2f9ff;
    width: 150px !important;
    box-shadow: none;
    border: 0;
    font: 400 var(--font-size-body-extra-small)/1 var(--regular-font);
    color: var(--heading-text-color);
    margin: 0 0 0 8px;
}

.top-search input.white-input {
    background-color: #f2f9ff !important;
}

.top-search:focus-within svg path {
    fill: var(--blue-color);
}

.top-search form {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.top-search img,
.top-search svg {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
    display: none;
}

.gsc-input-box {
    border: 0 !important;
    background: transparent !important;
}

table.gsc-search-box td.gsc-input {
    padding-right: 0 !important;
}

.gsc-search-button,
.gsib_b,
.gssb_e {
    display: none;
}

.gsc-input-box,
.gsib_a {
    padding: 0 !important;
}

.global-menu>ul>li.top-search {
    position: relative;
}

.search-results {
    margin-top: 140px;
    margin-bottom: 60px;
}

.search-results-top {
    border-bottom: 1px solid #dae5ee;
}

.search-results-top h1 {
    font-size: var(--font-size-heading-extra-large);
    line-height: var(--line-height-sm);
}

.gsc-result-info {
    font: 400 var(--font-size-heading-medium)/1.3 var(--semi-bold-font) !important;
    color: var(--heading-text-color) !important;
}

.gsc-control-cse {
    margin: 0 78px !important;
}

.gsc-expansionArea .gsc-webResult.gsc-result {
    border-bottom: 1px solid #dae5ee !important;
}

.gcsc-more-maybe-branding-root {
    display: none !important;
}

.gsc-results .gsc-cursor-box {
    margin-top: 60px !important;
    font-size: var(--font-size-body-small) !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page {
    margin-right: 30px !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page {
    color: #505e6b !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    color: var(--blue-color) !important;
}

.gsc-webResult.gsc-result {
    padding: 20px 0 !important;
}

@media only screen and (min-width: 1276px) {
    .global-menu ul>li:hover>.submenu-outer {
        border-top: 2px solid var(--blue-color);
    }

    .global-menu>ul>li>a:not(.no-dropdown):after {
        display: none;
    }

    .global-menu>ul>li>a:hover:not(.no-dropdown):after,
    .global-menu ul li:hover a:not(.no-dropdown):after {
        display: block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid var(--blue-color);
        transform: translateX(-50%);
        transition: all .1s ease-in;
        top: auto;
        bottom: 0;
        left: 50%;
    }

    .global-menu ul[role=menu]>li:hover>a:not(.no-dropdown):after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid var(--blue-color);
    }

    .global-menu ul li .inside-nav a:after {
        border: 0 !important;
    }

    .global-menu>ul>li,
    .global-menu li.border-btn-outer,
    .global-menu li.login-nav {
        margin-left: 0;
    }

    .global-menu ul li a {
        padding: 28px 15px;
    }

    .global-menu .inside-nav strong {
        font-size: var(--font-size-body-extra-small);
    }

    .global-menu .products-submenu .menu-title {
        font-size: var(--font-size-body-small);
        margin: 6px 0 10px 12px;
    }

    .global-menu .products-submenu strong {
        font-size: var(--font-size-body-small);
    }

    .regular-nav .products-submenu .external-link {
        margin-top: 50px;
    }

    .global-menu ul .rounded-nav figure {
        width: 48px;
        height: 48px;
        background-color: transparent;
        padding: 0;
        flex-shrink: 0;
        margin-right: 16px;
    }

    .global-menu ul .rounded-nav figure img {
        max-height: inherit;
        object-fit: inherit;
    }

    .global-menu ul .rounded-nav {
        width: 326px;
        left: -53%;
    }

    .regular-nav .cs-menu .inside-nav {
        flex-direction: column;
    }

    .global-menu ul .rounded-nav.resources-nav {
        width: 806px;
        left: -352px;
    }

    .global-menu ul .solutions-submenu {
        left: 0;
    }

    .global-menu ul li a.gsst_a {
        padding: 0 !important;
    }

    .global-menu>ul>li.top-search {
        top: -4px;
    }

    .main-nav {
        float: right;
        display: flex;
        justify-content: space-between;
        width: 85%;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1300px) {
    .main-nav {
        width: 88%;
    }
}

@media screen and (max-width: 1275px) {
    .global-menu .rounded-nav .inside-nav figure {
        width: 45px;
        height: 45px;
        max-height: inherit;
        object-fit: inherit;
        background-color: transparent;
        padding: 0;
    }

    .global-menu>ul>li.top-search {
        padding: 14px 24px 8px 24px
    }

    #___gcse_0 {
        width: 100% !important;
    }

    .top-search input {
        border: 1px solid #dae5ee !important;
        width: 100% !important;
    }

    .top-search img,
    .top-search svg {
        top: 19px;
        left: 30px;
    }

    .gsc-control-cse {
        margin: 0 30px !important;
    }

    .search-results-top h1 {
        font-size: 30px;
        margin: 20px;
    }
}

@media screen and (max-width: 767px) {
    .gsc-control-cse {
        margin: 0 !important;
    }

    .search-results {
        margin: 110px 4px 30px;
    }

    .gcsc-find-more-on-google-root {
        display: none !important;
    }

    .gsc-results .gsc-cursor {
        background: var(--blue-color) !important;
        color: var(--white-color) !important;
        border-radius: 50px !important;
    }

    .gsc-results .gsc-cursor-box {
        border: 0 !important;
    }

    .gsc-results .gsc-cursor path {
        fill: var(--white-color) !important;
    }
}

/* New global menu css ends */

/* Resources search css starts */

.case-studies-wrapper {
    margin-top: 50px;
}

.custom-search-field {
    position: relative;
    width: 310px;
    margin: 0 auto 50px;
    opacity: 0;
}

.custom-search-field svg {
    position: absolute;
    left: 12px;
    top: 7px;
}

.custom-search-field:focus-within svg path {
    fill: var(--blue-color);
}

.custom-search-field .form-control {
    border-radius: 4px;
    border: 1px solid #dae5ee;
    width: 310px;
    height: 36px;
    font-size: var(--font-size-body-extra-small);
    line-height: var(--line-height-xs);
    padding: 10px 32px 10px 48px;
    box-shadow: none;
    margin-bottom: 0;
}

.results {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin-bottom: 50px;
    border-bottom: 1px solid #dae5ee;
    padding-bottom: 10px;
}

.close-button,
.close-button:hover,
button.close-button:hover,
input[type=button].close-button:hover {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.close-button:after {
    content: "";
    display: block;
    position: absolute;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/close-icon-svg.svg) no-repeat 0 0;
    width: 16px;
    height: 16px;
    background-size: 16px;
    z-index: 1;
    left: 8px;
    top: 9px;
}

#search:not(:valid)~.close-button {
    display: none;
}

@media screen and (max-width: 767px) {
    .case-studies-wrapper {
        margin-top: 30px;
    }

    .custom-search-field {
        margin-bottom: 30px;
    }

    .results {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .custom-search-field .form-control,
    .custom-search-field {
        width: 250px;
    }
}

/* Resources search css ends */

/* Solutions page css fix */

@media only screen and (max-width: 1300px) and (min-width: 768px) {
    .more-options .side-by-side div {
        width: 300px;
    }
}

.hs-button {
    background: red !important;
}

/* Privacy and Security css starts */

.image-block {
    margin-top: 104px;
    margin-bottom: 104px;
}

.image-block .side-by-side {
    font-size: var(--font-size-body-medium);
    line-height: 1.67;
    color: #505e6b;
    margin-top: 96px;
    align-items: center;
}

.image-block .side-by-side figure {
    width: 231px;
    flex: auto 0 0;
    margin-right: 40px;
}

.data-container {
    background-color: #f7fafc;
    padding: 104px 0;
}

.data-accordian {
    margin-top: 88px;
    display: flex;
    grid-gap: 10px;
}

.data-accordian>div {
    width: 50%;
}

.accordion-container {
    position: relative;
    max-width: 500px;
    height: auto;
    margin: 10px auto;
}

.accordion-container>h2 {
    text-align: center;
    color: var(--white-color);
    padding-bottom: 5px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.accordian-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    background-color: var(--white-color);
    border-radius: var(--default-border-radius);
    box-shadow: 2px 2px 5px 0 rgba(138, 161, 184, 0.15);
}

.accordian-wrapper>a {
    display: flex;
    align-items: center;
    padding: 10px 60px 10px 36px;
    min-height: 72px;
    text-decoration: none;
    font: 400 var(--font-size-body-medium)/1.33 var(--semi-bold-font);
    color: #505e6b;
    position: relative;
}

.accordian-wrapper>a::after {
    content: "";
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/small-accordian-icon.svg') 0 0 no-repeat;
    position: absolute;
    top: 32px;
    right: 28px;
    width: 14px;
    height: 8px;
    transition: all .1s ease-in;
}

.accordian-wrapper>a.active::after {
    transform: rotate(180deg);
}

.accordian-content {
    background-color: var(--white-color);
    border-bottom: 1px solid #ddd;
    display: none;
}

.accordian-content p {
    padding: 0 36px 30px;
    margin: 0;
    color: #505e6b;
    line-height: 1.64;
}

.page-links {
    padding: 104px 0;
}

.page-links .columns-12 {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
}

.page-links .columns-12>div {
    padding: 30px 36px;
    border-radius: var(--default-border-radius);
    background-color: #f7fafc;
    flex: auto 1 1;
}

.page-links ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: var(--font-size-body-medium);
}

.page-links li {
    margin-top: 6px;
}

.page-links .slide-link,
.pri-sec-bottom .slide-link,
.image-block .side-by-side .slide-link {
    font-weight: 400;
}

.image-block .side-by-side .slide-link {
    font-size: var(--font-size-body-medium);
}

.page-links h3 {
    font: 400 var(--font-size-heading-medium)/1.25 var(--semi-bold-font);
    color: #505e6b;
    margin: 0 0 16px;
}

.image-block .side-by-side p {
    margin-bottom: 0;
    max-width: inherit;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .pri-sec-bottom {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .pri-sec-bottom {
        justify-items: center;
    }

    .data-container,
    .page-links {
        padding: 40px 0;
    }

    .data-accordian,
    .page-links .columns-12 {
        display: block;
    }

    .page-links .columns-12>div:first-child,
    .image-block .side-by-side figure {
        margin-bottom: 20px;
        margin-right: auto;
    }

    .image-block .side-by-side figure {
        width: 170px;
    }

    .data-accordian,
    .image-block .side-by-side,
    .image-block {
        margin-top: 40px;
    }

    .image-block {
        margin-bottom: 40px;
    }

    .data-accordian>div {
        width: 100%;
    }

    .accordian-wrapper>a {
        padding: 10px 50px 10px 20px;
        font-size: var(--font-size-body-small);
    }

    .accordian-content p {
        padding: 0 20px 20px;
    }

    .data-container .chromebook-intro h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 768px) and (min-width: 321px) {

    .image-block,
    .data-container .newGrid_container,
    .page-links .newGrid_container {
        margin-right: 24px;
        margin-left: 24px;
    }
}

/* Privacy and Security css ends */

/* UK Vs-alternatives page css starts */

.uk-vs-section .com-chart-section>div {
    width: 15%;
}

.uk-vs-section .div-table .div-table-inner>div {
    width: 20%;
}

.uk-vs-section .div-table .div-table-inner>.second-col {
    width: 37%;
}

.uk-vs-section .tp-icon {
    width: 18px;
    height: 18px;
    padding: 2px;
    font-size: var(--font-size-body-extra-small);
}

.uk-vs-section .com-chart-section img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.uk-vs-section .div-table .css-tooltip {
    float: none;
    margin: 0;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    left: 2px;
}

.uk-vs-section .div-table .css-tooltip .tooltiptext {
    bottom: 130%;
    right: -9px;
}

.icon-alignment {
    position: relative;
    left: 12px;
}

@media only screen and (max-width: 767px) {

    .uk-vs-section .div-table .div-table-inner>div,
    .uk-vs-section .div-table .div-table-inner>.second-col,
    .uk-vs-section .com-chart-section>div {
        width: 33.33%;
    }

    .icon-alignment {
        position: static;
        padding-left: 12px;
    }
}

/* UK Vs-alternatives page css ends */

/* Respond page css starts */
.respond-intro {
    background-color: #f7fafc;
    padding: 98px 0;
    margin-top: 72px;
}

.respond-intro .chromebook-intro {
    margin: 0 auto;
}

.img-right {
    justify-self: flex-end;
}

.middle-footer {
    text-align: center;
    background-color: #e6f2ff;
    padding: 146px 0;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: #505e6b;
}

.middle-footer h2 {
    font-size: var(--font-size-heading-large);
    font-weight: 600;
    line-height: 1.25;
    color: var(--heading-text-color);
    margin: 0 0 30px;
}

.middle-footer p {
    max-width: 70%;
    margin: 0 auto 40px;
}

@media only screen and (min-width: 767px) and (max-width: 1100px) {
    .middle-footer .inline-btns a {
        display: inline-block;
        margin: 0;
    }

    .middle-footer .inline-btns a:first-child {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 767px) {

    .respond-intro,
    .middle-footer {
        padding: 50px 0;
        margin-top: 40px;
    }

    .middle-footer {
        margin-top: 0;
        font-size: var(--font-size-body-medium);
    }

    .middle-footer h2 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .middle-footer p {
        max-width: inherit;
        margin-bottom: 20px;
    }
}

/* Respond page css ends */

/* Products menu css starts */

.regular-nav .products-submenu strong {
    color: var(--heading-text-color);
    display: block;
}

/* Products menu css ends */

/* Flex and pass css start */

.regular-nav .login-submenu .inside-nav a:hover .respond-menu-icon g path {
    fill: var(--white-color);
    stroke-width: 0;
}

.custom-panel .live-svg path {
    fill: #becbda;
}

.custom-panel .active .live-svg path {
    fill: #0096ff;
}

.custom-panel .faster-svg path:last-child {
    stroke-width: 0;
}

.custom-panel .active .faster-svg path:last-child {
    fill: #0096ff;
    stroke-width: 0;
}

.custom-panel .auto-svg path {
    stroke-width: 0;
}

.custom-panel .active .auto-svg .path2 {
    fill: var(--white-color);
    stroke-width: 0px;
}

.custom-panel .active .auto-svg path {
    fill: #0096ff;
}

.custom-panel .frequent-svg path {
    fill: transparent;
}

.red-star {
    display: inline-block;
    margin-left: -4px;
    color: #f44336;
}

@media only screen and (min-width: 767px) {
    .key-adv.clip-none span {
        display: block;
        height: 70px;
        margin-bottom: 20px;
    }
}

/* Flex and pass css end */

/* Rhithm page update css starts */
.inline-testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.inline-testimonial img {
    margin-right: 40px;
    margin-bottom: 0;
}

.inline-testimonial strong {
    margin-top: 16px;
}

.hero-btns .btn {
    height: 44px;
    padding: 12px 32px;
}

.hero-btns .btn:last-child {
    margin-left: 16px;
}

@media only screen and (max-width: 500px) {
    .hero-btns {
        margin: 20px auto 0;
    }

    .hero-btns .btn {
        width: 200px;
    }

    .hero-btns .btn:last-child {
        margin-left: 0;
    }
}

/* Rhithm page update css ends here */

/* Classroom hero css starts */
.logos-with-text {
    margin-top: 24px;
}

.logos-with-text strong {
    font-size: var(--font-size-body-small);
    display: block;
    margin-bottom: 8px;
}

@media only screen and (min-width: 1023px) {
    .logos-with-text {
        text-align: left;
    }

    .logos-with-text.align-right {
        float: right;
    }
}

/* Classroom hero css ends */

.uk-site .regular-nav .inside-nav a.aware-link:hover svg g circle {
    fill: var(--blue-color);
}

.uk-site .regular-nav .inside-nav a.aware-link span,
.uk-site .regular-nav .inside-nav a.reveal-link span,
.uk-site .regular-nav .inside-nav a.on-call-link span {
    padding: 5px 4px 4px 4px;
    border-radius: 8px;
    background-color: #ffe74d;
    color: var(--heading-text-color);
    font: 400 9px/1 var(--semi-bold-font);
    position: relative;
    top: -5px;
}

/* Case studies page css starts */
.top-center-content {
    margin-top: 158px;
    text-align: center;
}

.top-center-content h1 {
    font-size: var(--font-size-heading-extra-large);
    line-height: 1.25;
    color: var(--heading-text-color);
    max-width: 80%;
    margin: 36px auto 54px;
}

.success-story {
    font-size: var(--font-size-body-medium);
    line-height: 1.78;
    color: var(--blue-gray-color);
}

.success-story h2,
.blue-heading {
    font: 400 var(--font-size-heading-medium)/1.33 var(--bold-font);
    color: #2692ff;
    margin-top: 0;
}

.blue-heading {
    margin-bottom: 50px;
}

.securly-pass-intro {
    margin-bottom: 24px;
}

.securly-pass-intro h2 {
    font-size: 28px;
}

.success-story .inline-btns {
    margin-top: 32px;
}

.success-story .inline-btns .btn {
    height: 44px;
    margin-top: 0;
}

.chrometools-page .newGrid_container.light-blue-testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 400 italic 18px/1.6 var(--semi-bold-font);
    color: var(--body-text-color);
    padding: 22px 40px;
    border-radius: 10px;
    background-color: #f1f8ff;
    margin-top: 34px;
}

.light-blue-testimonial img {
    margin-right: 38px;
}

.light-blue-testimonial p {
    margin-bottom: 0;
}

.light-blue-testimonial strong {
    font-family: var(--bold-font);
    font-style: normal;
    font-weight: 400;
    color: var(--blue-btn-hover);
}

.case-study-stats.light-blue-stats {
    margin: 34px 0 94px;
}

.light-blue-stats .case-study-stats-block div {
    padding: 16px;
    border-radius: 10px;
    background-color: #f1f8ff;
    text-align: center;
    min-height: 152px;
    margin-bottom: 16px;
}

.light-blue-stats .case-study-stats-block h2 {
    font: 400 50px/1.1 var(--extra-bold-font);
    color: var(--blue-color);
    text-shadow: none;
    margin: 0;
}

.light-blue-stats .case-study-stats-block p {
    max-width: 80%;
    font-size: 17px;
    line-height: 1.28;
    color: var(--body-text-color);
    margin: 0 auto;
}

.page-wrapper {
    padding: 86px 0;
    background-color: #f7fafc;
}

.page-wrapper h2 {
    font-size: var(--font-size-heading-extra-large);
    line-height: 1.25;
    color: var(--heading-text-color);
    margin: 32px 0 18px;
}

.page-wrapper .text-center p,
.extra-bold {
    font-size: var(--font-size-heading-medium);
    line-height: 1.42;
    color: #505e6b;
    max-width: 60%;
    margin: 0 auto 80px;
}

.extra-bold {
    margin-top: 40px;
    margin-bottom: 60px;
}

.page-wrapper .rows p {
    font-size: var(--font-size-body-medium);
    line-height: 1.78;
    color: var(--body-text-color);
}

.full-header-top {
    background: 100% 0 / contain url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/aps-hero.webp') no-repeat;
    margin-top: 70px;
}

.full-header-top:after {
    content: "";
    background-color: #c5e1ff;
    height: 5%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.full-header-top img {
    margin-top: 132px;
}

.case-study-intro .center-heading {
    font-size: 46px;
    line-height: var(--line-height-md);
    color: var(--heading-text-color);
    margin: 46px 0;
    text-wrap: balance;
}

.case-study-stats.light-blue-bg {
    background-color: #f7fafc;
    padding: 100px 0;
    margin-top: 100px;
    margin-bottom: 0;
}

.case-study-stats.light-blue-bg .case-study-stats-block div {
    padding: 16px;
    border-radius: 10px;
    background-color: #e8f2fe;
    box-shadow: inset 4px -4px 0 0 #bedeff;
}

.light-blue-bg .case-study-stats-block p {
    font-size: var(--font-size-heading-small);
    line-height: 1.32;
    color: #2a3138;
    margin-top: 8px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
}

.case-study-stats.light-blue-bg .case-study-stats-block div {
    padding-top: 22px;
    padding-bottom: 22px;
    color: var(--heading-text-color);
}

.case-study-stats.light-blue-bg h2 {
    font-size: 56px;
    text-shadow: -2px 2px 0 var(--light-blue-btn-hover);
}

.case-study-stats.light-blue-bg h2.medium-font {
    font: 400 48px/1.17 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin-top: 12px;
    text-shadow: none;
}

.case-study-stats.light-blue-bg p.medium-font {
    font: 400 var(--font-size-body-large)/1.5 var(--semi-bold-font);
    color: #2a3138;
}

.sectionwise {
    background-color: #e8f2fe;
    margin-top: 100px;
}

.sectionwise .rows p {
    font-size: var(--font-size-body-extra-small);
}

.light-sectionwise,
.white-sectionwise {
    background-color: #f7fafc;
    margin-top: 0;
}

.white-sectionwise {
    background-color: var(--white-color);
}

.page-wrapper .case-study-stats.light-blue-bg {
    background-color: transparent;
    padding: 0;
    margin: 30px 0 0;
}

.page-wrapper .case-study-stats.light-blue-bg .case-study-stats-block div {
    background-color: #f7fafc;
    box-shadow: inset 4px -4px 0 0 #dae5ee;
}

.page-wrapper .light-blue-bg .case-study-stats-block p {
    font-size: var(--font-size-heading-small);
    line-height: 1.32;
    color: #2a3138;
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
}

.page-wrapper .rows {
    color: var(--body-text-color);
}

.bullet-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.bullet-list.top-margin {
    margin-top: 90px;
}

.bullet-list li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-studies-checkmark.svg) 0 0 no-repeat;
    font-family: var(--semi-bold-font);
    padding-left: 30px;
    background-size: 20px;
    padding-bottom: 18px;
}

.profiles {
    background-color: #e8f2fe;
    padding: 148px 0;
    text-align: center;
    color: #2a3138;
}

.profiles strong {
    display: block;
    font-weight: 400;
    font-family: var(--extra-bold-font);
}

.profiles strong,
.profiles p {
    color: #2a3138;
    line-height: 1.57;
}

.profiles img {
    margin-bottom: 34px;
}

.right-left-margin {
    margin: 0 10%;
}

.page-middle-video {
    width: 60%;
    margin: 0 auto;
}

.page-wrapper .text-center.right-left-margin p {
    font-size: var(--font-size-body-medium);
    line-height: 1.56;
    color: var(--body-text-color);
    margin-top: 30px;
    margin-bottom: 50px;
    max-width: inherit;
}

.page-wrapper .text-center.right-left-margin p.additional-line {
    margin: 58px 0 0;
}

.latest-case-studies .box-grid span {
    font-size: var(--font-size-body-small);
    font-weight: bold;
    padding: 0 24px;
    color: var(--blue-color);
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .light-blue-stats .case-study-stats-block h2 {
        font-size: 46px;
    }

    .light-blue-bg .case-study-stats-block p {
        font-size: var(--font-size-body-large);
    }

    .case-study-stats.light-blue-bg .case-study-stats-block div {
        min-height: 182px;
        margin-bottom: 30px;
    }

    .page-wrapper .case-study-stats.light-blue-bg .case-study-stats-block div {
        margin-bottom: 30px;
    }

    .page-wrapper .rows,
    .success-story .rows,
    .extra-grid-rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .light-blue-bg .columns-12.case-study-stats-block {
        grid-column: span 9;
    }

    .full-header-top:after {
        display: none;
    }

    .success-story .inline-btns .btn {
        margin-left: 0;
        margin-bottom: 20px;
        display: block;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .full-header-top .case-study-intro {
        text-align: left;
    }
}

@media only screen and (max-width: 767px) {
    .top-center-content {
        margin-top: 110px;
    }

    .blue-heading {
        margin-bottom: 20px;
    }

    .top-center-content h1 {
        font-size: 30px;
        margin: 30px auto;
        max-width: inherit;
    }

    .success-story img {
        margin-top: 20px;
    }

    .chrometools-page .newGrid_container.light-blue-testimonial {
        display: block;
        padding: 20px;
        font-size: var(--font-size-body-small);
    }

    .light-blue-stats .case-study-stats-block p {
        font-size: var(--font-size-body-small);
        max-width: inherit;
    }

    .light-blue-testimonial img {
        margin-bottom: 20px;
    }

    .ending-video {
        margin-top: 50px;
    }

    .full-header-top:after {
        display: none;
    }

    .full-header-top .case-study-intro .center-heading {
        font-size: 30px;
        margin: 50px 0 0;
    }

    .case-study-stats.light-blue-bg,
    .sectionwise {
        padding: 50px 0;
        margin-top: 50px;
    }

    .case-study-stats.light-blue-stats {
        margin: 30px 0 0;
    }

    .light-blue-stats .case-study-stats-block h2,
    .case-study-stats.light-blue-bg h2.medium-font {
        font-size: 26px;
    }

    .light-blue-stats .case-study-stats-block div {
        padding: 10px;
    }

    .light-blue-bg .case-study-stats-block p,
    .case-study-stats.light-blue-bg p.medium-font {
        font-size: var(--font-size-body-small);
    }

    .page-middle-video {
        width: 70%;
    }

    .page-wrapper h2 {
        font-size: 30px;
        margin-top: 20px;
    }

    .extra-bold,
    .page-wrapper .text-center p {
        font-size: var(--font-size-body-large);
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: inherit;
    }

    .page-wrapper .rows,
    .extra-grid-rows {
        grid-row-gap: 0;
    }

    .light-sectionwise,
    .white-sectionwise,
    .bullet-list.top-margin {
        margin-top: 0;
    }

    .page-wrapper .case-study-stats.light-blue-bg .case-study-stats-block div,
    .profiles img {
        margin-bottom: 16px;
    }

    .profiles,
    .page-wrapper {
        padding: 50px 0;
    }

    .profiles .columns-4 {
        margin-bottom: 30px;
    }

    .profiles img {
        width: 180px;
        height: 180px;
    }

    .right-left-margin,
    .latest-case-studies .box-grid {
        margin: 0;
    }

    .page-wrapper .text-center.right-left-margin p.additional-line {
        margin-top: 30px;
    }

    .latest-case-studies {
        padding-bottom: 20px;
    }

    .success-story .inline-btns .btn {
        margin-left: 0;
        margin-bottom: 20px;
        display: block;
        width: 240px;
    }
}

/* Case studies page css ends */
/* Back to school page css starts */
.main-nav ul li a.green-btn,
.green-btn {
    height: 44px;
    border-radius: 40px;
    background-color: #29d9c3;
    color: #013973;
    padding: 14px 34px;
    font-size: var(--font-size-body-small);
    line-height: 1;
    margin-top: 14px;
    text-align: center;
}

.main-nav ul li a.green-btn:hover,
.green-btn:hover {
    background-color: #28cdb8;
    color: #013973 !important;
}

.main-nav ul li a.green-btn:active,
.main-nav ul li a.green-btn:focus,
.green-btn:active,
.green-btn:focus {
    background-color: #2ac4b1;
    color: #013973;
}

.back-to-school-header {
    margin-top: 70px;
    padding-top: 70px;
    background-image: linear-gradient(to bottom, #f7fafc 27%, #e6f2ff);
}

.glow-box {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    background-color: var(--white-color);
}

.back-to-school-header .rows {
    padding-bottom: 50px;
}

.timer-block {
    padding: 24px 38px;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    width: fit-content;
}

.timer-block li {
    padding: 0 24px;
}

.securly-prepared-logo {
    padding-right: 22px;
}

.timer-block li:first-child {
    padding-left: 0;
}

.timer-block li:last-child {
    padding-right: 0;
}

.timer-block li:not(:first-child) {
    /* border-left: 1px solid var(--blue-gray-color); */
    border-image: linear-gradient(var(--white-color), var(--blue-gray-color), var(--white-color)) 1;
    border-style: solid;
    border-width: 0 0 0 1px;
}

.timer-block strong {
    font: 400 42px/1.3 var(--extra-bold-font);
    color: var(--heading-text-color);
}

.timer-block span {
    display: block;
    font-size: var(--font-size-body-medium);
    color: #0173e6;
}

.banner-details {
    font-size: var(--font-size-body-medium);
    line-height: var(--line-height-xl);
    color: #505e6b;
    width: 80%;
}

.banner-details h2,
.banner-heading {
    font: 400 28px/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 24px;
}

.banner-heading {
    font-size: 30px;
    margin: 0;
    padding: 20px;
}

.banner-heading a {
    color: var(--heading-text-color);
    text-decoration: underline;
}

.banner-details span {
    font-family: var(--extra-bold-font);
    color: #0173e6;
}

.banner-details strong {
    font-family: var(--bold-font);
}

.event-plan {
    padding: 30px 40px;
    margin-right: 22px;
    font-size: var(--font-size-body-small);
    color: var(--blue-gray-color);
}

.event-plan h3 {
    font: 400 var(--font-size-heading-small)/1.2 var(--semi-bold-font);
    color: var(--blue-gray-color);
    margin: 0 0 8px;
}

.event-plan ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.event-plan li {
    width: 50%;
    margin-top: 22px;
    display: flex;
    align-items: center;
}

.event-plan img {
    margin-right: 20px;
}

.dark-blue-bar {
    padding: 50px 0;
    background-color: #013973;
    color: var(--white-color);
    display: flex;
}

.dark-blue-bar .newGrid_container {
    display: flex;
}

.dark-blue-bar ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
    display: flex;
    width: 80%;
}

.dark-blue-bar ul li {
    display: flex;
    align-items: flex-start;
    margin: 0 12px;
}

.dark-blue-bar img {
    margin-right: 16px;
}

.dark-blue-bar h2 {
    margin: 0;
    color: var(--white-color);
    width: 20%;
    font-size: 30px;
    line-height: var(--line-height-sm);
}

.dark-blue-bar p {
    margin-bottom: 0;
}

.blue-subheading {
    text-align: center;
    font-size: var(--font-size-body-medium);
    color: #0173e6;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.section-heading {
    font: 400 30px/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    text-align: center;
    margin: 0 0 40px;
}

.speaker-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -6%;
}

.speaker-info {
    display: flex;
    align-items: flex-start;
    font-size: var(--font-size-body-extra-small);
    color: var(--blue-gray-color);
}

.speaker-info>img {
    margin-right: 22px;
}

.speaker-info h3 {
    margin: 0 0 6px;
    font: 400 17px/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
}

.speaker-info p {
    margin-bottom: 10px;
}

.speaker-info a {
    display: inline-block;
    margin-right: 6px;
}

.speakers {
    padding: 80px 0;
}

.speaker-profile {
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    padding: 24px 32px;
    width: 27%;
    margin-left: 6%;
    margin-bottom: 4%;
}

.about-speaker {
    font-size: 13px;
    line-height: 1.63;
    color: #505e6b;
    margin: 20px 0 10px;
}

.agenda-wrapper {
    padding: 40px 0;
    background-color: #e6f2ff;
}

.agenda-wrapper .overview-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6%;
}

.overview-items {
    display: flex;
    align-items: center;
    width: 27%;
    border-radius: 10px;
    padding: 16px 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
    background-color: var(--white-color);
    margin-left: 6%;
    margin-bottom: 3%;
}

.overview-items img {
    margin-right: 24px;
}

.overview-items p {
    margin-bottom: 0;
    color: var(--heading-text-color);
}

.overview-content {
    width: 27%;
    margin-left: 6%;
}

.agenda-wrapper .blue-subheading,
.agenda-wrapper .section-heading {
    text-align: left;
    margin-bottom: 0;
}

.agenda-wrapper .blue-subheading {
    font-size: var(--font-size-body-small);
    margin-bottom: 8px;
}

.agenda-wrapper .section-heading {
    font-size: var(--font-size-heading-medium);
}

.dark-blue-wrapper {
    padding: 80px 0;
    background-color: #013973;
    color: var(--white-color);
}

.dark-blue-wrapper h2 {
    margin: 0 0 60px;
    font: 400 36px/1.2 var(--bold-font);
    color: var(--white-color);
    text-align: center;
}

.register-form {
    padding: 30px 50px;
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--white-color);
}

.register-details {
    color: var(--white-color);
    margin: 0 auto;
    padding: 0 10%;
}

.register-details h3 {
    margin: 0 0 40px;
    font: 400 28px/1.2 var(--semi-bold-font);
    color: var(--white-color);
}

.register-details ul {
    margin: 0 0 80px;
    padding: 0;
    list-style: none;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
}

.register-details li {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}

.register-details img {
    margin-right: 22px;
}

.register-details p {
    margin-bottom: 0;
}

.green-quote {
    margin: 24px 0 0 64px;
    padding: 18px 0 0 70px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/green-quote-icon.svg) 0 0 no-repeat;
    background-size: 50px;
    color: var(--white-color);
    font: italic 28px/1.38 var(--regular-font);
}

.faqs-wrapper {
    padding: 80px 0;
    background-color: #f8fafc;
}

.faqs {
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--white-color);
    color: #505e6b;
    font-size: var(--font-size-body-small);
    line-height: 1.44;
}

.faqs strong {
    font-size: var(--font-size-body-medium);
    margin-bottom: 14px;
    display: block;
}

.faqs p {
    margin-bottom: 40px;
}

.faqs p:last-child {
    margin-bottom: 20px;
}

.event-banner {
    background: url('https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/back-to-school-footer.webp') 0 0 no-repeat;
    background-size: cover;
    color: #013973;
    font: 400 var(--font-size-body-large)/1.2 var(--semi-bold-font);
    padding: 30px 0;
}

.event-banner .newGrid_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 260px;
    position: relative;
}

.event-banner .last-image img {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%) rotate(-7deg);
}

.event-banner .last-image:before {
    content: "";
    width: 150px;
    height: 60px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/arrow-art-icon.svg) 0 0 no-repeat;
    position: absolute;
    right: 26%;
    bottom: 22%;
}

.event-banner span {
    display: block;
    margin-bottom: 20px;
}

.event-banner p {
    font: 400 28px/1.2 var(--semi-bold-font);
    margin-bottom: 0;
}

.show-on-responsive {
    display: none;
}

@media screen and (max-width: 1276px) and (min-width: 992px) {
    .securly-prepared-logo {
        padding-right: 0;
        margin-bottom: 30px;
    }

    .event-plan {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .event-banner .last-image img {
        right: 0;
    }

    .event-banner .last-image:before {
        right: 21%;
    }

    .speaker-info {
        display: block;
    }

    .speaker-info>img {
        margin-right: 16px;
        margin-bottom: 16px;
        width: 80px;
    }

    .agenda-wrapper .overview-wrapper {
        align-items: stretch;
    }

    .overview-content {
        align-self: center;
    }

    .register-form {
        margin-bottom: 30px;
    }

    .register-details {
        padding: 0;
    }
}

@media screen and (max-width: 768px) and (min-width: 321px) {

    .back-to-school-header .newGrid_container,
    .speakers .newGrid_container,
    .agenda-wrapper .newGrid_container,
    .dark-blue-wrapper .newGrid_container,
    .faqs-wrapper .newGrid_container,
    .event-banner .newGrid_container {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 991px) {
    .show-on-responsive {
        display: block;
    }

    .hide-on-responsive {
        display: none;
    }

    .securly-prepared-logo {
        padding-right: 0;
    }

    .back-to-school-header {
        padding-top: 40px;
    }

    .timer-block {
        padding: 20px;
        margin-top: 20px;
        justify-content: center;
    }

    .timer-block li {
        padding: 0 20px;
    }

    .timer-block strong {
        font-size: 26px;
    }

    .timer-block span,
    .banner-details {
        font-size: var(--font-size-body-small);
    }

    .banner-details {
        width: inherit;
    }

    .back-to-school-header .rows {
        padding-bottom: 20px;
    }

    .event-plan {
        margin-right: 0;
        margin-bottom: 30px;
        padding: 20px;
        font-size: var(--font-size-body-extra-small);
    }

    .event-plan h3 {
        font-size: var(--font-size-body-medium);
    }

    .banner-details h2 {
        font-size: var(--font-size-heading-small);
    }

    .event-plan ul,
    .dark-blue-bar,
    .dark-blue-bar .newGrid_container,
    .dark-blue-bar ul,
    .speaker-wrapper,
    .agenda-wrapper .overview-wrapper {
        display: block;
    }

    .event-plan li {
        margin-top: 20px;
    }

    .dark-blue-bar h2 {
        width: inherit;
        font-size: var(--font-size-heading-medium);
        margin-bottom: 30px;
    }

    .dark-blue-bar h2 br {
        display: none;
    }

    .dark-blue-bar ul li {
        margin: 0 0 20px;
    }

    .dark-blue-bar ul {
        width: inherit;
    }

    .dark-blue-bar {
        padding: 40px 0 20px;
    }

    .speakers,
    .faqs-wrapper {
        padding: 40px 0;
    }

    .section-heading,
    .agenda-wrapper .section-subheading {
        font-size: var(--font-size-heading-small);
    }

    .blue-subheading,
    .agenda-wrapper .blue-subheading {
        font-size: var(--font-size-body-small);
    }

    .speaker-wrapper {
        margin-left: 0;
    }

    .speaker-profile {
        width: inherit;
        margin-left: 0;
        margin-bottom: 30px;
        padding: 22px;
        border-radius: 8px;
    }

    .speaker-info>img {
        margin-right: 18px;
        width: 80px;
        height: 80px;
    }

    .banner-heading {
        font-size: var(--font-size-body-large);
        margin: 20px 0 0;
        padding: 10px;
    }

    .dark-blue-bar img {
        width: 30px;
        height: 30px;
    }

    .dark-blue-bar ul,
    .register-details ul {
        line-height: var(--line-height-lg);
    }

    .agenda-wrapper,
    .dark-blue-wrapper {
        padding: 30px 0;
    }

    .overview-content {
        width: inherit;
        margin-right: 0;
    }

    .agenda-wrapper .blue-subheading,
    .agenda-wrapper .section-heading {
        text-align: center;
    }

    .agenda-wrapper .section-heading,
    .register-details li {
        margin-bottom: 30px;
    }

    .overview-items {
        width: inherit;
        margin-bottom: 30px;
        border-radius: 8px;
        padding: 14px 16px;
    }

    .overview-items img {
        margin-right: 16px;
        width: 40px;
    }

    .dark-blue-wrapper h2 {
        font-size: 26px;
        margin-bottom: 30px;
    }

    .register-form {
        padding: 30px 20px 20px;
        border-radius: 10px;
    }

    .register-details {
        padding: 0;
    }

    .register-details h3 {
        margin: 30px 0;
        font-size: var(--font-size-heading-medium);
    }

    .main-nav ul li a.green-btn {
        margin: 20px;
    }

    .register-details li {
        align-items: flex-start;
    }

    .register-details img {
        margin-right: 18px;
        width: 30px;
        height: 30px;
    }

    .register-details ul {
        margin-bottom: 40px;
    }

    .green-quote {
        font-size: var(--font-size-heading-small);
        margin-left: 0;
        padding-left: 50px;
        background-size: 40px;
    }

    .faqs {
        width: 94%;
        padding: 30px;
    }

    .faqs p {
        margin-bottom: 30px;
    }

    .event-banner {
        background-position: top right;
        font-size: var(--font-size-body-medium);
    }

    .event-banner p {
        font-size: var(--font-size-heading-small);
    }

    .event-banner .last-image img {
        position: inherit;
        margin-top: 54%;
        width: 250px;
        margin-bottom: 80px;
        margin-top: 20px;
        transform: none;
    }

    .event-banner .last-image:before {
        display: none;
    }

    .event-banner .btn {
        position: absolute;
        bottom: 0;
    }
}

/* Back to school page css ends */
/* About page css starts */
.about-stats {
    background-color: var(--blue-color);
    background-image: none;
    padding: 110px 0;
}

.home-stats.about-stats .statsTitle {
    font-size: var(--font-size-heading-extra-large);
    font-family: var(--semi-bold-font);
    margin: 0 0 110px;
}

.home-stats.about-stats h2 {
    font-family: var(--bold-font);
    font-size: 48px;
    margin: 28px 0 10px;
}

.home-stats.about-stats h5,
.home-stats.about-stats .h5-heading {
    font-size: var(--font-size-heading-medium);
    margin: 0 0 108px;
}

.home-stats.about-stats strong {
    font-size: var(--font-size-heading-medium);
    margin: 0 0 6px;
    display: block;
}

.home-stats.about-stats p {
    font-size: var(--font-size-body-large);
    margin-bottom: 0;
}

.about-stats span {
    font-family: var(--regular-font);
    font-weight: 400;
    display: block;
    margin-top: 30px;
    margin-bottom: 116px;
}

.home-stats.about-stats img {
    height: 140px;
    object-fit: contain;
}

.about-page {
    margin-bottom: 0;
}

.about-page .holder {
    padding: 120px 0;
    margin-top: 70px;
}

.about-page .holder.light-blue-bg-section {
    margin-top: 0;
    padding-bottom: 0;
    min-height: inherit;
}

.about-page .image-align img {
    max-height: 454px;
}

.about-page h1 {
    font-size: 48px;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 30px;
}

.about-page .holder p {
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: var(--blue-gray-color);
    margin-bottom: 24px;
}

.about-page .holder.light-blue-bg-section ul,
.about-page .holder.light-blue-bg-section p {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: #505e6b;
    margin-bottom: 28px;
}

.about-page .holder.light-blue-bg-section ul {
    padding-left: 34px;
    margin-bottom: 120px;
}

.about-page .holder.light-blue-bg-section li {
    margin-bottom: 20px;
}

.about-page .image-align-left {
    align-self: flex-end;
}

.testimonial-wrapper p {
    margin-bottom: 30px;
}

.testimonial-wrapper strong {
    font-size: var(--font-size-body-large);
    font-style: normal;
}

.testimonial-wrapper {
    background-color: #f7fafd;
    padding: 190px 0;
    text-align: center;
    font-size: var(--font-size-heading-medium);
    font-style: italic;
    line-height: 1.33;
    color: var(--blue-gray-color);
}

.testimonial-wrapper .leader-testimonial {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/about-quote.svg) 0 0 no-repeat;
    width: 80%;
    margin: 0 auto;
    padding: 78px 0 0 60px;
}

.our-why-wrapper {
    padding: 110px 0;
    text-align: center;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    text-align: center;
    color: #505e6b;
}

.our-why-wrapper h2,
.vision-wrapper h2 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 80px;
}

.our-why-wrapper p {
    width: 600px;
    margin: 0 auto;
}

.our-why-wrapper .para-first {
    padding-top: 60px;
}

.our-why-wrapper .para-fifth,
.our-why-wrapper .para-fourth,
.our-why-wrapper .para-second,
.our-why-wrapper .para-third {
    padding-top: 174px;
}

.our-why-container {
    background: top url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/our-why-bg.svg) no-repeat;
    padding-bottom: 100px;
}

.vision-wrapper {
    padding-top: 60px;
    background-color: #e6f2ff;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    text-align: center;
    color: #505e6b;
}

.vision-wrapper h2 {
    margin: 60px 0 50px;
}

.vision-wrapper p {
    margin-bottom: 30px;
}

.vision-wrapper .newGrid_container {
    display: grid;
    grid-template-columns: 18% 44% 18%;
    justify-content: space-between;
}

.vision-wrapper .newGrid_container img:first-child {
    align-self: flex-end;
}

.vision-wrapper .newGrid_container div {
    margin-bottom: 120px;
}

@media only screen and (min-width: 1276px) {
    .our-why-img {
        display: none;
    }
}

@media only screen and (max-width: 1455px) {
    .our-why-wrapper p {
        width: 500px;
        font-size: var(--font-size-body-medium);
    }

    .our-why-container {
        background-size: contain;
    }

    .our-why-wrapper .para-first {
        padding-top: 40px;
    }

    .our-why-wrapper .para-second {
        padding-top: 130px;
    }

    .our-why-wrapper .para-third,
    .our-why-wrapper .para-fourth,
    .our-why-wrapper .para-fifth {
        padding-top: 150px;
    }
}

@media only screen and (max-width: 1275px) {
    .our-why-container {
        background: none;
        padding-bottom: 0;
    }

    .our-why-wrapper {
        padding: 60px 0;
    }

    .our-why-wrapper h2,
    .vision-wrapper h2 {
        margin-bottom: 40px;
    }

    .our-why-wrapper p {
        width: 50%;
        font-size: var(--font-size-body-small);
    }

    .our-why-wrapper .para-first,
    .our-why-wrapper .para-second,
    .our-why-wrapper .para-third,
    .our-why-wrapper .para-fourth,
    .our-why-wrapper .para-fifth {
        padding: 20px 0 50px;
    }
}

@media only screen and (max-width: 992px) {
    .about-page .holder {
        padding: 60px 0;
    }

    .about-page h1 {
        font-size: var(--font-size-heading-extra-large);
    }

    .about-page .holder p,
    .about-page .holder.light-blue-bg-section p,
    .about-page .holder.light-blue-bg-section ul {
        font-size: var(--font-size-heading-small);
    }

    .about-page .img-right {
        justify-self: center;
    }

    .about-stats {
        padding: 40px 20px;
    }

    .home-stats.about-stats h2 {
        display: block;
        font-family: var(--semi-bold-font);
        font-size: 28px;
        margin-bottom: 2px;
    }

    .home-stats.about-stats img {
        height: 130px;
    }

    .home-stats.about-stats strong {
        font-size: var(--font-size-body-large);
    }

    .home-stats.about-stats p {
        font-size: var(--font-size-body-medium);
    }

    .about-stats span {
        font-size: var(--font-size-heading-small);
        margin-bottom: 60px;
    }

    .home-stats.about-stats h5,
    .home-stats.about-stats .h5-heading {
        font-size: var(--font-size-body-medium);
        margin: 0 0 30px;
    }
}

@media only screen and (max-width: 767px) {
    .about-page .holder {
        padding: 40px 0;
    }

    .about-page h1 {
        font-size: 28px;
    }

    .about-page .holder p,
    .about-page .holder.light-blue-bg-section p {
        font-size: var(--font-size-body-medium);
    }

    .about-page .image-align img {
        max-height: 250px;
        width: inherit;
    }

    .about-page .content-right {
        padding-bottom: 0;
    }

    .about-page .image-align-left {
        justify-self: center;
        margin-top: 30px;
    }

    .home-stats.about-stats .statsTitle {
        font-size: 30px;
        margin: 20px 0 50px;
    }

    .our-why-wrapper p {
        width: auto;
    }

    .about-page .holder.light-blue-bg-section ul {
        font-family: var(--semi-bold-font);
        font-size: var(--font-size-body-small);
        text-align: left;
        margin-bottom: 0;
        color: var(--heading-text-color);
    }

    .about-page .holder.light-blue-bg-section li {
        margin-bottom: 4px;
    }

    .about-page .holder.light-blue-bg-section li::marker {
        color: var(--blue-color);
        font-size: 28px;
        line-height: var(--line-height-xs);
    }

    .about-stats {
        padding: 30px 20px;
    }

    .home-stats.about-stats h2 {
        font-size: 28px;
        margin-bottom: 2px;
    }

    .about-stats span {
        font-size: var(--font-size-body-large);
        margin-bottom: 60px;
    }

    .home-stats.about-stats h5,
    .home-stats.about-stats .h5-heading {
        font-size: var(--font-size-body-small);
        margin: 0 0 30px;
    }

    .home-stats.about-stats strong,
    .home-stats.about-stats p {
        display: none;
    }

    .testimonial-wrapper {
        padding: 40px 0;
        font-size: var(--font-size-body-large);
    }

    .testimonial-wrapper strong {
        font-size: var(--font-size-body-medium);
        color: var(--heading-text-color);
    }

    .testimonial-wrapper .leader-testimonial {
        background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/about-only-quote.svg) top center no-repeat;
        padding: 60px 0 0;
        width: auto;
    }

    .our-why-wrapper h2,
    .vision-wrapper h2 {
        font-size: 28px;
        margin: 0 0 20px;
    }

    .vision-wrapper {
        padding-top: 30px;
        font-size: var(--font-size-body-small);
    }

    .vision-wrapper p {
        margin-bottom: 20px;
    }

    .vision-wrapper .newGrid_container {
        grid-template-columns: inherit;
    }

    .vision-wrapper .newGrid_container img:first-child {
        order: 1;
    }

    .vision-wrapper .newGrid_container img:last-child {
        order: -1;
        justify-self: center;
        margin-bottom: 40px;
    }

    .vision-wrapper .newGrid_container div {
        margin-bottom: 30px;
    }

    .vision-wrapper img {
        max-width: 70%;
    }

    .profile-wrap img {
        width: 100px;
        height: 100px;
    }
}

/* About page css ends */
/* Student wellness page css starts */
.solutions-pages {
    background-color: var(--white-color);
}

.solutions-pages h1 {
    font-size: 48px;
    margin-top: 0;
}

.solutions-pages .hero-btns .btn {
    margin-top: 0;
    line-height: 1;
    padding-right: 26px;
    padding-left: 26px;
}

.solutions-pages .cloud-page-header-container {
    padding: 170px 0 100px;
}

.solutions-pages .img-align {
    margin-left: 90px;
}

.student-health-wrapper,
.approach-wrapper,
.blue-wrapper,
.wellness-video-wrapper,
.identify-wrapper,
.two-col-wrapper .holder,
.wellness-testimonial {
    background-color: #E6F2FF;
    padding: 100px 0;
    text-align: center;
}

.student-health-wrapper h2,
.approach-wrapper h2,
.blue-wrapper h2,
.identify-wrapper h2 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 46px;
}

.bullet-design {
    max-width: 798px;
    margin: 0 auto 60px;
}

.bullet-design div {
    background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-1.svg) no-repeat;
    min-height: 251px;
    max-width: 798px;
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-lg);
    text-align: left;
}

.bullet-design .design-second {
    background: top right/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-2.svg) no-repeat;
}

.bullet-design .design-third {
    background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-3.svg) no-repeat;
}

.bullet-design p {
    width: 94%;
    padding: 84px 0 0 260px;
    margin: 0 auto;
}

.bullet-design .design-second p {
    padding: 84px 260px 0 36px;
}

.bullet-design strong,
.bullet-design span {
    font: 400 26px/1.1 var(--extra-bold-font);
}

.bullet-design strong {
    font-size: var(--font-size-body-large);
}

.note-text {
    font-size: var(--font-size-body-small);
    line-height: 1.38;
    color: var(--heading-text-color);
    max-width: 40%;
    margin: 0 auto;
}


.approach-wrapper,
.wellness-video-wrapper {
    background-color: var(--white-color);
}

.approach-wrapper h3 {
    font: 400 var(--font-size-body-large)/1.4 var(--semi-bold-font);
    margin: 0 0 46px;
}

.approach-wrapper span {
    display: block;
    font-family: var(--regular-font);
}

.approach-wrapper img {
    margin-bottom: 40px;
}

.approach-slider-wrapper {
    max-width: 830px;
    margin: 0 auto;
}

.approach-wrapper .slick-dots li button:before,
.identify-wrapper .slick-dots li button:before {
    background-color: transparent;
    border: 3px solid #0073E6;
    width: 15px;
    height: 15px;
    top: 3px;
    left: 3px;
}

.approach-wrapper .slick-dots li button,
.identify-wrapper .slick-dots li button {
    margin: 0;
}

.approach-wrapper .slick-dots li.slick-active button:before,
.identify-wrapper .slick-dots li.slick-active button:before {
    background-color: #0073E6;
    border-width: 0;
}

.approach-wrapper .slick-dotted.slick-slider,
.identify-wrapper .slick-dotted.slick-slider {
    margin: 0;
}

.blue-wrapper {
    background-color: #0073E6;
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-xl);
}

.blue-wrapper .rows {
    color: var(--white-color);
}

.blue-wrapper h2 {
    color: var(--white-color);
    max-width: 64%;
    margin: 0 auto 74px;
}

.blue-wrapper .less-bottom-margin {
    margin-bottom: 36px;
}

.blue-wrapper .psubheading {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: var(--white-color);
    max-width: 60%;
    margin: 0 auto 46px;
}

.icon-text-row p {
    max-width: 86%;
    margin: 26px auto 0;
    color: var(--white-color);
}

.identify-wrapper {
    background-color: #f7fafd;
}

.identify-suheading {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    color: #505e6b;
    max-width: 62%;
    margin: 0 auto 48px;
}

.identify-slider p {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-lg);
    color: #505e6b;
    margin: 0 0 0 44px;
}

.img-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    max-width: 90%;
    margin: 0 auto;
}

.img-text-wrapper img {
    align-self: flex-start;
}

.img-text-wrapper:first-child {
    margin-bottom: 86px;
}

.identify-slider strong {
    font-family: var(--bold-font);
    font-weight: 400;
}

.identify-slider .slick-dots {
    width: 86px;
    top: 384px;
    left: 310px;
    bottom: auto;
}

.activity-video {
    max-width: 70%;
    margin-bottom: 58px;
}

.wrap-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 32px;
}

.wrap-center a {
    display: block;
    margin-bottom: 58px;
}

.wrap-center a:focus-visible,
.dark-blue-btn:focus-visible {
    outline-color: var(--white-color);
}

.two-col-wrapper h2 {
    margin: 0 0 46px;
    text-align: center;
    font-size: var(--font-size-heading-extra-large);
}

.two-col-wrapper .holder {
    min-height: inherit;
    background-color: var(--white-color);
    text-align: left;
}

.two-col-wrapper.cloud360 .holder p {
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-xl);
    margin-bottom: 30px;
}

.two-col-wrapper .white-btn.blue-btn {
    font-family: var(--semi-bold-font);
    margin-top: 10px;
}

.wellness-testimonial {
    background-color: #e6f2ff;
}

.wellness-testimonial strong {
    display: block;
    margin-top: 38px;
    font-size: var(--font-size-heading-medium);
}

.wellness-testimonial .designation {
    font: 400 var(--font-size-body-large)/1.6 var(--semi-bold-font);
    max-width: 78%;
    margin-left: auto;
    margin-right: auto;
}

.logos-wrapper {
    padding-bottom: 52px;
}

.logos-wrapper .icon-text-row p {
    max-width: 66%;
    margin-top: 16px;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .solutions-pages .cloud-page-header-container {
        padding: 120px 0 80px;
    }

    .solutions-pages .rows,
    .two-col-wrapper .rows {
        grid-template-columns: repeat(6, 1fr);
    }

    .solutions-pages img {
        margin-top: 30px;
    }

    .two-col-wrapper .image-align img {
        max-height: inherit;
    }

    .icon-text-row .columns-3 {
        margin-bottom: 30px;
    }

    .bullet-design p {
        padding: 76px 0 0 220px;
    }

    .bullet-design .design-second p {
        padding: 76px 220px 0 34px;
    }

    .bullet-design div {
        font-size: var(--font-size-body-medium);
    }

    .bullet-design span,
    .bullet-design strong {
        font-size: var(--font-size-body-medium);
    }

    .approach-wrapper,
    .blue-wrapper,
    .identify-wrapper,
    .student-health-wrapper,
    .two-col-wrapper .holder,
    .wellness-testimonial,
    .wellness-video-wrapper {
        padding: 80px 0;
    }

    .approach-wrapper h2,
    .blue-wrapper h2,
    .identify-wrapper h2,
    .student-health-wrapper h2 {
        font-size: 34px;
        margin-bottom: 70px;
    }

    .note-text {
        max-width: 70%;
    }

    .img-text-wrapper img {
        width: 300px;
    }

    .identify-slider .slick-dots {
        left: 160px;
    }

    .identify-slider p {
        font-size: var(--font-size-body-small);
    }
}

@media only screen and (max-width: 767px) {
    .solutions-pages .cloud-page-header-container {
        padding: 50px 0;
    }

    .solutions-pages .hero-btns .btn {
        margin: 0 auto;
        line-height: 1;
        display: block;
        width: 200px;
        margin-top: 40px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .solutions-pages .hero-btns img {
        margin: 40px auto;
    }

    .solutions-pages h1 {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .solutions-pages.cloud360 h1~p {
        font-size: var(--font-size-body-medium);
    }

    .approach-wrapper,
    .blue-wrapper,
    .identify-wrapper,
    .student-health-wrapper,
    .two-col-wrapper .holder,
    .wellness-testimonial,
    .wellness-video-wrapper {
        padding: 50px 0;
    }

    .approach-wrapper h2,
    .blue-wrapper h2,
    .identify-wrapper h2,
    .student-health-wrapper h2,
    .two-col-wrapper h2 {
        font-size: var(--font-size-heading-medium);
        margin: 0 auto 30px;
    }

    .bullet-design {
        margin: 0;
    }

    .bullet-design div,
    .bullet-design .design-second,
    .bullet-design .design-third {
        background: none;
        min-height: inherit;
        margin-bottom: 20px;
    }

    .bullet-design span,
    .bullet-design strong {
        font-size: 13px;
    }

    .bullet-design div p {
        font-size: 12px;
        position: relative;
        background-color: var(--blue-color);
        border-radius: 100px;
        height: 100px;
        width: 100%;
        padding: 4% 12px 0 112px;
    }

    .bullet-design div p:after {
        content: "";
        height: 100px;
        width: 100px;
        position: absolute;
        top: 0;
        left: 0;
        background-size: 100px;
        display: block;
    }

    .bullet-design div:first-child p:after {
        background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-1-pic.webp) no-repeat;
    }

    .bullet-design div:nth-child(2) p:after {
        background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-2-pic.webp) no-repeat;
        right: 0;
        left: auto;
    }

    .bullet-design div:nth-child(3) p:after {
        background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/point-3-pic.webp) no-repeat;
    }

    .bullet-design .design-second p {
        padding: 4% 112px 0 30px;
    }

    .approach-wrapper {
        margin-bottom: 50px;
    }

    .approach-wrapper h3 {
        font-size: var(--font-size-body-small);
        margin-bottom: 30px;
    }

    .blue-wrapper h2 {
        max-width: inherit;
    }

    .blue-wrapper .icon-text-row img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .blue-wrapper {
        font-size: var(--font-size-body-extra-small);
        line-height: var(--line-height-md);
    }

    .blue-wrapper .less-bottom-margin {
        margin-bottom: 30px;
    }

    .icon-text-row p {
        max-width: inherit;
        margin-bottom: 20px;
    }

    .identify-suheading,
    .blue-wrapper .psubheading,
    .two-col-wrapper.cloud360 .holder p {
        max-width: inherit;
        font-size: var(--font-size-body-small);
        line-height: var(--line-height-md);
        margin-bottom: 30px;
    }

    .two-col-wrapper.cloud360 .holder p,
    .wellness-testimonial p {
        margin-bottom: 20px;
    }

    .img-text-wrapper:first-child {
        margin-bottom: 50px;
    }

    .identify-slider p,
    .wellness-testimonial {
        font-size: var(--font-size-body-small);
    }

    .identify-slider p {
        max-width: 590px;
        margin: 30px auto 0;
        text-align: center;
    }

    .img-text-wrapper {
        max-width: inherit;
        display: block;
        text-align: center;
    }

    .identify-slider .slick-dots {
        width: 100%;
        left: 0;
    }

    .activity-video {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .two-col-wrapper .white-btn.blue-btn {
        padding-right: 22px;
        padding-left: 22px;
    }

    .wellness-testimonial .leader-testimonial img {
        width: 100px;
        height: 100px;
    }

    .wellness-testimonial strong {
        margin: 12px 0;
        font-size: var(--font-size-body-small);
    }

    .wellness-testimonial .designation {
        font: 400 var(--font-size-body-extra-small)/1.2 var(--regular-font);
        max-width: inherit;
        margin-bottom: 30px;
    }

    .wellness-testimonial .leader-testimonial .uintah-logo {
        width: 120px;
        height: auto;
    }

    .blue-wrapper .wrap-center img {
        width: 120px;
        height: auto;
        max-height: 26px;
    }

    .logos-wrapper .icon-text-row p {
        max-width: inherit;
    }

    .wrap-center a {
        width: 44%;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px) {

    .approach-wrapper .newGrid_container,
    .blue-wrapper .newGrid_container,
    .identify-wrapper .newGrid_container,
    .student-health-wrapper .newGrid_container,
    .two-col-wrapper .holder .newGrid_container,
    .wellness-testimonial .newGrid_container,
    .wellness-video-wrapper {
        margin: 0 16px;
    }
}

@media only screen and (max-width: 320px) {
    .two-col-wrapper .securlyhome-logo-img {
        width: inherit;
    }
}

/* Student wellness page css ends */
/* Frederick case study page css starts */
.margin-top-0,
.page-wrapper .margin-top-0 {
    margin-top: 0;
}

.frederick-county-public-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/frederick-hero.webp) no-repeat;
    margin-bottom: 110px;
}

.frederick-county-public-header .case-study-intro .center-heading {
    font-size: var(--font-size-heading-extra-large);
    margin-bottom: 10px;
}

.contained-text {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-body-extra-small);
    line-height: 1.78;
    color: var(--blue-gray-color);
}

.case-study-ul {
    margin: 0;
    padding: 0;
    font-family: var(--semi-bold-font);
    font-weight: 400;
}

.case-study-ul li {
    margin-bottom: 20px;
}

@media only screen and (max-width: 992px) {
    .frederick-county-public-header .case-study-intro .center-heading {
        font-size: 28px;
    }

    .frederick-county-public-header {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .contained-text {
        max-width: inherit;
    }
}

/* Frederick case study page css ends */
/* Case studies page css starts */
.pickens-county-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/pickens-hero.webp) no-repeat;
}

.chrometools-page .newGrid_container.light-blue-inline-testimonial img {
    margin: 0 0 20px;
}

.chrometools-page .newGrid_container.light-blue-inline-testimonial {
    display: block;
    text-align: center;
    font-style: normal;
    font-family: var(--regular-font);
}

.with-light-blue-bg {
    background-color: #E8F2FE;
}

.light-blue-testimonial strong {
    font-family: var(--extra-bold-font);
    font-style: italic;
}

.chico-header-top {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/chico-hero.webp) no-repeat;
}

.stats-caption .case-study-stats-block p {
    font-size: var(--font-size-body-large);
    max-width: inherit;
}

.white-btn.extra-top-margin-btn {
    margin-top: 50px;
}

.white-btn.btn-margin-bottom {
    margin-bottom: 40px;
}

.mobile-stats .case-study-stats-block div {
    min-height: inherit;
}

.justify-center {
    justify-self: center;
}

.carbon-county-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/carbon-county-hero.webp) no-repeat;
}

.numbers-block .case-study-stats.light-blue-stats {
    margin: 0;
}

.numbers-block .light-blue-stats .case-study-stats-block div,
.full-blue-holder {
    background-color: var(--blue-color);
    min-height: inherit;
    margin-bottom: 0;
    padding: 14px 20px;
}

.numbers-block .light-blue-stats .case-study-stats-block h2 {
    color: var(--white-color);
    font-size: 44px;
}

.static-pages .numbers-block .light-blue-stats .case-study-stats-block p {
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    line-height: 1.28;
    margin-top: 0;
}

.numbers-block .rows {
    grid-gap: 16px;
}

.numbers-block {
    justify-content: flex-start;
}

.about-para {
    color: var(--white-color);
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
    margin-bottom: 100px;
}

.font-size-adjusts .icon-text-row p {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.57;
}

.font-size-adjusts .icon-text-row p {
    color: var(--white-color);
}

.para-with-bg {
    border-radius: 10px;
    background-color: #f7fafc;
    padding: 20px 50px;
    text-align: center;
}

.para-with-bg.light-blue {
    background-color: #e8f2fe;
}

.page-wrapper .rows .para-with-bg {
    color: var(--heading-text-color);
}

.para-with-bg strong {
    color: var(--blue-btn-hover);
}

.virginia-beach-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/virginia-hero.webp) no-repeat;
}

.display-flex {
    display: flex;
    justify-content: center;
}

.numbers-block.display-flex .light-blue-stats {
    display: flex;
    grid-gap: 16px;
    margin-top: 98px;
}

.numbers-block.display-flex .light-blue-stats.margin-top-0 {
    margin-top: 0;
}

.static-pages.margin-bottom-0 {
    margin-bottom: 0;
}

.more-top-space {
    margin-top: 70px;
}

.cloud360 .heading-margin-bottom {
    margin-bottom: 60px;
}

.box-grid .display-none {
    display: none;
}

.cisd-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/cisd-hero.webp) no-repeat;
    margin-bottom: 110px;
}

.eastern-carver-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/eastern-carver-hero.webp) no-repeat;
    margin-bottom: 110px;
}

.eastern-carver-header .center-heading {
    font-size: var(--font-size-heading-extra-large);
    line-height: 1.25;
}

.full-blue-holder {
    border-radius: 10px;
    margin-top: 16px;
    color: var(--white-color);
    padding: 20px 58px;
}

.div-flex-wrap .numbers-block .light-blue-stats .case-study-stats-block div {
    padding: 16px 30px;
}

.full-blue-holder .display-flex {
    grid-gap: 30px;
}

.full-blue-holder strong {
    display: block;
    text-align: center;
    font: 900 18px/1.22 var(--extra-bold-font);
}

.full-blue-holder ul {
    color: var(--white-color);
    font-size: var(--font-size-body-medium);
    line-height: 1.22;
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
}

.full-blue-holder ul strong {
    display: inline-block;
    text-align: left;
}

.full-blue-holder .display-flex {
    justify-content: space-around;
}

.div-flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.numbers-block .light-blue-stats .case-study-stats-block h2.larger-size {
    font-size: 50px;
}

@media screen and (min-width: 768px) {
    .gap-8 {
        grid-gap: 8%;
    }
}

@media screen and (max-width: 1024px) {
    .numbers-block {
        justify-content: flex-start;
        margin-top: 50px;
    }
}

@media screen and (max-width: 767px) {
    .cloud360 .numbers-block {
        display: block;
        margin-top: 30px;
    }

    .numbers-block .light-blue-stats .case-study-stats-block h2 {
        font-size: 30px;
    }

    .full-header-top img.carbon-logo {
        width: 270px;
    }

    .para-with-bg {
        padding-right: 30px;
        padding-left: 30px;
    }

    .about-para {
        margin-bottom: 50px;
    }

    .numbers-block.display-flex .light-blue-stats {
        flex-direction: column;
    }

    .cisd-header {
        margin-bottom: 30px;
    }

    .div-flex-wrap {
        display: block;
    }

    .cloud360 .div-flex-wrap .numbers-block {
        padding-bottom: 0;
    }

    .full-blue-holder {
        padding-right: 20px;
        padding-left: 20px;
    }

    .full-blue-holder .display-flex {
        grid-gap: 15px;
    }

    .eastern-carver-header {
        margin-bottom: 50px;
    }

    .numbers-block .light-blue-stats .case-study-stats-block h2.larger-size {
        font-size: var(--font-size-heading-extra-large);
    }

    .mobile-stats .case-study-stats-block div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 1350px) and (min-width: 1199px) {}

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .gap-8 {
        grid-template-columns: repeat(12, 1fr);
    }

    .div-flex-wrap .numbers-block.display-flex .light-blue-stats {
        display: block;
    }

    .div-flex-wrap .numbers-block.display-flex .light-blue-stats .case-study-stats-block {
        margin-bottom: 20px;
    }

    .full-blue-holder {
        margin-top: 0;
    }

    .full-blue-holder {
        padding-right: 30px;
        padding-left: 30px;
    }
}

/* Case studies page css ends */

/* Discern page css starts */
.light-teal-bg {
    background-color: #EBF8F9;
    min-height: 600px;
    display: grid;
    place-items: center;
}

.light-teal-bg .chromebook-header-without-bg {
    padding-top: 0;
}

.light-teal-section-bg {
    background-color: #F2FAFB;
}

.blue-gradient-btn {
    background-image: linear-gradient(to right, #3FA9F5 22%, #3EA5F1 35%, #3B99E5 48%, #3685D2 61%, #2F69B7 73%, #264594 86%, #1C1A6A 98%, #1B1464);
    color: var(--white-color);
    padding: 13px 26px;
    box-shadow: 2px 4px 0 #9DB7D1;
    height: 42px;
    font: 400 var(--font-size-body-small)/1 var(--extra-bold-font);
    border-radius: 42px;
    border: 0;
}

.blue-gradient-btn:hover,
.blue-gradient-btn:focus,
.blue-gradient-btn:active {
    box-shadow: 2px 4px 0 #9DB7D1;
    background-image: linear-gradient(#1b1464, #1b1464);
    color: var(--white-color);
}

.light-teal-bg .chromebook-header-without-bg h1 {
    font: 400 62px/1 var(--bold-font);
    color: #0A4880;
    margin-bottom: 22px;
}

.light-teal-bg .chromebook-header-without-bg h3 {
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: #0A4880;
    margin-bottom: 28px;
}

.light-teal-section-bg .static-pages h3 {
    font-size: 44px;
    line-height: 1.14;
    text-align: right;
    color: #0A4880;
}

.light-teal-section-bg .cloud360 .holder p {
    font-size: var(--font-size-heading-medium);
    line-height: 1.25;
    text-align: right;
    color: #0A4880;
}

.discern-logo {
    margin-bottom: 28px;
}

.light-teal-section-bg .static-pages .text-left,
.light-teal-section-bg .cloud360 .holder .text-left {
    text-align: left;
}

.ceo-img {
    background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/discern-quote.webp) no-repeat;
    min-height: 346px;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 106px;
    font-size: var(--font-size-heading-small);
    line-height: 1.25;
    text-align: center;
    color: #0a4880;
    display: flex;
    justify-content: flex-end;
}

.ceo-msg {
    max-width: 488px;
    margin: 72px 50px 0 0;
}

.ceo-msg p {
    margin-bottom: 0;
}

.ceo-msg span,
.ceo-msg strong {
    font-size: var(--font-size-body-large);
    display: block;
}

.flex-wrap {
    flex-wrap: wrap;
}

.numbers-block .flex-wrap .case-study-stats-block div {
    height: 104px;
    width: 220px;
}

.numbers-block .flex-wrap .case-study-stats-block p {
    max-width: inherit;
}

.madison-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/madison-hero.webp) no-repeat;
    margin-bottom: 80px;
}

.width-100 {
    width: 100%;
}

.last-info-section {
    background-color: #ebf8f9;
    padding: 76px 0 106px 0;
}

.last-info-section h2 {
    font: 400 44px/1.14 var(--bold-font);
    color: #0a4880;
    margin: 0 0 24px;
    text-align: center;
}

.last-info-section h2::after {
    background: 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/discern-map.webp) no-repeat;
    content: "";
    width: 110px;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.last-details {
    border-radius: 25px;
    background-color: #f6fbff;
    padding: 36px 52px;
    max-width: 58%;
    margin: 0 auto;
    font-size: var(--font-size-heading-medium);
    line-height: 1.46;
    color: #0a4880;
}

.last-details strong {
    display: block;
    margin-bottom: 20px;
}

.last-details .bullet-list li {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/discern-bullet.svg) 0 6px no-repeat;
    font-family: var(--regular-font);
    padding-bottom: 0;
}

.button-with-icon {
    background: top left/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/apply-btn.webp) no-repeat;
    font: 400 var(--font-size-heading-medium)/1 var(--extra-bold-font);
    color: var(--white-color);
    margin: 0 auto 122px;
    width: 404px;
    height: 91px;
    display: block;
    padding-top: 54px;
    transition: none;
    border: 0;
}

.button-with-icon:hover,
.button-with-icon:focus,
.button-with-icon:active {
    background: 0 -93px/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/apply-btn.webp) no-repeat;
    color: var(--white-color);
    box-shadow: none;
}

.beta {
    font: 400 10px/1.6 var(--bold-font);
    color: var(--heading-text-color);
    background-color: #ff0;
    padding: 0 2px;
    border-radius: 2px;
    height: 14px;
    display: inline-block;
    position: relative;
    top: -3px;
}

.uplift-header-top {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/uplift-education-hero.webp) no-repeat;
}

.normal-font-ul li {
    font-family: var(--regular-font);
}

.page-wrapper .side-margin-subheading {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .light-teal-bg .rows {
        grid-template-columns: repeat(6, 1fr);
    }

    .light-teal-section-bg .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 50px;
    }

    .light-teal-section-bg .static-pages h3 {
        font-size: 30px;
    }

    .light-teal-section-bg .cloud360 .holder p {
        font-size: var(--font-size-heading-small);
    }
}

@media screen and (max-width: 1024px) {
    .light-teal-bg .chromebook-header-without-bg h1 {
        font-size: 46px;
    }

    .light-teal-bg {
        margin-top: 50px;
    }

    .ceo-img {
        padding-bottom: 50px;
        font-size: 17px;
    }

    .ceo-img p {
        margin-bottom: 4px;
    }

    .ceo-msg {
        max-width: 390px;
        margin: 9% 50px 0 0;
    }

    .ceo-msg span,
    .ceo-msg strong {
        font-size: var(--font-size-body-medium);
    }

    .last-info-section h2 {
        font-size: 38px;
    }

    .last-info-section h2:after {
        width: 90px;
        height: 80px;
    }

    .page-wrapper .side-margin-subheading {
        max-width: inherit;
    }
}

@media screen and (max-width: 767px) {
    .numbers-block .flex-wrap .case-study-stats-block div {
        height: inherit;
        margin: 0 auto;
    }

    .madison-header {
        margin-bottom: 0;
    }

    .light-teal-bg .chromebook-header-without-bg h1 {
        font-size: 36px;
    }

    .discern-logo {
        width: 200px;
    }

    .border-pic {
        border: 4px solid #73b9ff;
        border-radius: 50%;
    }

    .wellness-testimonial .designation.margin-bottom-0 {
        margin-bottom: 0;
    }

    .light-teal-section-bg .static-pages h3 {
        font-size: 26px;
        text-align: center;
    }

    .light-teal-section-bg .cloud360 .holder p {
        font-size: var(--font-size-body-large);
        text-align: center;
    }

    .light-teal-section-bg .cloud360 .holder .text-left,
    .light-teal-section-bg .static-pages .text-left {
        text-align: center;
    }

    .light-teal-section-bg .image-align-left,
    .light-teal-section-bg .img-right {
        justify-self: center;
    }

    .last-info-section {
        padding: 50px 0;
    }

    .last-info-section h2 {
        font-size: 30px;
    }

    .last-info-section h2:after {
        width: 50px;
        height: 60px;
        display: block;
        margin: 10px auto 0;
    }

    .button-with-icon {
        margin-bottom: 40px;
    }

    .last-details {
        max-width: inherit;
    }

    .last-details {
        font-size: var(--font-size-body-large);
        padding: 30px;
        border-radius: 12px;
    }

    .button-with-icon {
        font-size: 15px;
        width: 262px;
        height: 60px;
        padding-top: 36px;
    }

    .button-with-icon:active,
    .button-with-icon:focus,
    .button-with-icon:hover {
        background-position: 0 -60px;
    }

    .latest-case-studies .rows {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Discern page css ends */

/* New home page css starts */

.blue-bg-header {
    background: left bottom/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/home-hero-bg.webp) no-repeat;
    min-height: 670PX;
}

.second-slide-active {
    background: left bottom/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/home-hero2-bg.webp) no-repeat;
}

.blue-bg-header h1 {
    font: 400 48px/1.25 var(--extra-bold-font);
    color: var(--white-color);
    margin-bottom: 28px;
}

.blue-bg-header h1~p,
.element-styling {
    color: var(--white-color);
}

.white-btn.dark-blue-btn {
    background-color: var(--blue-btn-active);
    border-radius: var(--default-border-radius);
}

.white-btn.dark-blue-btn:hover,
.white-btn.dark-blue-btn:focus,
.white-btn.dark-blue-btn:active {
    background-color: var(--btn-color);
}

.slider-qoute {
    width: 60%;
    margin: 36px auto 0;
    color: var(--white-color);
    text-align: center;
    font-size: var(--font-size-body-medium);
    font-style: italic;
    line-height: 1.22;
}

.slider-qoute strong {
    display: block;
}

.index-intro h2 {
    font-family: var(--extra-bold-font);
}

.index-intro p {
    max-width: 74%;
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-heading-medium);
    color: var(--body-text-color);
    line-height: 1.25;
}

.colors-sections .rows {
    grid-gap: 18px;
}

.colors-sections img {
    filter: drop-shadow(0 0 8px rgba(167, 181, 219, 0.6));
    object-fit: cover;
}

.colors-sections .middle-desc {
    box-shadow: 0 0 8px 0 rgba(167, 181, 219, 0.6);
    border-radius: 0 0 6px 6px;
    padding: 22px;
    font-size: var(--font-size-body-small);
    line-height: 1.38;
    color: var(--body-text-color);
    transition: all .1s ease-in;
}

.hover-img {
    display: none;
}

.colors-sections a:hover>.normal-img {
    display: none;
}

.colors-sections a:hover>.hover-img,
.uk-index-section a:hover>.normal-img {
    display: block;
}

.colors-sections a:hover .plain-button {
    background-color: var(--blue-btn-hover);
}

.colors-sections a:nth-child(1):hover .middle-desc {
    background-color: #eafffd;
}

.colors-sections a:nth-child(2):hover .middle-desc {
    background-color: #f7f7ff;
}

.colors-sections a:nth-child(3):hover .middle-desc {
    background-color: #fffae8;
}

.colors-sections a:nth-child(4):hover .middle-desc {
    background-color: #fff8f8;
}

.colors-sections .middle-desc h3 {
    font: 400 var(--font-size-body-large)/1.3 var(--semi-bold-font);
    color: var(--heading-text-color);
    margin: 6px 0 8px;
}

.plain-button {
    display: block;
    background-color: var(--blue-color);
    font-family: var(--semi-bold-font);
    height: 44px;
    padding: 11px 0;
    border-radius: var(--default-border-radius);
    color: var(--white-color);
    text-align: center;
    transition: all .1s ease-in;
}

.home-latest-stats {
    background-color: transparent;
    padding-top: 0;
    color: var(--body-text-color);
}

.home-stats.home-latest-stats .statsTitle {
    font: 400 48px/1.25 var(--extra-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 10px;
}

.home-stats.home-latest-stats .stats-subheading {
    font-size: var(--font-size-heading-medium);
    line-height: 1.17;
    color: var(--body-text-color);
    margin: 0 auto 90px;
    max-width: 70%;
}

.home-stats.home-latest-stats p {
    line-height: var(--line-height-sm);
}

.home-stats.home-latest-stats h2 {
    font: 400 var(--font-size-heading-extra-large)/1.2 var(--extra-bold-font);
    color: var(--blue-color);
    margin: 24px 0 0;
}

.home-stats.home-latest-stats h5,
.home-stats.home-latest-stats .h5-heading {
    color: var(--body-text-color);
    margin-bottom: 54px;
}

.home-latest-stats .stats-source strong,
.home-latest-stats .stats-source em {
    font-size: var(--font-size-body-medium);
    line-height: 1.22;
    margin-bottom: 0;
}

.home-stats.home-latest-stats figure {
    height: 233px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.home-stats.home-latest-stats img {
    height: auto;
    object-fit: inherit;
}

.big-border-btn {
    height: 44px;
    padding: 10px 38px;
    border-radius: var(--default-border-radius);
    border: solid 3px var(--blue-color);
    background-color: var(--white-color);
    color: var(--blue-color);
    display: inline-block;
    font: 400 var(--font-size-body-large)/1 var(--semi-bold-font);
}

.big-border-btn:hover,
.big-border-btn:focus,
.big-border-btn:active {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.case-wrapper .rows {
    gap: 20px;
}

.case-wrapper .columns-3 {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
}

.case-wrapper .columns-3 a {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--white-color);
    font-size: var(--font-size-body-small);
    line-height: 1.25;
    border-radius: 25%;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

.case-wrapper figure {
    height: 114px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.case-wrapper img {
    margin-bottom: 30px;
    transition: all .1s ease-in;
}

.case-wrapper p {
    margin: 0 20px;
    text-align: center;
}

.case-wrapper strong {
    display: block;
    font-family: var(--extra-bold-font);
    font-style: italic;
}

.case-wrapper .columns-3:nth-child(1) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-study1-bg.webp);
}

.case-wrapper .columns-3:nth-child(2) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-study2-bg.webp);
}

.case-wrapper .columns-3:nth-child(3) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-study3-bg.webp);
}

.case-wrapper .columns-3:nth-child(4) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-study4-bg.webp);
}

.case-wrapper .columns-3 a:hover,
.case-wrapper .columns-3 a:active,
.case-wrapper .columns-3 a:focus {
    transition: all .1s ease-in;
    background-color: var(--blue-color);
    background-blend-mode: hue;
}

.case-wrapper .columns-3 a:hover img {
    transform: scale(1.1);
}

.why-love-us {
    text-align: center;
}

.extra-bold-heading {
    font: 400 48px/1.25 var(--extra-bold-font);
    color: var(--heading-text-color);
    margin: 0 0 70px;
    text-wrap: balance;
}

.why-love-us .its-content {
    max-width: 470px;
    margin: 26px auto 0;
    font-size: var(--font-size-body-medium);
    line-height: 1.22;
    color: var(--body-text-color);
}

.why-love-us strong {
    display: block;
    font-size: var(--font-size-body-large);
    margin-bottom: 22px;
}

.why-love-us .columns-6 {
    margin-bottom: 54px;
}

.ceo-quote {
    background: top/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/ceo-quote.svg) no-repeat;
    max-width: 856px;
    min-height: 206px;
    font-size: var(--font-size-body-medium);
    line-height: 1.22;
    color: var(--blue-gray-color);
    text-align: left;
    margin: 50px auto;
}

.ceo-quote .ceo-msg {
    max-width: 544px;
    margin: 42px 58px 0 0;
}

.ceo-quote span,
.ceo-quote strong {
    font-size: var(--font-size-body-medium);
    margin-bottom: 0;
}

.ceo-quote p {
    margin-bottom: 22px;
}

.learning-section {
    grid-gap: 20px;
}

.learning-section a {
    background-color: #ebfbf8;
    font-size: var(--font-size-body-small);
    line-height: 1.4;
    text-align: center;
    color: var(--body-text-color);
    padding: 28px;
    min-height: 300px;
    border-radius: var(--default-border-radius);
    display: block;
}

.learning-section strong {
    font: 400 var(--font-size-body-medium)/1.22 var(--extra-bold-font);
    color: var(--body-text-color);
    display: block;
    margin-bottom: 20px;
}

.learning-section img {
    margin-bottom: 20px;
}

.learning-section p {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.learning-section .columns-3 {
    min-height: 388px;
}

.learning-section a:hover>p,
.learning-section a:focus-visible>p {
    max-height: 100px;
    transition: max-height 0.2s ease-in;
}

.light-teal-btn {
    height: 44px;
    border-radius: var(--default-border-radius);
    background-color: #afefe6;
    width: 22%;
    padding: 10px;
    font: 400 var(--font-size-body-medium)/1.3 var(--semi-bold-font);
    color: var(--btn-color);
    margin-left: 2%;
}

.light-teal-btn:hover,
.light-teal-btn:active,
.light-teal-btn:focus {
    background-color: #21c1aa;
    color: var(--white-color);
}

.in-line-btns {
    margin-left: -2%;
}

.large-paragraph {
    font-size: var(--font-size-heading-medium);
    line-height: 1.17;
    color: var(--body-text-color);
}

.in-line-btns {
    margin-bottom: 100px;
}

.blue-bg-footer {
    background: top/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/new-footer-background.svg) no-repeat;
}

.blue-bg-footer>.newGrid_container {
    margin-top: 200px;
}

.blue-bg-post-footer {
    background-color: var(--blue-btn-active);
}

.blue-bg-post-footer ul#post-footer-menu li,
.blue-bg-post-footer ul#post-footer-menu li a {
    font-size: var(--font-size-body-small);
}

.blue-bg-footer .footer-nav ul,
.blue-bg-footer .footer-nav h4,
.blue-bg-footer .footer-nav h4 a {
    font-size: var(--font-size-body-small);
}

.blue-bg-footer .semi-bold-font {
    font-family: var(--semi-bold-font);
}

.blue-bg-footer .footer-nav h4 {
    padding-bottom: 20px;
}

.blue-bg-footer #stayconnected {
    padding-bottom: 0;
}

.centered-btn.marginTop0 {
    margin-top: 0;
}

.make-change .rows {
    grid-gap: 74px;
}

.blue-bg-header .white-btn.blue-btn {
    margin-top: 50px;
}

video {
    clip-path: inset(1px 1px);
}

@media screen and (max-width: 1300px) {
    .in-line-btns .light-teal-btn:not(.theme-btn) {
        padding: 13px 4px;
        font-size: var(--font-size-body-extra-small);
    }

    .post-footer.blue-bg-post-footer {
        height: auto;
        text-align: center;
    }

    .learning-section p {
        max-height: inherit;
        overflow: visible;
        transition: none;
        margin-bottom: 0;
    }

    .learning-section .columns-3 {
        min-height: inherit;
        display: flex;
        margin-bottom: 30px;
    }

    .full-section {
        background-position: 0 200%;
    }

    .case-wrapper img {
        max-width: 64%;
        margin: 0 auto 20px;
    }

    .case-wrapper figure {
        height: auto;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .blue-color-header.blue-bg-header {
        min-height: 600px;
    }

    .blue-bg-header .rows,
    .why-love-us .rows,
    .make-change .rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .why-love-us .rows,
    .make-change .rows {
        grid-gap: 0 50px;
    }

    .blue-bg-header .cloud-page-header-container {
        padding-top: 60px;
    }

    .blue-bg-header .header-width * {
        text-align: left;
    }

    .text-and-img span {
        margin-top: 20px;
    }

    .blue-color-header.blue-bg-header .header-slider .slick-dots {
        bottom: 0;
    }

    .case-wrapper img {
        max-width: 70%;
        margin-right: auto;
        margin-left: auto;
    }

    .case-wrapper .columns-3 a {
        font-size: var(--font-size-body-extra-small);
    }

    .case-wrapper figure {
        height: auto;
    }

    .index-intro h2,
    .home-stats.home-latest-stats .statsTitle,
    .extra-bold-heading {
        font-size: 34px;
    }

    .extra-bold-heading {
        margin-bottom: 30px;
    }

    .centered-btn {
        margin: 50px auto;
    }

    .home-stats.home-latest-stats h2 {
        font-size: 28px;
    }

    .home-stats.home-latest-stats img {
        max-width: 80%;
    }

    .home-stats.home-latest-stats h5,
    .home-stats.home-latest-stats .h5-heading {
        margin-bottom: 30px;
    }

    .ceo-quote .ceo-msg {
        max-width: 68%;
        margin: 5% 3% 0 0;
    }

    .ceo-quote,
    .ceo-quote span,
    .ceo-quote strong {
        font-size: var(--font-size-body-extra-small);
    }

    .ceo-quote {
        margin: 20px auto;
    }

    .ceo-quote p {
        margin-bottom: 16px;
    }

    .why-love-us .columns-6 {
        margin-bottom: 24px;
    }

    .home-stats.home-latest-stats figure {
        height: 140px;
    }

    .centered-btn.marginTop0 {
        margin-top: 50px;
    }

    .large-paragraph {
        font-size: var(--font-size-body-large);
    }

    .in-line-btns .light-teal-btn {
        width: 46%;
        margin-bottom: 2.5%;
    }

    .slider-qoute {
        width: 90%;
    }

    .in-line-btns {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
    .blue-bg-footer>.newGrid_container {
        margin-top: 0;
        padding-top: 220px;
    }
}

@media only screen and (min-width: 1150px) {

    .home-latest-stats .newGrid_container,
    .why-love-us .newGrid_container {
        width: 1074px;
        margin: 0 auto;
    }

    .home-intro {
        max-width: 1074px;
        margin-bottom: 124px;
    }

    .colors-sections {
        max-width: 1074px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1149px) {

    .middle-desc p {
        min-height: 68px;
    }

    .blue-bg-header h1 {
        font-size: 34px;
        margin-top: 50px;
    }

    .blue-color-header .white-btn.blue-btn {
        margin-top: 20px;
    }

    .why-love-us .newGrid_container.hidden-xs {
        margin: 0;
    }

    .blue-bg-post-footer ul#post-footer-menu li,
    .blue-bg-post-footer ul#post-footer-menu li a {
        font-size: 13px;
    }
}

@media only screen and (min-width: 991px) {
    .blue-bg-post-footer {
        height: 68px;
        padding: 24px 0;
    }

    .blue-bg-footer .footer-nav.company-section {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 992px) {
    .footer-nav .mob-view li {
        background-color: transparent;
    }

    .blue-gradiant.home-latest-stats .columns-4 {
        padding-right: 0;
        padding-left: 0;
    }

    .blue-bg-footer .footer-nav h4 {
        border-bottom: 1px solid #57687a;
    }

    .blue-bg-footer .mob-view li a:hover {
        background-color: #041f3b;
    }

    .blue-bg-footer #contactus,
    .blue-bg-footer #stayconnected {
        color: #4da6ff;
    }

    .blue-bg-footer .footer-nav h4#contactus {
        padding-top: 40px;
    }

    .blue-bg-footer #stayconnected {
        margin-bottom: 20px;
    }

    .blue-bg-footer .semi-bold-font {
        margin-top: 20px;
    }

    .post-footer.blue-bg-post-footer .newGrid_container {
        margin: 0 7.7777777778%;
    }
}

@media only screen and (max-width: 767px) {
    .blue-bg-footer {
        background: top/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/new-footer-mobile-background.svg) no-repeat;
        padding-top: 70px
    }

    .blue-bg-footer>.newGrid_container {
        margin-top: 0;
    }

    .in-line-btns .light-teal-btn {
        display: block;
        width: 250px;
        margin: 0 0 20px 0;
        padding: 11px;
        font-size: var(--font-size-body-small);
    }

    .in-line-btns {
        width: 250px;
        margin: 20px auto 30px;
    }

    .home-stats.home-latest-stats p {
        display: block;
    }

    .large-paragraph {
        font-size: var(--font-size-body-small);
    }

    .extra-bold-heading,
    .home-intro h2,
    .home-stats.home-latest-stats .statsTitle {
        font-size: var(--font-size-heading-medium);
        margin-bottom: 0;
    }

    .make-change .rows {
        grid-gap: 16px;
    }

    .index-intro p {
        max-width: inherit;
        font-size: var(--font-size-body-medium);
    }

    .post-footer.blue-bg-post-footer {
        padding-right: 0;
        padding-left: 0;
    }

    .blue-color-header.blue-bg-header h1 {
        font-size: var(--font-size-heading-small);
        margin: 30px 0 14px;
    }

    .blue-color-header.blue-bg-header p {
        font-size: var(--font-size-body-small);
    }

    .seo-header.header-slider .slick-dots {
        bottom: auto;
        text-align: center;
    }

    .seo-header.header-slider img {
        margin-bottom: 50px;
    }

    .seo-header.header-slider .slick-dots li button:before {
        width: 14px;
        height: 14px;
    }

    .seo-header.header-slider .slick-dots li {
        margin-right: 14px;
    }

    .seo-header.header-slider video {
        margin-bottom: 40px;
        max-height: 300px;
    }

    .blue-bg-header .visible-none {
        margin-top: 20px;
    }

    .blue-bg-header {
        background: bottom/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/slider-hero-mobile-bg1.webp) no-repeat;
    }

    .second-slide-active {
        background: bottom/cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/slider-hero-mobile-bg2.webp) no-repeat;
    }

    .colors-sections .rows {
        grid-gap: 16px;
        grid-template-columns: repeat(1, 1fr);
    }

    .colors-sections img {
        filter: none;
        margin: 0 auto 20px;
    }

    .colors-sections .middle-desc {
        background: transparent;
        box-shadow: none;
        transition: none;
        padding: 0;
        text-align: center;
    }

    .middle-desc p {
        min-height: auto;
    }

    .middle-desc br {
        display: none;
    }

    .middle-desc .plain-button,
    .colors-sections a:hover .plain-button,
    .colors-sections a:focus .plain-button,
    .colors-sections a:active .plain-button {
        background: transparent;
        color: var(--blue-color);
        border: 3px solid var(--blue-color);
        width: 180px;
        margin: 0 auto;
        height: 38px;
        padding: 7px;
    }

    .colors-sections a:hover .middle-desc,
    .colors-sections a:focus .middle-desc,
    .colors-sections a:active .middle-desc {
        background: transparent !important;
    }

    .colors-sections .columns-3 {
        margin-bottom: 30px;
    }

    .big-border-btn:not(.dark-blue-border-btn, .dark-blue-fill-btn, .dark-green-border-btn, .brown-border-btn, .navy-border-btn) {
        background-color: var(--blue-color);
        color: var(--white-color);
        width: 180px;
        margin: 0 auto;
        height: 38px;
        padding: 9px;
        font-size: var(--font-size-body-small);
    }

    .home-stats.home-latest-stats .stats-subheading {
        font-size: var(--font-size-body-medium);
        max-width: inherit;
        margin: 20px 0;
    }

    .centered-btn {
        margin-bottom: 40px;
    }

    .home-stats.home-latest-stats h2 {
        font-size: var(--font-size-heading-small);
        margin: 0;
    }

    .home-stats.home-latest-stats h5,
    .home-stats.home-latest-stats .h5-heading {
        margin-bottom: 0;
    }

    .case-wrapper .rows {
        margin-top: 30px;
        grid-template-columns: repeat(1, 1fr);
    }

    .case-wrapper .columns-3 {
        padding-bottom: 0;
    }

    .case-wrapper .columns-3 a {
        position: inherit;
        width: 240px;
        height: 140px;
        margin: 0 auto 30px;
        font-size: 12px;
    }

    .case-wrapper figure {
        height: auto;
    }

    .case-wrapper img {
        max-width: 60%;
        margin: 0 auto 20px;
    }

    .case-wrapper .big-border-btn {
        width: auto;
        padding-right: 14px;
        padding-left: 14px;
    }

    .case-wrapper .columns-3:first-child a {
        background-size: contain;
        background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/case-study1-mobile-bg.webp);
        border-radius: 10px;
    }

    .home-latest-stats .stats-source strong {
        margin-top: 30px;
    }

    .home-latest-stats .stats-source strong,
    .home-latest-stats .stats-source em {
        display: block;
        font-size: var(--font-size-body-extra-small);
    }

    .why-love-us .extra-bold-heading,
    .why-love-us .columns-6 {
        margin-bottom: 30px;
    }

    .why-love-us strong {
        font-size: var(--font-size-body-small);
        margin-bottom: 0;
    }
}

/* New home page css ends */
/* Case studies page css strts */
.chrometools-page .newGrid_container.blue-color-testimonial {
    font: 400 var(--font-size-body-large)/1.4 var(--bold-font);
    color: var(--white-color);
    margin-top: 60px;
    background-color: var(--blue-color);
}

.chrometools-page .newGrid_container.blue-color-testimonial span {
    font: 400 var(--font-size-body-medium)/1.4 var(--regular-font);
    color: var(--white-color);
    display: block;
    margin-top: 16px;
}

.white-bg-profiles {
    background-color: var(--white-color);
    font-size: var(--font-size-body-small);
    padding: 90px 0;
}

.white-bg-profiles h2 {
    font: 400 48px/1.25 "MetropolisExtraBold", sans-serif;
    color: var(--heading-text-color);
    margin: 0 0 84px;
}

.white-bg-profiles strong {
    font-family: var(--bold-font);
    color: var(--heading-text-color);
    font-style: italic;
}

.white-bg-profiles .strong-font {
    font-family: var(--bold-font);
    font-style: normal;
    color: var(--blue-gray-color);
    line-height: 1.38;
}

.white-bg-profiles p {
    font-size: var(--font-size-body-extra-small);
    line-height: 1.43;
    color: var(--blue-gray-color);
    max-width: 80%;
    margin: 0 auto;
}

.white-bg-profiles img {
    margin-bottom: 18px;
}

.top-yt-video {
    display: block;
}

.white-bg-profiles a:hover img,
.top-yt-video:hover img {
    transform: scale(1.02);
    transition: all .1s ease-in;
}

.with-large-text-size .text-center.right-left-margin p {
    font-size: var(--font-size-heading-medium);
    line-height: 1.42;
    color: var(--blue-gray-color);
}

.with-large-text-size .content-blue-btn {
    margin-top: 0;
}

.with-large-text-size .btn {
    width: 230px;
}

.with-large-text-size strong {
    font-family: var(--semi-bold-font);
}

.csiu-hero {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/csiu-hero.webp) no-repeat;
}

.case-study-stats.light-blue-bg .rows {
    grid-gap: 22px;
}

.sectionwise.margin-top-0 {
    margin-top: 0;
}

.pass-demo {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/pass-form-bg.webp) left bottom no-repeat;
}

.sheboygan-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/sheboygan-falls-hero.webp) no-repeat;
}

.south-gibson-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/south-gibson-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.queensbury-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/queensbury-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.camelback-header {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/camelback-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.perins-mat-hero {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/perins-mat-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.memphis-hero {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/memphis-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.tring-hero {
    background: 100% 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/tring-hero.webp) no-repeat;
    margin-bottom: 100px;
}

.newGrid_container.margin-adjust {
    margin: 20px 0 40px;
}

.inline-strong {
    padding-right: 30px;
    padding-left: 30px;
    align-self: flex-start;
    text-align: center;
}

.inline-strong strong {
    display: inline;
}

.page-wrapper .text-center.right-left-margin .margin-bottom-0 {
    margin-bottom: 0;
}

.page-middle-video-wrapper {
    background-color: #f7fafc;
    padding: 100px 0;
}

@media only screen and (min-width: 1400px) {
    .div-flex-wrap .numbers-block .light-blue-stats .case-study-stats-block .larger-width-stat {
        width: 242px;
    }
}

@media only screen and (max-width: 992px) {
    .pass-demo {
        background: none;
    }
}

@media only screen and (max-width: 767px) {
    .white-bg-profiles img {
        width: inherit;
        height: inherit;
    }

    .white-bg-profiles {
        padding: 50px 0;
    }

    .white-bg-profiles h2 {
        font-size: 30px;
        padding-bottom: 50px;
        margin-bottom: 0;
    }

    .with-large-text-size .text-center.right-left-margin p {
        font-size: var(--font-size-body-large);
    }

    .with-large-text-size .btn {
        display: inline-block;
    }
}

/* Case studies page css ends */

/* Pass page css starts */
.header-logo {
    margin-bottom: 20px;
}

.white-btn.white-border-btn {
    border: solid 3px var(--blue-btn-active);
    background-color: var(--white-color);
    color: var(--blue-btn-active);
    margin-left: 50px;
}

.white-btn.white-border-btn:hover,
.white-btn.white-border-btn:focus,
.white-btn.white-border-btn:active {
    color: var(--white-color);
    border-color: var(--btn-color);
}

.simplify-intro {
    margin-bottom: 104px;
}

.simplify-intro .home-intro {
    margin-bottom: 54px;
}

.bg-testimonial-wrapper {
    max-width: 924px;
    min-height: 260px;
    margin: 100px auto 190px;
    background-color: #f1f8ff;
    border-radius: 16px 120px 16px 16px;
    position: relative;
}

.bg-testimonial-wrapper::after {
    content: "";
    position: absolute;
    bottom: -16px;
    right: -10px;
    width: 86px;
    height: 75px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-quote.svg) 0 0 no-repeat;
}

.bg-testimonial-wrapper .page-carousel {
    text-align: left;
    padding: 6px 60px 0 0;
    max-width: inherit;
}

.bg-testimonial-wrapper .slick-dotted.slick-slider {
    margin-left: -82px;
}

.bg-testimonial-wrapper strong {
    margin-bottom: 0;
    color: var(--blue-gray-color);
}

.bg-testimonial-wrapper span {
    font-size: var(--font-size-body-large);
    font-style: normal;
}

.bg-testimonial-wrapper .page-testimonials-slider .flex-slide {
    display: flex;
    align-items: center;
}

.bg-testimonial-wrapper .page-testimonials-slider .flex-slide img {
    margin-right: 36px;
}

.bg-testimonial-wrapper .slick-dots {
    bottom: -80px;
}

.bg-testimonial-wrapper .slick-dots li {
    margin-left: 20px;
}

.bg-testimonial-wrapper .slick-dots li button:before {
    width: 20px;
    height: 20px;
    border: solid 3px #30e1c3;
    background-color: transparent;
    top: 0;
    left: 0;
}

.bg-testimonial-wrapper .slick-dots li.slick-active button:before {
    background-color: #30e1c3;
}

.group-section .eb-heading {
    font: 400 48px/1.25 var(--extra-bold-font);
    margin-bottom: 12px;
}

.group-section .font24 {
    font-size: var(--font-size-heading-medium);
    line-height: 1.25;
    color: var(--body-text-color);
}

.simple-design .custom-panel .panel {
    box-shadow: none;
    background-color: #fdfdfd;
}

.blue-bullet-ul {
    margin: 0 0 0 40px;
    padding: 0;
}

.blue-bullet-ul li {
    margin-bottom: 10px;
}

.blue-bullet-ul li::marker {
    color: #0096ff;
    font-size: 28px;
}

.simple-design .enlarge-image {
    margin-top: 10px;
}

.pass-page-stats {
    margin-top: 100px;
    padding: 0 0 50px;
}

.home-stats.about-stats.pass-page-stats h5 {
    margin-bottom: 0;
}

.home-stats.home-latest-stats.pass-page-stats .stats-subheading {
    font: 400 var(--font-size-heading-medium)/1.17 var(--bold-font);
    margin-bottom: 60px;
}

.pass-msg {
    margin-top: 100px;
}

.btns-center {
    text-align: center;
    margin-top: 30px;
}

.flex-section {
    display: flex;
    justify-content: center;
}

.simple-design .custom-panel span {
    font-size: var(--font-size-heading-small);
}

.simple-design .custom-panel a {
    padding-right: 14px;
    padding-left: 14px;
}

.black-bg-page,
.black-bg-page .center-heading,
.black-bg-page .index-intro p {
    color: var(--white-color);
}

.black-bg-page .bg-testimonial-wrapper {
    background-color: #505e6b;
}

.black-bg-page .page-testimonial {
    color: #f6f9fb;
}

.ai-chat-bar {
    background-color: var(--blue-color);
    min-height: 122px;
    padding: 20px 24px;
    color: var(--white-color);
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-md);
    position: relative;
    border-radius: 20px;
    margin-top: 74px;
    margin-bottom: 74px;
    display: flex;
    align-items: center;
}

.ai-chat-bar p {
    margin-bottom: 0;
}

.ai-chat-bar strong {
    font-family: var(--bold-font);
}

.ai-chat-bar .content-blue-btn {
    background-color: var(--white-color);
    height: 44px;
    margin: 0 0 0 60px;
    padding: 14px 36px;
    border: 0;
}

.ai-chat-bar .content-blue-btn:hover,
.ai-chat-bar .content-blue-btn:focus,
.ai-chat-bar .content-blue-btn:active {
    background-color: var(--blue-btn-active);
    color: var(--white-color);
    box-shadow: none;
}

.ai-chat-bar::before {
    font: 400 var(--font-size-body-small)/1 var(--bold-font);
    content: "NEW!";
    position: absolute;
    padding: 2px 0;
    top: -10px;
    left: 24px;
    min-width: 64px;
    min-height: 20px;
    background-color: #ff0;
    color: var(--heading-text-color);
    text-align: center;
    line-height: 1;
}

.ai-chat-bar::after {
    content: "";
    position: absolute;
    top: -20px;
    right: -68px;
    width: 198px;
    height: 145px;
    background: 0/contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/ai-bot.webp) no-repeat;
}

.dark-blue-bg-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/new-discern-hero-bg.svg) no-repeat;
}

.dark-blue-bg-header .white-btn.dark-blue-btn {
    background-color: #00264d;
    width: 214px;
}

.text-and-img span {
    font-size: var(--font-size-heading-extra-large);
    line-height: var(--line-height-xl);
    color: var(--white-color);
    display: block;
    margin-bottom: 4px;
}

.black-bg-page .home-stats.home-latest-stats h2 {
    color: var(--white-color);
    font-size: 48px;
}

.black-bg-page .home-stats.home-latest-stats .feature-content h2 {
    font-size: 30px;
    max-width: 70%;
    margin: 28px auto 38px;
}

.black-bg-page .feature-content ul {
    margin: 52px 30px 30px 70px;
    padding: 0;
    font-size: var(--font-size-heading-medium);
    line-height: 1.33;
    color: var(--white-color);
    text-align: left;
}

.black-bg-page .feature-content li::marker {
    color: #0096ff;
}

.black-bg-page .home-stats.home-latest-stats.pass-page-stats .stats-subheading {
    font: 400 var(--font-size-heading-medium)/1.25 var(--regular-font);
    color: #f6f9fb;
    max-width: inherit;
    margin-top: 12px;
}

.black-bg-page .feature-content {
    border-radius: 15px;
    background-color: var(--heading-text-color);
}

.black-bg-page .bg-testimonial-wrapper strong {
    color: #f6f9fb;
}

.black-bg-page .learning-section a {
    background-color: #505e6b;
}

.black-bg-page .learning-section strong,
.black-bg-page .extra-bold-heading,
.black-bg-page .large-paragraph,
.black-bg-page .group-section .eb-heading,
.black-bg-page .group-section .font24 {
    color: var(--white-color);
}

.ai-insight.group-section .font24 {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.black-bg-page .bg-testimonial-wrapper .page-testimonials-slider .flex-slide {
    margin-bottom: 30px;
}

.black-bg-page .bg-testimonial-wrapper .slick-dots li button:before {
    border: 3px solid #89a0b7;
}

.black-bg-page .bg-testimonial-wrapper .slick-dots li.slick-active button:before {
    background-color: #89a0b7;
}

.black-bg-page .bg-testimonial-wrapper:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/dark-quote-mark.svg) 0 0 no-repeat;
}

.black-bg-page .group-section .eb-heading {
    margin-bottom: 18px;
}

.black-bg-page .simple-design .custom-panel .panel,
.black-bg-page .custom-panel .panel-default.active>.panel-heading {
    background-color: var(--heading-text-color);
}

.black-bg-page .accheading,
.black-bg-page .accheading p,
.black-bg-page .custom-panel .panel-heading+.panel-collapse .panel-body {
    color: var(--white-color);
}

.black-bg-page .custom-panel .panel-heading+.panel-collapse .panel-body {
    padding-bottom: 10px;
}

.black-bg-page .enlarge-image {
    color: #a3c0db;
}

.black-bg-page .enlarge-image:hover,
.black-bg-page .enlarge-image:focus,
.black-bg-page .enlarge-image:active {
    color: var(--white-color);
}

.default-blue-btn {
    background-color: #0073e5;
    color: var(--white-color);
    font-size: var(--font-size-body-large);
}

.default-blue-btn:hover,
.default-blue-btn:focus {
    background-color: var(--blue-btn-hover);
}

.default-blue-btn:active {
    background-color: var(--blue-btn-active);
}

.static-pages .text-align-right,
.static-pages .text-align-right h3 {
    text-align: right;
}

.static-pages .formatted-text h3 {
    font: 400 var(--font-size-heading-medium)/1.25 var(--extra-bold-font);
    color: var(--body-text-color);
    margin-bottom: 26px;
}

.static-pages .formatted-text p {
    font-size: var(--font-size-heading-medium);
    line-height: 1.25;
    color: var(--body-text-color);
}

.introduce-section .font24 {
    margin: 0 auto 90px;
    max-width: 80%;
    text-align: center;
}

.introduce-section .default-blue-btn {
    margin: 60px 0 0;
    width: inherit;
    padding: 8px 78px;
}

.last-img-content-section {
    margin: 90px 0;
}

.last-img-content-section .extra-bold-heading {
    margin-bottom: 24px;
}

.last-img-content-section .light-teal-btn {
    margin: 50px 0 0;
    padding: 10px 78px;
    width: inherit;
}

.black-bg-page .simple-design .custom-panel span {
    line-height: var(--line-height-sm);
    width: 90%;
}

.black-bg-page .learning-section a {
    min-height: 280px;
}

.discern-msg {
    border-top: 2px solid var(--white-color);
    margin-top: 98px;
}

.discern-msg .home-intro {
    margin-top: 0;
    margin-bottom: 60px;
}

.discern-msg .default-blue-btn {
    margin-left: 0;
    width: inherit;
    padding: 11px 50px;
    font-size: var(--font-size-body-medium);
}

.discern-msg .index-intro p {
    max-width: inherit;
    margin-bottom: 24px;
}

.discern-msg .extra-bold-heading {
    margin-bottom: 20px;
}

@media (max-width: 1280px) {
    .ai-chat-bar .chat-bar-content {
        max-width: 44%;
    }

    .ai-chat-bar::after {
        right: -20px;
    }
}

@media (max-width: 1200px) {
    .chrometools-page .ai-chat-bar {
        margin-top: 74px;
        margin-bottom: 74px;
    }
}

@media only screen and (max-width: 1050px) {
    .pass-header {
        padding-top: 50px;
    }

    .pass-header .btn {
        display: block;
        width: 220px;
        margin-left: 0;
        text-align: center;
    }

    .pass-header h1 {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1140px) and (min-width: 1024px) {
    .black-bg-page .bg-testimonial-wrapper .page-testimonials-slider .flex-slide img {
        width: 100px;
        height: 100px;
        position: relative;
        left: 40px;
    }

    .black-bg-page .bg-testimonial-wrapper .page-testimonials-slider .flex-slide>div {
        margin-left: 20px;
    }

}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .blue-bg-header .ai-chat-logo {
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .bg-testimonial-wrapper .page-testimonials-slider .flex-slide img {
        width: 100px;
        height: 100px;
        margin-right: 0;
    }

    .bg-testimonial-wrapper .page-carousel {
        font-size: var(--font-size-body-large);
    }

    .bg-testimonial-wrapper strong,
    .bg-testimonial-wrapper span {
        font-size: var(--font-size-body-medium);

    }

    .bg-testimonial-wrapper {
        margin: 100px 30px;
        border-radius: 10px 60px 10px 10px;
    }

    .bg-testimonial-wrapper .page-testimonials-slider .flex-slide {
        flex-direction: column;
    }

    .bg-testimonial-wrapper .slick-dotted.slick-slider {
        margin-left: 0;
    }

    .bg-testimonial-wrapper .page-carousel {
        padding: 30px;
    }

    .bg-testimonial-wrapper .slick-dots {
        bottom: -130px;
    }

    .static-pages .text-align-right,
    .static-pages .text-align-right h3 {
        text-align: left;
    }

    .static-pages.ai-chat-page .image-align img {
        max-width: 100%;
    }

    .black-bg-page .bg-testimonial-wrapper .page-testimonials-slider .flex-slide img {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1270px) and (min-width: 767px) {
    .simple-design .custom-panel span {
        max-width: 250px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .pass-header .cloud-page-header-container {
        padding-top: 20px;
    }

    .home-stats.home-latest-stats.pass-page-stats figure {
        height: 233px;
    }
}

@media only screen and (max-width: 767px) {
    .pass-header .seo-header.header-slider .header-logo {
        max-width: 40%;
        margin-bottom: 10px;
    }

    .pass-header .btn {
        display: inline-block;
        margin-left: 20px;
    }

    .group-section .eb-heading {
        font-size: 30px;
    }

    .group-section .font24 {
        font-size: var(--font-size-body-large);
    }

    .group-section.simple-design .acc-heading,
    .simple-design img,
    .simplify-intro {
        margin-bottom: 40px;
    }

    .pass-msg,
    .pass-page-stats {
        display: block;
        margin-top: 40px;
    }

    .blue-bullet-ul {
        margin: 0;
    }

    .bg-testimonial-wrapper:after {
        width: 50px;
        height: 50px;
        background-size: 50px;
    }

    .flex-section {
        margin-top: 40px;
        flex-direction: column;
        align-items: center;
    }

    .flex-section .columns-3 {
        max-width: 300px;
    }

    .camelback-header {
        margin-bottom: 50px;
    }

    .page-middle-video-wrapper {
        padding: 50px 0;
    }

    .ai-chat-bar {
        display: block;
        font-size: var(--font-size-body-medium);
        border-radius: 10px;
    }

    .ai-chat-bar .content-blue-btn {
        margin: 10px 0 0;
        height: 36px;
        padding: 10px 26px;
    }

    .ai-chat-bar .chat-bar-content {
        margin-top: 20px;
        max-width: inherit;
    }

    .ai-chat-bar:after {
        width: 120px;
        height: 100px;
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }

    .seo-header.header-slider .ai-chat-logo,
    .ai-chat-display-img {
        margin: 30px 0 0;
    }

    .last-img-content-section {
        margin: 50px 0;
    }

    .last-img-content-section img {
        margin-bottom: 30px;
    }

    .last-img-content-section .light-teal-btn {
        margin-top: 10px;
    }

    .introduce-section .default-blue-btn {
        padding: 8px 50px;
        margin-top: 30px;
    }

    .last-img-content-section .light-teal-btn {
        padding: 10px 60px;
    }

    .introduce-section .eb-heading,
    .introduce-section .font24,
    .cloud360.ai-chat-page .content-right {
        text-align: left;
        padding: 0;
    }

    .introduce-section .font24 {
        margin: 0 auto 40px;
        max-width: inherit;
    }

    .cloud360.ai-chat-page .holder {
        padding: 10px 0;
    }

    .static-pages .formatted-text h3,
    .static-pages .formatted-text p {
        text-align: left;
        font-size: var(--font-size-body-large);
    }

    .static-pages .formatted-text h3 {
        margin-bottom: 16px;
    }

    .black-bg-page .home-stats.home-latest-stats h2 {
        font-size: 30px;
    }

    .black-bg-page .home-stats.home-latest-stats .feature-content h2 {
        font-size: 26px;
    }

    .black-bg-page .bg-testimonial-wrapper:after {
        background-size: 50px;
    }

    .black-bg-page .bg-testimonial-wrapper {
        margin-top: 50px;
        margin-bottom: 100px;
    }

    .black-bg-page .simple-design .custom-panel .panel {
        padding: 14px;
    }

    .black-bg-page .simple-design .custom-panel a {
        padding-top: 10px;
    }

    .ai-insight.group-section .font24 {
        max-width: inherit;
        margin-bottom: 40px;
    }

    .discern-msg {
        padding-top: 40px;
        margin-top: 40px;
    }

    .discern-msg .default-blue-btn,
    .discern-msg .home-intro {
        margin-bottom: 50px;
    }
}

/* Pass page css ends */
/* Private schools page css starts */

.bg-testimonial-single-wrapper .page-carousel {
    padding: 30px 60px 20px 0;
}

.bg-testimonial-single-wrapper .flex-slide img {
    margin-left: -82px;
}

.page-middle-video-wrapper.background-white {
    background-color: var(--white-color);
}

.color-3-sections {
    margin-bottom: 90px;
}

.color-3-sections .rows {
    grid-gap: 32px;
}

.color-3-sections .middle-desc,
.color-3-sections a:first-child:hover .middle-desc {
    background-color: #f2f9ff;
}

.default-sections {
    margin-bottom: 40px;
}

.default-sections .formatted-text h3 {
    color: var(--heading-text-color);
}

.default-sections .formatted-text p {
    font-size: var(--font-size-body-medium);
    line-height: 1.33;
}

.default-sections .img-align {
    margin-left: 50px;
}

.default-sections .img-size-left {
    margin-right: 50px;
}

.default-sections .holder {
    padding: 50px 0;
    min-height: inherit;
}

.ai-chat-guy {
    min-height: 84px;
    max-width: 996px;
    margin-right: auto;
    margin-left: auto;
}

.ai-chat-guy:after {
    width: 190px;
    height: 113px;
    right: -3px;
    top: -28px;
    background: 0 / contain url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/private-schools-ai.webp) no-repeat;
}

.ai-chat-guy:before {
    content: "Dive deeper";
    min-width: 124px;
}

.with-lighter-blue-bg {
    background-color: #f7fafc;
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .default-sections .rows {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 40px;
    }
}

@media only screen and (max-width: 1024px) {
    .bg-testimonial-single-wrapper .page-carousel {
        padding: 30px;
    }

    .bg-testimonial-single-wrapper .page-carousel img {
        margin-bottom: 20px;
    }

    .default-sections .order-change {
        order: inherit;
    }

    .color-3-sections .display-flex,
    .learning-section.display-flex {
        display: grid;
    }

    .ai-chat-guy {
        margin-right: 20px;
        margin-left: 20px;
    }

    .color-3-sections .middle-desc {
        min-height: 270px;
    }
}

@media only screen and (max-width: 767px) {
    .default-sections .order-change {
        order: -1;
    }

    .schools-hero {
        margin-bottom: 80px;
    }

    .ai-chat-guy {
        padding-bottom: 0;
    }

    .color-3-sections img {
        width: 100%;
        margin-bottom: 0;
    }

    .color-3-sections {
        margin-bottom: 20px;
    }

    .color-3-sections .middle-desc {
        padding: 20px;
    }

    .default-sections .holder {
        padding: 20px 0;
    }

    .learning-section.display-flex {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }

    .learning-section.display-flex a {
        min-height: inherit;
    }

    .color-3-sections .middle-desc {
        min-height: inherit;
    }
}

/* Private schools page css ends */
/* New form design css starts */

.form-holder {
    width: 506px;
    margin: 0 auto;
}

.left-img-holder {
    width: 602px;
}

.right-img-holder {
    width: 130px;
    margin-left: 24px;
}

.left-img-holder,
.right-img-holder {
    margin-top: 120px;
}

.form-holder .user-says,
.form-holder .user-says strong,
.form-holder .user-says span {
    color: var(--white-color);
    letter-spacing: inherit;
}

.form-holder .user-says span {
    margin-right: 0;
}

.form-div,
#thank-you,
.thanks-msg {
    display: none;
}

.thanks-msg {
    line-height: 1.29;
    text-align: center;
    margin-bottom: 40px;
}

.thanks-msg strong {
    font-family: var(--extra-bold-font);
    display: block;
}

.thanks-msg#thank-you {
    font-size: 21px;
    line-height: var(--line-height-xl);
}

.thanks-msg#thank-you strong {
    font-size: var(--font-size-heading-medium);
}

.thanks-msg#thank-you img {
    margin-top: 24px;
}

.blue-design-container {
    color: var(--white-color);
    margin-top: 70px;
}

.blue-design-container .newGrid_container,
.securly-features {
    display: flex;
}

.blue-design-container h1 {
    font: 400 var(--font-size-heading-medium)/1.17 var(--extra-bold-font);
    color: var(--white-color);
    font-weight: 400;
    margin: 26px 0 38px;
}

.blue-design-container h1 span.ticker-item {
    font-family: var(--regular-font);
    margin-top: 2px;
}

.form-holder {
    padding: 72px 44px 0;
    position: relative;
}

.form-holder:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
}

.form-holder .user-says:after,
.form-holder .user-says:before {
    display: none;
}

.form-holder .user-says {
    margin: 20px 0 48px;
    width: auto;
    font-size: var(--font-size-body-extra-small);
    font-style: italic;
    line-height: 1.33;
}

.form-holder .font-style-normal {
    font-style: normal;
}

.form-holder .user-says a {
    color: #fbfc34;
    text-decoration: underline;
}

.form-holder .user-says strong,
.form-holder .user-says span {
    font-style: normal;
    font-size: var(--font-size-body-extra-small);
}

.form-holder .user-says span {
    font-family: var(--regular-font);
}

.securly-features {
    gap: 0 24px;
    justify-content: space-around;
    margin-bottom: 32px;
}

.securly-features div {
    flex: 0 0 28%;
}

.securly-features h2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.17;
    color: var(--white-color);
    margin: 14px 0;
    text-wrap: balance;
}

.minus-margin-left {
    margin-left: 70px;
}

.ticker-text {
    overflow: hidden;
    position: relative;
    display: block;
}

.ticker-item {
    display: none;
}

.ticker-item.active {
    display: inline-block;
}

@media only screen and (min-width: 768px) {

    .form-holder,
    .form-holder:after {
        background-color: rgba(51, 49, 135, 0.6);
    }

    .orange-design-container .form-holder,
    .orange-design-container .form-holder:after {
        background-color: #c33608;
    }

    .teal-design-container .form-holder,
    .teal-design-container .form-holder:after {
        background-color: rgba(0, 91, 96, 0.9);
    }

    .light-teal-design-container .form-holder,
    .light-teal-design-container .form-holder:after {
        background-color: rgba(0, 108, 132, 0.9);
    }

    .dark-blue-design-container .form-holder,
    .dark-blue-design-container .form-holder:after {
        background-color: rgba(0, 57, 115, 0.9);
    }

    .light-blue-design-container .form-holder,
    .light-blue-design-container .form-holder:after {
        background-color: #0060bf;
    }

    .yellow-design-container .form-holder,
    .yellow-design-container .form-holder:after {
        background-color: rgba(178, 91, 0, 0.9);
    }

    .pink-design-container .form-holder,
    .pink-design-container .form-holder:after {
        background-color: rgba(150, 15, 57, 0.5);
    }

    .black-design-container .form-holder,
    .black-design-container .form-holder:after {
        background-color: rgba(5, 6, 7, 0.5);
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .right-img-holder {
        display: none;
    }

    .left-img-holder,
    .form-holder {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) and (min-width: 321px) {
    .blue-design-container .newGrid_container {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .blue-design-container {
        padding-bottom: 60px;
        text-align: center;
    }

    .form-holder .user-says {
        position: absolute;
        bottom: 0;
    }

    .newGrid_container {
        flex-direction: column;
    }

    .right-img-holder {
        width: auto;
        margin: 20px auto 0;
        text-align: center;
    }

    .left-img-holder {
        width: 100%;
    }

    .form-holder {
        width: 100%;
        padding: 42px 0 260px;
        position: relative;
    }

    .form-holder.padding-bottom-0 {
        padding-bottom: 0;
    }

    .form-holder:after {
        display: none;
    }

    .form-holder h1 {
        font-size: var(--font-size-body-large);
        line-height: var(--line-height-sm);
        margin: 26px 0 24px;
    }

    .form-holder .user-says {
        margin: 24px 0 32px;
    }

    .securly-features {
        margin-bottom: 24px;
    }

    .securly-features h2 {
        font-size: 12px;
        margin: 14px 0;
    }

    .minus-margin-left {
        margin-left: 0;
        display: inline-block;
    }

    .securly-pass-intro h2 {
        font-size: var(--font-size-heading-small);
    }

    .default-header h3,
    .careers-header p {
        font-size: var(--font-size-body-small);
        line-height: 24px;
    }
}

/* New form design css ends */