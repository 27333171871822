* {
  margin: 0;
}

html,
body {
  height: 100%;
}

.alert {
  margin-left: 20px;
}

.wrapper {
  background: #ebebeb;
  min-height: 100%;
  margin: 0 auto -202px;
  /* the bottom margin is the negative value of the footer's height */
}

/*EDITOR CHECKBOXES*/
label input[type="checkbox"] {
  display: none;
}

label.col-sm-2 input[type="checkbox"]+.custom-checkbox {
  background-color: #f6f6f6;
  border: 1px solid #b6b1ae;
  margin-top: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 15px 10px 12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  margin-left: 0px;
}

.global-settings-list label.col-sm-2 input[type="checkbox"]+.custom-checkbox {
  right: 100px;
}

label.col-sm-2:active input[type="checkbox"]+.custom-checkbox,
label:active input[type="checkbox"]:checked+.custom-checkbox {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

label.col-sm-2 input[type="checkbox"]:checked+.custom-checkbox {
  background: #52b266;
  border-color: #52b266;
}

label.col-sm-2 input[type="checkbox"]:checked+.custom-checkbox:after {
  font-family: "SSGizmo";
  content: '\2713';
  font-size: 13px;
  font-weight: 100;
  position: absolute;
  top: -21px;
  left: 1px;
  color: #f6f6f6;
}

/*MAP CHECK BOXES*/
label input[type="checkbox"] {
  display: none;
}

label input[type="checkbox"]+.custom-checkbox {
  background-color: #f6f6f6;
  border: 1px solid #b6b1ae;
  margin-top: 1px;
  margin-left: -15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 15px 10px 12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
}

label:active input[type="checkbox"]+.custom-checkbox,
label:active input[type="checkbox"]:checked+.custom-checkbox {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

label input[type="checkbox"]:checked+.custom-checkbox {
  background: #52b266;
  border-color: #52b266;
}

label input[type="checkbox"]:checked+.custom-checkbox:after {
  font-family: "SSGizmo";
  content: '\2713';
  font-size: 14px;
  font-weight: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #f6f6f6;
}

/*RADIO BUTTON STYLE*/
label input[type="radio"] {
  display: none;
}

label input[type="radio"]+.custom-radio {
  background-color: #fafafa;
  border: 1px solid #cbd0d5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 15px 10px 12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}

label input[type="radio"]:checked+.custom-radio:after {
  content: ' ';
  width: 5px;
  height: 5px;
  border-radius: 50px;
  position: absolute;
  top: 7px;
  background: #33b1ad;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  left: 7px;
  font-size: 22px;
}

label input[type="radio"]:checked+.custom-radio {
  background-color: #ededed;
  color: #99a1a7;
  border: 1px solid #cbd0d5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 15px 10px 12px rgba(0, 0, 0, 0.05), inset 15px 10px 12px rgba(255, 255, 255, 0.1), inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

label:active input[type="radio"]+.custom-radio,
label:active input[type="radio"]:checked+.custom-radio {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

label input[type="radio"]+.big-radio {
  padding: 16px;
}

label input[type="radio"]:checked+.big-radio:after {
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  line-height: 1.2;
}

h1,
h2 {
  font-family: 'MetropolisSemiBold', sans-serif;
  font-weight: 300;
}

h3,
h4,
h5,
h6 {
  font-family: 'MetropolisSemiBold', sans-serif;
  font-weight: 500;
}

/*FONT SIZES*/
h1 {
  font-size: 46px;
}

h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

h3 {
  font-size: 26px;
  margin-bottom: 20px;
}

.h2-heading {
  font-size: 38px;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  .h2-heading {
    font-size: 32px;
  }
}

/*FONT COLORS */
.page-footer h1,
.page-footer h2,
.page-footer h3,
.page-footer h4,
.page-footer h5,
.page-footer h6 {
  color: #f6f6f6;
}

ol.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_14-1>li:before {
  content: " ";
}

.lst-kix_list_14-3>li:before {
  content: " ";
}

.lst-kix_list_2-1>li {
  counter-increment: lst-ctn-kix_list_2-1;
}

ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 2021298695;
}

.lst-kix_list_14-0>li:before {
  content: " ";
}

.lst-kix_list_14-4>li:before {
  content: " ";
}

.lst-kix_list_6-1>li {
  counter-increment: lst-ctn-kix_list_6-1;
}

.lst-kix_list_14-5>li:before {
  content: " ";
}

.lst-kix_list_14-7>li:before {
  content: " ";
}

ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}

.lst-kix_list_14-6>li:before {
  content: " ";
}

.lst-kix_list_13-0>li {
  counter-increment: lst-ctn-kix_list_13-0;
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

.lst-kix_list_9-0>li {
  counter-increment: lst-ctn-kix_list_9-0;
}

ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

ol.lst-kix_list_7-5 {
  list-style-type: none;
}

ol.lst-kix_list_7-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-8 {
  list-style-type: none;
}

ol.lst-kix_list_7-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0;
}

ol.lst-kix_list_7-3 {
  list-style-type: none;
}

ol.lst-kix_list_7-4 {
  list-style-type: none;
}

.lst-kix_list_14-2>li:before {
  content: " ";
}

ul.lst-kix_list_17-1 {
  list-style-type: none;
}

ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 2021298983;
}

ul.lst-kix_list_17-0 {
  list-style-type: none;
}

.lst-kix_list_4-3>li {
  counter-increment: lst-ctn-kix_list_4-3;
}

ul.lst-kix_list_17-8 {
  list-style-type: none;
}

ul.lst-kix_list_17-7 {
  list-style-type: none;
}

ul.lst-kix_list_17-6 {
  list-style-type: none;
}

ul.lst-kix_list_17-5 {
  list-style-type: none;
}

ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

ul.lst-kix_list_17-4 {
  list-style-type: none;
}

ul.lst-kix_list_17-3 {
  list-style-type: none;
}

.lst-kix_list_14-8>li:before {
  content: " ";
}

ul.lst-kix_list_17-2 {
  list-style-type: none;
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

.lst-kix_list_3-2>li {
  counter-increment: lst-ctn-kix_list_3-2;
}

ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}

.lst-kix_list_7-2>li {
  counter-increment: lst-ctn-kix_list_7-2;
}

.lst-kix_list_5-0>li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_6-0 {
  list-style-type: none;
}

ol.lst-kix_list_6-1 {
  list-style-type: none;
}

.lst-kix_list_14-8>li {
  counter-increment: lst-ctn-kix_list_14-8;
}

.lst-kix_list_1-4>li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.lst-kix_list_5-3>li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}

.lst-kix_list_5-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_8-3>li {
  counter-increment: lst-ctn-kix_list_8-3;
}

.lst-kix_list_5-1>li:before {
  content: "o  ";
}

ol.lst-kix_list_16-0 {
  list-style-type: none;
}

.lst-kix_list_5-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_9-4>li {
  counter-increment: lst-ctn-kix_list_9-4;
}

ol.lst-kix_list_6-6 {
  list-style-type: none;
}

ol.lst-kix_list_6-7 {
  list-style-type: none;
}

.lst-kix_list_5-4>li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_6-8 {
  list-style-type: none;
}

.lst-kix_list_5-5>li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_6-2 {
  list-style-type: none;
}

ol.lst-kix_list_6-3 {
  list-style-type: none;
}

ol.lst-kix_list_6-4 {
  list-style-type: none;
}

ol.lst-kix_list_6-5 {
  list-style-type: none;
}

ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0;
}

ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}

.lst-kix_list_6-1>li:before {
  content: ""counter(lst-ctn-kix_list_6-1, decimal) ". ";
}

.lst-kix_list_6-3>li:before {
  content: ""counter(lst-ctn-kix_list_6-3, decimal) ". ";
}

.lst-kix_list_6-5>li {
  counter-increment: lst-ctn-kix_list_6-5;
}

.lst-kix_list_6-8>li {
  counter-increment: lst-ctn-kix_list_6-8;
}

.lst-kix_list_6-0>li:before {
  content: ""counter(lst-ctn-kix_list_6-0, decimal) ". ";
}

.lst-kix_list_6-4>li:before {
  content: ""counter(lst-ctn-kix_list_6-4, decimal) ". ";
}

ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0;
}

ul.lst-kix_list_16-2 {
  list-style-type: none;
}

ul.lst-kix_list_16-1 {
  list-style-type: none;
}

.lst-kix_list_3-6>li {
  counter-increment: lst-ctn-kix_list_3-6;
}

.lst-kix_list_6-2>li:before {
  content: ""counter(lst-ctn-kix_list_6-2, decimal) ". ";
}

ul.lst-kix_list_16-8 {
  list-style-type: none;
}

ul.lst-kix_list_16-7 {
  list-style-type: none;
}

ul.lst-kix_list_16-6 {
  list-style-type: none;
}

.lst-kix_list_2-5>li {
  counter-increment: lst-ctn-kix_list_2-5;
}

.lst-kix_list_2-8>li {
  counter-increment: lst-ctn-kix_list_2-8;
}

ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

ul.lst-kix_list_16-5 {
  list-style-type: none;
}

ul.lst-kix_list_16-4 {
  list-style-type: none;
}

.lst-kix_list_6-8>li:before {
  content: ""counter(lst-ctn-kix_list_6-8, decimal) ". ";
}

ul.lst-kix_list_16-3 {
  list-style-type: none;
}

.lst-kix_list_6-5>li:before {
  content: ""counter(lst-ctn-kix_list_6-5, decimal) ". ";
}

.lst-kix_list_6-7>li:before {
  content: ""counter(lst-ctn-kix_list_6-7, decimal) ". ";
}

.lst-kix_list_6-6>li:before {
  content: ""counter(lst-ctn-kix_list_6-6, decimal) ". ";
}

ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}

.lst-kix_list_7-4>li:before {
  content: ""counter(lst-ctn-kix_list_7-4, decimal) ". ";
}

.lst-kix_list_7-6>li:before {
  content: ""counter(lst-ctn-kix_list_7-6, decimal) ". ";
}

ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}

.lst-kix_list_7-2>li:before {
  content: ""counter(lst-ctn-kix_list_7-2, decimal) ". ";
}

.lst-kix_list_7-6>li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.lst-kix_list_8-6>li {
  counter-increment: lst-ctn-kix_list_8-6;
}

.lst-kix_list_12-6>li {
  counter-increment: lst-ctn-kix_list_12-6;
}

ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}

ol.lst-kix_list_9-7 {
  list-style-type: none;
}

ol.lst-kix_list_9-8 {
  list-style-type: none;
}

.lst-kix_list_13-7>li:before {
  content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
}

ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 2021298215;
}

ol.lst-kix_list_9-3 {
  list-style-type: none;
}

ol.lst-kix_list_9-4 {
  list-style-type: none;
}

ol.lst-kix_list_9-5 {
  list-style-type: none;
}

.lst-kix_list_7-8>li:before {
  content: ""counter(lst-ctn-kix_list_7-8, decimal) ". ";
}

ol.lst-kix_list_9-6 {
  list-style-type: none;
}

.lst-kix_list_4-7>li {
  counter-increment: lst-ctn-kix_list_4-7;
}

ol.lst-kix_list_9-0 {
  list-style-type: none;
}

ol.lst-kix_list_9-1 {
  list-style-type: none;
}

ol.lst-kix_list_9-2 {
  list-style-type: none;
}

ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

.lst-kix_list_15-5>li:before {
  content: " ";
}

.lst-kix_list_9-8>li {
  counter-increment: lst-ctn-kix_list_9-8;
}

.lst-kix_list_13-4>li {
  counter-increment: lst-ctn-kix_list_13-4;
}

.lst-kix_list_4-1>li:before {
  content: ""counter(lst-ctn-kix_list_4-1, decimal) ". ";
}

.lst-kix_list_15-7>li:before {
  content: " ";
}

.lst-kix_list_4-3>li:before {
  content: ""counter(lst-ctn-kix_list_4-3, decimal) ". ";
}

.lst-kix_list_4-5>li:before {
  content: ""counter(lst-ctn-kix_list_4-5, decimal) ". ";
}

.lst-kix_list_1-8>li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.lst-kix_list_10-5>li {
  counter-increment: lst-ctn-kix_list_10-5;
}

.lst-kix_list_15-1>li:before {
  content: " ";
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.lst-kix_list_3-5>li {
  counter-increment: lst-ctn-kix_list_3-5;
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.lst-kix_list_15-3>li:before {
  content: " ";
}

ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}

.lst-kix_list_6-4>li {
  counter-increment: lst-ctn-kix_list_6-4;
}

ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}

.lst-kix_list_9-3>li {
  counter-increment: lst-ctn-kix_list_9-3;
}

.lst-kix_list_11-2>li {
  counter-increment: lst-ctn-kix_list_11-2;
}

ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.lst-kix_list_12-3>li:before {
  content: ""counter(lst-ctn-kix_list_12-3, decimal) ". ";
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.lst-kix_list_12-1>li:before {
  content: ""counter(lst-ctn-kix_list_12-1, decimal) ". ";
}

ol.lst-kix_list_8-5 {
  list-style-type: none;
}

ol.lst-kix_list_8-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-1 {
  list-style-type: none;
}

ol.lst-kix_list_8-2 {
  list-style-type: none;
}

.lst-kix_list_13-3>li {
  counter-increment: lst-ctn-kix_list_13-3;
}

ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}

ol.lst-kix_list_8-3 {
  list-style-type: none;
}

.lst-kix_list_10-4>li {
  counter-increment: lst-ctn-kix_list_10-4;
}

.lst-kix_list_14-1>li {
  counter-increment: lst-ctn-kix_list_14-1;
}

.lst-kix_list_13-3>li:before {
  content: ""counter(lst-ctn-kix_list_13-3, decimal) ". ";
}

.lst-kix_list_1-3>li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_13-5>li:before {
  content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
}

.lst-kix_list_12-5>li:before {
  content: ""counter(lst-ctn-kix_list_12-5, decimal) ". ";
}

ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}

ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}

.lst-kix_list_12-7>li:before {
  content: ""counter(lst-ctn-kix_list_12-7, decimal) ". ";
}

ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 2021298407;
}

.lst-kix_list_4-2>li {
  counter-increment: lst-ctn-kix_list_4-2;
}

.lst-kix_list_13-1>li:before {
  content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
}

ol.lst-kix_list_3-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-2 {
  list-style-type: none;
}

ol.lst-kix_list_3-3 {
  list-style-type: none;
}

ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

ol.lst-kix_list_3-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-0 {
  list-style-type: none;
}

ol.lst-kix_list_11-6 {
  list-style-type: none;
}

ol.lst-kix_list_11-7 {
  list-style-type: none;
}

ol.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_1-1>li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.lst-kix_list_7-1>li {
  counter-increment: lst-ctn-kix_list_7-1;
}

ol.lst-kix_list_11-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-3 {
  list-style-type: none;
}

ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

.lst-kix_list_3-0>li:before {
  content: ""counter(lst-ctn-kix_list_3-0, decimal) ". ";
}

ol.lst-kix_list_11-4 {
  list-style-type: none;
}

ol.lst-kix_list_11-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ol.lst-kix_list_11-0 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ol.lst-kix_list_11-1 {
  list-style-type: none;
}

.lst-kix_list_16-0>li {
  counter-increment: lst-ctn-kix_list_16-0;
}

.lst-kix_list_4-0>li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.lst-kix_list_8-0>li {
  counter-increment: lst-ctn-kix_list_8-0;
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.lst-kix_list_10-0>li {
  counter-increment: lst-ctn-kix_list_10-0;
}

.lst-kix_list_3-4>li:before {
  content: ""counter(lst-ctn-kix_list_3-4, decimal) ". ";
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

.lst-kix_list_3-3>li:before {
  content: ""counter(lst-ctn-kix_list_3-3, decimal) ". ";
}

ol.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.lst-kix_list_8-0>li:before {
  content: ""counter(lst-ctn-kix_list_8-0, decimal) ". ";
}

ol.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ol.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_8-7>li:before {
  content: ""counter(lst-ctn-kix_list_8-7, decimal) ". ";
}

.lst-kix_list_3-8>li:before {
  content: ""counter(lst-ctn-kix_list_3-8, decimal) ". ";
}

ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}

.lst-kix_list_13-1>li {
  counter-increment: lst-ctn-kix_list_13-1;
}

.lst-kix_list_8-3>li:before {
  content: ""counter(lst-ctn-kix_list_8-3, decimal) ". ";
}

.lst-kix_list_3-7>li:before {
  content: ""counter(lst-ctn-kix_list_3-7, decimal) ". ";
}

.lst-kix_list_8-4>li:before {
  content: ""counter(lst-ctn-kix_list_8-4, decimal) ". ";
}

.lst-kix_list_10-2>li {
  counter-increment: lst-ctn-kix_list_10-2;
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

.lst-kix_list_11-1>li:before {
  content: ""counter(lst-ctn-kix_list_11-1, decimal) ". ";
}

.lst-kix_list_11-0>li:before {
  content: ""counter(lst-ctn-kix_list_11-0, decimal) ". ";
}

ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}

.lst-kix_list_8-8>li:before {
  content: ""counter(lst-ctn-kix_list_8-8, decimal) ". ";
}

ol.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_16-8>li:before {
  content: " ";
}

ol.lst-kix_list_2-3 {
  list-style-type: none;
}

ol.lst-kix_list_2-4 {
  list-style-type: none;
}

.lst-kix_list_16-7>li:before {
  content: " ";
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

ol.lst-kix_list_2-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-0 {
  list-style-type: none;
}

ol.lst-kix_list_2-1 {
  list-style-type: none;
}

.lst-kix_list_4-8>li:before {
  content: ""counter(lst-ctn-kix_list_4-8, decimal) ". ";
}

ol.lst-kix_list_12-5 {
  list-style-type: none;
}

ol.lst-kix_list_12-6 {
  list-style-type: none;
}

.lst-kix_list_4-7>li:before {
  content: ""counter(lst-ctn-kix_list_4-7, decimal) ". ";
}

ol.lst-kix_list_12-7 {
  list-style-type: none;
}

.lst-kix_list_14-2>li {
  counter-increment: lst-ctn-kix_list_14-2;
}

.lst-kix_list_17-0>li:before {
  content: "  ";
}

ol.lst-kix_list_12-8 {
  list-style-type: none;
}

ol.lst-kix_list_12-1 {
  list-style-type: none;
}

ol.lst-kix_list_12-2 {
  list-style-type: none;
}

ol.lst-kix_list_12-3 {
  list-style-type: none;
}

ol.lst-kix_list_12-4 {
  list-style-type: none;
}

.lst-kix_list_16-0>li:before {
  content: ""counter(lst-ctn-kix_list_16-0, decimal) ". ";
}

ol.lst-kix_list_12-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}

.lst-kix_list_8-4>li {
  counter-increment: lst-ctn-kix_list_8-4;
}

.lst-kix_list_16-4>li:before {
  content: " ";
}

ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}

.lst-kix_list_16-3>li:before {
  content: " ";
}

ol.lst-kix_list_2-6 {
  list-style-type: none;
}

ol.lst-kix_list_2-7 {
  list-style-type: none;
}

ol.lst-kix_list_2-8 {
  list-style-type: none;
}

.lst-kix_list_11-3>li {
  counter-increment: lst-ctn-kix_list_11-3;
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

.lst-kix_list_17-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_17-8>li:before {
  content: "\0025ab  ";
}

.lst-kix_list_17-3>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_17-4>li:before {
  content: "\0025ab  ";
}

ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 2021298599;
}

.lst-kix_list_7-0>li:before {
  content: ""counter(lst-ctn-kix_list_7-0, decimal) ". ";
}

.lst-kix_list_13-8>li {
  counter-increment: lst-ctn-kix_list_13-8;
}

.lst-kix_list_2-2>li {
  counter-increment: lst-ctn-kix_list_2-2;
}

ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}

.lst-kix_list_3-7>li {
  counter-increment: lst-ctn-kix_list_3-7;
}

ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}

ol.lst-kix_list_13-8 {
  list-style-type: none;
}

.lst-kix_list_2-4>li:before {
  content: ""counter(lst-ctn-kix_list_2-4, decimal) ". ";
}

.lst-kix_list_2-8>li:before {
  content: ""counter(lst-ctn-kix_list_2-8, decimal) ". ";
}

ol.lst-kix_list_13-4 {
  list-style-type: none;
}

ol.lst-kix_list_13-5 {
  list-style-type: none;
}

ol.lst-kix_list_13-6 {
  list-style-type: none;
}

ol.lst-kix_list_13-7 {
  list-style-type: none;
}

ol.lst-kix_list_13-0 {
  list-style-type: none;
}

ol.lst-kix_list_13-1 {
  list-style-type: none;
}

.lst-kix_list_6-6>li {
  counter-increment: lst-ctn-kix_list_6-6;
}

ol.lst-kix_list_13-2 {
  list-style-type: none;
}

.lst-kix_list_7-3>li:before {
  content: ""counter(lst-ctn-kix_list_7-3, decimal) ". ";
}

ol.lst-kix_list_13-3 {
  list-style-type: none;
}

.lst-kix_list_10-0>li:before {
  content: ""counter(lst-ctn-kix_list_10-0, decimal) ". ";
}

.lst-kix_list_9-7>li {
  counter-increment: lst-ctn-kix_list_9-7;
}

.lst-kix_list_13-6>li {
  counter-increment: lst-ctn-kix_list_13-6;
}

.lst-kix_list_13-8>li:before {
  content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
}

ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0;
}

.lst-kix_list_8-7>li {
  counter-increment: lst-ctn-kix_list_8-7;
}

ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

.lst-kix_list_7-7>li:before {
  content: ""counter(lst-ctn-kix_list_7-7, decimal) ". ";
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.lst-kix_list_15-4>li:before {
  content: " ";
}

.lst-kix_list_9-5>li {
  counter-increment: lst-ctn-kix_list_9-5;
}

.lst-kix_list_10-4>li:before {
  content: ""counter(lst-ctn-kix_list_10-4, decimal) ". ";
}

.lst-kix_list_10-8>li:before {
  content: ""counter(lst-ctn-kix_list_10-8, decimal) ". ";
}

.lst-kix_list_4-0>li:before {
  content: ""counter(lst-ctn-kix_list_4-0, decimal) ". ";
}

ul.lst-kix_list_15-3 {
  list-style-type: none;
}

ul.lst-kix_list_15-2 {
  list-style-type: none;
}

.lst-kix_list_15-0>li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_15-1 {
  list-style-type: none;
}

.lst-kix_list_15-8>li:before {
  content: " ";
}

ul.lst-kix_list_15-0 {
  list-style-type: none;
}

ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0;
}

.lst-kix_list_4-4>li:before {
  content: ""counter(lst-ctn-kix_list_4-4, decimal) ". ";
}

ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

ul.lst-kix_list_15-8 {
  list-style-type: none;
}

ul.lst-kix_list_15-7 {
  list-style-type: none;
}

ul.lst-kix_list_15-6 {
  list-style-type: none;
}

.lst-kix_list_9-3>li:before {
  content: ""counter(lst-ctn-kix_list_9-3, decimal) ". ";
}

ul.lst-kix_list_15-5 {
  list-style-type: none;
}

ul.lst-kix_list_15-4 {
  list-style-type: none;
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 2021298503;
}

.lst-kix_list_12-8>li {
  counter-increment: lst-ctn-kix_list_12-8;
}

ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}

ol.lst-kix_list_4-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_4-2 {
  list-style-type: none;
}

ol.lst-kix_list_4-3 {
  list-style-type: none;
}

ol.lst-kix_list_14-7 {
  list-style-type: none;
}

ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0;
}

ol.lst-kix_list_14-8 {
  list-style-type: none;
}

.lst-kix_list_9-7>li:before {
  content: ""counter(lst-ctn-kix_list_9-7, decimal) ". ";
}

.lst-kix_list_2-4>li {
  counter-increment: lst-ctn-kix_list_2-4;
}

ol.lst-kix_list_14-3 {
  list-style-type: none;
}

ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

ol.lst-kix_list_14-4 {
  list-style-type: none;
}

ol.lst-kix_list_14-5 {
  list-style-type: none;
}

ol.lst-kix_list_14-6 {
  list-style-type: none;
}

.lst-kix_list_11-4>li:before {
  content: ""counter(lst-ctn-kix_list_11-4, decimal) ". ";
}

ol.lst-kix_list_14-0 {
  list-style-type: none;
}

.lst-kix_list_12-4>li:before {
  content: ""counter(lst-ctn-kix_list_12-4, decimal) ". ";
}

ol.lst-kix_list_14-1 {
  list-style-type: none;
}

ol.lst-kix_list_14-2 {
  list-style-type: none;
}

ol.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_7-4>li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.lst-kix_list_1-0>li:before {
  content: ""counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

ol.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_11-8>li:before {
  content: ""counter(lst-ctn-kix_list_11-8, decimal) ". ";
}

.lst-kix_list_12-3>li {
  counter-increment: lst-ctn-kix_list_12-3;
}

ol.lst-kix_list_4-5 {
  list-style-type: none;
}

ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 2021298119;
}

ol.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_12-0>li:before {
  content: ""counter(lst-ctn-kix_list_12-0, decimal) ". ";
}

ol.lst-kix_list_4-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.lst-kix_list_1-4>li:before {
  content: ""counter(lst-ctn-kix_list_1-4, decimal) ". ";
}

.lst-kix_list_13-0>li:before {
  content: ""counter(lst-ctn-kix_list_13-0, decimal) ". ";
}

ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

.lst-kix_list_1-6>li {
  counter-increment: lst-ctn-kix_list_1-6;
}

ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}

.lst-kix_list_14-4>li {
  counter-increment: lst-ctn-kix_list_14-4;
}

.lst-kix_list_13-4>li:before {
  content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
}

.lst-kix_list_10-7>li {
  counter-increment: lst-ctn-kix_list_10-7;
}

.lst-kix_list_2-0>li:before {
  content: ""counter(lst-ctn-kix_list_2-0, decimal) ". ";
}

ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

.lst-kix_list_11-5>li {
  counter-increment: lst-ctn-kix_list_11-5;
}

.lst-kix_list_4-5>li {
  counter-increment: lst-ctn-kix_list_4-5;
}

ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0;
}

ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}

.lst-kix_list_1-8>li:before {
  content: ""counter(lst-ctn-kix_list_1-8, decimal) ". ";
}

.lst-kix_list_12-8>li:before {
  content: ""counter(lst-ctn-kix_list_12-8, decimal) ". ";
}

.lst-kix_list_8-2>li {
  counter-increment: lst-ctn-kix_list_8-2;
}

.lst-kix_list_4-1>li {
  counter-increment: lst-ctn-kix_list_4-1;
}

ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}

.lst-kix_list_8-1>li {
  counter-increment: lst-ctn-kix_list_8-1;
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}

.lst-kix_list_7-0>li {
  counter-increment: lst-ctn-kix_list_7-0;
}

.lst-kix_list_11-0>li {
  counter-increment: lst-ctn-kix_list_11-0;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}

.lst-kix_list_2-3>li {
  counter-increment: lst-ctn-kix_list_2-3;
}

ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}

.lst-kix_list_1-2>li {
  counter-increment: lst-ctn-kix_list_1-2;
}

ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0;
}

ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0;
}

.lst-kix_list_9-2>li {
  counter-increment: lst-ctn-kix_list_9-2;
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.lst-kix_list_13-2>li {
  counter-increment: lst-ctn-kix_list_13-2;
}

.lst-kix_list_14-3>li {
  counter-increment: lst-ctn-kix_list_14-3;
}

.lst-kix_list_10-3>li {
  counter-increment: lst-ctn-kix_list_10-3;
}

.lst-kix_list_12-1>li {
  counter-increment: lst-ctn-kix_list_12-1;
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}

.lst-kix_list_3-0>li {
  counter-increment: lst-ctn-kix_list_3-0;
}

ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 2021298311;
}

ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0;
}

ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0;
}

ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-7>li:before {
  content: ""counter(lst-ctn-kix_list_2-7, decimal) ". ";
}

.lst-kix_list_2-7>li {
  counter-increment: lst-ctn-kix_list_2-7;
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-5>li:before {
  content: ""counter(lst-ctn-kix_list_2-5, decimal) ". ";
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}

ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}

.lst-kix_list_14-6>li {
  counter-increment: lst-ctn-kix_list_14-6;
}

.lst-kix_list_10-1>li:before {
  content: ""counter(lst-ctn-kix_list_10-1, decimal) ". ";
}

.lst-kix_list_7-7>li {
  counter-increment: lst-ctn-kix_list_7-7;
}

ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

.lst-kix_list_10-7>li:before {
  content: ""counter(lst-ctn-kix_list_10-7, decimal) ". ";
}

.lst-kix_list_7-8>li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.lst-kix_list_10-5>li:before {
  content: ""counter(lst-ctn-kix_list_10-5, decimal) ". ";
}

ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}

.lst-kix_list_10-3>li:before {
  content: ""counter(lst-ctn-kix_list_10-3, decimal) ". ";
}

.lst-kix_list_2-6>li {
  counter-increment: lst-ctn-kix_list_2-6;
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}

.lst-kix_list_11-7>li {
  counter-increment: lst-ctn-kix_list_11-7;
}

.lst-kix_list_9-2>li:before {
  content: ""counter(lst-ctn-kix_list_9-2, decimal) ". ";
}

ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0;
}

.lst-kix_list_12-5>li {
  counter-increment: lst-ctn-kix_list_12-5;
}

.lst-kix_list_9-0>li:before {
  content: ""counter(lst-ctn-kix_list_9-0, decimal) ". ";
}

.lst-kix_list_3-4>li {
  counter-increment: lst-ctn-kix_list_3-4;
}

ol.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_9-6>li:before {
  content: ""counter(lst-ctn-kix_list_9-6, decimal) ". ";
}

ol.lst-kix_list_10-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_9-4>li:before {
  content: ""counter(lst-ctn-kix_list_9-4, decimal) ". ";
}

ol.lst-kix_list_10-4 {
  list-style-type: none;
}

ol.lst-kix_list_10-5 {
  list-style-type: none;
}

.lst-kix_list_11-3>li:before {
  content: ""counter(lst-ctn-kix_list_11-3, decimal) ". ";
}

ol.lst-kix_list_10-6 {
  list-style-type: none;
}

.lst-kix_list_6-3>li {
  counter-increment: lst-ctn-kix_list_6-3;
}

ol.lst-kix_list_10-0 {
  list-style-type: none;
}

ol.lst-kix_list_10-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ol.lst-kix_list_10-2 {
  list-style-type: none;
}

ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.lst-kix_list_11-5>li:before {
  content: ""counter(lst-ctn-kix_list_11-5, decimal) ". ";
}

ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}

.lst-kix_list_9-8>li:before {
  content: ""counter(lst-ctn-kix_list_9-8, decimal) ". ";
}

.lst-kix_list_1-1>li:before {
  content: ""counter(lst-ctn-kix_list_1-1, decimal) ". ";
}

.lst-kix_list_11-7>li:before {
  content: ""counter(lst-ctn-kix_list_11-7, decimal) ". ";
}

.lst-kix_list_8-5>li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.lst-kix_list_1-3>li:before {
  content: ""counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}

.lst-kix_list_4-8>li {
  counter-increment: lst-ctn-kix_list_4-8;
}

.lst-kix_list_1-7>li:before {
  content: ""counter(lst-ctn-kix_list_1-7, decimal) ". ";
}

ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

.lst-kix_list_1-5>li:before {
  content: ""counter(lst-ctn-kix_list_1-5, decimal) ". ";
}

ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}

.lst-kix_list_14-7>li {
  counter-increment: lst-ctn-kix_list_14-7;
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.lst-kix_list_2-1>li:before {
  content: ""counter(lst-ctn-kix_list_2-1, decimal) ". ";
}

.lst-kix_list_2-3>li:before {
  content: ""counter(lst-ctn-kix_list_2-3, decimal) ". ";
}

.lst-kix_list_11-8>li {
  counter-increment: lst-ctn-kix_list_11-8;
}

.lst-kix_list_3-1>li {
  counter-increment: lst-ctn-kix_list_3-1;
}

.lst-kix_list_9-1>li {
  counter-increment: lst-ctn-kix_list_9-1;
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

.lst-kix_list_3-1>li:before {
  content: ""counter(lst-ctn-kix_list_3-1, decimal) ". ";
}

.lst-kix_list_3-2>li:before {
  content: ""counter(lst-ctn-kix_list_3-2, decimal) ". ";
}

.lst-kix_list_14-0>li {
  counter-increment: lst-ctn-kix_list_14-0;
}

.lst-kix_list_8-1>li:before {
  content: ""counter(lst-ctn-kix_list_8-1, decimal) ". ";
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.lst-kix_list_8-2>li:before {
  content: ""counter(lst-ctn-kix_list_8-2, decimal) ". ";
}

.lst-kix_list_6-0>li {
  counter-increment: lst-ctn-kix_list_6-0;
}

.lst-kix_list_3-5>li:before {
  content: ""counter(lst-ctn-kix_list_3-5, decimal) ". ";
}

.lst-kix_list_12-0>li {
  counter-increment: lst-ctn-kix_list_12-0;
}

ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}

ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0;
}

.lst-kix_list_8-5>li:before {
  content: ""counter(lst-ctn-kix_list_8-5, decimal) ". ";
}

.lst-kix_list_11-1>li {
  counter-increment: lst-ctn-kix_list_11-1;
}

.lst-kix_list_8-6>li:before {
  content: ""counter(lst-ctn-kix_list_8-6, decimal) ". ";
}

.lst-kix_list_2-0>li {
  counter-increment: lst-ctn-kix_list_2-0;
}

.lst-kix_list_3-6>li:before {
  content: ""counter(lst-ctn-kix_list_3-6, decimal) ". ";
}

.lst-kix_list_11-2>li:before {
  content: ""counter(lst-ctn-kix_list_11-2, decimal) ". ";
}

ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}

ol.lst-kix_list_16-0.start {
  counter-reset: lst-ctn-kix_list_16-0 0;
}

ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0;
}

ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}

.lst-kix_list_16-6>li:before {
  content: " ";
}

ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}

.lst-kix_list_4-4>li {
  counter-increment: lst-ctn-kix_list_4-4;
}

ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}

.lst-kix_list_17-1>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_16-1>li:before {
  content: " ";
}

ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}

.lst-kix_list_7-3>li {
  counter-increment: lst-ctn-kix_list_7-3;
}

.lst-kix_list_16-2>li:before {
  content: " ";
}

.lst-kix_list_16-5>li:before {
  content: " ";
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

.lst-kix_list_12-4>li {
  counter-increment: lst-ctn-kix_list_12-4;
}

ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 2021298887;
}

.lst-kix_list_3-3>li {
  counter-increment: lst-ctn-kix_list_3-3;
}

ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}

ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}

.lst-kix_list_12-7>li {
  counter-increment: lst-ctn-kix_list_12-7;
}

.lst-kix_list_17-2>li:before {
  content: "o  ";
}

.lst-kix_list_17-6>li:before {
  content: "o  ";
}

.lst-kix_list_17-5>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-2>li {
  counter-increment: lst-ctn-kix_list_6-2;
}

.lst-kix_list_2-6>li:before {
  content: ""counter(lst-ctn-kix_list_2-6, decimal) ". ";
}

.lst-kix_list_14-5>li {
  counter-increment: lst-ctn-kix_list_14-5;
}

.lst-kix_list_7-1>li:before {
  content: ""counter(lst-ctn-kix_list_7-1, decimal) ". ";
}

.lst-kix_list_7-5>li:before {
  content: ""counter(lst-ctn-kix_list_7-5, decimal) ". ";
}

.lst-kix_list_13-5>li {
  counter-increment: lst-ctn-kix_list_13-5;
}

.lst-kix_list_9-6>li {
  counter-increment: lst-ctn-kix_list_9-6;
}

ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}

.lst-kix_list_13-6>li:before {
  content: ""counter(lst-ctn-kix_list_13-6, decimal) ". ";
}

.lst-kix_list_6-7>li {
  counter-increment: lst-ctn-kix_list_6-7;
}

.lst-kix_list_10-6>li {
  counter-increment: lst-ctn-kix_list_10-6;
}

.lst-kix_list_11-6>li {
  counter-increment: lst-ctn-kix_list_11-6;
}

.lst-kix_list_1-7>li {
  counter-increment: lst-ctn-kix_list_1-7;
}

ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 2021298791;
}

.lst-kix_list_7-5>li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.lst-kix_list_15-6>li:before {
  content: " ";
}

.lst-kix_list_11-4>li {
  counter-increment: lst-ctn-kix_list_11-4;
}

.lst-kix_list_3-8>li {
  counter-increment: lst-ctn-kix_list_3-8;
}

ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}

.lst-kix_list_10-2>li:before {
  content: ""counter(lst-ctn-kix_list_10-2, decimal) ". ";
}

.lst-kix_list_4-6>li {
  counter-increment: lst-ctn-kix_list_4-6;
}

.lst-kix_list_13-7>li {
  counter-increment: lst-ctn-kix_list_13-7;
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_1-5>li {
  counter-increment: lst-ctn-kix_list_1-5;
}

ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}

.lst-kix_list_4-2>li:before {
  content: ""counter(lst-ctn-kix_list_4-2, decimal) ". ";
}

.lst-kix_list_4-6>li:before {
  content: ""counter(lst-ctn-kix_list_4-6, decimal) ". ";
}

.lst-kix_list_15-2>li:before {
  content: " ";
}

.lst-kix_list_10-8>li {
  counter-increment: lst-ctn-kix_list_10-8;
}

.lst-kix_list_10-6>li:before {
  content: ""counter(lst-ctn-kix_list_10-6, decimal) ". ";
}

.lst-kix_list_9-1>li:before {
  content: ""counter(lst-ctn-kix_list_9-1, decimal) ". ";
}

ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}

ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}

.lst-kix_list_12-2>li {
  counter-increment: lst-ctn-kix_list_12-2;
}

.lst-kix_list_9-5>li:before {
  content: ""counter(lst-ctn-kix_list_9-5, decimal) ". ";
}

.lst-kix_list_12-2>li:before {
  content: ""counter(lst-ctn-kix_list_12-2, decimal) ". ";
}

ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}

.lst-kix_list_11-6>li:before {
  content: ""counter(lst-ctn-kix_list_11-6, decimal) ". ";
}

ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}

.lst-kix_list_1-2>li:before {
  content: ""counter(lst-ctn-kix_list_1-2, decimal) ". ";
}

.lst-kix_list_10-1>li {
  counter-increment: lst-ctn-kix_list_10-1;
}

.lst-kix_list_1-0>li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_8-8>li {
  counter-increment: lst-ctn-kix_list_8-8;
}

.lst-kix_list_1-6>li:before {
  content: ""counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

.lst-kix_list_12-6>li:before {
  content: ""counter(lst-ctn-kix_list_12-6, decimal) ". ";
}

ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0;
}

.lst-kix_list_2-2>li:before {
  content: ""counter(lst-ctn-kix_list_2-2, decimal) ". ";
}

.lst-kix_list_13-2>li:before {
  content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c11 {
  border-right-style: solid;
  padding: 0pt 4.8pt 0pt 4.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 383.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c22 {
  border-right-style: solid;
  padding: 0pt 4.8pt 0pt 4.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 93.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c21 {
  border-right-style: solid;
  padding: 0.8pt 0.8pt 0.8pt 0.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 93.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c19 {
  border-right-style: solid;
  padding: 0.8pt 0.8pt 0.8pt 0.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 383.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c0 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c24 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: justify;
}

.c3 {
  vertical-align: baseline;
  font-size: 12pt;
  font-weight: normal;
}

.c15 {
  margin-left: -4.8pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c6 {
  orphans: 2;
  text-indent: 36pt;
  widows: 2;
  text-align: justify;
}

.c2 {
  font-size: 12pt;
  font-weight: bold;
}

.c5 {
  color: #000000;
  text-decoration: none;
  font-style: normal;
}

.c18 {
  text-indent: 72pt;
  text-align: justify;
}

.c17 {
  padding: 0;
  margin: 0;
}

.c1 {
  color: #000000;
  vertical-align: baseline;
}

.c8 {
  orphans: 2;
  widows: 2;
}

.c16 {
  height: 0pt;
}

.c10 {
  text-decoration: underline;
}

.c23 {
  text-indent: 36pt;
}

.c14 {
  text-align: center;
}

.c12 {
  color: #000000;
}

.c4 {
  vertical-align: baseline;
}

.c25 {
  padding-bottom: 12pt;
}

.c20 {
  font-weight: bold;
}

.c9 {
  height: 12pt;
}

/*COLORS*/
/*FONTS AND SIZES*/